import { ToDoList as textLang } from 'staticData/languages';

/**
 * Function to determine the completion status of a user's profile.
 *
 * This function calculates the completion percentage based on several criteria
 * and determines if the user can run a real bot based on these criteria.
 *
 * @param {Object} userInfos - The information of the user.
 * @param {Object} [progressMapAction=null] - Object containing actions for each field (optional).
 *
 * @returns {Object} An object containing:
 * - {boolean} completed - Whether the profile is 100% completed.
 * - {number} level - The completion percentage.
 * - {boolean} canRunRealBot - Whether the user can run a real bot.
 * - {Object} exchange - Status and action for the exchange field.
 * - {Object} kyc - Status and action for the kyc field.
 * - {Object} emailConfirmed - Status and action for the emailConfirmed field.
 * - {Object} proTier - Status and action for the proTier field.
 * - {Object} realBotStarted - Status and action for the realBotStarted field.
 */
export const completionStatus = (userInfos, progressMapAction = null) => {
  let criteriaMet = 0;

  // Create an object to store the status and action for each field
  const status = {
    exchange: {
      value:
        Array.isArray(userInfos.connected_exchanges) &&
        userInfos.connected_exchanges.length > 0,
      action: progressMapAction?.exchange || null
    },
    kyc: {
      value:
        userInfos.kyc_status === 'approved' ||
        userInfos.kyc_status === 'contact_support' ||
        userInfos.kyc_status === 'processing',
      action: progressMapAction?.kyc || null
    },
    emailConfirmed: {
      value: userInfos.email_confirmed,
      action: progressMapAction?.emailConfirmed || null
    },
    ...(userInfos.isInCluster
      ? {
          privateSub: {
            value: userInfos.pro_tier >= 5,
            action: progressMapAction?.privateSub || null
          }
        }
      : {
          realBotStarted: {
            value:
              userInfos.real_bot_started || userInfos.real_portfolio_started,
            action: progressMapAction?.realBotStarted || null
          }
        })
  };
  // Check each field status and count criteria met
  for (let key in status) {
    if (status[key].value) criteriaMet++;
  }

  // Calculate the completion percentage
  const totalCriteria = Object.keys(status).length;
  const completionPercentage = (criteriaMet / totalCriteria) * 100;

  // Determine if all criteria (excluding firstBotRun) are met
  const canRunRealBot = ['exchange', 'kyc', 'emailConfirmed'].every(
    field => status[field].value
  );

  // Return an object containing both the completion percentage and each field status with associated actions
  return {
    completed: completionPercentage >= 100,
    level: completionPercentage, // Include the percentage of completion
    completedNumber: criteriaMet, // Include the number of criteria met
    canRunRealBot,
    ...status // Spread the status object to include each field status with associated actions
  };
};

/**
 * To add another value to check in the status:
 * 1. Add a new field in the `status` object, following the current structure.
 * 2. Make sure to update the criteria met check and percentage calculation if necessary.
 * 3. If the new criterion should affect the `canRunRealBot` evaluation, include it in the array passed to `every`.
 * 4. If the new criterion is shown in onboard update also todoList and progressMapAction
 */

export const getTodoList = (userInfos, lang) => {
  const todoList = [];

  // Aggiungi elementi predefiniti all'array
  todoList.push(
    {
      id: 'emailConfirmed',
      task: textLang.mailTask[lang],
      description: textLang.mailDescr[lang],
      color: 'primary',
      time: '1 min'
    },
    {
      id: 'kyc',
      task: textLang.kycTask[lang],
      description: textLang.kycDescr[lang],
      customColor: '#ffc107',
      time: '2 min'
    },
    {
      id: 'exchange',
      task: textLang.exchangeTask[lang],
      description: textLang.exchangeDescr[lang],
      color: 'success',
      time: '1 min'
    }
  );

  // Aggiungi gli elementi in base al valore di isInCluster
  if (userInfos.isInCluster) {
    todoList.push({
      id: 'privateSub',
      task: textLang.proTierTask[lang],
      description: textLang.proTierDescr[lang],
      color: 'warning',
      time: '5 min'
    });
  } else {
    todoList.push({
      id: 'realBotStarted',
      task:
        textLang.lastStepTask[lang] +
        (userInfos.trial_allowed
          ? textLang.lastStepTaskTrialAllowed[lang]
          : textLang.lastStepTaskTrialNOTAllowed[lang]),
      description:
        textLang.lastStepDescr[lang] +
        (userInfos.trial_allowed
          ? textLang.lastStepDescrTrialAllowed[lang]
          : '.'),
      color: 'warning',
      time: '5 min'
    });
  }

  return todoList;
};
