import { breakpoints } from 'helpers/utils';

// START levels
export const devLevel = 1; // use to access to dev
export const userMinLevel = 1; // use to fix the user min level
export const firstProTier = 1; // First pro tier that allows real mode
export const secondProTier = 2; // Second level of tier
export const traderProTier = 5; // First pro tier that allows to be trader
export const traderClusterID = 1; // First pro tier that allows to be trader
export const teamLevel = 99; // Our min Level -> used to check new feature
export const FabesLevel = 100; // Fabes Level -> used to check new feature and easter eggs
// END levels

export const markets = [
  'BTCUSDT',
  'ETHUSDT',
  'BNBUSDT',
  'SOLUSDT',
  'XRPUSDT',
  'DOTUSDT',
  'BTCTUSD',
  'ETHTUSD',
  'BNBTUSD',
  'BTCFDUSD'
];

export const goldColor = '#FAC70F';
export const blueColor = '#1E90FF';

export const coinColor = {
  BTC: '#f2a900',
  ETH: '#D1D4E0',
  BNB: goldColor,
  SOL: '#9945FF',
  USDT: '#26A17B',
  TUSD: '#3B5BFF',
  FDUSD: '#3B5BFF'
};

export const maximumFee = 0.1; // Default maximum fee value, to be overrided getting it from backend according to botType
export const initialAmount = 1000; // Default initial amount for backtesting

// Object for mapping asset/quote for each allowed coin
export const coinAssetQuoteMap = {
  BTCUSDT: { asset: 'BTC', quote: 'USDT' },
  ETHUSDT: { asset: 'ETH', quote: 'USDT' },
  BNBUSDT: { asset: 'BNB', quote: 'USDT' },
  BTCTUSD: { asset: 'BTC', quote: 'TUSD' },
  ETHTUSD: { asset: 'ETH', quote: 'TUSD' },
  BNBTUSD: { asset: 'BNB', quote: 'TUSD' },
  SOLUSDT: { asset: 'SOL', quote: 'USDT' },
  ADAUSDT: { asset: 'ADA', quote: 'USDT' },
  XRPUSDT: { asset: 'XRP', quote: 'USDT' },
  DOTUSDT: { asset: 'DOT', quote: 'USDT' },
  BTCFDUSD: { asset: 'BTC', quote: 'FDUSD' }
};

// Object for mapping each coin/token with its name
export const coinNameMap = {
  BTC: 'Bitcoin',
  ETH: 'Ethereum',
  BNB: 'Binance Coin',
  USDT: 'Tether',
  TUSD: 'TrueUSD',
  SOL: 'Solana',
  XRP: 'Ripple',
  ADA: 'Cardano',
  DOT: 'Polkadot',
  DOGE: 'Dogecoin',
  MATIC: 'Polygon',
  LTC: 'Litecoin',
  LINK: 'Chainlink',
  AVAX: 'Avalanche',
  UNI: 'Uniswap',
  SHIB: 'Shibainu',
  FDUSD: 'FirstDigitalUSD'
};

export const coinDecimalPrecision = {
  BTC: 8,
  ETH: 8,
  BNB: 8,
  SOL: 8,
  USDT: 2,
  TUSD: 2,
  FDUSD: 2,
  VIRTUAL: 2
};

// START img import
import binanceImg from 'assets/img/hodlie/exchange/binance.png';
import hodlieIMG from 'assets/img/hodlie/exchange/hodlie.png';
import BtcImg from 'assets/img/hodlie/coin/bitcoin-btc-logo.png';
import EthImg from 'assets/img/hodlie/coin/ethereum-eth-logo.png';
import BNBImg from 'assets/img/hodlie/coin/BNB.png';
import tetherImg from 'assets/img/hodlie/coin/tether-usdt-logo.png';
import tusdImg from 'assets/img/hodlie/coin/tusd.png';
import fdusdImg from 'assets/img/hodlie/coin/fdusd.png';
import polkadotImg from 'assets/img/hodlie/coin/polkadot-logo.png';
import solanaImg from 'assets/img/hodlie/coin/solana.png';
import cardanoImg from 'assets/img/hodlie/coin/cardano.png';
import rippleImg from 'assets/img/hodlie/coin/ripple.png';
import usdcImg from 'assets/img/hodlie/coin/usd-coin-usdc-logo.png';

export const exchangeImgMap = {
  Binance: binanceImg,
  Virtual: hodlieIMG
};
// Object for mapping each coin/token with its logo
export const coinImgMap = {
  BTC: BtcImg,
  ETH: EthImg,
  BNB: BNBImg,
  USDT: tetherImg,
  TUSD: tusdImg,
  SOL: solanaImg,
  ADA: cardanoImg,
  XRP: rippleImg,
  DOT: polkadotImg,
  FDUSD: fdusdImg,
  USDC: usdcImg
};
// END img import

export const botListOrdered = ['iBot30', 'iBot60', 'takeProfitBot60'];
export const botListInfo = [
  'takeProfitBot30',
  'iBot60',
  'iBot30',
  'takeProfitBot60'
];

export const botGeneralInfo = {
  iBot30: {
    // Dynamo
    name: 'Dynamo',
    id: 2,
    min: 500,
    max: 10000,
    volume: { vol: 15, freq: 'month', barValue: 30 },
    standardFee: 0.1,
    availableCoins: {
      BTC: { minLevel: userMinLevel },
      SOL: {
        minLevel: userMinLevel,
        realMinTier: userMinLevel
      }
    },
    availableQuotes: { USDT: { minLevel: userMinLevel } },
    parameters: {
      risk_level: {
        type: 'slider',
        default: 2,
        max: 2,
        min: 1,
        name: 'Risk Level'
      }
    },
    maximumAllowedFee: 0.1
  },
  iBot60: {
    // Grizzly
    name: 'Grizzly',
    id: 4,
    min: 500,
    max: 10000,
    volume: { vol: 5, freq: 'month', barValue: 10 },
    standardFee: 0.1,
    availableCoins: {
      BTC: { minLevel: userMinLevel },
      ETH: { minLevel: userMinLevel }
    },
    availableQuotes: { USDT: { minLevel: userMinLevel } },
    parameters: {
      risk_level: {
        type: 'slider',
        default: 2,
        max: 2,
        min: 1,
        name: 'Risk Level'
      }
    },
    maximumAllowedFee: 0.1
  },
  takeProfitBot30: {
    // Deep Scalper
    name: 'Deep Scalper',
    id: 3,
    min: 500,
    max: 5000,
    volume: { vol: 40, freq: 'day', barValue: 95 },
    standardFee: 0,
    availableCoins: { BTC: { minLevel: userMinLevel } },
    availableQuotes: { FDUSD: { minLevel: userMinLevel } },
    parameters: {
      risk_level: {
        type: 'slider',
        default: 1,
        max: 1,
        min: 1,
        name: 'Risk Level'
      }
    },
    maximumAllowedFee: 0
  },
  takeProfitBot60: {
    // Centurion
    name: 'Centurion',
    id: 5,
    min: 500,
    max: 1000,
    volume: { vol: 5, freq: 'month', barValue: 10 },
    standardFee: 0.1,
    availableCoins: { BTC: { minLevel: userMinLevel } },
    availableQuotes: { USDT: { minLevel: userMinLevel } },
    parameters: {
      risk_level: {
        type: 'slider',
        default: 1,
        max: 1,
        min: 1,
        name: 'Risk Level'
      }
    },
    maximumAllowedFee: 0.1
  },
  6: {
    id: 6
  },
  7: {
    id: 7
  },
  8: {
    id: 8
  }
};

// the last item of this is saved in db to check the current frontend version of user
// add a value in array to show the newRelease modal in dashboard
export const frontendVersions = [
  'multiLang',
  'preRelease',
  'addETH',
  'sentimentSinglePage',
  'proPlus',
  'addCapital',
  'SOLforPRO'
];

// incredible variable -> MIO PADRE
export const maintenanceVariable = 'fattoilmisfatto';

// This function was created to avoid rounding of the number!
Number.prototype.toFixedDown = function (digits) {
  var multiplier = Math.pow(10, digits);
  var result;

  if (this >= 0) {
    result = Math.floor(this * multiplier) / multiplier;
  } else {
    result = Math.ceil(this * multiplier) / multiplier;
  }

  return parseFloat(result.toFixed(digits)); // This ensure no exponential notation
};

export const walletNames = ['virtual', 'binance'];

export const orderObjByDateKey = wallet => {
  const orderedKeys = Object.keys(wallet).sort((a, b) => {
    const A = new Date(a);
    const B = new Date(b);
    return A - B;
  });
  const orderedObject = orderedKeys.map(key => ({
    ts: key,
    ...wallet[key]
  }));
  return orderedObject;
};

export const ISOFormatDateFromSeconds = timestamp => {
  // Create a Date object with the timestamp
  const date = new Date(timestamp * 1000); // Multiply by 1000 to convert from seconds to milliseconds

  // Get the necessary parts of the date
  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // January is 0, so add 1
  const day = date.getUTCDate().toString().padStart(2, '0');
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  const seconds = date.getUTCSeconds().toString().padStart(2, '0');

  // Build the string in the desired format
  const isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+00:00`;
  return isoString;
};

// Get right screen breakpoint from screen width (use window.innerWidth)
export const getBreakpoint = width => {
  const breakpointsKeys = Object.keys(breakpoints);
  const breakpointsLen = breakpointsKeys.length;
  var returnSize = 'xs';
  Object.entries(breakpoints).map(([size, pixels], index) => {
    if (
      width >= pixels &&
      (breakpointsLen === index + 1 ||
        width < breakpoints[breakpointsKeys[index + 1]])
    )
      returnSize = size;
  });
  return returnSize;
};

// START sharpe ratio
// use this const to set in coming soon the sharpe variable (in dashboard and botDetails)
export const sharpeComingSoon = false;

// Use this to see the correct sharpe ratio
export const UnlockSharpe = (
  localSharpe,
  localCoinSharpe,
  setShowSharpe,
  setShowCoinSharpe
) => {
  if (localSharpe === null) {
    setShowSharpe(false);
  } else if (localSharpe >= 1) {
    setShowSharpe(true);
  } else if (localSharpe < 1 && localSharpe > 0) {
    setShowSharpe(true);
    if (localSharpe > localCoinSharpe) setShowCoinSharpe(true);
  } else if (localSharpe <= 0) {
    setShowSharpe(false);
  } else {
    setShowSharpe(false);
  }
};
// END sharpe ratio

// if true see dev as prod
export const showEnvAsProduction = false;

export const getCleanBigNumber = value => {
  var auxArray = [
    { number: 1000, abbr: 'K' },
    { number: 1000000, abbr: 'M' },
    { number: 1000000000, abbr: 'B' }
  ];
  let result = Number(value).toFixed(2);
  auxArray.map(d => {
    if (value >= d.number)
      result = Math.round((100 * value) / d.number) / 100 + d.abbr;
  });
  return result;
};

// USED in navbar bottom and vertical to hide route with permissions
export const canShowRoute = (permissionName, userInfos) => {
  const now = new Date();
  if (permissionName === 'sentiment')
    return (
      Boolean(userInfos?.permissions?.insights) &&
      userInfos.permissions.insights.includes('sentiment')
    );
  if (permissionName === 'virtual')
    return (
      Boolean(userInfos?.permissions?.bot_type_virtual_allowed_categories) &&
      (userInfos.permissions.bot_type_virtual_allowed_categories.includes(
        'bot'
      ) ||
        userInfos.permissions.bot_type_virtual_allowed_categories.includes(
          'portfolio'
        ))
    );
  if (permissionName === 'virtual-page')
    return (
      userInfos.virtual_trial == null ||
      new Date(userInfos.virtual_trial?.end) > now ||
      userInfos.pro_tier >= firstProTier
    );
  if (permissionName === 'cluster-page') return userInfos?.cluster?.is_owner;
  if (permissionName === 'cluster-child') return !userInfos.isInCluster;
};

export const LinePaymentButtonMap = {
  '24h': '1d_timepoints',
  '7d': '7d_timepoints',
  '1m': '30d_timepoints',
  '6m': '6m_timepoints',
  All: 'all_timepoints'
};

// USED to new release for all users
export const newReleaseTime = new Date('2024-01-19T18:00:00');

export const sharedState = {
  config: null,
  backendCache: {},
  userInfos: {}
};

export const calcFinalPerformance = (
  startValue = 0,
  endValue = 0,
  eventList = [],
  currency = 'USDT'
) => {
  const addAllocationEvents = eventList?.filter(
    item => item.event_type === 'change_allocated'
  );
  let botAmount = startValue;
  let endAmount = endValue;
  if (addAllocationEvents?.length > 0) {
    botAmount = addAllocationEvents.reduce((total, item) => {
      return (
        total +
        (item.event_metadata?.[currency] ||
          item.event_metadata.eqv[currency] ||
          0)
      );
    }, botAmount);
  }
  return [
    Number((endAmount - botAmount).toFixed(2)), // final performance
    Number((((endAmount - botAmount) / botAmount) * 100).toFixed(2)), // final performance percentage
    Number(botAmount.toFixed(2)) // bot amount
  ];
};

export const saveToLocalStorage = (key, value) => {
  try {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
  } catch (error) {
    console.error('Error localStorage', error);
  }
};

export const removeFromLocalStorage = key => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error('Error removing from localStorage', error);
  }
};

export const getFromLocalStorage = key => {
  try {
    const serializedValue = localStorage.getItem(key);
    if (serializedValue === null) {
      return null;
    }
    try {
      return JSON.parse(serializedValue);
    } catch (jsonError) {
      return serializedValue;
    }
  } catch (error) {
    console.error('Error getting from localStorage', error);
    return null;
  }
};

export const isTraderCompleted = userInfos => {
  if (!userInfos) return false;

  const numberOfDays = 2;
  // get trader completed info
  const traderStartDate = new Date(userInfos?.subscription?.start_date * 1000);
  const traderEndDate = new Date(traderStartDate);
  traderEndDate.setDate(traderStartDate.getDate() + numberOfDays);

  const now = new Date();

  // final check
  const traderCompleted =
    userInfos.pro_tier === traderProTier && now > traderEndDate;

  return traderCompleted;
};

/* Manage Portfolio Data */
const convertWalletStats = (walletObj, exchange, localCurrency) => {
  const filteredAndMappedArray = Object.keys(walletObj)
    .filter(key => key !== exchange)
    .map(key => {
      const { bot_details, indicators, last_stat, snapshots, ...wallet } =
        walletObj[key];
      const added_capital = snapshots?.reduce(
        (sum, snapshot) =>
          sum + (snapshot.event_metadata.eqv[localCurrency] || 0),
        0
      );
      return {
        bot_details: bot_details,
        indicators: indicators,
        last_stat: last_stat,
        added_capital: added_capital,
        coins: bot_details.assets,
        wallet: wallet,
        snapshots: snapshots,
        isPending: bot_details.status === 'PENDING'
      };
    })
    .sort(
      (a, b) =>
        new Date(b.bot_details.created_at) - new Date(a.bot_details.created_at)
    );

  const bots = filteredAndMappedArray.filter(
    item => item.bot_details?.category === 'bot'
  );
  const portfolios = filteredAndMappedArray.filter(
    item => item.bot_details?.category === 'portfolio'
  );

  return {
    bots,
    portfolios
  };
};

export const manageBotsData = (localWalletStat, exchange, localCurrency) => {
  let localBotList = convertWalletStats(
    localWalletStat,
    exchange,
    localCurrency
  );
  return {
    localBots: localBotList.bots,
    localPortfolios: localBotList.portfolios
  };
};

export const getCurrentAndPreviousMinuteIsoStrings = () => {
  const now = new Date();
  const oneMinuteAgo = new Date(now);

  // Set current time to 00:00:00 UTC for both
  now.setUTCHours(0, 0, 0, 0);
  oneMinuteAgo.setUTCHours(0, 0, 0, 0);

  // Subtract one minute from the previous time
  oneMinuteAgo.setMinutes(oneMinuteAgo.getMinutes() - 1);

  // Convert to ISO string and remove milliseconds
  const formatToIsoString = date => date.toISOString().split('.')[0] + '+00:00';

  // Return array with one minute ago first, then now
  return [formatToIsoString(oneMinuteAgo), formatToIsoString(now)];
};

export const hideCapitalAlignement = (data, quote) => {
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const item = data[key];

      // Initialize capitalAlignment if it doesn't exist
      if (!item.capitalAlignment) {
        item.capitalAlignment = [];
      }

      // Filter the snapshots and separate those to be moved
      item?.snapshots?.forEach(snapshot => {
        if (snapshot.event_metadata.eqv[quote] <= 0) {
          // Remove the snapshot from snapshots
          item.capitalAlignment.push(snapshot);
        }
      });

      // Filter snapshots to remove those that have been moved
      item.snapshots = item.snapshots?.filter(snapshot => {
        return snapshot.event_metadata.eqv[quote] > 0;
      });
    }
  }
  return data;
};

/* END  Manage Portfolio Data */

export default [markets, goldColor];
