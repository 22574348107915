import React, { useState, useContext, useEffect } from 'react';
import {
  Col,
  Row,
  Card,
  Tooltip,
  OverlayTrigger,
  Tabs,
  Tab
} from 'react-bootstrap';
import AppContext from 'context/Context';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from 'helpers/utils';
import { endpointInterfaceV2 } from 'services/endpointInterface/endpointInterfaceV2';
import IconButton from 'components/common/IconButton';
import { ShareReferralOnSocial as shareTextLang } from 'staticData/languages';
import ClusterUsers from './Users/Users';
import ClusterInvoices from './Invoices/Invoices';
import ClusterOverview from './Overview';

const index = () => {
  const {
    // config: { lang, currency },
    userInfos
  } = useContext(AppContext);
  const [userDataLoaded, setUserDataLoaded] = useState(false);
  const [invoicesLoaded, setInvoicesLoaded] = useState(false);
  const [activeUsers, setActiveUsers] = useState([]);
  const [inactiveUsers, setInactiveUsers] = useState([]);
  const [invoices, setInvoices] = useState();
  const [usersData, setUsersData] = useState();
  const defaultTooltipText = 'Copia negli appunti';
  const linkDefaultTooltipText = 'Copia link';
  const [tooltipText, setTooltipText] = useState(defaultTooltipText);
  const [linkTooltipText, setLinkTooltipText] = useState(
    linkDefaultTooltipText
  );
  const [tabKey, setTabKey] = useState('overview');

  const copyReferral = async () => {
    navigator.clipboard.writeText(userInfos.my_referral);
    setTooltipText('Copiato');
    setTimeout(() => {
      setTooltipText(defaultTooltipText);
    }, 1500);
  };

  const copyLink = async () => {
    navigator.clipboard.writeText(
      shareTextLang.shareUrl + userInfos.my_referral
    );
    setLinkTooltipText('Copiato');
    setTimeout(() => {
      setLinkTooltipText(linkDefaultTooltipText);
    }, 1500);
  };

  const getUsers = async () => {
    let resp = await endpointInterfaceV2({
      internalEndpoint: 'clusterUsers',
      httpMethod: 'get',
      usingCredentials: true,
      showError: false
    });
    if (resp.validResponse) {
      setUsersData(resp.data);
      initUsersData(resp.data);
      setUserDataLoaded(true);
    }
  };

  const getInvoices = async () => {
    let resp = await endpointInterfaceV2({
      internalEndpoint: 'clusterInvoice',
      httpMethod: 'get',
      usingCredentials: true,
      showError: false
    });
    if (resp.validResponse) {
      setInvoicesLoaded(true);
      setInvoices(resp.data);
    }
  };

  const initUsersData = users => {
    // Divide objects in two groups
    const [withBots, withoutBots] = users.reduce(
      ([withBots, withoutBots], user) => {
        if (user.bots && user.bots.length > 0) {
          withBots.push(user);
        } else {
          withoutBots.push(user);
        }
        return [withBots, withoutBots];
      },
      [[], []] // init arrays for each group
    );
    const filteredWithBots = withBots.map(user => ({
      ...user,
      bots: user.bots.filter(bot => bot.category === 'portfolio')
    }));
    setActiveUsers(filteredWithBots);
    setInactiveUsers(withoutBots);
  };

  useEffect(() => {
    const fetchData = async () => {
      getInvoices();
      getUsers();
    };
    if (!(userDataLoaded && invoicesLoaded)) fetchData();
  }, []);

  return (
    <>
      <Row className="mb-3">
        <Col>
          <Card>
            <Card.Header>
              <h4>Cluster Dashboard</h4>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xs={12} sm={6}>
                  <Row>
                    <Col as={Flex} xs={12}>
                      <FontAwesomeIcon icon="user" className="me-2" />
                      <h6>Cluster info</h6>
                    </Col>
                    <Col
                      as={Flex}
                      xs={12}
                      lg={12}
                      justifyContent="between"
                      className="mt-2"
                    >
                      <h5>
                        {userInfos.kyc?.name
                          ? capitalize(userInfos.kyc?.name)
                          : '----'}{' '}
                        {userInfos.kyc?.surname
                          ? capitalize(userInfos.kyc?.surname)
                          : '----'}
                      </h5>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} className="my-1 d-sm-none">
                  <hr />
                </Col>
                <Col>
                  <Row>
                    <Col as={Flex} xs={12}>
                      <FontAwesomeIcon icon="gift" className="me-2" />
                      <h6>Referral Code</h6>
                    </Col>
                    <Col as={Flex} xs={12} lg={12} className="mt-2">
                      <h5 className="me-3 mb-0">{userInfos.my_referral}</h5>
                      <Flex>
                        <OverlayTrigger
                          key="left"
                          placement="left"
                          overlay={
                            <Tooltip id="copyReferral">{tooltipText}</Tooltip>
                          }
                        >
                          <div style={{ width: 'fit-content' }}>
                            <IconButton
                              style={{ padding: '0 0.35rem' }}
                              variant="falcon-default"
                              icon="clone"
                              onClick={copyReferral}
                              iconAlign="right"
                            ></IconButton>
                          </div>
                        </OverlayTrigger>
                        <OverlayTrigger
                          key="left"
                          placement="left"
                          overlay={
                            <Tooltip id="copyReferral">
                              {linkTooltipText}
                            </Tooltip>
                          }
                        >
                          <div style={{ width: 'fit-content' }}>
                            <IconButton
                              className="ms-2"
                              style={{ padding: '0 0.35rem' }}
                              variant="falcon-default"
                              icon="link"
                              onClick={copyLink}
                              iconAlign="right"
                            ></IconButton>
                          </div>
                        </OverlayTrigger>
                      </Flex>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Tabs id="controlled-tab" activeKey={tabKey} onSelect={k => setTabKey(k)}>
        <Tab eventKey="overview" title="Overview">
          <ClusterOverview
            invoices={invoices}
            activeUsers={activeUsers}
            inactiveUsers={inactiveUsers}
            dataLoaded={userDataLoaded && invoicesLoaded}
            setTabKey={setTabKey}
          />
        </Tab>
        <Tab eventKey="users" title="Utenti">
          <ClusterUsers
            activeUsers={activeUsers}
            inactiveUsers={inactiveUsers}
            dataLoaded={userDataLoaded}
          />
        </Tab>
        <Tab eventKey="invoices" title="Fatturazione">
          <ClusterInvoices
            invoices={invoices}
            usersData={usersData}
            dataLoaded={userDataLoaded && invoicesLoaded}
          />
        </Tab>
      </Tabs>
    </>
  );
};

export default index;
