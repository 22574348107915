import React, { useContext, useState, useEffect } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import AppContext from 'context/Context';
import UserProgress from 'pages/Dashboard/Onboard/UserProgress';
import { useHistory } from 'react-router-dom';
import { endpointInterfaceV2 } from 'services/endpointInterface/endpointInterfaceV2';
import { breakpoints } from 'helpers/utils';
import { Link } from 'react-router-dom';
import {
  Wallet as textLang,
  VideoCarousel as VideoCarouselLang
} from 'staticData/languages';
import { getTodoList } from 'services/onboard';
import { completionStatus } from 'services/onboard';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createMarkup from 'helpers/createMarkup';
import walletBg from 'assets/img/hodlie/wallet.png';
import Background from 'components/common/Background';
import { VideoItem } from 'pages/Dashboard/Onboard/VideoCarousel';
import BotSummary from 'pages/CreateBot/BotSummary';
import { portfolioList } from 'pages/CreateBot/staticData/portfolioList';

const Notification = ({
  notification: { title, linkText, action, type },
  isLast
}) => {
  return (
    <li
      className={classNames('alert mb-0 rounded-0 py-3 px-x1', {
        [`alert-${type}`]: type,
        'border-top': !type,
        'border-0': isLast,
        'border-x-0 border-top-0': !isLast
      })}
    >
      <Row className="flex-between-center pointerCursor" onClick={action}>
        <Col>
          <Flex>
            <FontAwesomeIcon
              icon="circle"
              className={classNames('mt-1 fs--2', {
                'text-primary': !type
              })}
            />
            <p
              className="fs--1 ps-2 mb-0"
              dangerouslySetInnerHTML={createMarkup(title)}
            ></p>
          </Flex>
        </Col>
        <Col xs="auto" className="d-flex align-items-center">
          <Link to="#!" className="alert-link fs--1 fw-medium">
            {linkText}
            <FontAwesomeIcon icon="chevron-right" className="ms-1 fs--2" />
          </Link>
        </Col>
      </Row>
    </li>
  );
};

Notification.propTypes = {
  notification: PropTypes.object.isRequired,
  isLast: PropTypes.bool
};

const NoWalletConnected = ({ botID }) => {
  const {
    config: { lang },
    userInfos
  } = useContext(AppContext);
  const [loaded, setLoaded] = useState(false);
  const [canStartTrial, setCanStartTrial] = useState(false);
  const [playSrc, setPlaySrc] = useState(null);
  const todoList = getTodoList(userInfos, lang);
  const routerHistory = useHistory();
  const queryParameters = new URLSearchParams(window.location.search);
  const exchange = queryParameters.get('exchange') || 'binance';
  const isMobile = window.innerWidth < breakpoints['sm'];
  const { name, coins, riskLevels, bgStyle } = portfolioList.find(
    bot => String(bot.id) === String(botID)
  );
  const type = Number(botID);
  const riskUrl = queryParameters.get('risk') || riskLevels[0];

  const goBack = () => {
    queryParameters.delete('wallet');
    routerHistory.push(`?${queryParameters.toString()}`);
  };

  const goVirtual = () => {
    queryParameters.set('exchange', 'virtual');
    routerHistory.push(`?${queryParameters.toString()}`);
  };

  const notifications = [
    {
      id: 1,
      title: 'Avvia una prova gratuita virtuale. Nessuna carta richiesta',
      linkText: 'Vai alla prova virtuale',
      action: goVirtual,
      type: 'success'
    }
    // {
    //   id: 2,
    //   title: 'Torna ai dettagli del portfolio',
    //   linkText: 'Indietro',
    //   action: goBack
    // }
  ];

  const videosMap = {
    kyc: {
      id: 'kyc',
      src: `https://hodlie.ai/static/kyc${isMobile ? '_small' : ''}.mp4`,
      title: VideoCarouselLang.kyc[lang],
      time: '0:30',
      poster: 'video/poster/kyc.png'
    },
    wallet: {
      id: 'binance',
      src: `https://hodlie.ai/static/binance${isMobile ? '_small' : ''}.mp4`,
      title: VideoCarouselLang.binance[lang],
      time: '0:50',
      poster: 'video/poster/binance.png'
    }
  };

  const selectedVideo =
    userInfos.connected_exchanges.length > 0
      ? videosMap.exchange
      : videosMap.kyc;

  const progressMapAction = {
    kyc: () => routerHistory.push('/'),
    exchange: () => routerHistory.push('/'),
    emailConfirmed: () => routerHistory.push('/'),
    realBotStarted: () => routerHistory.push('/')
  };

  const completionInfo = completionStatus(userInfos, progressMapAction);

  const getNextAction = info => {
    // It assumes that actionsOrder is available in the scope or passed as a parameter
    for (let key of todoList) {
      if (!info[key.id].value) {
        // Found the first unmet criterion, return its action and description
        return {
          action: info[key.id].action,
          task: key.task,
          description: key.description,
          id: key.id
        };
      }
    }
    return null;
  };

  const getVirtualTrial = async () => {
    let resp = await endpointInterfaceV2({
      internalEndpoint: 'virtual_trial',
      httpMethod: 'get',
      usingCredentials: true,
      showError: false
    });
    if (resp.validResponse) {
      if (resp.data.can_start_trial)
        setCanStartTrial(true && !userInfos.isInCluster);
    }
  };

  useEffect(async () => {
    window.scrollTo(0, 0);
    await getVirtualTrial();
    setLoaded(true);
  }, []);

  return (
    loaded && (
      <>
        <Row>
          <Col sm={8} className="h-100">
            <Card className="bg-transparent-50 overflow-hidden">
              <Card.Header className="position-relative">
                {canStartTrial && (
                  <Background
                    image={walletBg}
                    className="d-none d-md-block bg-card z-index-1 mt-3"
                    style={{
                      backgroundSize: '230px',
                      backgroundPosition: 'right bottom',
                      zIndex: '-1'
                    }}
                  />
                )}
                <div className="position-relative z-index-2">
                  <div>
                    <h3 className="text-primary mb-1">
                      {textLang.noWalletTitle[lang]}
                    </h3>
                    <p className="text-700">{textLang.noWalletText[lang]}</p>
                  </div>
                  {canStartTrial && (
                    <Flex className="pb-3 pt-4">
                      <div className="pe-3">
                        <p className="text-600 fs--1 fw-medium">
                          Wallet collegati
                        </p>
                        <h4 className="text-800 mb-0">200+</h4>
                      </div>
                      <div className="ps-3">
                        <p className="text-600 fs--1">Portfolio avviati</p>
                        <h4 className="text-800 mb-0">500+</h4>
                      </div>
                    </Flex>
                  )}
                </div>
              </Card.Header>
              {canStartTrial && (
                <Card.Body className="p-0">
                  <ul
                    className="mb-0 list-unstyled mt-3"
                    style={{ width: '-webkit-fill-available' }}
                  >
                    {notifications.map((notification, index) => (
                      <Notification
                        key={notification.id}
                        notification={notification}
                        isLast={notifications.length - 1 === index}
                      />
                    ))}
                  </ul>
                </Card.Body>
              )}
            </Card>
            {!canStartTrial && (
              <div className="mt-3">
                <UserProgress
                  info={completionInfo}
                  getNextAction={getNextAction}
                  todoList={todoList}
                  mobileVersion={true}
                />
              </div>
            )}
            <Row className="mt-3 justify-content-between d-none d-sm-flex">
              <Col xs="auto" as={Flex}>
                <Button
                  variant="secondary"
                  className="w-100 fs--1"
                  onClick={() => goBack()}
                >
                  <FontAwesomeIcon icon="chevron-left" className="me-1 fs--2" />
                  {textLang.back[lang]}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col as={Flex} justifyContent="center" className="mt-3 mt-sm-0">
            {canStartTrial ? (
              <BotSummary
                botType={type}
                assets={coins}
                exchange={exchange}
                total={null}
                botName={name}
                bgStyle={bgStyle}
                riskLevel={Number(riskUrl)}
              />
            ) : (
              <VideoItem
                data={selectedVideo}
                playSrc={playSrc}
                setPlaySrc={setPlaySrc}
                showDescription={false}
              />
            )}
          </Col>
        </Row>
        <Row className="mt-3 justify-content-between d-sm-none">
          <Col xs="auto" as={Flex}>
            <Button
              variant="secondary"
              className="w-100 fs--1"
              onClick={() => goBack()}
            >
              <FontAwesomeIcon icon="chevron-left" className="me-1 fs--2" />
              {textLang.back[lang]}
            </Button>
          </Col>
        </Row>
      </>
    )
  );
};

NoWalletConnected.propTypes = {
  botID: PropTypes.string.isRequired
};

export default NoWalletConnected;
