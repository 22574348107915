import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import * as echarts from 'echarts/core';
import { BarChart } from 'echarts/charts';
import { breakpoints, getColor } from 'helpers/utils';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { GaugeChart } from 'echarts/charts';
import { currencyMap } from 'services/coins/common';
import { calculateCoinsValue } from 'services/coins/functions';
import Flex from 'components/common/Flex';
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  GaugeChart,
  CanvasRenderer,
  LegendComponent
]);

const ClusterOverview = ({
  invoices,
  activeUsers,
  inactiveUsers,
  dataLoaded,
  setTabKey
}) => {
  const {
    config: { currency },
    coinValues
  } = useContext(AppContext);
  const [dynamicCoins, setDynamicCoins] = useState({});

  const calcTotalMetrics = () => {
    return activeUsers.reduce(
      (totals, user) => {
        const userManaged = user.bots.reduce((totalUserManaged, bot) => {
          const managed = Object.keys(dynamicCoins).length
            ? calculateCoinsValue(bot.last_stat.wallet, dynamicCoins)
            : bot.last_stat.eqv[currencyMap[currency]];

          return totalUserManaged + managed;
        }, 0);

        const userInvested = user.bots.reduce((totalUserInvested, bot) => {
          const allocationChanges = bot.allocation_changes;
          const addedCapital = (allocationChanges || []).reduce(
            (sum, item) => sum + item.metadata?.eqv[currencyMap[currency]] || 0,
            0
          );

          const invested =
            bot.initial_eqv[currencyMap[currency]] + addedCapital;

          return totalUserInvested + invested;
        }, 0);

        return {
          totalManaged: totals.totalManaged + userManaged,
          totalInvested: totals.totalInvested + userInvested
        };
      },
      { totalInvested: 0, totalManaged: 0 }
    );
  };

  const { totalInvested, totalManaged } = calcTotalMetrics();

  const getGaugeOptions = data => ({
    series: [
      {
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        radius: '80%',
        pointer: {
          show: false
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            color: getColor('primary')
          }
        },
        axisLine: {
          lineStyle: {
            width: 20,
            color: [[1, getColor('300')]],
            opacity: 0.5
          }
        },
        splitLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        data: [
          {
            value: data,
            detail: {
              offsetCenter: ['7%', '4%']
            }
          }
        ],
        detail: {
          show: false
        }
      }
    ]
  });

  const getBarOptions = () => ({
    xAxis: {
      type: 'category',
      data: [
        `Investito ${currency}${totalInvested.toFixed(2)}`,
        `Gestito ${currency}${totalManaged.toFixed(2)}`
      ],
      show: true
    },
    yAxis: {
      type: 'value',
      show: false
    },
    series: [
      {
        name: 'Values',
        type: 'bar',
        data: [totalInvested, totalManaged],
        itemStyle: {
          color: function (params) {
            return params.dataIndex === 0
              ? getColor('info')
              : getColor('primary');
          }
        }
      }
    ],
    grid: { right: '0', left: '0', bottom: '50px', top: '50px' }
  });

  const getInvoicesOptions = () => ({
    yAxis: [
      {
        data: ['in corso', 'passate'],
        axisLabel: {
          inside: true,
          color: getColor('gray-700'),
          fontWeight: 500,
          fontSize: 11,
          fontFamily: 'poppins'
        },
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        },
        z: 10
      }
    ],
    xAxis: {
      type: 'value',
      min: 0,
      max:
        Math.max(
          Object.keys(invoices?.future_invoices).length,
          invoices?.paid_invoices.length
        ) * 2,
      axisLine: {
        show: false
      },
      splitLine: {
        show: false
      },
      inverse: true,
      axisTick: {
        show: false
      },
      axisLabel: {
        show: false
      }
    },

    series: [
      {
        type: 'bar',
        showBackground: true,
        barWidth: 50,
        label: {
          show: true,
          formatter: '{c} ',
          position: 'insideLeft'
        },
        backgroundStyle: {
          color: getColor('gray-300'),
          borderRadius: 5
        },
        itemStyle: {
          color: getColor('primary'),
          borderRadius: 5
        },
        data: [
          Object.keys(invoices?.future_invoices).length,
          invoices?.paid_invoices.length
        ]
      }
    ]
    // grid: { right: '65px', left: '0', bottom: '0', top: '0' }
  });

  useEffect(() => {
    const checkCoins = () => {
      if (coinValues.current) {
        setDynamicCoins(coinValues.current);
        return true;
      }
      return false;
    };
    if (!checkCoins()) {
      const intervalCoinsData = setInterval(() => {
        if (checkCoins()) {
          clearInterval(intervalCoinsData);
        }
      }, 1000);
      return () => clearInterval(intervalCoinsData);
    }
  }, []);

  return (
    <Row>
      <Col
        {...(window.innerWidth < breakpoints['sm'] ? { xs: 12 } : {})}
        className="mb-3 pointerCursor hover-card me-0 me-sm-2"
        onClick={() => setTabKey('users')}
      >
        <h5>Performance</h5>
        <ReactEChartsCore
          echarts={echarts}
          option={getBarOptions()}
          style={{ height: '20rem' }}
        />
      </Col>
      <Col
        {...(window.innerWidth < breakpoints['sm'] ? { xs: 12 } : {})}
        className="mb-3 pointerCursor hover-card me-0 me-sm-2"
        onClick={() => setTabKey('users')}
      >
        <h5>Utenti</h5>
        <div className="position-relative py-3">
          <ReactEChartsCore
            echarts={echarts}
            option={getGaugeOptions(
              (activeUsers.length * 100) /
                (activeUsers.length + inactiveUsers.length)
            )}
            style={{ height: '20rem' }}
          />
          <div className="position-absolute top-50 start-50 translate-middle text-center">
            <p className="fs--1 mb-0 text-400 font-sans-serif fw-medium">
              <b>Utenti Attivi / Totali</b>
            </p>
            <p className="fs-6 mb-0 font-sans-serif fw-medium mt-n2 pt-2">
              {activeUsers.length}
              <small className="fs-1">
                /{activeUsers.length + inactiveUsers.length}
              </small>
            </p>
          </div>
        </div>
      </Col>
      <Col
        {...(window.innerWidth < breakpoints['sm'] ? { xs: 12 } : {})}
        as={Flex}
        alignItems="center"
        justifyContent="center"
        className="mb-3 pointerCursor hover-card"
        onClick={() => setTabKey('invoices')}
      >
        <div style={{ alignSelf: 'flex-start', marginBottom: '1rem' }}>
          <h5>Fatture</h5>
        </div>
        <div
          style={{
            width: '100%',
            height: 'fit-content'
          }}
        >
          {dataLoaded && (
            <ReactEChartsCore
              echarts={echarts}
              option={getInvoicesOptions()}
              style={{ height: '15rem' }}
            />
          )}
        </div>
      </Col>
    </Row>
  );
};

ClusterOverview.propTypes = {
  invoices: PropTypes.object,
  activeUsers: PropTypes.array,
  inactiveUsers: PropTypes.array,
  dataLoaded: PropTypes.bool,
  setTabKey: PropTypes.func
};

export default ClusterOverview;
