import React, { useState, useEffect, useContext } from 'react';
import AppContext from 'context/Context';
import { Row, Col, Alert, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { breakpoints } from 'helpers/utils';
import { trackEvent } from 'services/externals/mixpanel';
import { Banner as textLang } from 'staticData/languages';
import IconButton from 'components/common/IconButton';
import SoftBadge from 'components/common/SoftBadge';
import {
  banner_endDate as endDate,
  banner_START_dashboard
} from 'staticData/unlockTimers';
import Flex from 'components/common/Flex';

const BannerAlert = () => {
  const {
    config: { lang },
    userInfos
  } = useContext(AppContext);

  var defaultTooltipText = textLang.copy[lang];
  const [tooltipText, setTooltipText] = useState(defaultTooltipText);
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });
  const now = new Date();
  const couponCode = 'XMAS10';

  const calculateTimeRemaining = endDate => {
    const now = new Date();
    const distance = endDate - now;
    if (distance < 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    return { days, hours, minutes, seconds };
  };
  // Funzione per formattare i numeri
  const formatNumber = number => {
    return number < 10 ? `0${number}` : number.toString();
  };

  const copyCoupon = () => {
    trackEvent(`Copy ${couponCode}`, {
      user_mail: userInfos.email
    });
    navigator.clipboard.writeText(couponCode);
    setTooltipText(textLang.copied[lang]);
    setTimeout(() => {
      setTooltipText(defaultTooltipText);
    }, 1500);
  };

  useEffect(() => {
    const updateTimer = () => {
      const newTimeRemaining = calculateTimeRemaining(endDate);
      // Controlla se ci sono cambiamenti effettivi nel tempo rimanente
      if (JSON.stringify(newTimeRemaining) !== JSON.stringify(timeRemaining)) {
        setTimeRemaining(newTimeRemaining);
      }
    };
    // Aggiorna immediatamente il timer
    updateTimer();
    // Aggiorna ogni secondo
    const intervalId = setInterval(updateTimer, 1000);
    // Pulizia
    return () => clearInterval(intervalId);
  }, [endDate, timeRemaining]);

  return (
    <>
      {banner_START_dashboard && endDate > now && (
        <Row className="mb-3">
          <Col>
            <Alert variant="success" className="mb-0">
              <Row>
                <Col>
                  <h4 style={{ color: 'var(--falcon-alert-success-color)' }}>
                    Regalati uno sconto su Hodlie
                  </h4>
                  <div className="d-sm-flex align-items-center">
                    <p className="mb-0">
                      Utilizza il codice{' '}
                      <span className="d-sm-none">{couponCode}</span>
                    </p>
                    <Flex className="m-2 d-none d-sm-flex">
                      <SoftBadge
                        className=" d-flex align-items-center justify-content-center"
                        bg="success"
                      >
                        <p className="m-0 p-0" style={{ fontSize: '20px' }}>
                          {couponCode}
                        </p>
                      </SoftBadge>
                      <Flex
                        style={{
                          alignItems: 'flex-end'
                        }}
                      >
                        <OverlayTrigger
                          key="copy"
                          placement="top"
                          overlay={
                            <Tooltip id="copyCoupon">{tooltipText}</Tooltip>
                          }
                        >
                          <div
                            style={{
                              marginLeft: '5px',
                              width: 'fit-content'
                            }}
                          >
                            <IconButton
                              style={{ padding: '0 0.35rem' }}
                              variant="outline-success"
                              icon="copy"
                              onClick={() => copyCoupon(false)}
                            ></IconButton>
                          </div>
                        </OverlayTrigger>
                      </Flex>
                    </Flex>
                    <p className="mb-0">
                      per avere
                      {userInfos.pro_tier === 0
                        ? " l'abbonamento"
                        : " l'upgrade"}{' '}
                      scontato del 10%.
                    </p>
                    <Flex className="d-sm-none mt-2">
                      <SoftBadge
                        className=" d-flex align-items-center justify-content-center"
                        bg="success"
                      >
                        <p className="m-0 p-0" style={{ fontSize: '20px' }}>
                          {couponCode}
                        </p>
                      </SoftBadge>
                      <Flex
                        style={{
                          alignItems: 'flex-end'
                        }}
                      >
                        <OverlayTrigger
                          key="copy"
                          placement="top"
                          overlay={
                            <Tooltip id="copyCoupon">{tooltipText}</Tooltip>
                          }
                        >
                          <div
                            style={{
                              marginLeft: '5px',
                              width: 'fit-content'
                            }}
                          >
                            <IconButton
                              style={{ padding: '0 0.35rem' }}
                              variant="outline-success"
                              icon="copy"
                              onClick={() => copyCoupon(false)}
                            ></IconButton>
                          </div>
                        </OverlayTrigger>
                      </Flex>
                    </Flex>
                  </div>
                  <div
                    className={`align-items-center mt-2 ${
                      window.innerWidth > breakpoints['sm'] ? 'd-flex' : ''
                    }`}
                  >
                    <p className="mb-0">
                      <strong>Affrettati</strong>, la promozione termina tra:
                    </p>
                    <Row className="ms-sm-3 mt-2 mt-sm-0 justify-content-center">
                      <Col
                        xs={3}
                        className="text-center px-1"
                        style={{ width: '60px' }}
                      >
                        <p className="fs--2 mb-1">{textLang.days[lang]}</p>
                        <h4
                          className="mb-0 d-none d-sm-block"
                          style={{
                            color: 'var(--falcon-alert-success-color)'
                          }}
                        >
                          {formatNumber(timeRemaining.days)}
                        </h4>
                        <h2
                          className="mb-0 d-sm-none"
                          style={{
                            color: 'var(--falcon-alert-success-color)'
                          }}
                        >
                          {formatNumber(timeRemaining.days)}
                        </h2>
                      </Col>
                      <Col
                        xs={3}
                        className="text-center px-1"
                        style={{ width: '60px' }}
                      >
                        <p className="fs--2 mb-1">{textLang.hours[lang]}</p>
                        <h4
                          className="mb-0 d-none d-sm-block"
                          style={{
                            color: 'var(--falcon-alert-success-color)'
                          }}
                        >
                          {formatNumber(timeRemaining.hours)}
                        </h4>
                        <h2
                          className="mb-0 d-sm-none"
                          style={{
                            color: 'var(--falcon-alert-success-color)'
                          }}
                        >
                          {formatNumber(timeRemaining.hours)}
                        </h2>
                      </Col>
                      <Col
                        xs={3}
                        className="text-center px-1"
                        style={{ width: '60px' }}
                      >
                        <p className="fs--2 mb-1">{textLang.minutes[lang]}</p>
                        <h4
                          className="mb-0 d-none d-sm-block"
                          style={{
                            color: 'var(--falcon-alert-success-color)'
                          }}
                        >
                          {formatNumber(timeRemaining.minutes)}
                        </h4>
                        <h2
                          className="mb-0 d-sm-none"
                          style={{
                            color: 'var(--falcon-alert-success-color)'
                          }}
                        >
                          {formatNumber(timeRemaining.minutes)}
                        </h2>
                      </Col>
                      <Col
                        xs={3}
                        className="text-center px-1"
                        style={{ width: '60px' }}
                      >
                        <p className="fs--2 mb-1">{textLang.seconds[lang]}</p>
                        <h4
                          className="mb-0 d-none d-sm-block"
                          style={{
                            color: 'var(--falcon-alert-success-color)'
                          }}
                        >
                          {formatNumber(timeRemaining.seconds)}
                        </h4>
                        <h2
                          className="mb-0 d-sm-none"
                          style={{
                            color: 'var(--falcon-alert-success-color)'
                          }}
                        >
                          {formatNumber(timeRemaining.seconds)}
                        </h2>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Alert>
          </Col>
        </Row>
      )}
    </>
  );
};

export default BannerAlert;
