import React, { useState, useEffect /*, useContext*/ } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Card,
  Accordion,
  ListGroup,
  Form,
  Dropdown,
  Pagination,
  Spinner
} from 'react-bootstrap';
// import AppContext from 'context/Context';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActiveUserRow from './ActiveUserRow';
import InactiveUserRow from './InactiveUserRow';
import FalconCloseButton from 'components/common/FalconCloseButton';

const ClusterUsers = ({ activeUsers, inactiveUsers, dataLoaded }) => {
  // const {
  //   config: { lang}
  // } = useContext(AppContext);
  const [activeKey, setActiveKey] = useState(null);
  const [activeUserPageShow, setActiveUserPageShow] = useState(0);
  const [inactiveUserPageShow, setInactiveUserPageShow] = useState(0);
  const itemPerPage = 10;

  const [searchActiveUsers, setSearchActiveUsers] = useState([]);
  const [searchInactiveUsers, setSearchInactiveUsers] = useState([]);
  const [activeSearchValue, setActiveSearchValue] = useState('');
  const [inactiveSearchValue, setInactiveSearchValue] = useState('');

  const filterActiveUsers = (usersList, searchString, isActive) => {
    if (isActive) setActiveSearchValue(searchString);
    else setInactiveSearchValue(searchString);
    const lowerCasedSearch = searchString.toLowerCase();

    const filteredUsers = usersList.filter(
      user =>
        user.name?.toLowerCase().includes(lowerCasedSearch) ||
        user.surname?.toLowerCase().includes(lowerCasedSearch) ||
        user.email?.toLowerCase().includes(lowerCasedSearch)
    );
    if (isActive) setSearchActiveUsers(filteredUsers);
    else setSearchInactiveUsers(filteredUsers);
  };

  useEffect(() => {
    if (dataLoaded) {
      setSearchActiveUsers(activeUsers);
      setSearchInactiveUsers(inactiveUsers);
    }
  }, [activeUsers, inactiveUsers, dataLoaded]);

  return dataLoaded ? (
    <>
      {/* active users */}
      <Row className="mb-3">
        <Col>
          <Card>
            <Card.Header className="d-sm-flex justify-content-between align-items-center">
              <h4 className="mb-0">Utenti Attivi</h4>
              <div className="mt-2 mt-sm-0">
                <Dropdown className="search-box w-auto">
                  <Dropdown.Toggle
                    as="div"
                    data-toggle="dropdown"
                    bsPrefix="toggle"
                  >
                    <Form className="position-relative">
                      <Form.Control
                        type="search"
                        placeholder="Search..."
                        aria-label="Search"
                        value={activeSearchValue}
                        className="rounded-pill search-input"
                        onChange={({ target }) =>
                          filterActiveUsers(activeUsers, target.value, true)
                        }
                      />
                      <FontAwesomeIcon
                        icon="search"
                        className="position-absolute text-400 search-box-icon"
                      />
                      {activeSearchValue && (
                        <div className="search-box-close-btn-container">
                          <FalconCloseButton
                            size="sm"
                            noOutline
                            onClick={() =>
                              filterActiveUsers(activeUsers, '', true)
                            }
                          />
                        </div>
                      )}
                    </Form>
                  </Dropdown.Toggle>
                </Dropdown>
              </div>
            </Card.Header>
            <Card.Body>
              {searchActiveUsers.length > 0 ? (
                <Accordion onSelect={key => setActiveKey(key)}>
                  {searchActiveUsers
                    .slice(
                      activeUserPageShow * itemPerPage,
                      activeUserPageShow * itemPerPage + itemPerPage
                    )
                    .map((user, index) => (
                      <ActiveUserRow
                        key={index}
                        user={user}
                        activeKey={Number(activeKey)}
                      />
                    ))}
                </Accordion>
              ) : (
                <p>Non è presente nessun utente</p>
              )}
              {searchActiveUsers.length > itemPerPage && (
                <Row className="mt-3">
                  <Col as={Flex} justifyContent="end">
                    <Pagination>
                      {activeUserPageShow !== 0 && (
                        <Pagination.Prev
                          onClick={() =>
                            setActiveUserPageShow(activeUserPageShow - 1)
                          } // go to prev
                        />
                      )}
                      {Array.from(
                        {
                          length: Math.ceil(
                            searchActiveUsers.length / itemPerPage
                          )
                        },
                        (_, i) => (
                          <Pagination.Item
                            key={i}
                            active={i === activeUserPageShow}
                            onClick={() => setActiveUserPageShow(i)}
                          >
                            {i + 1}
                          </Pagination.Item>
                        )
                      )}
                      {activeUserPageShow !==
                        Math.ceil(searchActiveUsers.length / itemPerPage) -
                          1 && (
                        <Pagination.Next
                          onClick={() =>
                            setActiveUserPageShow(activeUserPageShow + 1)
                          } // go to next
                        />
                      )}
                    </Pagination>
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* NOT active users */}
      <Row className="mb-3">
        <Col>
          <Card>
            <Card.Header className="d-sm-flex justify-content-between align-items-center">
              <h4 className="mb-0">Utenti inattivi</h4>
              <div className="mt-2 mt-sm-0">
                <Dropdown className="search-box w-auto">
                  <Dropdown.Toggle
                    as="div"
                    data-toggle="dropdown"
                    bsPrefix="toggle"
                  >
                    <Form className="position-relative">
                      <Form.Control
                        type="search"
                        placeholder="Search..."
                        aria-label="Search"
                        value={inactiveSearchValue}
                        className="rounded-pill search-input"
                        onChange={({ target }) =>
                          filterActiveUsers(inactiveUsers, target.value, false)
                        }
                      />
                      <FontAwesomeIcon
                        icon="search"
                        className="position-absolute text-400 search-box-icon"
                      />
                      {inactiveSearchValue && (
                        <div className="search-box-close-btn-container">
                          <FalconCloseButton
                            size="sm"
                            noOutline
                            onClick={() =>
                              filterActiveUsers(inactiveUsers, '', false)
                            }
                          />
                        </div>
                      )}
                    </Form>
                  </Dropdown.Toggle>
                </Dropdown>
              </div>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                {searchInactiveUsers.length > 0 ? (
                  searchInactiveUsers
                    .slice(
                      inactiveUserPageShow * itemPerPage,
                      inactiveUserPageShow * itemPerPage + itemPerPage
                    )
                    .map((user, index) => (
                      <InactiveUserRow key={index} user={user} />
                    ))
                ) : (
                  <p>Non è presente nessun utente</p>
                )}
              </ListGroup>
              {searchInactiveUsers.length > itemPerPage && (
                <Row className="mt-3">
                  <Col as={Flex} justifyContent="end">
                    <Pagination>
                      {inactiveUserPageShow !== 0 && (
                        <Pagination.Prev
                          onClick={() =>
                            setInactiveUserPageShow(inactiveUserPageShow - 1)
                          } // go to prev
                        />
                      )}
                      {Array.from(
                        {
                          length: Math.ceil(
                            searchInactiveUsers.length / itemPerPage
                          )
                        },
                        (_, i) => (
                          <Pagination.Item
                            key={i}
                            active={i === inactiveUserPageShow}
                            onClick={() => setInactiveUserPageShow(i)}
                          >
                            {i + 1}
                          </Pagination.Item>
                        )
                      )}
                      {inactiveUserPageShow !==
                        Math.ceil(searchInactiveUsers.length / itemPerPage) -
                          1 && (
                        <Pagination.Next
                          onClick={() =>
                            setInactiveUserPageShow(inactiveUserPageShow + 1)
                          } // go to next
                        />
                      )}
                    </Pagination>
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  ) : (
    <Flex justifyContent="center" className="mt-3">
      <Spinner />
    </Flex>
  );
};

ClusterUsers.propTypes = {
  activeUsers: PropTypes.array,
  inactiveUsers: PropTypes.array,
  dataLoaded: PropTypes.bool
};

export default ClusterUsers;
