import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import {
  defaultPortfolio,
  portfolioList as portList
} from 'pages/CreateBot/staticData/portfolioList';
import CoverSection from './CoverSection';
import 'assets/scss/custom/addCapital.scss';
import AddCapitalSelection from './AddCapitalSelection';
import PacSection from './PacSection';

const AddCapitalModal = ({
  addCapitalModalShow,
  setAddCapitalModalShow,
  addCapitalBotList,
  dynamicCoins
}) => {
  const [type, setType] = useState('');
  const [botList, setBotList] = useState([]);
  const [portfolioList, setPortfolioList] = useState([]);

  const handleCloseModal = () => {
    setAddCapitalModalShow(false);
    setType('');
  };

  useEffect(() => {
    if (addCapitalBotList.length) {
      let localPortfolioList = addCapitalBotList.filter(function (item) {
        return item.bot_details?.category == 'portfolio';
      });
      localPortfolioList = localPortfolioList.map(bot => {
        const { name, bgStyle, riskLevels, backendMinLevels } =
          portList.find(X => String(X.id) === String(bot.bot_details.type)) ||
          defaultPortfolio;
        const riskLevel = riskLevels[backendMinLevels.indexOf(bot.min_level)];
        bot.name = name;
        bot.riskLevel = riskLevel;
        bot.bgStyle = bgStyle;
        return bot;
      });
      setBotList(
        addCapitalBotList.filter(function (item) {
          return item.bot_details?.category == 'bot';
        })
      );
      setPortfolioList(localPortfolioList);
    }
  }, [addCapitalBotList]);

  return (
    <>
      <Modal
        show={addCapitalModalShow}
        onHide={handleCloseModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        {!type ? (
          <Modal.Body>
            <CoverSection setType={setType} />
          </Modal.Body>
        ) : (
          <>
            {type === 'add' && (
              <AddCapitalSelection
                botList={portfolioList.concat(botList)}
                dynamicCoins={dynamicCoins}
                setType={setType}
              />
            )}
            {type === 'pac' && (
              <PacSection
                portfolioList={portfolioList}
                dynamicCoins={dynamicCoins}
                setType={setType}
              />
            )}
          </>
        )}
      </Modal>
    </>
  );
};

AddCapitalModal.propTypes = {
  addCapitalModalShow: PropTypes.bool.isRequired,
  setAddCapitalModalShow: PropTypes.func.isRequired,
  addCapitalBotList: PropTypes.array.isRequired,
  dynamicCoins: PropTypes.object
};

export default AddCapitalModal;
