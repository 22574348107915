import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Accordion } from 'react-bootstrap';
import AppContext from 'context/Context';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const InvoiceRow = ({ invoice }) => {
  const {
    // config: { lang, currency },
    userInfos
  } = useContext(AppContext);

  const {
    id: invoiceID = null,
    user_id: userID = null,
    name = null,
    surname = null,
    email = null,
    management_amount = null,
    management_quantity = null,
    performance_amount = null,
    performance_quantity = null,
    cur_start = null,
    cur_end = null,
    period_start = null,
    period_end = null,
    fixed_amount = null
    // paid_at = null
  } = invoice || {};

  const truncateToTwoDecimals = num => {
    return Math.floor(num * 100) / 100;
  };

  const invoiceStartDate = new Date(period_start ? period_start : cur_start);
  const invoiceEndDate = new Date(period_end ? period_end : cur_end);
  // calc fee for cluster header
  const management_fee = truncateToTwoDecimals(
    management_amount * (userInfos.cluster.commission_management / 100)
  );
  // calc fee for cluster header
  const performance_fee = truncateToTwoDecimals(
    performance_amount * (userInfos.cluster.commission_performance / 100)
  );
  const fixed_fee = truncateToTwoDecimals(
    fixed_amount * (userInfos.cluster.commission_fixed / 100)
  );
  // use cur_end check if is future invoice
  const total_fee = truncateToTwoDecimals(
    management_fee + performance_fee + (cur_end ? 0 : fixed_fee)
  );

  return (
    <Accordion.Item eventKey={invoiceID || userID}>
      <Accordion.Header>
        <Row className="w-100">
          <Col sm={9}>
            <Row>
              <Col sm={7} as={Flex} alignItems="center">
                {name && surname ? (
                  <>
                    <FontAwesomeIcon icon="user" size="xs" className="me-2" />
                    <p className="mb-0">{`${name} ${surname}`}</p>
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon="at" size="xs" className="me-2" />
                    <p className="mb-0">{email}</p>
                  </>
                )}
              </Col>
              <Col as={Flex} alignItems="center" className="mt-2 mt-sm-0">
                <FontAwesomeIcon icon="calendar" size="xs" className="me-2" />
                <p className="mb-0">
                  {invoiceEndDate.toLocaleDateString('it-IT')}
                </p>
              </Col>
            </Row>
          </Col>
          <Col className="mt-2 mt-sm-0">
            <b>€{total_fee > 0 ? total_fee : 0}</b>
          </Col>
        </Row>
      </Accordion.Header>
      <Accordion.Body className="">
        <Row>
          <Col>
            <Flex alignItems="center" className="mb-1">
              <FontAwesomeIcon icon="at" size="xs" className="me-2" />
              <p className="mb-0">
                <b>{email}</b>
              </p>
            </Flex>
            {fixed_amount ? (
              <p className="mb-1">Costi fissi: €{fixed_amount}</p>
            ) : null}
            <p className="mb-1">Gestito totale: €{management_quantity}</p>
            <p className="mb-1">
              Performance totale:{' '}
              {performance_quantity >= 0 ? `€${performance_quantity}` : '---'}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <hr />
          </Col>
        </Row>
        <Row as={Flex} justifyContent="end">
          <Col sm={4}>
            {fixed_amount ? (
              <Row>
                <Col as={Flex} justifyContent="between">
                  <p className="mb-1">Fee costi fissi:</p>
                  <p className="mb-1">€{fixed_fee}€</p>
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col as={Flex} justifyContent="between">
                <p className="mb-1">Management fee: </p>
                <p className="mb-1">
                  {management_fee > 0 ? management_fee : 0}€
                </p>
              </Col>
            </Row>
            <Row>
              <Col as={Flex} justifyContent="between">
                <p className="mb-1">Performance fee: </p>
                <p className="mb-1">
                  {performance_fee > 0 ? performance_fee : 0}€
                </p>
              </Col>
            </Row>
            <div className="mt-4">
              <Row>
                <Col as={Flex} justifyContent="between">
                  <h5 className="mb-1">Totale: </h5>
                  <h5 className="mb-1">{total_fee > 0 ? total_fee : 0}€</h5>
                </Col>
              </Row>
              <small>
                Periodo{' '}
                {invoiceStartDate.toLocaleDateString('it-IT') +
                  ' - ' +
                  invoiceEndDate.toLocaleDateString('it-IT')}
              </small>
            </div>
          </Col>
        </Row>
      </Accordion.Body>
    </Accordion.Item>
  );
};

InvoiceRow.propTypes = {
  invoice: PropTypes.object
};

export default InvoiceRow;
