export const mixed_coins_low = {
  INITIAL_DATE: '2022-01-01',
  INITIAL_WALLET: 10000,
  RETURN: 101.23,
  VOLUME: 4707766.21,
  ORDERS: 3408,
  PAID_FEES: 4707.77,
  MIN_LEVEL: 9,
  MAXIMUM: 23848.48,
  MINIMUM: 7748.89,
  MAX_DRAWDOWN: 30.65,
  SHARPE: 1.66,
  BETA: 0.29,
  AVG_MONTHLY_RETURN: 2.32,
  TOTAL_MONTHS: 36,
  POSITIVE_MONTHS: 19,
  PERC_POSITIVE_MONTHS: 52.78,
  MONTHLY_RETURNS: [
    1.6, -3.88, 8.21, -3.99, -11.97, -3.51, 5.99, -0.94, 2.51, 9.49, 6.91,
    -8.67, 11.39, -4.01, 11.41, -0.89, -0.99, 2.42, -0.02, -4.94, 0.53, 17.95,
    -7.49, 15.5, 1.32, 14.7, 13.77, -10.45, -0.1, -5.4, -4.3, 1.05, 4.48, 6.09,
    25.38, -5.81
  ],
  MIN_ALLOCATION: {
    BTC: 0.0,
    ETH: 0.0,
    BNB: 0.23,
    SOL: 0.0,
    XRP: 0.0,
    ADA: 0.0,
    DOGE: 0.0,
    LINK: 0.0,
    DOT: 0.0,
    AVAX: 0.0,
    LTC: 0.0,
    SHIB: 0.0,
    POL: 0.0,
    UNI: 0.0,
    USDT: 50.52
  },
  AVG_ALLOCATION: {
    BTC: 4.01,
    ETH: 5.4,
    BNB: 1.04,
    SOL: 4.53,
    XRP: 1.97,
    ADA: 1.08,
    DOGE: 1.0,
    LINK: 1.59,
    DOT: 0.26,
    AVAX: 1.23,
    LTC: 0.94,
    SHIB: 0.82,
    POL: 1.22,
    UNI: 1.32,
    USDT: 73.62
  },
  MAX_ALLOCATION: {
    BTC: 48.92,
    ETH: 48.93,
    BNB: 22.22,
    SOL: 32.65,
    XRP: 24.52,
    ADA: 27.99,
    DOGE: 19.61,
    LINK: 24.5,
    DOT: 21.74,
    AVAX: 24.49,
    LTC: 21.77,
    SHIB: 19.6,
    POL: 24.49,
    UNI: 21.77,
    USDT: 99.57
  },
  MAX_DRAWDOWN_PER_YEAR: {
    2022: 30.65,
    2023: 15.4,
    2024: 27.23
  },
  POSITIVE_MONTHS_PER_YEAR: {
    2022: {
      TOTAL_MONTHS: 12,
      POSITIVE_MONTHS: 6,
      PERC_POSITIVE_MONTHS: 50.0
    },
    2023: {
      TOTAL_MONTHS: 12,
      POSITIVE_MONTHS: 6,
      PERC_POSITIVE_MONTHS: 50.0
    },
    2024: {
      TOTAL_MONTHS: 12,
      POSITIVE_MONTHS: 7,
      PERC_POSITIVE_MONTHS: 58.33
    }
  },
  LAST_12_MONTHS_RETURN: 41.1,
  LAST_24_MONTHS_RETURN: 82.39,
  AVG_ALLOCATION_PER_YEAR: {
    2022: {
      BTC: 2.49,
      ETH: 7.97,
      BNB: 0.96,
      SOL: 2.47,
      XRP: 1.91,
      ADA: 1.27,
      DOGE: 1.21,
      LINK: 1.41,
      DOT: 0.21,
      AVAX: 1.07,
      LTC: 0.78,
      SHIB: 0.64,
      POL: 1.58,
      UNI: 0.98,
      USDT: 75.03
    },
    2023: {
      BTC: 4.4,
      ETH: 2.54,
      BNB: 0.86,
      SOL: 6.94,
      XRP: 1.96,
      ADA: 0.9,
      DOGE: 0.58,
      LINK: 1.96,
      DOT: 0.18,
      AVAX: 1.63,
      LTC: 1.18,
      SHIB: 0.94,
      POL: 1.61,
      UNI: 1.13,
      USDT: 73.16
    },
    2024: {
      BTC: 5.13,
      ETH: 5.67,
      BNB: 1.29,
      SOL: 4.18,
      XRP: 2.03,
      ADA: 1.06,
      DOGE: 1.2,
      LINK: 1.41,
      DOT: 0.39,
      AVAX: 0.99,
      LTC: 0.86,
      SHIB: 0.87,
      POL: 0.48,
      UNI: 1.84,
      USDT: 72.58
    }
  },
  RETURN_PER_YEAR: {
    2022: -0.83,
    2023: 44.18,
    2024: 41.65
  },
  CHARTS: {
    ALL: {
      TIMESTAMP: [
        '2022-01-01',
        '2022-01-02',
        '2022-01-03',
        '2022-01-04',
        '2022-01-05',
        '2022-01-06',
        '2022-01-07',
        '2022-01-08',
        '2022-01-09',
        '2022-01-10',
        '2022-01-11',
        '2022-01-12',
        '2022-01-13',
        '2022-01-14',
        '2022-01-15',
        '2022-01-16',
        '2022-01-17',
        '2022-01-18',
        '2022-01-19',
        '2022-01-20',
        '2022-01-21',
        '2022-01-22',
        '2022-01-23',
        '2022-01-24',
        '2022-01-25',
        '2022-01-26',
        '2022-01-27',
        '2022-01-28',
        '2022-01-29',
        '2022-01-30',
        '2022-01-31',
        '2022-02-01',
        '2022-02-02',
        '2022-02-03',
        '2022-02-04',
        '2022-02-05',
        '2022-02-06',
        '2022-02-07',
        '2022-02-08',
        '2022-02-09',
        '2022-02-10',
        '2022-02-11',
        '2022-02-12',
        '2022-02-13',
        '2022-02-14',
        '2022-02-15',
        '2022-02-16',
        '2022-02-17',
        '2022-02-18',
        '2022-02-19',
        '2022-02-20',
        '2022-02-21',
        '2022-02-22',
        '2022-02-23',
        '2022-02-24',
        '2022-02-25',
        '2022-02-26',
        '2022-02-27',
        '2022-02-28',
        '2022-03-01',
        '2022-03-02',
        '2022-03-03',
        '2022-03-04',
        '2022-03-05',
        '2022-03-06',
        '2022-03-07',
        '2022-03-08',
        '2022-03-09',
        '2022-03-10',
        '2022-03-11',
        '2022-03-12',
        '2022-03-13',
        '2022-03-14',
        '2022-03-15',
        '2022-03-16',
        '2022-03-17',
        '2022-03-18',
        '2022-03-19',
        '2022-03-20',
        '2022-03-21',
        '2022-03-22',
        '2022-03-23',
        '2022-03-24',
        '2022-03-25',
        '2022-03-26',
        '2022-03-27',
        '2022-03-28',
        '2022-03-29',
        '2022-03-30',
        '2022-03-31',
        '2022-04-01',
        '2022-04-02',
        '2022-04-03',
        '2022-04-04',
        '2022-04-05',
        '2022-04-06',
        '2022-04-07',
        '2022-04-08',
        '2022-04-09',
        '2022-04-10',
        '2022-04-11',
        '2022-04-12',
        '2022-04-13',
        '2022-04-14',
        '2022-04-15',
        '2022-04-16',
        '2022-04-17',
        '2022-04-18',
        '2022-04-19',
        '2022-04-20',
        '2022-04-21',
        '2022-04-22',
        '2022-04-23',
        '2022-04-24',
        '2022-04-25',
        '2022-04-26',
        '2022-04-27',
        '2022-04-28',
        '2022-04-29',
        '2022-04-30',
        '2022-05-01',
        '2022-05-02',
        '2022-05-03',
        '2022-05-04',
        '2022-05-05',
        '2022-05-06',
        '2022-05-07',
        '2022-05-08',
        '2022-05-09',
        '2022-05-10',
        '2022-05-11',
        '2022-05-12',
        '2022-05-13',
        '2022-05-14',
        '2022-05-15',
        '2022-05-16',
        '2022-05-17',
        '2022-05-18',
        '2022-05-19',
        '2022-05-20',
        '2022-05-21',
        '2022-05-22',
        '2022-05-23',
        '2022-05-24',
        '2022-05-25',
        '2022-05-26',
        '2022-05-27',
        '2022-05-28',
        '2022-05-29',
        '2022-05-30',
        '2022-05-31',
        '2022-06-01',
        '2022-06-02',
        '2022-06-03',
        '2022-06-04',
        '2022-06-05',
        '2022-06-06',
        '2022-06-07',
        '2022-06-08',
        '2022-06-09',
        '2022-06-10',
        '2022-06-11',
        '2022-06-12',
        '2022-06-13',
        '2022-06-14',
        '2022-06-15',
        '2022-06-16',
        '2022-06-17',
        '2022-06-18',
        '2022-06-19',
        '2022-06-20',
        '2022-06-21',
        '2022-06-22',
        '2022-06-23',
        '2022-06-24',
        '2022-06-25',
        '2022-06-26',
        '2022-06-27',
        '2022-06-28',
        '2022-06-29',
        '2022-06-30',
        '2022-07-01',
        '2022-07-02',
        '2022-07-03',
        '2022-07-04',
        '2022-07-05',
        '2022-07-06',
        '2022-07-07',
        '2022-07-08',
        '2022-07-09',
        '2022-07-10',
        '2022-07-11',
        '2022-07-12',
        '2022-07-13',
        '2022-07-14',
        '2022-07-15',
        '2022-07-16',
        '2022-07-17',
        '2022-07-18',
        '2022-07-19',
        '2022-07-20',
        '2022-07-21',
        '2022-07-22',
        '2022-07-23',
        '2022-07-24',
        '2022-07-25',
        '2022-07-26',
        '2022-07-27',
        '2022-07-28',
        '2022-07-29',
        '2022-07-30',
        '2022-07-31',
        '2022-08-01',
        '2022-08-02',
        '2022-08-03',
        '2022-08-04',
        '2022-08-05',
        '2022-08-06',
        '2022-08-07',
        '2022-08-08',
        '2022-08-09',
        '2022-08-10',
        '2022-08-11',
        '2022-08-12',
        '2022-08-13',
        '2022-08-14',
        '2022-08-15',
        '2022-08-16',
        '2022-08-17',
        '2022-08-18',
        '2022-08-19',
        '2022-08-20',
        '2022-08-21',
        '2022-08-22',
        '2022-08-23',
        '2022-08-24',
        '2022-08-25',
        '2022-08-26',
        '2022-08-27',
        '2022-08-28',
        '2022-08-29',
        '2022-08-30',
        '2022-08-31',
        '2022-09-01',
        '2022-09-02',
        '2022-09-03',
        '2022-09-04',
        '2022-09-05',
        '2022-09-06',
        '2022-09-07',
        '2022-09-08',
        '2022-09-09',
        '2022-09-10',
        '2022-09-11',
        '2022-09-12',
        '2022-09-13',
        '2022-09-14',
        '2022-09-15',
        '2022-09-16',
        '2022-09-17',
        '2022-09-18',
        '2022-09-19',
        '2022-09-20',
        '2022-09-21',
        '2022-09-22',
        '2022-09-23',
        '2022-09-24',
        '2022-09-25',
        '2022-09-26',
        '2022-09-27',
        '2022-09-28',
        '2022-09-29',
        '2022-09-30',
        '2022-10-01',
        '2022-10-02',
        '2022-10-03',
        '2022-10-04',
        '2022-10-05',
        '2022-10-06',
        '2022-10-07',
        '2022-10-08',
        '2022-10-09',
        '2022-10-10',
        '2022-10-11',
        '2022-10-12',
        '2022-10-13',
        '2022-10-14',
        '2022-10-15',
        '2022-10-16',
        '2022-10-17',
        '2022-10-18',
        '2022-10-19',
        '2022-10-20',
        '2022-10-21',
        '2022-10-22',
        '2022-10-23',
        '2022-10-24',
        '2022-10-25',
        '2022-10-26',
        '2022-10-27',
        '2022-10-28',
        '2022-10-29',
        '2022-10-30',
        '2022-10-31',
        '2022-11-01',
        '2022-11-02',
        '2022-11-03',
        '2022-11-04',
        '2022-11-05',
        '2022-11-06',
        '2022-11-07',
        '2022-11-08',
        '2022-11-09',
        '2022-11-10',
        '2022-11-11',
        '2022-11-12',
        '2022-11-13',
        '2022-11-14',
        '2022-11-15',
        '2022-11-16',
        '2022-11-17',
        '2022-11-18',
        '2022-11-19',
        '2022-11-20',
        '2022-11-21',
        '2022-11-22',
        '2022-11-23',
        '2022-11-24',
        '2022-11-25',
        '2022-11-26',
        '2022-11-27',
        '2022-11-28',
        '2022-11-29',
        '2022-11-30',
        '2022-12-01',
        '2022-12-02',
        '2022-12-03',
        '2022-12-04',
        '2022-12-05',
        '2022-12-06',
        '2022-12-07',
        '2022-12-08',
        '2022-12-09',
        '2022-12-10',
        '2022-12-11',
        '2022-12-12',
        '2022-12-13',
        '2022-12-14',
        '2022-12-15',
        '2022-12-16',
        '2022-12-17',
        '2022-12-18',
        '2022-12-19',
        '2022-12-20',
        '2022-12-21',
        '2022-12-22',
        '2022-12-23',
        '2022-12-24',
        '2022-12-25',
        '2022-12-26',
        '2022-12-27',
        '2022-12-28',
        '2022-12-29',
        '2022-12-30',
        '2022-12-31',
        '2023-01-01',
        '2023-01-02',
        '2023-01-03',
        '2023-01-04',
        '2023-01-05',
        '2023-01-06',
        '2023-01-07',
        '2023-01-08',
        '2023-01-09',
        '2023-01-10',
        '2023-01-11',
        '2023-01-12',
        '2023-01-13',
        '2023-01-14',
        '2023-01-15',
        '2023-01-16',
        '2023-01-17',
        '2023-01-18',
        '2023-01-19',
        '2023-01-20',
        '2023-01-21',
        '2023-01-22',
        '2023-01-23',
        '2023-01-24',
        '2023-01-25',
        '2023-01-26',
        '2023-01-27',
        '2023-01-28',
        '2023-01-29',
        '2023-01-30',
        '2023-01-31',
        '2023-02-01',
        '2023-02-02',
        '2023-02-03',
        '2023-02-04',
        '2023-02-05',
        '2023-02-06',
        '2023-02-07',
        '2023-02-08',
        '2023-02-09',
        '2023-02-10',
        '2023-02-11',
        '2023-02-12',
        '2023-02-13',
        '2023-02-14',
        '2023-02-15',
        '2023-02-16',
        '2023-02-17',
        '2023-02-18',
        '2023-02-19',
        '2023-02-20',
        '2023-02-21',
        '2023-02-22',
        '2023-02-23',
        '2023-02-24',
        '2023-02-25',
        '2023-02-26',
        '2023-02-27',
        '2023-02-28',
        '2023-03-01',
        '2023-03-02',
        '2023-03-03',
        '2023-03-04',
        '2023-03-05',
        '2023-03-06',
        '2023-03-07',
        '2023-03-08',
        '2023-03-09',
        '2023-03-10',
        '2023-03-11',
        '2023-03-12',
        '2023-03-13',
        '2023-03-14',
        '2023-03-15',
        '2023-03-16',
        '2023-03-17',
        '2023-03-18',
        '2023-03-19',
        '2023-03-20',
        '2023-03-21',
        '2023-03-22',
        '2023-03-23',
        '2023-03-24',
        '2023-03-25',
        '2023-03-26',
        '2023-03-27',
        '2023-03-28',
        '2023-03-29',
        '2023-03-30',
        '2023-03-31',
        '2023-04-01',
        '2023-04-02',
        '2023-04-03',
        '2023-04-04',
        '2023-04-05',
        '2023-04-06',
        '2023-04-07',
        '2023-04-08',
        '2023-04-09',
        '2023-04-10',
        '2023-04-11',
        '2023-04-12',
        '2023-04-13',
        '2023-04-14',
        '2023-04-15',
        '2023-04-16',
        '2023-04-17',
        '2023-04-18',
        '2023-04-19',
        '2023-04-20',
        '2023-04-21',
        '2023-04-22',
        '2023-04-23',
        '2023-04-24',
        '2023-04-25',
        '2023-04-26',
        '2023-04-27',
        '2023-04-28',
        '2023-04-29',
        '2023-04-30',
        '2023-05-01',
        '2023-05-02',
        '2023-05-03',
        '2023-05-04',
        '2023-05-05',
        '2023-05-06',
        '2023-05-07',
        '2023-05-08',
        '2023-05-09',
        '2023-05-10',
        '2023-05-11',
        '2023-05-12',
        '2023-05-13',
        '2023-05-14',
        '2023-05-15',
        '2023-05-16',
        '2023-05-17',
        '2023-05-18',
        '2023-05-19',
        '2023-05-20',
        '2023-05-21',
        '2023-05-22',
        '2023-05-23',
        '2023-05-24',
        '2023-05-25',
        '2023-05-26',
        '2023-05-27',
        '2023-05-28',
        '2023-05-29',
        '2023-05-30',
        '2023-05-31',
        '2023-06-01',
        '2023-06-02',
        '2023-06-03',
        '2023-06-04',
        '2023-06-05',
        '2023-06-06',
        '2023-06-07',
        '2023-06-08',
        '2023-06-09',
        '2023-06-10',
        '2023-06-11',
        '2023-06-12',
        '2023-06-13',
        '2023-06-14',
        '2023-06-15',
        '2023-06-16',
        '2023-06-17',
        '2023-06-18',
        '2023-06-19',
        '2023-06-20',
        '2023-06-21',
        '2023-06-22',
        '2023-06-23',
        '2023-06-24',
        '2023-06-25',
        '2023-06-26',
        '2023-06-27',
        '2023-06-28',
        '2023-06-29',
        '2023-06-30',
        '2023-07-01',
        '2023-07-02',
        '2023-07-03',
        '2023-07-04',
        '2023-07-05',
        '2023-07-06',
        '2023-07-07',
        '2023-07-08',
        '2023-07-09',
        '2023-07-10',
        '2023-07-11',
        '2023-07-12',
        '2023-07-13',
        '2023-07-14',
        '2023-07-15',
        '2023-07-16',
        '2023-07-17',
        '2023-07-18',
        '2023-07-19',
        '2023-07-20',
        '2023-07-21',
        '2023-07-22',
        '2023-07-23',
        '2023-07-24',
        '2023-07-25',
        '2023-07-26',
        '2023-07-27',
        '2023-07-28',
        '2023-07-29',
        '2023-07-30',
        '2023-07-31',
        '2023-08-01',
        '2023-08-02',
        '2023-08-03',
        '2023-08-04',
        '2023-08-05',
        '2023-08-06',
        '2023-08-07',
        '2023-08-08',
        '2023-08-09',
        '2023-08-10',
        '2023-08-11',
        '2023-08-12',
        '2023-08-13',
        '2023-08-14',
        '2023-08-15',
        '2023-08-16',
        '2023-08-17',
        '2023-08-18',
        '2023-08-19',
        '2023-08-20',
        '2023-08-21',
        '2023-08-22',
        '2023-08-23',
        '2023-08-24',
        '2023-08-25',
        '2023-08-26',
        '2023-08-27',
        '2023-08-28',
        '2023-08-29',
        '2023-08-30',
        '2023-08-31',
        '2023-09-01',
        '2023-09-02',
        '2023-09-03',
        '2023-09-04',
        '2023-09-05',
        '2023-09-06',
        '2023-09-07',
        '2023-09-08',
        '2023-09-09',
        '2023-09-10',
        '2023-09-11',
        '2023-09-12',
        '2023-09-13',
        '2023-09-14',
        '2023-09-15',
        '2023-09-16',
        '2023-09-17',
        '2023-09-18',
        '2023-09-19',
        '2023-09-20',
        '2023-09-21',
        '2023-09-22',
        '2023-09-23',
        '2023-09-24',
        '2023-09-25',
        '2023-09-26',
        '2023-09-27',
        '2023-09-28',
        '2023-09-29',
        '2023-09-30',
        '2023-10-01',
        '2023-10-02',
        '2023-10-03',
        '2023-10-04',
        '2023-10-05',
        '2023-10-06',
        '2023-10-07',
        '2023-10-08',
        '2023-10-09',
        '2023-10-10',
        '2023-10-11',
        '2023-10-12',
        '2023-10-13',
        '2023-10-14',
        '2023-10-15',
        '2023-10-16',
        '2023-10-17',
        '2023-10-18',
        '2023-10-19',
        '2023-10-20',
        '2023-10-21',
        '2023-10-22',
        '2023-10-23',
        '2023-10-24',
        '2023-10-25',
        '2023-10-26',
        '2023-10-27',
        '2023-10-28',
        '2023-10-29',
        '2023-10-30',
        '2023-10-31',
        '2023-11-01',
        '2023-11-02',
        '2023-11-03',
        '2023-11-04',
        '2023-11-05',
        '2023-11-06',
        '2023-11-07',
        '2023-11-08',
        '2023-11-09',
        '2023-11-10',
        '2023-11-11',
        '2023-11-12',
        '2023-11-13',
        '2023-11-14',
        '2023-11-15',
        '2023-11-16',
        '2023-11-17',
        '2023-11-18',
        '2023-11-19',
        '2023-11-20',
        '2023-11-21',
        '2023-11-22',
        '2023-11-23',
        '2023-11-24',
        '2023-11-25',
        '2023-11-26',
        '2023-11-27',
        '2023-11-28',
        '2023-11-29',
        '2023-11-30',
        '2023-12-01',
        '2023-12-02',
        '2023-12-03',
        '2023-12-04',
        '2023-12-05',
        '2023-12-06',
        '2023-12-07',
        '2023-12-08',
        '2023-12-09',
        '2023-12-10',
        '2023-12-11',
        '2023-12-12',
        '2023-12-13',
        '2023-12-14',
        '2023-12-15',
        '2023-12-16',
        '2023-12-17',
        '2023-12-18',
        '2023-12-19',
        '2023-12-20',
        '2023-12-21',
        '2023-12-22',
        '2023-12-23',
        '2023-12-24',
        '2023-12-25',
        '2023-12-26',
        '2023-12-27',
        '2023-12-28',
        '2023-12-29',
        '2023-12-30',
        '2023-12-31',
        '2024-01-01',
        '2024-01-02',
        '2024-01-03',
        '2024-01-04',
        '2024-01-05',
        '2024-01-06',
        '2024-01-07',
        '2024-01-08',
        '2024-01-09',
        '2024-01-10',
        '2024-01-11',
        '2024-01-12',
        '2024-01-13',
        '2024-01-14',
        '2024-01-15',
        '2024-01-16',
        '2024-01-17',
        '2024-01-18',
        '2024-01-19',
        '2024-01-20',
        '2024-01-21',
        '2024-01-22',
        '2024-01-23',
        '2024-01-24',
        '2024-01-25',
        '2024-01-26',
        '2024-01-27',
        '2024-01-28',
        '2024-01-29',
        '2024-01-30',
        '2024-01-31',
        '2024-02-01',
        '2024-02-02',
        '2024-02-03',
        '2024-02-04',
        '2024-02-05',
        '2024-02-06',
        '2024-02-07',
        '2024-02-08',
        '2024-02-09',
        '2024-02-10',
        '2024-02-11',
        '2024-02-12',
        '2024-02-13',
        '2024-02-14',
        '2024-02-15',
        '2024-02-16',
        '2024-02-17',
        '2024-02-18',
        '2024-02-19',
        '2024-02-20',
        '2024-02-21',
        '2024-02-22',
        '2024-02-23',
        '2024-02-24',
        '2024-02-25',
        '2024-02-26',
        '2024-02-27',
        '2024-02-28',
        '2024-02-29',
        '2024-03-01',
        '2024-03-02',
        '2024-03-03',
        '2024-03-04',
        '2024-03-05',
        '2024-03-06',
        '2024-03-07',
        '2024-03-08',
        '2024-03-09',
        '2024-03-10',
        '2024-03-11',
        '2024-03-12',
        '2024-03-13',
        '2024-03-14',
        '2024-03-15',
        '2024-03-16',
        '2024-03-17',
        '2024-03-18',
        '2024-03-19',
        '2024-03-20',
        '2024-03-21',
        '2024-03-22',
        '2024-03-23',
        '2024-03-24',
        '2024-03-25',
        '2024-03-26',
        '2024-03-27',
        '2024-03-28',
        '2024-03-29',
        '2024-03-30',
        '2024-03-31',
        '2024-04-01',
        '2024-04-02',
        '2024-04-03',
        '2024-04-04',
        '2024-04-05',
        '2024-04-06',
        '2024-04-07',
        '2024-04-08',
        '2024-04-09',
        '2024-04-10',
        '2024-04-11',
        '2024-04-12',
        '2024-04-13',
        '2024-04-14',
        '2024-04-15',
        '2024-04-16',
        '2024-04-17',
        '2024-04-18',
        '2024-04-19',
        '2024-04-20',
        '2024-04-21',
        '2024-04-22',
        '2024-04-23',
        '2024-04-24',
        '2024-04-25',
        '2024-04-26',
        '2024-04-27',
        '2024-04-28',
        '2024-04-29',
        '2024-04-30',
        '2024-05-01',
        '2024-05-02',
        '2024-05-03',
        '2024-05-04',
        '2024-05-05',
        '2024-05-06',
        '2024-05-07',
        '2024-05-08',
        '2024-05-09',
        '2024-05-10',
        '2024-05-11',
        '2024-05-12',
        '2024-05-13',
        '2024-05-14',
        '2024-05-15',
        '2024-05-16',
        '2024-05-17',
        '2024-05-18',
        '2024-05-19',
        '2024-05-20',
        '2024-05-21',
        '2024-05-22',
        '2024-05-23',
        '2024-05-24',
        '2024-05-25',
        '2024-05-26',
        '2024-05-27',
        '2024-05-28',
        '2024-05-29',
        '2024-05-30',
        '2024-05-31',
        '2024-06-01',
        '2024-06-02',
        '2024-06-03',
        '2024-06-04',
        '2024-06-05',
        '2024-06-06',
        '2024-06-07',
        '2024-06-08',
        '2024-06-09',
        '2024-06-10',
        '2024-06-11',
        '2024-06-12',
        '2024-06-13',
        '2024-06-14',
        '2024-06-15',
        '2024-06-16',
        '2024-06-17',
        '2024-06-18',
        '2024-06-19',
        '2024-06-20',
        '2024-06-21',
        '2024-06-22',
        '2024-06-23',
        '2024-06-24',
        '2024-06-25',
        '2024-06-26',
        '2024-06-27',
        '2024-06-28',
        '2024-06-29',
        '2024-06-30',
        '2024-07-01',
        '2024-07-02',
        '2024-07-03',
        '2024-07-04',
        '2024-07-05',
        '2024-07-06',
        '2024-07-07',
        '2024-07-08',
        '2024-07-09',
        '2024-07-10',
        '2024-07-11',
        '2024-07-12',
        '2024-07-13',
        '2024-07-14',
        '2024-07-15',
        '2024-07-16',
        '2024-07-17',
        '2024-07-18',
        '2024-07-19',
        '2024-07-20',
        '2024-07-21',
        '2024-07-22',
        '2024-07-23',
        '2024-07-24',
        '2024-07-25',
        '2024-07-26',
        '2024-07-27',
        '2024-07-28',
        '2024-07-29',
        '2024-07-30',
        '2024-07-31',
        '2024-08-01',
        '2024-08-02',
        '2024-08-03',
        '2024-08-04',
        '2024-08-05',
        '2024-08-06',
        '2024-08-07',
        '2024-08-08',
        '2024-08-09',
        '2024-08-10',
        '2024-08-11',
        '2024-08-12',
        '2024-08-13',
        '2024-08-14',
        '2024-08-15',
        '2024-08-16',
        '2024-08-17',
        '2024-08-18',
        '2024-08-19',
        '2024-08-20',
        '2024-08-21',
        '2024-08-22',
        '2024-08-23',
        '2024-08-24',
        '2024-08-25',
        '2024-08-26',
        '2024-08-27',
        '2024-08-28',
        '2024-08-29',
        '2024-08-30',
        '2024-08-31',
        '2024-09-01',
        '2024-09-02',
        '2024-09-03',
        '2024-09-04',
        '2024-09-05',
        '2024-09-06',
        '2024-09-07',
        '2024-09-08',
        '2024-09-09',
        '2024-09-10',
        '2024-09-11',
        '2024-09-12',
        '2024-09-13',
        '2024-09-14',
        '2024-09-15',
        '2024-09-16',
        '2024-09-17',
        '2024-09-18',
        '2024-09-19',
        '2024-09-20',
        '2024-09-21',
        '2024-09-22',
        '2024-09-23',
        '2024-09-24',
        '2024-09-25',
        '2024-09-26',
        '2024-09-27',
        '2024-09-28',
        '2024-09-29',
        '2024-09-30',
        '2024-10-01',
        '2024-10-02',
        '2024-10-03',
        '2024-10-04',
        '2024-10-05',
        '2024-10-06',
        '2024-10-07',
        '2024-10-08',
        '2024-10-09',
        '2024-10-10',
        '2024-10-11',
        '2024-10-12',
        '2024-10-13',
        '2024-10-14',
        '2024-10-15',
        '2024-10-16',
        '2024-10-17',
        '2024-10-18',
        '2024-10-19',
        '2024-10-20',
        '2024-10-21',
        '2024-10-22',
        '2024-10-23',
        '2024-10-24',
        '2024-10-25',
        '2024-10-26',
        '2024-10-27',
        '2024-10-28',
        '2024-10-29',
        '2024-10-30',
        '2024-10-31',
        '2024-11-01',
        '2024-11-02',
        '2024-11-03',
        '2024-11-04',
        '2024-11-05',
        '2024-11-06',
        '2024-11-07',
        '2024-11-08',
        '2024-11-09',
        '2024-11-10',
        '2024-11-11',
        '2024-11-12',
        '2024-11-13',
        '2024-11-14',
        '2024-11-15',
        '2024-11-16',
        '2024-11-17',
        '2024-11-18',
        '2024-11-19',
        '2024-11-20',
        '2024-11-21',
        '2024-11-22',
        '2024-11-23',
        '2024-11-24',
        '2024-11-25',
        '2024-11-26',
        '2024-11-27',
        '2024-11-28',
        '2024-11-29',
        '2024-11-30',
        '2024-12-01',
        '2024-12-02',
        '2024-12-03',
        '2024-12-04',
        '2024-12-05',
        '2024-12-06',
        '2024-12-07',
        '2024-12-08',
        '2024-12-09',
        '2024-12-10',
        '2024-12-11',
        '2024-12-12',
        '2024-12-13',
        '2024-12-14',
        '2024-12-15',
        '2024-12-16',
        '2024-12-17',
        '2024-12-18',
        '2024-12-19',
        '2024-12-20',
        '2024-12-21',
        '2024-12-22',
        '2024-12-23',
        '2024-12-24',
        '2024-12-25',
        '2024-12-26',
        '2024-12-27',
        '2024-12-28',
        '2024-12-29',
        '2024-12-30',
        '2024-12-31',
        '2025-01-01'
      ],
      BTC: [
        10000.0, 10264.8, 10224.69, 10000.21, 9987.66, 9398.52, 9343.11,
        8988.44, 9068.35, 9132.01, 9002.11, 9241.87, 9486.34, 9216.19, 9358.16,
        9355.32, 9345.06, 9104.28, 9191.64, 9082.58, 8907.4, 7814.79, 7545.94,
        7683.0, 7869.29, 7960.54, 7899.58, 7941.96, 8147.44, 8252.39, 8175.75,
        8311.34, 8373.02, 8007.23, 7991.26, 8793.69, 8996.67, 8999.03, 9535.25,
        9533.93, 9641.0, 9511.14, 9121.12, 9125.56, 9167.97, 9234.29, 9556.92,
        9522.59, 8808.83, 8641.86, 8658.17, 8398.69, 8123.27, 8204.21, 8145.98,
        8193.98, 8530.24, 8469.88, 8182.82, 9309.67, 9554.24, 9534.9, 9194.05,
        8439.49, 8539.06, 8432.87, 8267.85, 8341.59, 9064.22, 8534.11, 8420.44,
        8460.53, 8161.71, 8509.02, 8539.52, 8845.89, 8868.57, 9019.89, 9077.73,
        8965.54, 8897.17, 9202.07, 9193.31, 9509.28, 9585.12, 9599.11, 10085.7,
        10286.41, 10241.08, 10192.05, 9907.16, 10032.77, 9977.03, 10103.47,
        10097.31, 9921.58, 9414.59, 9440.2, 9152.7, 9205.15, 9111.8, 8604.29,
        8617.61, 8931.59, 8621.02, 8755.98, 8742.65, 8654.84, 8854.71, 8956.76,
        8972.4, 8743.98, 8592.35, 8615.1, 8557.11, 8729.91, 8269.92, 8454.55,
        8610.46, 8353.79, 8276.28, 8288.44, 8349.98, 8165.77, 8585.99, 7840.99,
        7789.19, 7582.95, 7416.17, 6726.51, 6661.01, 6245.13, 6236.14, 6449.91,
        6542.91, 6727.12, 6495.12, 6567.17, 6269.59, 6531.2, 6307.85, 6364.39,
        6550.76, 6320.46, 6405.79, 6444.72, 6384.86, 6236.45, 6273.17, 6367.11,
        6873.77, 6895.59, 6436.42, 6595.62, 6432.79, 6449.59, 6496.04, 6793.08,
        6763.75, 6558.32, 6505.76, 6313.3, 6195.95, 5841.03, 4887.36, 4673.38,
        4862.81, 4406.4, 4425.04, 4151.49, 4477.84, 4433.36, 4521.5, 4349.77,
        4542.01, 4611.08, 4637.12, 4574.9, 4503.63, 4394.06, 4373.64, 4088.23,
        4176.09, 4182.74, 4175.95, 4362.83, 4421.59, 4438.58, 4679.7, 4711.05,
        4679.56, 4509.13, 4328.99, 4192.26, 4294.31, 4435.94, 4520.16, 4607.98,
        4544.26, 4715.27, 5085.1, 5043.4, 4980.23, 4917.31, 4861.65, 4902.36,
        4686.24, 4567.66, 4949.14, 5184.66, 5180.54, 5128.2, 5058.2, 5012.19,
        4998.37, 4961.86, 4894.86, 5029.2, 5014.1, 5029.46, 5152.53, 5013.39,
        5158.88, 5169.81, 5244.66, 5276.65, 5248.87, 5195.87, 5160.0, 5051.58,
        5051.05, 4538.41, 4573.29, 4666.25, 4581.86, 4646.67, 4650.62, 4668.48,
        4459.95, 4331.76, 4306.62, 4371.53, 4307.31, 4360.96, 4344.37, 4325.15,
        4282.78, 4291.44, 4285.13, 4091.39, 4184.01, 4177.29, 4618.89, 4707.8,
        4681.38, 4830.16, 4375.92, 4391.49, 4268.8, 4268.75, 4354.73, 4207.74,
        4241.55, 4102.52, 4000.46, 4197.07, 4183.13, 4086.45, 4065.26, 4140.99,
        4128.4, 4216.14, 4209.73, 4194.32, 4181.21, 4147.97, 4223.34, 4392.25,
        4368.62, 4306.07, 4229.98, 4189.1, 4197.13, 4139.24, 4119.73, 4144.67,
        4204.31, 4145.63, 4124.27, 4176.41, 4222.31, 4180.4, 4141.42, 4118.41,
        4152.02, 4152.66, 4239.34, 4189.19, 4341.12, 4504.21, 4385.75, 4463.73,
        4491.38, 4454.35, 4431.17, 4430.95, 4351.18, 4369.7, 4582.31, 4608.04,
        4573.91, 4427.26, 3994.14, 3434.81, 3856.43, 3661.2, 3642.75, 3556.78,
        3550.76, 3652.44, 3606.82, 3615.76, 3605.98, 3621.66, 3526.18, 3405.73,
        3501.94, 3572.83, 3594.46, 3576.43, 3553.96, 3585.92, 3509.7, 3569.17,
        3710.39, 3670.79, 3687.38, 3656.95, 3700.7, 3666.19, 3672.65, 3644.09,
        3721.98, 3707.07, 3708.22, 3700.25, 3712.49, 3846.4, 3859.41, 3751.85,
        3618.48, 3621.91, 3626.74, 3552.12, 3650.02, 3627.44, 3634.69, 3633.5,
        3640.63, 3639.75, 3643.41, 3611.44, 3574.03, 3595.99, 3588.14, 3574.62,
        3592.22, 3613.05, 3608.44, 3640.8, 3643.22, 3669.29, 3664.53, 3670.33,
        3722.62, 3770.93, 3798.69, 4092.42, 4294.83, 4541.61, 4524.59, 4584.01,
        4594.07, 4501.77, 4557.9, 4888.92, 4924.54, 4906.53, 4960.38, 4923.64,
        4961.86, 4977.35, 4978.31, 4971.69, 5142.25, 4918.07, 5015.87, 5131.13,
        5096.87, 5067.62, 5063.7, 4972.1, 4950.43, 5026.01, 4961.11, 4709.86,
        4667.38, 4722.19, 4715.7, 4682.35, 4800.77, 5225.7, 5191.77, 5339.85,
        5328.63, 5299.17, 5345.34, 5276.01, 5214.69, 5163.93, 5002.47, 5002.22,
        5079.62, 5080.85, 5011.44, 5091.63, 5076.27, 4829.61, 4826.37, 4853.85,
        4853.99, 4783.02, 4717.35, 4401.76, 4348.46, 4399.59, 4695.77, 5236.42,
        5344.01, 5271.04, 5405.29, 5953.08, 5822.64, 6082.81, 6048.04, 6065.13,
        5887.28, 6104.71, 5906.5, 5938.8, 6067.1, 5863.65, 5889.26, 6142.65,
        6036.44, 6181.78, 6170.45, 6081.28, 6015.11, 6098.9, 6096.31, 6060.09,
        6048.86, 6044.92, 6138.74, 6403.28, 6548.2, 6456.8, 6555.19, 6585.32,
        6567.07, 6562.75, 6383.44, 6571.37, 6291.24, 6112.28, 5899.58, 6026.64,
        5970.05, 5942.39, 6108.85, 6122.92, 6358.1, 6349.48, 6318.17, 6356.7,
        6062.71, 6217.83, 6297.93, 6227.13, 6402.35, 6239.08, 6218.33, 5983.32,
        5967.42, 5987.06, 5821.21, 5773.8, 5818.2, 5820.54, 5909.13, 5841.66,
        5914.23, 5818.59, 5814.14, 5856.58, 5788.96, 5814.33, 5884.19, 5701.06,
        5723.97, 5774.07, 5799.45, 6077.95, 5991.14, 6001.81, 5881.83, 5816.26,
        5898.9, 5857.49, 5892.66, 5563.05, 5875.04, 5685.34, 5743.43, 5732.25,
        5598.63, 5603.1, 5610.48, 5591.02, 5416.84, 5545.44, 5705.81, 5749.0,
        5712.68, 5789.0, 6104.57, 6515.91, 6475.5, 6630.51, 6602.63, 6599.5,
        6540.36, 6618.01, 6514.05, 6579.69, 6594.27, 6616.11, 6632.05, 6733.62,
        6668.55, 6585.66, 6527.32, 6556.92, 6539.7, 6528.78, 6566.28, 6617.61,
        6570.9, 6774.4, 6540.37, 6552.89, 6560.59, 6514.23, 6452.11, 6463.6,
        6450.88, 6474.91, 6449.59, 6494.06, 6309.65, 6319.43, 6377.75, 6318.53,
        6337.94, 6351.0, 6308.51, 6320.19, 6329.72, 6315.0, 6325.66, 6287.22,
        6294.36, 6296.4, 6313.96, 6440.93, 6405.0, 6375.37, 6368.78, 6367.04,
        6343.87, 6369.53, 6314.48, 6255.29, 5799.02, 5641.22, 5637.33, 5670.97,
        5654.2, 5584.1, 5724.31, 5651.55, 5637.33, 5632.18, 5644.96, 5638.93,
        5980.12, 5894.16, 5637.32, 5574.65, 5595.68, 5614.95, 5576.96, 5575.51,
        5571.17, 5695.75, 5605.24, 5605.85, 5593.7, 5441.44, 5619.16, 5678.04,
        5755.72, 5793.62, 5746.82, 5732.1, 5814.35, 5883.0, 5862.77, 5753.31,
        5752.09, 5747.29, 5731.85, 5686.63, 5659.75, 5697.94, 5844.39, 5817.61,
        5844.24, 6057.62, 5962.0, 5929.47, 6004.89, 5936.19, 6052.16, 6047.79,
        6040.86, 5968.96, 5938.93, 5795.31, 5789.02, 5805.21, 5809.44, 5864.12,
        6168.65, 6148.6, 6132.73, 6198.95, 6417.92, 6490.41, 6499.56, 7208.6,
        7390.29, 7487.84, 7405.89, 7314.75, 7379.2, 7508.6, 7458.0, 7477.59,
        7633.62, 7527.28, 7492.53, 7607.6, 7566.79, 7598.02, 7634.01, 7742.54,
        7908.06, 8081.82, 7982.33, 8039.4, 7890.46, 7704.53, 8158.62, 7836.43,
        7907.23, 7898.83, 8001.46, 8097.85, 7871.05, 8102.2, 8071.71, 8153.32,
        8162.6, 8147.07, 8023.73, 8192.04, 8176.8, 8155.89, 8374.38, 8513.98,
        8667.97, 9076.89, 9582.07, 9469.87, 9333.38, 9576.69, 9498.72, 9473.47,
        8933.15, 8921.01, 9295.81, 9319.32, 9100.81, 9154.93, 9026.53, 9198.16,
        9159.77, 9436.29, 9509.38, 9477.05, 9465.33, 9299.04, 9451.11, 9189.27,
        9374.01, 9223.04, 9035.87, 9116.29, 9143.38, 9418.61, 9732.73, 9249.79,
        9589.13, 9513.27, 9494.06, 9490.64, 10169.43, 10008.02, 10148.38,
        10039.61, 9197.92, 9301.93, 9146.22, 9223.46, 9348.52, 9243.83, 8947.95,
        9019.87, 9040.31, 9008.25, 8618.66, 8539.88, 8628.01, 8645.38, 9067.3,
        9121.23, 9051.66, 9327.99, 9374.87, 9229.53, 9288.18, 9347.78, 9310.73,
        9211.26, 9196.79, 9347.22, 9555.08, 9795.66, 10228.42, 10348.13,
        10415.23, 10823.12, 10715.02, 11206.38, 11174.35, 11246.5, 11188.65,
        11303.17, 11237.53, 11287.78, 11145.51, 11133.59, 10989.05, 11155.35,
        11186.81, 11826.79, 12309.97, 13258.11, 13247.1, 13521.04, 13346.85,
        13594.03, 14697.88, 13717.48, 14262.42, 14518.96, 14735.31, 14792.2,
        14803.79, 15674.15, 15397.39, 15784.65, 15479.0, 14952.16, 14358.61,
        14762.55, 14646.58, 13552.17, 14644.4, 14148.96, 13636.12, 14009.55,
        14486.3, 15213.04, 15199.89, 15024.83, 15329.45, 15104.42, 15085.58,
        15355.85, 15083.6, 14240.66, 14310.77, 14699.6, 14632.84, 14941.71,
        14972.87, 15560.55, 15000.05, 15260.21, 15178.69, 14508.39, 13911.23,
        14135.58, 13730.89, 13800.7, 13347.91, 13739.5, 13850.44, 13980.49,
        14070.69, 14502.9, 14358.99, 13868.51, 13978.2, 13809.21, 13687.63,
        13664.05, 13823.94, 13109.87, 12523.67, 12836.86, 13584.29, 13764.21,
        13817.45, 13738.62, 13599.12, 13252.94, 13692.06, 13167.04, 13179.15,
        13269.16, 13581.61, 13351.99, 14305.65, 14154.76, 14447.43, 14482.14,
        14359.15, 15057.42, 15194.04, 14960.16, 14645.38, 14883.73, 14957.51,
        14834.39, 15057.6, 14846.38, 14643.99, 14781.36, 14607.1, 14662.47,
        14697.64, 14938.79, 15267.41, 15393.67, 15340.91, 15037.57, 15002.76,
        15066.85, 15036.91, 14574.79, 14788.05, 14474.35, 14283.51, 14323.75,
        14444.06, 14422.42, 14124.69, 14028.63, 14061.93, 13881.28, 13916.06,
        13773.74, 12992.85, 13395.52, 13182.31, 13337.11, 13061.88, 13192.14,
        13609.95, 13625.98, 13434.47, 13050.63, 12557.92, 12251.16, 12572.55,
        12202.35, 12264.87, 12535.66, 12486.92, 12406.4, 12504.51, 12866.4,
        13168.64, 13922.09, 13981.22, 13905.98, 13854.67, 14436.53, 14521.51,
        14715.82, 14634.25, 14262.74, 14126.43, 14203.45, 14697.21, 14847.37,
        14715.43, 14546.05, 14328.52, 14059.78, 14104.0, 13282.15, 13118.53,
        12704.4, 11834.59, 12160.11, 11961.85, 13486.83, 13147.47, 13183.53,
        12765.02, 12777.06, 13108.86, 12762.85, 12466.72, 12758.65, 12833.86,
        12841.61, 12818.03, 12845.5, 13236.81, 13076.39, 13866.12, 13811.81,
        13952.03, 13625.53, 12875.57, 12808.17, 12811.75, 12817.27, 12771.07,
        12400.0, 12841.61, 12520.3, 12577.86, 12139.41, 11607.0, 11674.08,
        11798.71, 12379.88, 12466.39, 12434.41, 12580.53, 13113.81, 12983.76,
        12858.99, 12543.03, 13034.19, 13130.42, 13618.39, 13670.37, 13681.2,
        13822.1, 13678.75, 13973.21, 13688.06, 14082.79, 14252.35, 14211.25,
        14210.03, 13735.66, 13148.43, 13166.54, 13175.96, 13461.12, 13423.55,
        13548.89, 13516.93, 13452.87, 13154.92, 13014.28, 13512.42, 13695.55,
        13596.32, 14378.71, 14442.24, 14667.78, 14592.92, 14757.71, 14784.63,
        14959.29, 14665.85, 14653.5, 14382.82, 14752.99, 14416.02, 14525.41,
        14740.05, 15137.67, 15721.51, 15664.69, 15249.82, 15024.8, 15023.5,
        14903.63, 14695.91, 15058.12, 16365.97, 16416.49, 16524.06, 16556.68,
        17442.52, 19036.16, 19168.73, 19411.75, 18962.75, 19734.76, 19544.43,
        19377.31, 19721.78, 19868.52, 20373.6, 21281.38, 21340.09, 21124.73,
        21204.34, 20426.39, 19865.26, 20781.88, 20696.26, 21051.31, 20872.46,
        21109.7, 20635.73, 20797.09, 21242.13, 20899.06, 21746.15, 21549.49,
        21684.7, 20984.52, 20955.4, 21898.84, 21733.16, 21902.64, 21878.92,
        22346.2, 22895.51, 22929.26, 21886.45, 21289.0, 21088.36, 21019.89,
        20673.68, 20596.74, 21372.49, 21379.87, 20733.08, 20433.58, 20625.86,
        20151.78, 20075.92, 20317.16
      ],
      ETH: [
        10000.0, 10222.13, 10412.02, 10206.08, 10381.89, 9613.79, 9328.99,
        8654.98, 8482.74, 8648.54, 8331.17, 8792.1, 9147.69, 8832.72, 9013.28,
        9078.87, 9108.21, 8684.36, 8648.02, 8506.16, 8326.52, 6924.57, 6472.31,
        6652.19, 6562.41, 6630.65, 6632.29, 6480.53, 6842.15, 7072.71, 7006.31,
        7290.08, 7584.0, 7287.52, 7236.27, 8040.33, 8213.04, 8131.16, 8573.16,
        8470.42, 8843.35, 8475.59, 7872.17, 7930.95, 7894.34, 8000.62, 8502.46,
        8531.21, 7886.81, 7547.27, 7461.67, 7214.46, 7122.62, 7084.44, 7172.89,
        6980.58, 7593.6, 7603.91, 7154.31, 7881.01, 8090.9, 8053.9, 7708.99,
        7104.32, 7263.04, 7151.6, 6811.92, 6986.48, 7389.86, 7106.23, 6992.0,
        7037.89, 6813.58, 6992.95, 7131.6, 7472.14, 7669.03, 7974.72, 7985.36,
        7825.41, 7910.53, 8159.99, 8172.94, 8460.84, 8429.62, 8525.8, 8894.5,
        9169.89, 9211.28, 9220.38, 8948.66, 9388.18, 9430.2, 9641.62, 9594.54,
        9368.27, 8673.78, 8802.61, 8679.33, 8833.67, 8765.91, 8161.05, 8198.1,
        8494.87, 8200.45, 8250.27, 8326.44, 8284.6, 8338.06, 8427.84, 8393.2,
        8115.13, 8047.73, 8039.4, 7967.38, 8185.65, 7675.36, 7820.73, 7966.48,
        7668.89, 7565.39, 7635.49, 7784.91, 7575.43, 7957.34, 7444.66, 7331.75,
        7080.49, 6907.78, 6238.37, 6311.25, 5668.11, 5234.12, 5600.33, 5592.25,
        5794.98, 5531.0, 5662.25, 5299.21, 5458.12, 5313.52, 5371.78, 5536.71,
        5380.78, 5393.79, 5345.59, 4952.42, 4738.93, 4853.72, 4925.32, 5438.78,
        5323.63, 4946.4, 4989.22, 4849.95, 4893.23, 4938.7, 5046.75, 4920.18,
        4896.57, 4865.94, 4557.2, 4211.19, 3961.7, 3246.78, 3201.13, 3312.17,
        2902.21, 2949.36, 2695.75, 3105.25, 3012.57, 3094.94, 2888.61, 3105.27,
        3346.63, 3379.55, 3302.92, 3270.45, 3123.44, 3016.87, 2784.76, 2896.12,
        2912.04, 2926.64, 3109.0, 3132.72, 3238.65, 3345.35, 3349.68, 3308.53,
        3178.91, 2988.72, 2837.07, 2955.09, 3229.73, 3397.64, 3694.22, 3700.26,
        4119.47, 4252.37, 4152.0, 4286.95, 4188.15, 4194.11, 4366.86, 4031.39,
        3838.12, 4422.74, 4748.72, 4738.14, 4621.39, 4582.66, 4430.35, 4481.11,
        4412.35, 4343.74, 4666.28, 4660.97, 4637.12, 4837.29, 4634.64, 5032.3,
        5095.14, 5254.43, 5382.34, 5257.42, 5155.42, 5091.82, 4975.53, 5078.3,
        4406.82, 4280.23, 4402.88, 4318.93, 4499.42, 4517.4, 4608.94, 4213.5,
        4037.37, 3983.59, 4191.14, 4204.34, 4266.79, 4315.64, 4299.3, 4230.34,
        4261.6, 4369.48, 4295.63, 4448.52, 4439.66, 4677.29, 4851.16, 4749.07,
        4640.82, 4317.77, 4458.1, 4012.37, 3896.03, 3985.98, 3647.18, 3769.54,
        3620.89, 3383.6, 3600.26, 3617.24, 3575.65, 3511.59, 3610.88, 3617.87,
        3639.71, 3615.88, 3597.41, 3571.02, 3509.03, 3578.51, 3696.92, 3687.09,
        3674.53, 3627.33, 3568.41, 3581.53, 3515.07, 3484.28, 3521.3, 3515.64,
        3524.75, 3457.35, 3558.86, 3620.86, 3570.5, 3499.16, 3489.89, 3540.04,
        3577.71, 3714.43, 3662.28, 3966.21, 4266.38, 4127.6, 4239.73, 4382.21,
        4317.44, 4259.34, 4293.83, 4111.55, 4165.16, 4487.37, 4440.2, 4357.23,
        4241.36, 3586.78, 3040.2, 3601.89, 3482.89, 3429.27, 3341.17, 3318.65,
        3388.99, 3307.65, 3282.41, 3291.49, 3330.02, 3108.81, 3013.88, 3094.59,
        3194.52, 3273.65, 3262.2, 3267.81, 3308.42, 3184.82, 3327.25, 3533.27,
        3475.03, 3510.61, 3378.49, 3469.83, 3424.97, 3408.06, 3346.88, 3493.53,
        3438.66, 3452.24, 3433.71, 3461.29, 3590.17, 3568.31, 3436.65, 3206.33,
        3225.69, 3230.41, 3162.27, 3306.35, 3290.51, 3307.55, 3314.35, 3314.73,
        3311.3, 3314.73, 3294.68, 3225.34, 3258.26, 3259.32, 3253.85, 3267.01,
        3305.31, 3298.73, 3409.23, 3402.27, 3453.75, 3435.88, 3453.24, 3593.14,
        3629.97, 3670.4, 3869.56, 3953.38, 4219.16, 4236.81, 4297.89, 4282.03,
        4165.63, 4220.36, 4493.58, 4431.07, 4430.38, 4427.01, 4249.36, 4314.07,
        4357.13, 4333.08, 4256.27, 4469.31, 4254.25, 4325.96, 4455.35, 4503.64,
        4535.11, 4569.91, 4445.5, 4441.65, 4529.94, 4490.74, 4183.61, 4103.41,
        4178.96, 4116.61, 4057.42, 4228.62, 4531.21, 4477.65, 4620.85, 4597.03,
        4585.0, 4605.05, 4496.83, 4446.33, 4480.38, 4365.56, 4329.74, 4443.52,
        4436.86, 4386.16, 4506.05, 4484.98, 4262.43, 4247.7, 4258.68, 4268.99,
        4225.2, 4204.29, 3896.76, 3873.94, 3970.62, 4255.21, 4570.72, 4628.12,
        4483.65, 4550.01, 4820.67, 4812.48, 4909.87, 4753.51, 4879.35, 4696.99,
        4919.44, 4741.98, 4727.2, 4831.25, 4650.12, 4816.02, 4870.54, 4854.08,
        4967.98, 4959.64, 4878.4, 4913.49, 5078.55, 5195.41, 5085.18, 5080.83,
        5034.91, 5075.33, 5179.08, 5152.32, 5203.73, 5460.43, 5725.35, 5699.48,
        5784.3, 5657.13, 5691.98, 5347.87, 5285.31, 5019.0, 5106.95, 5064.21,
        5002.6, 5080.23, 5073.14, 5182.84, 5152.24, 5177.94, 5147.64, 4967.02,
        5097.42, 5186.97, 5090.24, 5409.3, 5155.78, 5188.71, 5024.38, 5015.9,
        5011.71, 4868.25, 4902.99, 4901.3, 4896.38, 4959.38, 4959.97, 4951.59,
        4920.15, 4929.88, 4946.21, 4909.9, 4947.02, 5044.29, 4894.64, 4916.13,
        4967.83, 4978.11, 5186.62, 5147.21, 5180.31, 5085.16, 5079.8, 5185.15,
        5144.87, 5172.72, 4915.83, 5121.07, 4977.16, 5030.89, 5001.0, 4770.89,
        4770.81, 4741.27, 4719.18, 4488.27, 4530.46, 4679.3, 4707.35, 4685.29,
        4714.96, 4855.9, 5149.9, 5088.12, 5142.11, 5100.18, 5168.77, 5056.76,
        5130.31, 4971.95, 5036.65, 5262.43, 5230.2, 5282.73, 5318.87, 5281.42,
        5192.25, 5082.92, 5080.83, 5062.06, 5077.81, 5103.85, 5111.24, 5090.63,
        5416.47, 5240.18, 5246.14, 5259.16, 5194.97, 5171.87, 5136.13, 5153.32,
        5149.52, 5125.25, 5123.51, 5026.21, 5058.77, 5093.88, 5050.04, 5103.13,
        5113.26, 5048.21, 5049.74, 5035.92, 5012.2, 4999.17, 4974.12, 5000.61,
        4978.31, 4971.78, 5052.46, 5047.48, 5036.71, 5023.13, 5031.4, 5002.76,
        5020.79, 4973.49, 4929.53, 4611.54, 4521.98, 4533.02, 4580.27, 4534.81,
        4405.79, 4559.53, 4500.45, 4499.06, 4484.35, 4508.51, 4490.94, 4705.74,
        4628.33, 4487.37, 4424.89, 4452.44, 4449.62, 4418.44, 4438.65, 4438.29,
        4489.66, 4449.94, 4448.82, 4405.22, 4210.62, 4344.34, 4370.9, 4433.92,
        4486.97, 4450.18, 4408.56, 4466.72, 4466.54, 4414.82, 4313.8, 4338.03,
        4326.58, 4323.18, 4316.87, 4319.4, 4347.47, 4489.03, 4534.65, 4556.14,
        4718.28, 4519.04, 4508.89, 4484.43, 4395.16, 4474.58, 4447.96, 4437.02,
        4289.7, 4265.62, 4258.2, 4188.86, 4214.87, 4233.63, 4230.36, 4357.59,
        4249.43, 4256.02, 4254.96, 4374.87, 4435.14, 4533.42, 4856.53, 4878.91,
        4876.68, 4916.15, 4833.76, 4833.41, 4910.3, 4921.73, 4920.86, 5014.27,
        4885.22, 4981.2, 5053.58, 5129.09, 5176.74, 5112.6, 5144.1, 5696.3,
        5662.6, 5562.77, 5605.77, 5599.95, 5404.45, 5584.01, 5352.96, 5338.57,
        5323.9, 5388.21, 5505.81, 5283.73, 5616.79, 5619.65, 5641.46, 5665.8,
        5649.08, 5495.63, 5573.95, 5519.35, 5558.25, 5672.96, 5869.85, 5997.91,
        6060.75, 6206.55, 6076.31, 6391.93, 6430.09, 6401.69, 6423.4, 6047.75,
        5952.07, 6149.49, 6297.08, 6068.72, 6063.11, 6035.75, 6019.02, 5924.06,
        5962.98, 6106.12, 6291.75, 6246.7, 6146.52, 6215.5, 6060.42, 6432.49,
        6412.01, 6206.42, 6223.28, 6187.78, 6349.46, 6416.99, 5999.06, 6174.8,
        6114.28, 6086.26, 6028.98, 6358.77, 6367.67, 6929.92, 7114.41, 6796.64,
        7013.08, 6824.49, 6838.48, 7054.92, 6878.27, 6720.28, 6775.7, 6723.87,
        6697.56, 6312.5, 6056.24, 6042.55, 6042.63, 6169.39, 6165.18, 6118.75,
        6287.5, 6433.12, 6217.65, 6248.39, 6280.59, 6252.06, 6224.21, 6241.81,
        6478.79, 6597.93, 6594.48, 6787.44, 6789.54, 6801.48, 7222.27, 7170.07,
        7540.39, 7652.97, 7586.69, 7577.14, 7834.11, 8055.84, 8147.07, 7981.54,
        8127.59, 7950.83, 8128.9, 8439.08, 8663.72, 8834.19, 9086.29, 9038.09,
        9338.94, 9259.49, 9449.42, 9815.72, 9538.34, 10373.19, 10491.76,
        10545.81, 10611.63, 10401.14, 11065.12, 10744.14, 10834.77, 10547.79,
        10145.72, 9678.37, 9916.38, 9581.32, 8735.28, 9576.28, 9474.93, 8941.77,
        9186.32, 9361.6, 9824.6, 9806.3, 9561.84, 9702.04, 9580.17, 9538.04,
        9873.7, 9532.08, 8972.08, 9027.08, 9009.99, 9016.42, 9189.85, 9305.73,
        10122.43, 9571.49, 9624.56, 9535.94, 8756.12, 8211.71, 8586.76, 8505.53,
        8415.15, 8183.9, 8351.9, 8350.51, 8544.08, 8583.82, 8755.68, 8746.49,
        8520.15, 8595.22, 8520.69, 8827.49, 8951.05, 8746.49, 8188.04, 8077.73,
        8153.5, 8448.88, 8452.69, 8519.57, 8383.02, 8260.2, 8076.7, 8308.35,
        7903.65, 7932.02, 7975.48, 7998.06, 7860.2, 8196.85, 7996.07, 8408.04,
        8481.3, 8370.13, 9825.17, 10374.22, 10186.93, 10366.6, 10166.34,
        10167.1, 10440.21, 10575.67, 10508.05, 10280.48, 10193.71, 10256.11,
        10396.14, 10288.53, 10278.23, 10375.82, 10474.75, 10369.13, 10051.22,
        10001.26, 10085.71, 9995.65, 9522.26, 9715.12, 9471.09, 9479.88,
        9712.75, 9870.47, 9585.93, 9467.01, 9675.7, 9573.75, 9584.74, 9527.43,
        9317.73, 9065.46, 9257.15, 9195.13, 9388.04, 9178.76, 9185.14, 9386.39,
        9367.04, 9315.77, 8982.01, 8508.97, 8098.89, 8335.63, 7990.79, 8227.7,
        8322.98, 8435.11, 8440.66, 8506.76, 8682.59, 8829.4, 9446.48, 9326.59,
        9235.49, 9339.97, 9543.32, 9559.25, 9611.73, 9379.67, 9472.72, 9056.16,
        8609.48, 8921.26, 8909.1, 8867.05, 9056.53, 8919.58, 8822.46, 8729.62,
        8102.0, 7914.01, 7404.79, 6672.02, 6733.53, 6410.0, 7365.34, 7068.11,
        7089.79, 6984.23, 7334.47, 7381.17, 7263.15, 7008.41, 7061.31, 7096.62,
        7199.2, 7134.46, 7037.07, 7156.17, 7138.73, 7507.31, 7485.39, 7537.73,
        7308.03, 6692.7, 6888.69, 6866.28, 6871.5, 6846.69, 6545.62, 6923.23,
        6674.21, 6699.01, 6451.37, 6010.18, 6160.75, 6238.62, 6412.44, 6502.75,
        6365.19, 6422.32, 6655.73, 6574.48, 6382.68, 6214.09, 6359.21, 6364.54,
        6724.28, 6938.03, 7006.88, 7045.08, 7198.52, 7252.49, 6997.41, 7175.04,
        7359.45, 7276.48, 7224.31, 7078.96, 6675.13, 6479.79, 6398.08, 6585.31,
        6577.39, 6615.23, 6573.85, 6642.02, 6456.23, 6468.47, 6635.55, 6765.95,
        6710.98, 7207.93, 7057.5, 7132.07, 7106.96, 7171.65, 7210.64, 7473.48,
        7282.77, 7169.93, 6829.31, 6890.81, 6703.06, 6764.46, 6839.86, 6987.57,
        7172.11, 7235.1, 6859.26, 6836.44, 6777.19, 6692.16, 6545.3, 6623.06,
        7440.53, 7837.95, 8048.65, 8526.04, 8662.03, 9090.29, 8945.04, 8649.62,
        8399.91, 8433.89, 8522.29, 8345.73, 8566.14, 8412.61, 8362.86, 9170.64,
        8998.73, 9264.57, 9136.61, 9387.74, 9045.65, 9971.9, 9772.57, 9781.68,
        10088.47, 10122.36, 9833.52, 9880.84, 10450.93, 10345.71, 10945.84,
        10865.21, 10908.84, 10103.26, 9883.01, 10434.64, 10561.72, 10599.04,
        10505.03, 10579.34, 10965.14, 10563.35, 10012.3, 9410.21, 9444.46,
        9059.55, 8946.55, 9410.95, 9500.57, 9454.25, 9088.01, 9033.28, 9277.46,
        9086.59, 9136.89, 9120.08
      ],
      BNB: [
        10000.0, 10275.72, 10387.17, 10023.47, 9974.58, 9305.83, 9317.56,
        8760.27, 8506.07, 8609.7, 8273.37, 9026.21, 9485.73, 9325.38, 9587.41,
        9696.91, 9763.4, 9256.94, 9247.16, 9159.17, 8756.35, 7465.78, 6941.73,
        7260.46, 7195.94, 7471.64, 7291.75, 7573.33, 7508.81, 7622.21, 7356.27,
        7354.32, 7543.99, 7217.44, 7217.44, 7737.58, 8101.3, 8046.54, 8502.15,
        8023.08, 8277.28, 8201.02, 7706.29, 7884.24, 7815.8, 7888.15, 8410.25,
        8371.14, 7919.44, 7774.74, 7800.16, 7524.44, 7104.02, 7252.64, 7254.59,
        6937.82, 7326.94, 7303.49, 7102.07, 7692.61, 7970.28, 8007.43, 7868.6,
        7338.68, 7520.53, 7442.32, 7469.69, 7436.45, 7661.32, 7280.02, 7303.49,
        7332.81, 7082.52, 7227.22, 7276.11, 7487.29, 7653.5, 7735.63, 7802.11,
        7686.74, 7755.18, 7940.95, 7948.76, 8093.47, 8025.03, 8103.25, 8388.73,
        8498.24, 8470.87, 8664.45, 8437.62, 8705.52, 8629.25, 8818.92, 8756.35,
        8779.82, 8275.32, 8537.35, 8246.0, 8289.01, 8214.7, 7747.36, 8066.1,
        8283.15, 8103.25, 8142.35, 8152.13, 8093.47, 8169.73, 8228.4, 8201.02,
        7915.53, 7960.5, 7903.79, 7831.44, 7894.01, 7545.96, 7622.21, 7983.96,
        7690.66, 7538.13, 7598.74, 7600.71, 7487.29, 7862.73, 7356.27, 7430.59,
        7088.39, 6978.88, 6028.55, 6138.06, 5295.27, 5215.1, 5784.12, 5766.52,
        6052.01, 5840.83, 5960.11, 5725.46, 5958.16, 5917.09, 6120.46, 6224.09,
        6261.25, 6415.72, 6437.23, 6063.74, 5934.69, 6007.04, 5981.62, 6296.44,
        6300.35, 5883.84, 6024.64, 5827.14, 5897.54, 5860.39, 5789.99, 5658.97,
        5668.76, 5658.97, 5621.82, 5342.2, 5039.11, 4301.91, 4266.72, 4518.96,
        4098.56, 4227.61, 3887.37, 4211.97, 4219.79, 4342.98, 4227.61, 4470.08,
        4710.6, 4669.53, 4608.91, 4583.5, 4440.75, 4323.43, 4170.91, 4247.16,
        4280.41, 4274.54, 4501.36, 4560.03, 4665.63, 4710.6, 4732.11, 4751.66,
        4579.58, 4417.29, 4344.94, 4429.01, 4636.29, 4693.0, 4904.18, 4892.45,
        5078.22, 5281.58, 5043.02, 5172.08, 5164.25, 5066.48, 5117.32, 4898.32,
        4829.88, 5263.98, 5434.1, 5809.54, 5658.97, 5543.61, 5531.87, 5565.12,
        5864.29, 6079.39, 6184.98, 6198.68, 6341.41, 6337.51, 6378.57, 6400.08,
        6317.95, 6353.15, 6360.97, 6233.86, 6212.36, 6179.11, 5977.71, 5915.14,
        5482.99, 5520.14, 5909.27, 5866.24, 5844.74, 5799.76, 5897.54, 5529.92,
        5408.69, 5461.47, 5561.21, 5531.87, 5482.99, 5434.1, 5426.29, 5426.29,
        5424.32, 5396.95, 5187.72, 5434.1, 5494.72, 5727.42, 5817.36, 5739.14,
        5705.91, 5420.42, 5469.3, 5281.58, 5365.67, 5461.47, 5207.27, 5305.05,
        5222.92, 5136.88, 5371.53, 5404.77, 5400.86, 5334.37, 5371.53, 5332.42,
        5502.54, 5512.32, 5514.27, 5543.61, 5584.67, 5588.59, 5782.17, 5746.97,
        5555.34, 5526.01, 5402.82, 5426.29, 5326.55, 5297.22, 5295.27, 5340.24,
        5262.02, 5248.34, 5334.37, 5379.35, 5342.2, 5314.82, 5260.07, 5275.72,
        5285.49, 5396.95, 5350.02, 5578.8, 5694.17, 5592.49, 5838.87, 5917.09,
        6106.76, 6423.55, 6359.01, 6253.43, 6501.76, 6888.93, 6840.05, 6824.4,
        6507.63, 6175.21, 5263.98, 5979.66, 5647.24, 5533.82, 5406.72, 5391.09,
        5387.17, 5316.77, 5250.29, 5344.15, 5338.29, 5170.12, 4966.76, 5211.19,
        5795.86, 5877.98, 5868.21, 6069.61, 6138.06, 5727.42, 5809.54, 5876.03,
        5725.46, 5707.86, 5684.39, 5696.13, 5635.51, 5647.24, 5555.34, 5664.84,
        5590.54, 5629.64, 5582.72, 5410.64, 5312.87, 5238.57, 5029.33, 4501.36,
        4696.91, 4913.96, 4698.86, 4908.1, 4822.06, 4802.5, 4812.28, 4779.03,
        4749.71, 4743.84, 4814.23, 4759.48, 4804.46, 4794.68, 4816.2, 4786.86,
        4810.33, 4810.33, 5039.11, 5023.47, 5080.17, 5103.64, 5314.82, 5346.11,
        5412.59, 5482.99, 5610.09, 5731.32, 5967.93, 5921.01, 5860.39, 5881.89,
        5676.57, 5746.97, 5928.82, 5889.71, 5897.54, 5979.66, 5911.23, 5962.06,
        5954.24, 6005.08, 5960.11, 6220.18, 5983.58, 6112.63, 6200.63, 6386.39,
        6464.61, 6472.43, 6415.72, 6386.39, 6499.8, 6405.95, 5948.38, 5967.93,
        6077.43, 6181.08, 5696.13, 5788.04, 6179.11, 6010.95, 6132.19, 6184.98,
        6120.46, 6134.14, 6083.3, 6077.43, 6032.46, 5885.81, 5897.54, 6028.55,
        5956.2, 5919.04, 5905.36, 5870.16, 5655.07, 5633.56, 5651.16, 5625.74,
        5623.77, 5619.87, 5400.86, 5416.5, 5355.89, 5563.16, 6046.15, 6044.19,
        5973.79, 6468.52, 6583.88, 6493.93, 6619.08, 6576.07, 6558.47, 6286.66,
        6419.63, 6290.58, 6292.53, 6427.45, 6036.37, 6104.81, 6140.01, 6173.25,
        6198.68, 6165.43, 6118.5, 6008.99, 6081.34, 6151.74, 6108.73, 6079.39,
        6073.53, 6122.41, 6169.34, 6341.41, 6245.6, 6341.41, 6443.1, 6527.18,
        6838.09, 6662.1, 6705.12, 6349.23, 6204.54, 6269.06, 6495.9, 6462.65,
        6480.25, 6591.71, 6450.92, 6503.72, 6335.55, 6302.31, 6603.45, 6425.5,
        6312.08, 6388.35, 6321.86, 6396.17, 6310.13, 6310.13, 6128.28, 6091.13,
        6165.43, 5993.36, 6008.99, 6093.08, 6098.94, 6138.06, 6083.3, 6132.19,
        6069.61, 6042.24, 6065.71, 5997.26, 6050.06, 6128.28, 5977.71, 5952.29,
        5999.22, 5995.31, 6136.1, 6079.39, 6096.99, 5987.49, 5977.71, 6012.91,
        5989.44, 5983.58, 5375.44, 5516.24, 5048.88, 5144.7, 5105.6, 4679.31,
        4597.18, 4513.1, 4720.38, 4620.65, 4624.56, 4677.36, 4802.5, 4757.53,
        4745.8, 4781.0, 4884.63, 4696.91, 4784.9, 4616.74, 4675.4, 4618.7,
        4648.03, 4515.06, 4558.08, 4712.55, 4843.57, 4825.97, 4818.15, 4747.75,
        4673.45, 4569.81, 4605.01, 4616.74, 4581.54, 4767.3, 4865.08, 4767.3,
        4974.58, 4816.2, 4917.87, 4782.95, 4765.35, 4704.73, 4714.51, 4749.71,
        4763.4, 4728.2, 4730.15, 4663.67, 4648.03, 4677.36, 4698.86, 4736.02,
        4736.02, 4728.2, 4724.28, 4818.15, 4718.42, 4714.51, 4722.33, 4753.62,
        4753.62, 4730.15, 4796.63, 4767.3, 4716.46, 4677.36, 4702.78, 4698.86,
        4700.82, 4634.33, 4534.61, 4323.43, 4227.61, 4235.43, 4243.26, 4123.97,
        4080.96, 4227.61, 4254.99, 4262.81, 4231.52, 4268.68, 4272.59, 4440.75,
        4364.49, 4243.26, 4168.94, 4190.46, 4186.54, 4202.19, 4192.41, 4206.11,
        4249.12, 4194.37, 4194.37, 4159.17, 4032.07, 4139.62, 4159.17, 4159.17,
        4196.32, 4204.14, 4233.48, 4237.39, 4245.21, 4182.64, 4122.02, 4131.79,
        4110.29, 4102.46, 4104.42, 4137.66, 4141.57, 4204.14, 4204.14, 4206.11,
        4282.36, 4194.37, 4174.81, 4174.81, 4125.93, 4178.73, 4149.39, 4129.84,
        4026.21, 4082.91, 4034.02, 4014.47, 4028.16, 4037.94, 4094.64, 4208.06,
        4131.79, 4122.02, 4112.24, 4159.17, 4200.24, 4260.86, 4495.5, 4436.84,
        4352.76, 4376.23, 4376.23, 4411.42, 4454.44, 4460.31, 4423.16, 4440.75,
        4509.19, 4503.33, 4649.98, 4779.03, 4984.35, 4812.28, 4820.1, 4855.3,
        4913.96, 4910.05, 4859.21, 4722.33, 4739.93, 4943.3, 4749.71, 4784.9,
        4775.13, 4782.95, 4972.63, 4399.69, 4620.65, 4573.71, 4550.26, 4565.9,
        4544.39, 4429.01, 4487.68, 4446.61, 4444.66, 4462.26, 4470.08, 4470.08,
        4534.61, 4507.23, 4495.5, 4540.48, 4659.76, 4675.4, 4687.13, 4786.86,
        4986.31, 4955.03, 4960.9, 4800.55, 4792.73, 4714.51, 4700.82, 4929.6,
        5070.4, 5307.0, 5320.69, 5314.82, 5156.43, 5228.78, 5750.88, 6212.36,
        6360.97, 6079.39, 6169.34, 6077.43, 6132.19, 6098.94, 6190.85, 6290.58,
        6188.89, 6010.95, 5895.58, 5981.62, 5877.98, 5995.31, 6008.99, 5772.39,
        5915.14, 5930.78, 6184.98, 6173.25, 6077.43, 6112.63, 6126.32, 6196.71,
        6253.43, 5989.44, 5813.46, 5725.46, 5713.72, 5909.27, 5979.66, 5950.33,
        6063.74, 6052.01, 5891.67, 5860.39, 5891.67, 5866.24, 5977.71, 5874.07,
        5932.74, 6022.68, 6239.73, 6325.78, 6327.73, 6272.98, 6421.58, 6347.28,
        6527.18, 6869.38, 7029.72, 6902.62, 6908.49, 6908.49, 6935.87, 7332.81,
        7524.44, 7319.12, 7469.69, 7631.99, 7851.0, 7743.45, 8056.31, 7747.36,
        7944.86, 7995.7, 8114.98, 8193.2, 7665.23, 8341.8, 9184.59, 9374.26,
        9544.39, 10179.9, 10211.19, 10492.77, 12031.68, 11808.77, 11937.82,
        11460.7, 11271.03, 10827.14, 9960.9, 10874.07, 10817.36, 10590.54,
        10915.14, 11157.61, 11509.58, 11351.2, 11247.56, 11439.18, 12088.39,
        11808.77, 11845.92, 11300.35, 10907.31, 11012.91, 11376.61, 11353.15,
        11499.8, 11380.53, 11533.05, 11396.17, 11888.93, 11857.65, 11628.86,
        10850.61, 11071.57, 10895.58, 10574.89, 10520.14, 10774.34, 10885.81,
        11122.41, 11345.33, 11828.32, 11912.4, 11853.74, 11990.62, 11699.25,
        11624.95, 11742.28, 11626.9, 11298.4, 10944.46, 11003.13, 11482.21,
        11441.15, 11525.23, 11552.6, 11366.83, 11493.93, 11707.08, 11458.75,
        11589.75, 11638.64, 11576.07, 11069.61, 11431.36, 11122.41, 11349.24,
        11302.31, 11255.38, 11578.02, 12090.34, 12025.82, 11693.4, 11773.57,
        11759.88, 11750.1, 11808.77, 11785.3, 11664.06, 11634.73, 11626.9,
        11754.01, 11773.57, 12280.02, 13343.77, 13605.79, 13916.7, 13394.6,
        13349.63, 13167.78, 12086.43, 11800.94, 12127.49, 11765.74, 11763.78,
        11896.75, 11941.73, 11853.74, 11523.27, 11695.35, 11572.15, 11462.65,
        11542.83, 11341.41, 11030.51, 11288.62, 11198.68, 11345.33, 11093.08,
        11132.19, 11433.32, 11308.18, 11339.46, 10893.63, 10256.16, 9708.65,
        10332.42, 9636.29, 9994.13, 10082.13, 10240.52, 10263.98, 10420.42,
        10434.1, 10651.16, 11380.53, 11194.76, 11130.23, 11188.89, 11605.4,
        11601.48, 11742.28, 11560.42, 11392.26, 11216.26, 11186.94, 11378.57,
        11419.63, 11386.39, 11290.58, 11388.35, 11314.04, 11259.29, 10606.18,
        10322.65, 9849.43, 9139.62, 9526.79, 9309.74, 10148.62, 9958.93,
        10238.57, 9910.05, 10058.67, 10258.12, 10248.34, 10170.12, 10168.17,
        10586.62, 10486.9, 10928.82, 11218.23, 11143.92, 11468.52, 11587.8,
        11286.66, 11302.31, 10727.42, 10383.27, 10508.41, 10484.94, 10471.25,
        10434.1, 9947.2, 10320.69, 10238.57, 9990.22, 9814.23, 9466.18, 9614.78,
        9798.6, 10152.52, 10179.9, 10363.71, 10602.27, 10913.18, 10805.63,
        10960.11, 10369.57, 10664.84, 10799.77, 11067.66, 11110.68, 11491.98,
        11505.67, 11855.69, 11886.98, 11480.25, 11654.28, 11906.53, 11744.23,
        11677.75, 11151.74, 10709.82, 10658.97, 10655.07, 10874.07, 11026.59,
        11095.03, 11104.81, 11351.2, 11155.65, 10971.84, 11196.71, 11298.4,
        11175.21, 11568.25, 11576.07, 11791.17, 11581.93, 11710.99, 11687.53,
        11824.4, 11754.01, 11644.5, 11396.17, 11601.48, 11364.88, 11411.81,
        11536.96, 11769.65, 11836.14, 11669.93, 11274.93, 11188.89, 11116.54,
        10924.91, 10789.2, 11048.7, 11616.34, 11678.34, 11657.41, 12196.13,
        12252.44, 12502.74, 12412.01, 12033.25, 12330.86, 12099.92, 12151.93,
        12055.93, 12105.99, 11972.63, 11908.1, 12173.65, 12279.82, 12795.66,
        12938.02, 12491.79, 12000.2, 12590.93, 12889.91, 12762.61, 12814.04,
        12850.61, 12540.87, 14381.3, 14351.2, 13978.5, 14342.98, 14637.27,
        14538.13, 13337.9, 13215.69, 13877.4, 13844.54, 14211.19, 13975.76,
        13965.59, 14164.65, 14109.89, 13584.67, 13202.78, 13253.81, 12930.78,
        12713.54, 13648.42, 13610.09, 13709.42, 13512.91, 13502.15, 14147.05,
        13558.67, 13775.91, 13742.67
      ],
      SOL: [
        10000.0, 10427.68, 10402.38, 10005.89, 9937.06, 8917.0, 8940.53,
        8045.77, 8486.39, 8319.9, 7936.95, 8250.49, 8948.76, 8628.16, 8644.04,
        8735.22, 8695.81, 8183.43, 8286.96, 8061.06, 7768.11, 6486.27, 5483.26,
        5653.87, 5419.74, 5500.91, 5393.85, 5196.78, 5315.61, 5611.51, 5466.79,
        5875.65, 6465.09, 5952.71, 5917.99, 6470.39, 6749.81, 6635.1, 6946.88,
        6652.74, 6763.34, 6359.79, 5622.68, 5655.63, 5514.45, 5696.81, 6038.59,
        6033.89, 5533.86, 5285.6, 5333.26, 5470.91, 5015.0, 5005.59, 5093.24,
        5129.13, 5573.27, 5336.79, 5029.12, 5787.4, 5759.16, 5945.65, 5631.51,
        5203.24, 5297.96, 5043.24, 4838.52, 4809.1, 5169.13, 4906.76, 4775.58,
        4815.58, 4605.57, 4752.63, 4900.88, 5055.59, 5205.01, 5229.13, 5438.56,
        5260.9, 5219.14, 5382.67, 5549.74, 6022.71, 5766.22, 5962.7, 6203.31,
        6442.74, 6517.44, 7066.3, 7348.08, 8034.01, 7896.35, 8092.25, 7771.64,
        7567.51, 6775.69, 6973.35, 6502.74, 6525.1, 6556.27, 5879.17, 6001.53,
        6194.48, 5914.47, 5943.88, 6005.06, 5999.18, 6026.24, 6345.08, 6263.31,
        5971.53, 5927.41, 5965.65, 5854.47, 5923.88, 5668.57, 5762.1, 5760.34,
        5533.86, 5021.48, 5241.49, 5151.48, 5049.12, 5417.97, 4934.41, 4829.69,
        4579.68, 4461.44, 3858.46, 3832.0, 2810.75, 2543.1, 2949.0, 3073.12,
        3396.09, 3197.25, 3326.67, 2993.71, 3021.95, 2925.46, 2957.82, 3089.01,
        2899.0, 2919.0, 2864.28, 2588.97, 2431.91, 2588.97, 2638.39, 2793.69,
        2710.16, 2373.08, 2410.73, 2253.66, 2254.25, 2303.67, 2501.33, 2323.67,
        2294.84, 2350.13, 2217.19, 2036.0, 1841.87, 1598.91, 1678.33, 2007.77,
        1780.69, 1819.52, 1863.05, 2040.71, 2050.71, 2156.01, 2051.88, 2220.13,
        2484.27, 2471.91, 2338.96, 2276.6, 2076.0, 2008.36, 1911.29, 1937.17,
        1970.12, 1958.94, 2146.01, 2117.77, 2165.43, 2253.07, 2266.02, 2246.61,
        2163.66, 1972.46, 1942.47, 2008.36, 2161.89, 2224.25, 2341.91, 2304.85,
        2412.49, 2663.1, 2496.03, 2516.62, 2414.85, 2350.72, 2416.61, 2223.66,
        2113.65, 2344.85, 2547.2, 2514.85, 2597.8, 2501.33, 2433.67, 2422.5,
        2281.9, 2268.96, 2377.2, 2379.55, 2397.79, 2483.68, 2374.84, 2468.38,
        2508.38, 2633.68, 2736.05, 2654.27, 2546.04, 2533.09, 2374.26, 2394.85,
        2129.54, 2064.24, 2151.89, 2050.13, 2086.6, 2083.65, 2075.41, 1900.11,
        1847.17, 1841.28, 1886.58, 1869.53, 1878.93, 1850.11, 1833.63, 1829.52,
        1875.99, 1894.23, 1832.47, 1924.82, 1972.46, 2041.3, 2076.59, 2048.95,
        2193.66, 1964.24, 2006.0, 1943.65, 1887.76, 1976.58, 1840.11, 1932.46,
        1865.99, 1802.46, 1904.82, 1985.41, 1969.53, 1899.52, 1966.59, 1931.88,
        1956.0, 1988.36, 1930.71, 1915.4, 1895.4, 1933.05, 2000.71, 2003.65,
        1961.88, 1947.18, 1911.88, 1924.82, 1886.58, 1840.7, 1831.29, 1801.87,
        1761.28, 1744.81, 1780.69, 1824.23, 1775.4, 1716.57, 1661.27, 1658.33,
        1647.75, 1717.16, 1673.04, 1823.05, 1844.23, 1804.22, 1891.88, 1926.0,
        1935.41, 1918.35, 1904.82, 1797.75, 1817.75, 1993.05, 2173.65, 1954.82,
        1730.69, 1383.02, 836.52, 1057.71, 940.06, 877.11, 804.75, 807.69,
        835.93, 842.4, 805.93, 774.75, 757.69, 724.74, 694.16, 721.81, 830.05,
        858.29, 831.81, 827.11, 847.11, 785.34, 789.46, 817.11, 794.75, 807.1,
        783.57, 802.98, 815.34, 828.87, 797.11, 804.16, 797.7, 798.87, 792.99,
        782.4, 810.64, 835.93, 823.57, 734.16, 731.22, 731.81, 693.57, 722.4,
        707.69, 693.57, 692.39, 671.8, 668.86, 657.69, 649.45, 571.8, 538.86,
        577.68, 585.32, 586.5, 663.57, 786.51, 797.11, 790.05, 786.51, 767.1,
        806.52, 959.47, 958.3, 952.41, 964.17, 1063.0, 1389.5, 1361.84, 1391.26,
        1364.2, 1244.19, 1260.66, 1483.62, 1459.49, 1424.2, 1434.21, 1352.44,
        1423.61, 1430.09, 1418.31, 1403.61, 1545.39, 1397.72, 1414.21, 1467.15,
        1445.97, 1453.03, 1448.32, 1387.14, 1353.02, 1391.26, 1361.84, 1192.43,
        1176.54, 1222.42, 1269.49, 1202.42, 1276.55, 1398.31, 1318.31, 1363.61,
        1387.73, 1482.44, 1543.03, 1461.85, 1418.31, 1401.85, 1341.84, 1306.55,
        1356.56, 1335.38, 1302.43, 1318.31, 1297.73, 1257.73, 1218.89, 1238.31,
        1218.31, 1179.48, 1080.65, 1007.71, 1064.18, 1055.95, 1166.54, 1208.31,
        1228.9, 1131.83, 1154.78, 1246.54, 1266.54, 1294.2, 1318.9, 1321.84,
        1261.25, 1296.55, 1208.9, 1196.55, 1237.14, 1165.36, 1202.42, 1242.42,
        1201.83, 1258.89, 1245.37, 1208.9, 1203.01, 1232.43, 1230.66, 1211.25,
        1205.36, 1173.01, 1197.72, 1225.95, 1364.79, 1393.03, 1434.79, 1453.62,
        1418.9, 1495.39, 1458.32, 1455.38, 1345.96, 1307.72, 1249.49, 1285.96,
        1263.6, 1256.55, 1276.55, 1247.72, 1308.9, 1368.32, 1355.97, 1361.25,
        1292.43, 1309.49, 1304.19, 1274.2, 1344.2, 1288.9, 1303.6, 1214.78,
        1212.43, 1237.73, 1186.54, 1218.31, 1229.49, 1232.43, 1241.83, 1214.78,
        1234.19, 1203.01, 1197.72, 1190.66, 1156.54, 1153.01, 1178.9, 1131.83,
        1131.24, 1140.06, 1198.31, 1232.43, 1210.66, 1249.49, 1221.83, 1209.48,
        1254.19, 1243.6, 1299.48, 1175.96, 1199.48, 1093.01, 1113.6, 1026.53,
        925.93, 912.41, 894.17, 877.7, 847.69, 868.87, 902.99, 925.35, 904.76,
        939.47, 971.82, 1015.36, 977.71, 1002.41, 973.59, 1001.23, 957.71,
        980.06, 941.24, 1053.0, 1125.95, 1084.77, 1147.71, 1121.83, 1144.77,
        1107.71, 1194.19, 1264.78, 1288.9, 1258.89, 1251.25, 1294.79, 1293.02,
        1516.56, 1548.92, 1608.33, 1623.62, 1566.56, 1500.09, 1552.45, 1500.67,
        1514.8, 1481.26, 1451.85, 1377.15, 1362.43, 1490.09, 1478.32, 1464.79,
        1485.97, 1417.73, 1398.9, 1392.44, 1365.96, 1336.55, 1341.84, 1333.02,
        1367.73, 1355.38, 1417.73, 1432.44, 1447.73, 1445.38, 1470.09, 1428.91,
        1475.39, 1402.43, 1343.61, 1294.2, 1258.89, 1281.84, 1282.43, 1250.07,
        1196.55, 1266.54, 1231.84, 1197.72, 1195.37, 1223.6, 1207.13, 1285.37,
        1225.95, 1164.18, 1133.01, 1147.13, 1147.71, 1137.72, 1189.48, 1152.42,
        1172.42, 1153.01, 1146.54, 1078.89, 1033.59, 1059.48, 1083.59, 1114.19,
        1126.54, 1121.83, 1105.36, 1165.36, 1180.07, 1193.6, 1150.07, 1145.36,
        1141.83, 1147.71, 1139.48, 1114.19, 1121.24, 1167.13, 1193.01, 1260.07,
        1434.21, 1373.03, 1385.96, 1364.79, 1338.9, 1383.02, 1370.67, 1367.14,
        1299.48, 1303.02, 1291.84, 1255.37, 1281.84, 1295.96, 1286.55, 1420.08,
        1410.67, 1390.08, 1459.49, 1607.74, 1748.93, 1721.28, 1906.59, 1783.64,
        1892.47, 1933.05, 1857.75, 1860.7, 1938.94, 2056.0, 2231.89, 2488.97,
        2354.84, 2337.79, 2494.85, 2407.79, 2428.96, 2533.09, 2544.27, 2670.74,
        3330.79, 3423.73, 3357.85, 3073.12, 3363.14, 3830.23, 3416.68, 3454.33,
        3431.97, 3571.98, 3320.19, 3048.41, 3411.97, 3343.72, 3345.49, 3450.21,
        3401.96, 3220.2, 3401.96, 3480.2, 3486.09, 3536.1, 3697.86, 3749.64,
        3584.33, 3555.51, 3690.22, 3994.94, 4325.55, 4346.73, 4363.79, 4102.01,
        3984.94, 4194.36, 4454.38, 4393.79, 4352.02, 4247.31, 4384.97, 4284.37,
        4802.05, 5563.27, 5709.75, 6400.97, 6586.28, 7043.95, 6568.63, 6306.85,
        6103.3, 6175.66, 5989.18, 5913.88, 6358.61, 6352.14, 5812.7, 6163.89,
        5836.23, 5494.45, 5252.66, 5765.05, 5855.06, 6075.06, 5900.35, 5310.31,
        5657.4, 5686.81, 5542.09, 5773.29, 5953.3, 5568.57, 5496.8, 5477.98,
        5382.08, 4939.71, 4863.82, 5147.36, 5133.83, 5429.73, 5511.5, 5579.16,
        5936.23, 6086.24, 5747.4, 5713.28, 5902.11, 5762.1, 5633.87, 5573.86,
        5733.28, 5939.17, 6037.41, 6346.26, 6435.08, 6326.26, 6563.92, 6590.97,
        6889.82, 6589.81, 6453.91, 6425.68, 6635.69, 6592.15, 6403.32, 6154.48,
        6057.41, 5872.7, 6125.07, 6089.19, 6451.55, 6380.38, 6813.93, 7273.96,
        7686.34, 7547.5, 7703.39, 7693.99, 7171.01, 7687.51, 8514.62, 8476.97,
        8526.39, 8343.44, 8698.16, 8727.58, 9615.87, 10172.37, 10832.4,
        10648.28, 11833.05, 11656.57, 9956.48, 11172.42, 10489.45, 10018.82,
        10280.02, 10881.23, 11234.19, 11309.49, 10998.89, 11173.01, 11308.9,
        11540.69, 11801.88, 11344.21, 10830.06, 10950.07, 10723.58, 10259.43,
        10506.51, 10504.74, 10655.92, 10235.32, 10233.55, 10162.36, 8912.88,
        8295.19, 8859.94, 8232.26, 8094.01, 7916.94, 8405.79, 8451.09, 8775.22,
        8762.29, 9296.43, 9134.66, 8642.87, 8546.39, 8189.31, 8275.78, 8181.07,
        8096.95, 7493.39, 7846.35, 8161.66, 8434.02, 8588.75, 8539.33, 9094.07,
        8825.23, 8325.79, 8997.59, 8578.15, 8576.98, 8425.79, 8642.87, 8415.79,
        9169.95, 9345.25, 9921.17, 10119.42, 10084.13, 10918.29, 10500.62,
        10441.79, 10367.09, 9838.82, 9878.83, 9633.51, 10068.24, 9943.53,
        9979.41, 9835.88, 9774.11, 9807.05, 9605.86, 9732.93, 10048.24,
        10187.66, 10032.95, 9594.1, 9324.09, 9525.27, 9375.26, 8793.46, 9153.48,
        8716.4, 8431.09, 8557.56, 8847.59, 8460.5, 8096.95, 7924.59, 7878.12,
        7931.65, 7898.7, 7601.62, 7659.87, 8053.42, 8082.83, 8836.4, 8237.54,
        8239.31, 8649.93, 8635.21, 9101.72, 8315.78, 7792.23, 7896.94, 8463.44,
        7826.93, 8253.43, 8314.61, 8328.14, 8045.77, 8164.01, 8365.2, 8658.16,
        9340.56, 9407.61, 9177.01, 9410.56, 9929.41, 10199.42, 10821.23,
        10501.8, 10171.78, 10434.15, 10135.31, 10779.46, 10923.59, 10790.64,
        10783.58, 10549.45, 10196.49, 9851.76, 8988.76, 8402.85, 8279.31,
        7795.17, 8537.56, 8579.33, 9500.57, 9162.9, 9098.19, 8364.61, 8478.74,
        8653.45, 8491.68, 8452.86, 8212.26, 8325.79, 8505.8, 8446.97, 8426.38,
        8406.38, 8424.03, 8932.29, 9357.62, 9448.79, 9261.73, 8675.81, 8444.03,
        8225.19, 8100.48, 7964.59, 7595.16, 7929.88, 7617.51, 7886.94, 7622.21,
        7336.91, 7459.86, 7612.22, 7909.29, 7999.29, 7799.28, 8021.65, 8198.72,
        8068.71, 7758.11, 7689.87, 7748.11, 7732.22, 8378.14, 8655.22, 8738.75,
        8558.15, 8494.62, 9021.12, 8775.22, 9197.02, 9298.2, 9219.37, 9354.67,
        9082.89, 8511.1, 8327.55, 8055.78, 8408.15, 8410.5, 8550.51, 8498.15,
        8426.38, 8188.72, 8151.07, 8579.92, 8651.11, 8695.22, 9293.49, 8999.95,
        9108.18, 8878.76, 9076.42, 9348.2, 9805.87, 9871.17, 9940.0, 10055.89,
        10514.74, 9635.28, 10081.18, 10457.09, 10508.86, 10564.16, 10315.32,
        9971.77, 9756.46, 9759.41, 9579.39, 9295.85, 9847.05, 11120.66,
        11485.97, 11701.29, 11768.34, 12384.85, 12957.24, 12650.75, 12533.1,
        12475.45, 12838.41, 12600.74, 13809.05, 14084.95, 13850.82, 13909.06,
        14996.18, 14991.48, 14997.95, 14910.89, 13927.29, 13525.51, 14157.9,
        13998.48, 14343.2, 14024.95, 14017.3, 13203.14, 13930.24, 13472.56,
        13826.7, 13967.3, 14040.25, 13922.59, 12816.64, 12617.21, 13428.44,
        13424.92, 13157.25, 12824.88, 12901.35, 12817.23, 13163.13, 12144.25,
        11581.28, 11333.61, 10658.87, 10672.4, 11325.38, 11587.74, 11574.8,
        11121.25, 10873.58, 11481.86, 11148.31, 11245.97, 11363.03
      ],
      XRP: [
        10000.0, 10213.33, 10315.77, 10045.8, 10002.42, 9181.63, 9445.58,
        9180.43, 9082.81, 9141.86, 8848.98, 9257.56, 9610.7, 9258.77, 9369.65,
        9419.07, 9390.15, 9152.71, 9121.37, 8959.87, 8842.95, 7636.49, 7064.0,
        7344.83, 7331.57, 7500.3, 7425.58, 7259.25, 7318.31, 7444.86, 7246.0,
        7337.59, 7572.62, 7262.87, 7295.41, 7720.86, 8039.05, 8096.9, 9948.18,
        10386.89, 10800.29, 10178.38, 9071.96, 9973.49, 9787.88, 9675.79,
        9985.53, 10121.73, 9363.63, 9462.46, 9852.96, 9533.57, 8754.97, 8557.31,
        8498.25, 8184.89, 9080.39, 9046.65, 8692.3, 9384.12, 9484.16, 9269.62,
        9053.88, 8595.88, 9081.6, 8921.3, 8720.02, 8665.79, 9174.4, 8934.56,
        9693.87, 9533.57, 9157.53, 9310.6, 9223.82, 9463.66, 9572.14, 9597.44,
        9824.04, 9752.92, 10078.35, 10116.91, 10063.88, 10169.94, 9930.1,
        10021.69, 10277.21, 10646.02, 10289.26, 10354.35, 9874.65, 9987.95,
        10009.64, 10194.05, 9983.13, 9910.81, 9249.12, 9492.59, 9117.76,
        9162.35, 9116.55, 8453.66, 8525.97, 8726.04, 8722.43, 9399.79, 9387.73,
        9243.11, 9234.67, 9304.57, 9086.42, 8763.41, 8673.01, 8580.21, 8452.46,
        8398.22, 7785.95, 7800.42, 7743.76, 7366.52, 7294.2, 7279.74, 7405.09,
        7278.53, 7781.13, 7118.23, 7280.95, 6929.01, 6880.8, 6074.49, 6088.95,
        4945.16, 4559.48, 5183.8, 5146.44, 5347.72, 5129.57, 5260.94, 4959.63,
        5031.94, 4963.24, 5011.45, 5081.35, 4911.41, 4934.31, 4919.86, 4770.4,
        4640.23, 4637.83, 4678.8, 5021.09, 5123.54, 4788.48, 4881.28, 4710.13,
        4727.01, 4780.04, 4840.3, 4901.78, 4849.94, 4828.25, 4649.87, 4400.39,
        4196.69, 3700.14, 3803.78, 4111.13, 3751.96, 3871.28, 3737.49, 3933.95,
        3888.16, 3999.04, 3923.11, 4023.14, 4424.49, 4410.03, 4342.54, 4270.22,
        4078.58, 3988.19, 3897.79, 3773.65, 3811.02, 3847.18, 3952.03, 3942.39,
        3999.04, 4120.76, 4121.97, 4155.72, 3941.19, 3780.89, 3756.78, 3862.84,
        3970.11, 4059.3, 4244.91, 4208.75, 4337.71, 4508.86, 4353.38, 4383.52,
        4334.1, 4329.28, 4330.48, 4146.08, 4044.83, 4317.22, 4513.68, 4476.32,
        4688.44, 4607.69, 4553.46, 4522.12, 4458.24, 4459.45, 4530.56, 4507.66,
        4508.86, 4552.25, 4431.72, 4582.38, 4569.12, 4558.27, 4547.43, 4536.58,
        4512.48, 4524.53, 4542.61, 4551.04, 4046.04, 4068.94, 4159.34, 4108.71,
        4171.39, 4166.57, 4197.9, 4096.66, 4031.57, 3984.57, 3986.98, 3964.09,
        3973.72, 4003.86, 3986.98, 3966.49, 3980.96, 4001.45, 3892.97, 4071.35,
        4120.76, 4261.78, 4332.89, 4267.81, 4297.94, 4012.3, 4124.39, 3933.95,
        4275.04, 4523.32, 4401.6, 4645.05, 5018.68, 4723.4, 5927.45, 6354.1,
        5869.59, 5922.62, 5615.29, 5406.78, 5417.62, 5769.55, 5758.71, 5700.86,
        5453.78, 5553.82, 5787.63, 5961.19, 5929.85, 6251.65, 6191.4, 6432.45,
        6013.02, 5904.54, 5865.98, 5861.16, 5888.88, 5805.71, 5767.15, 5767.15,
        5632.16, 5483.91, 5418.83, 5526.1, 5611.67, 5650.24, 5499.58, 5542.97,
        5644.21, 5571.9, 5711.7, 5645.41, 5530.92, 5573.1, 5611.67, 5447.75,
        5480.29, 6025.07, 5999.76, 5765.94, 5569.48, 4822.22, 3993.01, 4781.25,
        4607.69, 4414.85, 4095.46, 4489.58, 4686.03, 4545.01, 4625.77, 4610.1,
        4631.8, 4389.54, 4370.26, 4557.07, 4579.97, 4843.93, 4940.34, 4757.14,
        4865.62, 4705.32, 4802.94, 4947.57, 4784.87, 4753.53, 4670.36, 4688.44,
        4694.47, 4684.83, 4614.92, 4752.32, 4692.06, 4655.9, 4583.59, 4661.92,
        4749.91, 4649.87, 4547.43, 4244.91, 4243.7, 4220.8, 4078.58, 4252.14,
        4168.98, 4201.52, 4264.19, 4237.67, 4167.77, 4414.85, 4467.88, 4302.76,
        4114.74, 4146.08, 4106.31, 4080.99, 4201.52, 4148.49, 4179.82, 4089.43,
        4170.18, 4144.87, 4124.39, 4217.19, 4232.85, 4481.14, 4526.93, 4624.57,
        4757.14, 4647.47, 4666.75, 4687.24, 4601.67, 4740.27, 4941.55, 4861.99,
        4825.85, 5141.62, 4922.26, 4986.14, 4936.73, 4945.16, 4902.97, 4976.5,
        4737.86, 4909.0, 4994.58, 4951.19, 4965.65, 4993.37, 4807.77, 4778.84,
        4858.38, 4794.51, 4538.99, 4589.61, 4620.94, 4528.14, 4471.5, 4571.54,
        4821.02, 4667.95, 4764.37, 4757.14, 4667.95, 4786.06, 4717.37, 4758.35,
        4687.24, 4541.4, 4542.61, 4540.2, 4576.35, 4549.83, 4619.75, 4560.69,
        4523.32, 4479.93, 4438.95, 4473.9, 4595.64, 4665.54, 4507.66, 4466.68,
        4358.2, 4414.85, 4505.24, 4505.24, 4328.07, 4397.97, 4565.51, 4510.06,
        4719.78, 4536.58, 5565.87, 5072.92, 5368.2, 5092.2, 5352.54, 5427.26,
        5749.07, 6287.81, 6569.84, 6440.88, 6495.12, 6163.67, 6270.94, 6010.6,
        6073.28, 6111.85, 6054.0, 6181.75, 6093.77, 6103.42, 6214.3, 6229.96,
        6075.69, 6174.53, 6340.84, 6280.59, 6287.81, 6180.55, 6375.8, 5981.68,
        5699.65, 5374.23, 5681.57, 5577.92, 5559.84, 5649.03, 5527.3, 5600.82,
        5752.68, 5759.91, 5704.47, 5593.59, 5603.24, 5602.03, 5545.37, 5634.57,
        5510.42, 5473.07, 5131.98, 5141.62, 5191.04, 5058.45, 5185.01, 5115.1,
        5134.38, 5165.72, 5256.12, 5383.88, 5569.48, 5645.41, 5664.7, 5526.1,
        5564.66, 5611.67, 5470.65, 5469.44, 5632.16, 5687.6, 5828.61, 5945.52,
        6289.02, 6255.28, 6134.75, 6322.76, 6261.3, 6524.04, 6091.36, 6399.9,
        6243.22, 6339.65, 6479.45, 6140.77, 6235.99, 6320.36, 6256.48, 5768.36,
        5781.61, 5705.68, 5797.28, 5870.8, 5961.19, 5929.85, 6052.79, 5957.57,
        5979.27, 5865.98, 5912.98, 5782.81, 5810.53, 5604.43, 5715.32, 5735.81,
        5696.04, 5845.48, 5890.08, 5891.29, 5761.12, 5639.38, 5638.19, 5665.9,
        5650.24, 5750.28, 5740.63, 5677.96, 9764.98, 8658.55, 8605.52, 9093.65,
        8888.76, 9269.62, 9850.55, 9645.65, 9285.28, 9021.33, 8859.83, 8481.38,
        8570.57, 8665.79, 8605.52, 8580.21, 8588.65, 8454.86, 8422.32, 8482.59,
        8277.69, 8036.64, 7676.27, 7595.51, 7536.46, 7497.89, 7765.45, 7723.28,
        7626.85, 7625.65, 7571.41, 7549.72, 7624.44, 7337.59, 7079.67, 6150.42,
        6105.82, 6251.65, 6536.1, 6316.74, 6239.61, 6364.95, 6240.81, 6344.47,
        6320.36, 6299.87, 6303.49, 6533.69, 6362.55, 6169.7, 6000.96, 6011.81,
        6076.89, 6123.9, 6093.77, 6057.61, 6088.95, 6088.95, 6072.08, 6009.41,
        5700.86, 5816.56, 5835.84, 5925.03, 6060.02, 6029.89, 5937.09, 6090.16,
        6191.4, 6278.17, 6126.31, 6197.42, 6111.85, 6109.44, 6084.13, 6031.1,
        6033.51, 6126.31, 6281.79, 6222.73, 6330.0, 6168.5, 6363.74, 6428.83,
        6316.74, 6344.47, 6289.02, 6232.38, 6058.82, 6015.43, 5878.03, 5840.66,
        5862.37, 5861.16, 5875.62, 6013.02, 5920.22, 5893.69, 6129.93, 6269.73,
        6295.05, 6292.64, 6631.31, 6774.74, 6684.34, 6675.9, 6575.87, 6580.7,
        6715.68, 6970.0, 7209.84, 7349.65, 7277.33, 7385.8, 7429.2, 7857.06,
        8697.12, 8222.25, 8284.92, 7996.87, 7970.36, 8020.97, 8013.74, 7882.37,
        7587.08, 7814.87, 7413.52, 7421.96, 7355.67, 7505.13, 7385.8, 6996.51,
        7394.24, 7477.4, 7478.61, 7479.81, 7456.92, 7244.79, 7356.88, 7332.77,
        7297.82, 7373.75, 7446.07, 7538.87, 7507.53, 7471.37, 7659.39, 7730.5,
        8055.92, 8088.47, 7986.02, 7473.79, 7446.07, 7587.08, 7626.85, 7459.32,
        7467.76, 7389.42, 7374.96, 7311.08, 7455.71, 7517.18, 7524.4, 7466.55,
        7355.67, 7759.43, 7482.23, 7624.44, 7635.3, 7465.35, 7487.05, 7368.93,
        7555.74, 7560.56, 7027.85, 7083.28, 6918.16, 6847.05, 6636.13, 6990.48,
        6891.65, 7324.33, 7260.45, 6838.62, 6945.89, 7055.56, 6970.0, 6970.0,
        6874.78, 6661.45, 6589.13, 6630.11, 6608.41, 6377.0, 6210.68, 6223.94,
        6204.65, 6415.58, 6395.08, 6309.51, 6463.78, 6145.59, 6051.58, 6093.77,
        6158.85, 6269.73, 6072.08, 6120.29, 6119.08, 6173.32, 6197.42, 6338.44,
        6316.74, 6326.39, 6402.32, 6339.65, 6498.74, 6759.07, 6809.69, 6632.52,
        6750.64, 6821.75, 6802.46, 6601.18, 6551.77, 6437.27, 6577.07, 6560.2,
        6627.7, 7071.23, 6904.91, 7041.1, 7239.97, 7658.19, 7577.44, 7853.44,
        7098.96, 7356.88, 7611.19, 7449.68, 7493.07, 7233.94, 8747.75, 8282.52,
        8268.05, 8065.56, 7631.67, 7305.06, 7472.58, 7629.27, 7066.41, 7385.8,
        7694.34, 7333.98, 7501.51, 7632.88, 7753.4, 7630.47, 7405.09, 7534.05,
        7575.03, 7472.58, 7575.03, 7374.96, 7094.13, 6930.21, 7108.59, 7072.44,
        7161.63, 7142.34, 7459.32, 7502.71, 7438.84, 7330.36, 6568.64, 5732.2,
        6082.92, 6016.63, 5988.91, 5991.33, 6061.23, 6094.97, 6343.26, 6337.23,
        6733.76, 6589.13, 6337.23, 6339.65, 6316.74, 6234.78, 6186.58, 6214.3,
        6022.66, 6203.45, 6261.3, 6427.63, 6377.0, 6375.8, 6532.48, 6369.77,
        6227.56, 6279.38, 6046.76, 6097.39, 6019.04, 6078.1, 6033.51, 6242.02,
        6210.68, 6316.74, 6272.15, 6145.59, 6446.91, 6486.69, 6351.69, 6348.08,
        6454.14, 6526.46, 6383.03, 6454.14, 6383.03, 6323.97, 6263.71, 6256.48,
        6267.33, 6193.8, 6280.59, 6332.41, 6334.82, 6290.23, 6035.92, 5946.73,
        6005.78, 5986.51, 5788.84, 5920.22, 5776.79, 5722.55, 5933.48, 5897.32,
        6109.44, 5920.22, 5944.32, 5896.11, 5906.96, 5873.21, 5784.02, 5692.42,
        5733.39, 5680.36, 5734.6, 5685.19, 5691.22, 5747.86, 5755.1, 5844.29,
        5627.33, 5356.16, 5122.33, 5412.8, 5069.3, 5199.47, 5248.89, 5282.63,
        5395.93, 5717.73, 6310.71, 6364.95, 6455.35, 6950.71, 7632.88, 6859.1,
        6877.18, 7186.94, 7202.6, 7320.72, 7217.07, 7348.44, 7189.34, 7268.9,
        7241.17, 7217.07, 7261.66, 7596.72, 7642.52, 7212.24, 6760.27, 6691.58,
        6366.16, 5978.07, 6117.87, 7395.44, 7371.34, 7007.35, 7074.85, 6654.21,
        6819.33, 6942.27, 6851.88, 6756.66, 6800.05, 6816.92, 6886.83, 7195.37,
        7168.86, 7238.76, 7203.81, 7362.91, 7348.44, 7267.69, 7096.54, 6827.77,
        6873.57, 6755.46, 6825.36, 6838.62, 6573.46, 6843.44, 6785.59, 6747.01,
        6566.23, 6268.54, 6321.57, 6348.08, 6499.94, 6531.28, 6454.14, 6789.2,
        6898.88, 7180.91, 6945.89, 7010.97, 7056.77, 7007.35, 7106.18, 7078.46,
        7214.66, 7104.98, 7056.77, 7139.93, 7050.74, 7120.65, 7088.1, 7421.96,
        7718.45, 7461.73, 7172.47, 6569.84, 6326.39, 6439.68, 6414.37, 6420.4,
        6407.14, 6383.03, 6340.84, 6374.6, 6501.14, 6503.56, 6402.32, 6620.47,
        6511.99, 6637.34, 6585.52, 6571.05, 6551.77, 6608.41, 6584.31, 6448.11,
        6332.41, 6409.55, 6169.7, 6174.53, 6226.35, 6264.91, 6357.72, 6316.74,
        6156.45, 6163.67, 6145.59, 6067.26, 6066.05, 6193.8, 6577.07, 6667.47,
        6669.88, 6689.16, 7109.8, 7448.47, 8622.4, 8330.72, 9560.09, 10831.63,
        13331.32, 12857.66, 13557.92, 13144.51, 13392.8, 14520.91, 17669.04,
        17873.93, 17391.83, 17118.23, 16751.85, 17794.39, 18178.86, 21138.97,
        23046.89, 26155.24, 33181.88, 31406.54, 28574.19, 27542.49, 29496.21,
        30655.67, 31264.31, 26709.66, 28130.65, 29323.86, 28330.72, 29112.94,
        28922.51, 28941.79, 30190.44, 30779.81, 28304.21, 27526.82, 27554.54,
        26941.06, 26640.96, 27214.66, 28036.64, 27666.63, 26133.55, 25817.77,
        26481.86, 25195.86, 24887.31, 25288.66
      ],
      ADA: [
        10000.0, 10496.94, 10481.65, 10114.68, 10152.9, 9441.89, 9816.5,
        9250.77, 9258.41, 9036.69, 8646.79, 9029.05, 9954.12, 9480.12, 9824.16,
        9915.9, 10779.82, 11735.46, 11437.3, 10336.39, 9831.8, 8631.49, 8088.68,
        8256.88, 8081.04, 7920.49, 8142.2, 7943.43, 7981.65, 8142.2, 7920.49,
        8027.52, 8218.65, 7905.2, 8042.8, 8555.04, 8646.79, 8577.98, 9204.89,
        8975.53, 9189.6, 8944.95, 8203.36, 8103.97, 8004.58, 8035.16, 8379.2,
        8302.75, 7882.26, 7584.1, 7599.39, 7270.64, 6773.7, 6674.31, 6827.21,
        6383.79, 6911.32, 6842.51, 6498.47, 7240.06, 7370.03, 7209.48, 6918.96,
        6444.95, 6643.73, 6406.72, 6123.85, 6085.62, 6429.66, 6185.01, 6070.33,
        6055.05, 6009.17, 6116.2, 6139.14, 6299.69, 6414.37, 6483.18, 6842.51,
        6773.7, 6934.24, 7438.83, 8218.65, 8769.11, 8417.43, 8723.24, 8952.59,
        9120.79, 9059.63, 9105.5, 8830.28, 8960.24, 8929.65, 9097.85, 9243.11,
        9044.34, 8180.42, 8348.62, 7851.68, 7928.13, 7912.84, 7133.03, 7247.7,
        7438.83, 7102.44, 7285.93, 7301.22, 7155.97, 7140.67, 7270.64, 7186.54,
        6941.89, 6934.24, 6850.15, 6781.34, 6856.26, 6388.38, 6406.72, 6457.19,
        6180.42, 5861.62, 5992.35, 5976.3, 5886.85, 6805.04, 6006.11, 5990.83,
        5772.93, 5718.65, 4857.03, 4779.81, 3967.12, 3532.1, 4129.97, 4108.56,
        4536.7, 4308.1, 4412.08, 3988.53, 4020.64, 3954.89, 4029.05, 4130.74,
        3941.13, 3974.77, 3960.25, 3699.54, 3511.46, 3533.64, 3662.84, 4324.92,
        4892.2, 4160.55, 4474.76, 4307.33, 4328.75, 4372.32, 4662.08, 4713.3,
        4955.65, 4832.57, 4458.71, 4322.63, 3872.32, 3444.95, 3594.8, 4023.7,
        3618.5, 3731.65, 3504.59, 3726.3, 3736.24, 3697.25, 3527.53, 3642.97,
        3834.86, 3802.75, 3776.0, 3740.83, 3594.04, 3585.63, 3413.61, 3434.25,
        3500.0, 3479.36, 3568.81, 3508.41, 3532.87, 3640.67, 3592.5, 3672.79,
        3548.17, 3343.28, 3232.41, 3259.17, 3350.92, 3399.84, 3501.53, 3458.72,
        3684.25, 3947.25, 3767.58, 3790.52, 3711.01, 3926.6, 3932.72, 3732.42,
        3552.75, 3879.96, 3948.77, 4064.98, 4024.47, 3967.12, 3906.73, 3831.81,
        3840.22, 3805.81, 3951.83, 3943.42, 4049.7, 4097.1, 3934.25, 4091.74,
        4056.57, 4123.09, 4272.94, 4357.8, 4182.72, 4274.46, 4084.1, 4026.76,
        3470.18, 3431.19, 3545.87, 3477.83, 3535.17, 3501.53, 3550.46, 3360.09,
        3423.55, 3340.21, 3420.49, 3470.95, 3454.13, 3487.77, 3476.29, 3681.95,
        3812.69, 3802.75, 3573.39, 3648.31, 3657.49, 3788.23, 3943.42, 3864.68,
        3849.38, 3569.57, 3676.61, 3551.22, 3587.16, 3699.54, 3446.48, 3472.47,
        3377.68, 3327.21, 3518.35, 3548.93, 3457.19, 3396.02, 3410.55, 3382.26,
        3347.85, 3328.75, 3306.57, 3288.22, 3238.53, 3262.23, 3325.69, 3298.93,
        3272.17, 3240.82, 3215.59, 3223.24, 3097.09, 3003.82, 2912.84, 2902.14,
        2797.4, 2774.46, 2834.09, 2837.16, 2758.41, 2685.01, 2603.97, 2665.9,
        2682.72, 2758.41, 2754.59, 3087.92, 3084.1, 2981.65, 3108.56, 3172.78,
        3091.74, 3102.45, 3073.4, 2935.02, 2980.88, 3222.48, 3290.52, 3152.9,
        3066.51, 2818.81, 2411.31, 2837.16, 2694.19, 2617.74, 2523.7, 2502.29,
        2574.16, 2533.64, 2493.12, 2483.94, 2518.35, 2388.37, 2326.45, 2387.62,
        2419.72, 2413.61, 2406.72, 2389.14, 2437.31, 2344.04, 2373.85, 2444.18,
        2410.55, 2437.31, 2436.54, 2461.77, 2443.43, 2415.9, 2365.44, 2408.26,
        2379.96, 2386.85, 2344.04, 2339.45, 2380.73, 2357.04, 2285.93, 2027.53,
        2032.87, 2038.99, 1922.78, 1977.06, 1927.37, 1957.95, 1979.36, 1978.59,
        1972.48, 2012.23, 1989.3, 1899.85, 1854.74, 1868.5, 1882.27, 1904.43,
        1939.6, 1925.84, 2034.4, 2051.22, 2113.15, 2123.08, 2219.42, 2427.37,
        2457.18, 2420.49, 2513.76, 2619.27, 2678.14, 2684.25, 2681.19, 2655.2,
        2525.99, 2590.21, 2742.36, 2812.69, 2866.21, 2872.32, 2762.23, 2808.1,
        2905.97, 2941.89, 2905.2, 3022.93, 2837.16, 2971.71, 3045.87, 3059.63,
        3095.56, 3077.22, 2990.83, 2952.6, 3038.99, 3009.93, 2746.18, 2720.18,
        2814.98, 2787.46, 2727.83, 2947.25, 3082.56, 2989.29, 3087.92, 3105.5,
        3078.74, 3077.98, 2986.24, 2963.3, 2926.61, 2779.81, 2753.05, 2805.81,
        2781.35, 2695.72, 2743.12, 2672.78, 2618.5, 2553.52, 2584.87, 2532.88,
        2509.17, 2419.72, 2331.04, 2415.13, 2315.74, 2475.53, 2643.73, 2628.43,
        2459.48, 2467.12, 2642.96, 2595.56, 2659.78, 2557.34, 2820.34, 2733.95,
        2833.34, 2744.64, 2689.6, 2725.54, 2626.91, 2795.11, 2919.72, 2871.56,
        3068.04, 3020.64, 2917.43, 2970.19, 2986.24, 3000.77, 2928.89, 2935.02,
        2941.13, 2984.71, 3022.18, 3068.04, 3068.81, 3256.88, 3358.56, 3487.77,
        3462.54, 3330.28, 3380.73, 3192.66, 3076.45, 2907.49, 3029.82, 2963.3,
        2933.48, 2993.11, 3068.04, 3121.56, 3094.8, 3074.92, 3054.28, 2949.54,
        2993.88, 3024.46, 2952.6, 3016.05, 2900.61, 2890.67, 2782.11, 2763.0,
        2829.5, 2740.82, 2821.86, 2793.58, 2827.22, 2819.57, 2806.58, 2866.97,
        2863.91, 2818.81, 2796.63, 2758.41, 2811.16, 2832.57, 2780.58, 2738.53,
        2770.64, 2804.28, 2927.37, 2894.49, 2891.44, 2863.91, 2795.11, 2892.97,
        2870.8, 2907.49, 2683.49, 2703.36, 2462.54, 2473.24, 2272.93, 2126.15,
        2079.51, 2105.5, 2084.86, 2001.53, 2003.05, 2016.05, 2042.81, 1991.59,
        2009.17, 2048.93, 2198.01, 2212.54, 2263.0, 2207.95, 2243.11, 2142.2,
        2191.89, 2048.17, 2102.44, 2203.37, 2230.88, 2236.24, 2267.59, 2252.29,
        2167.43, 2163.61, 2176.61, 2215.6, 2181.96, 2201.83, 2233.18, 2200.3,
        2642.2, 2487.0, 2487.0, 2423.54, 2383.03, 2337.15, 2459.48, 2423.54,
        2393.73, 2382.26, 2412.08, 2331.81, 2325.69, 2348.63, 2347.86, 2373.09,
        2388.37, 2377.68, 2347.86, 2344.8, 2292.05, 2237.0, 2244.65, 2244.65,
        2237.77, 2219.42, 2283.64, 2308.1, 2266.82, 2243.88, 2233.95, 2208.71,
        2223.24, 2151.38, 2094.03, 1966.36, 2019.12, 2043.58, 2068.04, 2013.0,
        1956.42, 2038.99, 2029.05, 1995.41, 1998.46, 2012.23, 2040.52, 2089.45,
        2033.64, 1945.72, 1948.01, 1954.13, 1951.07, 1955.65, 1966.36, 1969.42,
        1971.71, 1942.67, 1937.31, 1902.91, 1840.21, 1883.79, 1903.67, 1927.37,
        1924.31, 1910.55, 1886.85, 1933.49, 1954.13, 1922.78, 1882.27, 1873.86,
        1876.91, 1870.02, 1873.09, 1871.56, 1874.61, 1904.43, 1897.55, 1938.08,
        2045.87, 1978.59, 1988.53, 1983.18, 1992.35, 2025.23, 1974.0, 1958.72,
        1922.78, 1905.96, 1891.43, 1878.43, 1881.5, 1886.09, 1885.32, 1925.84,
        1885.32, 1864.68, 1877.68, 1923.55, 1977.82, 2014.53, 2134.56, 2142.97,
        2148.31, 2202.6, 2200.3, 2227.83, 2270.64, 2308.87, 2227.83, 2339.45,
        2454.13, 2498.47, 2513.76, 2623.09, 2790.52, 2679.66, 2742.36, 2808.86,
        2912.08, 2955.66, 2949.54, 2725.54, 2743.12, 2893.72, 2836.39, 2801.22,
        2879.2, 2906.72, 2918.2, 2734.71, 2907.49, 2927.37, 2949.54, 3009.93,
        2975.53, 2864.67, 2932.72, 2924.31, 2870.03, 2922.02, 3016.82, 3040.52,
        3074.92, 3227.82, 3412.84, 3474.77, 4170.48, 4458.71, 4555.8, 4234.7,
        4323.4, 5046.63, 4925.07, 4645.26, 4667.42, 4451.07, 4557.34, 4399.08,
        4497.7, 4824.16, 4743.89, 4688.83, 4539.75, 4799.7, 4634.55, 4831.8,
        4775.99, 4613.15, 4590.97, 4504.58, 4732.41, 4648.32, 4272.17, 4360.85,
        4094.8, 3983.94, 3771.41, 4136.85, 3905.19, 4353.98, 4435.02, 4156.73,
        4210.24, 4078.75, 4040.52, 4128.44, 4033.63, 3857.79, 3813.46, 3935.01,
        3864.68, 3679.66, 3610.85, 3609.32, 3576.45, 3714.83, 3723.24, 3724.76,
        4013.76, 3954.13, 3822.63, 3861.62, 3926.6, 3930.42, 3801.23, 3759.17,
        3824.15, 3822.63, 4025.22, 4159.02, 4227.06, 4128.44, 4289.76, 4159.02,
        4418.95, 4570.33, 4533.64, 4653.67, 4765.29, 4831.04, 4781.35, 4548.16,
        4518.34, 4451.07, 4546.64, 4520.64, 4698.77, 4775.99, 4805.81, 4957.95,
        5340.97, 5618.49, 5586.39, 5900.61, 5189.61, 5577.21, 5674.31, 5510.71,
        5668.96, 5386.84, 5987.76, 5672.78, 5785.17, 5730.12, 5533.63, 5090.21,
        5214.07, 5022.93, 4503.82, 4891.43, 4822.63, 4637.61, 4825.68, 4928.14,
        5055.81, 5103.98, 4973.24, 4984.7, 5025.23, 4923.55, 4960.24, 4749.23,
        4472.48, 4383.03, 4415.9, 4389.14, 4474.76, 4469.42, 4704.13, 4563.46,
        4477.06, 4476.3, 3825.69, 3412.07, 3603.98, 3530.58, 3513.76, 3421.25,
        3507.64, 3601.68, 3840.97, 3819.57, 3977.07, 3820.33, 3614.68, 3605.5,
        3531.35, 3554.28, 3543.58, 3490.82, 3372.33, 3432.72, 3511.46, 3579.5,
        3547.4, 3492.36, 3483.18, 3408.25, 3465.59, 3542.05, 3415.14, 3361.62,
        3340.21, 3337.92, 3266.06, 3452.6, 3514.53, 3687.3, 3679.66, 3597.09,
        3799.69, 3797.4, 3698.01, 3547.4, 3509.94, 3525.99, 3517.58, 3580.27,
        3496.18, 3461.01, 3424.31, 3428.14, 3457.95, 3411.32, 3508.41, 3531.35,
        3516.05, 3493.12, 3448.78, 3330.28, 3393.73, 3374.61, 3221.71, 3357.03,
        3230.12, 3149.85, 3162.08, 3189.6, 3086.4, 2926.61, 2931.96, 2943.43,
        2886.08, 2949.54, 2922.78, 2870.03, 2998.47, 2948.78, 2994.65, 2953.37,
        2944.19, 3003.06, 3090.97, 3206.42, 3118.5, 2846.33, 2668.96, 2848.62,
        2665.9, 2826.45, 2863.15, 2947.25, 3015.29, 3162.08, 3349.39, 3311.16,
        3378.44, 3334.1, 3358.56, 3251.53, 3347.1, 3334.87, 3399.09, 3262.23,
        3138.37, 3103.97, 2998.47, 3194.18, 3214.07, 3104.74, 3103.97, 3080.27,
        2983.18, 2990.06, 2785.94, 2753.82, 2672.01, 2428.13, 2534.4, 2492.35,
        2694.95, 2655.96, 2653.67, 2520.65, 2564.21, 2601.68, 2565.75, 2487.76,
        2522.17, 2564.21, 2587.15, 2569.57, 2637.61, 2824.93, 2877.67, 2973.24,
        2994.65, 2968.65, 2815.75, 2675.08, 2675.08, 2714.06, 2657.5, 2639.91,
        2510.7, 2571.87, 2452.59, 2483.18, 2481.65, 2396.03, 2470.18, 2568.8,
        2629.2, 2625.38, 2726.3, 2719.42, 2763.76, 2699.54, 2590.21, 2504.58,
        2555.05, 2582.57, 2688.83, 2697.24, 2728.59, 2693.42, 2784.4, 2974.01,
        2922.02, 3065.74, 3078.74, 3051.22, 3043.58, 2876.91, 2677.37, 2640.68,
        2641.43, 2681.96, 2678.14, 2720.18, 2694.95, 2626.91, 2587.15, 2586.39,
        2689.6, 2703.36, 2656.73, 2792.05, 2713.31, 2722.47, 2633.79, 2670.49,
        2692.65, 2792.05, 2782.11, 2795.11, 2662.84, 2655.2, 2584.87, 2553.52,
        2607.03, 2632.27, 2716.36, 2715.59, 2620.03, 2731.65, 2651.37, 2558.1,
        2501.53, 2559.63, 2786.69, 3029.82, 3390.67, 3571.86, 4516.82, 4585.63,
        4485.47, 4406.72, 4421.25, 5165.13, 5659.79, 5385.32, 5679.66, 5634.56,
        6271.4, 6249.23, 7847.86, 8250.76, 7855.5, 7322.63, 7263.75, 7690.37,
        7940.36, 8081.8, 8232.41, 8605.5, 9111.62, 9345.56, 9074.92, 9025.99,
        9447.25, 9178.89, 9109.33, 7766.82, 7754.59, 8340.21, 8533.64, 8463.3,
        8082.56, 8186.54, 8334.86, 8061.16, 7496.17, 6884.56, 7225.53, 6902.14,
        6790.52, 7128.44, 7156.72, 6970.95, 6587.15, 6685.01, 6844.03, 6534.41,
        6549.69, 6516.05
      ],
      DOGE: [
        10000.0, 10146.8, 10252.5, 9976.51, 9935.41, 9266.0, 9418.68, 9060.49,
        8960.66, 8878.45, 8379.33, 8925.43, 9418.68, 9806.23, 11015.85,
        10845.57, 10481.5, 9876.69, 9765.12, 9624.19, 9277.75, 8402.82, 7674.69,
        8132.71, 8050.5, 8349.97, 8367.58, 8209.04, 8279.51, 8379.33, 8185.55,
        8308.87, 8361.71, 8091.61, 8038.75, 8549.61, 8672.93, 8896.07, 9806.23,
        9301.23, 9371.69, 9001.76, 8408.69, 8461.54, 9031.12, 8596.6, 8807.99,
        8761.01, 8226.66, 8126.84, 8267.77, 8103.35, 7704.06, 7627.72, 7592.49,
        7105.11, 7522.02, 7480.91, 7251.91, 7751.03, 7862.59, 7833.23, 7598.36,
        7193.18, 7351.73, 7152.08, 6870.23, 6829.12, 7099.24, 6876.11, 6811.51,
        6776.28, 6494.42, 6670.58, 6617.73, 6799.76, 6858.49, 6952.44, 7228.42,
        7022.9, 7017.02, 7204.94, 7610.1, 7997.65, 7657.07, 7915.45, 8379.33,
        8643.57, 8438.05, 8408.69, 8173.81, 8326.48, 8244.28, 8661.19, 8778.63,
        10005.87, 8396.95, 8561.37, 8379.33, 8432.18, 8854.96, 7968.29, 8032.88,
        8238.41, 8367.58, 8578.98, 8432.18, 8332.35, 8226.66, 8332.35, 8297.12,
        7985.91, 7997.65, 7921.32, 7756.9, 9066.36, 8279.51, 8115.09, 8032.88,
        7909.57, 7621.84, 7721.67, 7692.3, 7574.87, 7927.19, 7492.66, 7527.89,
        7375.23, 7328.24, 6382.86, 6306.52, 4973.57, 4756.31, 5273.05, 5249.56,
        5384.62, 5161.48, 5284.79, 4991.19, 5055.79, 4932.47, 4950.09, 5049.91,
        4891.37, 4897.24, 4903.11, 4644.74, 4797.41, 4773.93, 4850.27, 5166.18,
        5073.99, 4754.55, 4859.66, 4719.31, 4788.02, 4803.28, 4824.42, 4721.67,
        4694.07, 4650.61, 4451.56, 4170.87, 3820.9, 3142.1, 3162.65, 3573.69,
        3228.42, 3336.46, 3137.99, 3539.63, 3513.21, 3905.46, 3664.71, 3751.03,
        3943.04, 4003.52, 4357.61, 4266.0, 3859.66, 4130.94, 3764.53, 3867.3,
        3930.71, 3928.36, 4061.07, 3985.32, 4031.12, 4131.53, 4083.97, 4075.16,
        3958.9, 3614.8, 3547.27, 3586.03, 3631.24, 3735.76, 3786.85, 3771.58,
        3904.28, 4068.7, 4100.42, 4077.51, 3978.27, 3988.84, 3973.58, 3765.12,
        3642.99, 3904.28, 4082.21, 4115.09, 4088.67, 4020.55, 4021.14, 3954.78,
        3906.63, 3928.36, 4098.06, 4059.89, 4059.89, 4099.83, 4058.14, 4159.72,
        4162.66, 4226.66, 4274.81, 4764.53, 4485.03, 5069.88, 4699.94, 4522.03,
        4000.59, 4064.01, 4083.38, 3966.53, 4022.32, 3991.19, 4048.74, 3781.56,
        3719.91, 3706.98, 3724.6, 3645.33, 3635.94, 3648.86, 3608.34, 3672.34,
        3684.68, 3677.04, 3479.74, 3588.37, 3578.39, 3719.32, 3801.53, 3721.08,
        3743.39, 3528.48, 3598.94, 3461.53, 3530.83, 3638.28, 3383.45, 3443.34,
        3442.16, 3329.42, 3495.01, 3750.44, 3710.51, 3574.87, 3553.73, 3557.25,
        3554.31, 3548.44, 3652.38, 3563.71, 3501.47, 3534.35, 3874.34, 3790.37,
        3724.6, 3658.25, 3607.17, 3630.65, 3500.88, 3520.85, 3519.08, 3510.86,
        3439.82, 3451.56, 3470.93, 3517.91, 3501.47, 3453.32, 3495.6, 3485.03,
        3501.47, 3536.11, 3496.18, 3670.0, 4233.71, 4517.32, 5080.45, 7011.74,
        6843.22, 7322.96, 8225.49, 7432.76, 7334.11, 7462.12, 7438.06, 6993.54,
        6399.88, 5035.23, 4290.66, 5271.88, 4927.19, 5358.2, 4981.8, 5002.94,
        5076.34, 5028.77, 4992.96, 4952.43, 4965.35, 4557.84, 4362.3, 4602.47,
        4764.53, 4778.04, 5226.66, 5191.42, 6152.09, 5588.96, 6025.25, 6335.87,
        5976.52, 5940.7, 5869.06, 6098.65, 5934.82, 5813.86, 5610.1, 5786.85,
        5654.72, 5667.06, 5436.29, 5268.94, 5328.25, 5207.28, 4965.35, 4502.64,
        4650.61, 4645.33, 4167.94, 4375.8, 4292.42, 4518.5, 4509.69, 4549.62,
        4439.81, 4384.61, 4322.96, 4118.03, 4136.81, 3996.47, 4125.07, 4118.03,
        4216.09, 4118.03, 4251.32, 4247.8, 4261.9, 4229.01, 4283.61, 4456.26,
        4539.64, 4516.14, 4691.13, 4892.54, 5111.57, 5069.88, 4913.68, 4898.42,
        4768.06, 4785.67, 5047.57, 5049.32, 5174.99, 5192.6, 4952.43, 5034.06,
        5065.18, 5082.21, 5115.68, 5287.14, 5111.57, 5625.37, 5527.89, 5380.5,
        5435.71, 5716.39, 5426.9, 5330.01, 5423.96, 5285.38, 4697.01, 4714.03,
        4836.76, 4832.06, 4782.73, 4884.32, 5196.72, 4992.37, 5150.91, 5220.2,
        5125.66, 5154.44, 5016.44, 5002.35, 4943.63, 4716.97, 4721.08, 4811.51,
        4765.12, 4749.26, 4802.12, 4725.2, 4495.01, 4332.36, 4401.65, 4390.49,
        4306.52, 4165.59, 3831.48, 3852.62, 3843.81, 4109.22, 4297.13, 4381.67,
        4070.47, 4189.67, 4496.77, 4323.55, 4416.91, 4208.45, 4479.75, 4320.02,
        4519.08, 4384.61, 4325.31, 4369.35, 4270.7, 4315.91, 4449.8, 4330.01,
        4563.72, 4826.19, 4624.19, 5545.51, 5636.52, 5478.56, 5044.04, 4854.96,
        4766.89, 4898.42, 4971.82, 4932.47, 4869.05, 5136.82, 5224.31, 5223.14,
        5352.32, 5410.45, 5488.55, 5172.64, 4897.83, 4586.02, 4718.15, 4618.91,
        4614.21, 4678.21, 4594.83, 4699.94, 4716.97, 4775.69, 4694.66, 4614.79,
        4621.25, 4719.9, 4589.55, 4719.9, 4501.46, 4524.37, 4240.17, 4281.27,
        4304.75, 4248.38, 4234.29, 4223.72, 4226.66, 4244.86, 4260.72, 4398.71,
        4355.25, 4323.55, 4320.02, 4241.33, 4288.91, 4279.51, 4151.5, 4162.66,
        4161.48, 4290.08, 4314.74, 4293.01, 4257.19, 4201.41, 4211.39, 4260.72,
        4263.06, 4281.86, 3899.59, 4160.31, 3945.4, 3995.3, 4086.32, 3651.2,
        3611.86, 3611.27, 3611.27, 3523.79, 3614.8, 3648.86, 3657.08, 3637.69,
        3655.9, 3688.78, 3867.3, 3847.91, 4001.18, 3910.74, 3926.01, 3790.37,
        3839.11, 3675.28, 3724.02, 3923.67, 4008.81, 4012.92, 4012.33, 4055.2,
        3925.42, 3865.53, 3844.98, 3869.05, 3850.26, 3824.43, 3825.61, 3789.79,
        4098.65, 4005.87, 4217.27, 4105.11, 4086.9, 4023.48, 4128.01, 4140.93,
        4327.66, 4162.07, 4202.0, 4358.78, 4789.19, 4587.2, 4544.92, 4546.68,
        4727.54, 4557.26, 4563.13, 4530.24, 4376.98, 4332.94, 4308.28, 4469.17,
        4388.72, 4311.22, 4401.06, 4409.28, 4444.51, 4443.34, 4516.14, 4385.79,
        4392.25, 4157.95, 3960.07, 3631.82, 3713.45, 3736.93, 3746.33, 3666.47,
        3660.6, 3738.11, 3695.83, 3704.64, 3694.65, 3711.1, 3722.84, 3926.01,
        3853.79, 3742.8, 3734.59, 3729.89, 3701.11, 3696.42, 3755.14, 3734.59,
        3739.87, 3731.65, 3727.54, 3603.64, 3543.75, 3585.44, 3596.0, 3655.32,
        3678.22, 3645.92, 3611.86, 3648.27, 3677.63, 3676.46, 3606.58, 3613.63,
        3603.05, 3586.62, 3573.11, 3540.81, 3550.21, 3604.81, 3660.6, 3655.9,
        3729.3, 3636.53, 3610.1, 3608.34, 3585.44, 3605.99, 3609.51, 3575.45,
        3447.44, 3467.41, 3419.84, 3400.47, 3425.72, 3515.56, 3480.92, 3533.76,
        3467.99, 3453.91, 3443.92, 3527.3, 3596.59, 3606.58, 3934.82, 3938.93,
        4046.39, 4234.88, 3964.77, 4056.37, 4096.3, 4080.44, 3991.78, 4024.07,
        3977.69, 4000.59, 4049.92, 4177.34, 4461.54, 4303.59, 4443.34, 4264.24,
        4411.04, 4598.36, 4605.41, 4371.11, 4259.54, 4470.94, 4614.79, 5006.46,
        4731.65, 4672.34, 4576.63, 4202.58, 4452.73, 4476.22, 4604.23, 4627.13,
        4580.15, 4575.46, 4707.57, 4731.65, 4893.13, 4911.92, 5027.6, 5040.52,
        5267.76, 5503.23, 5648.85, 5563.12, 5968.88, 5854.96, 6009.98, 5560.78,
        5446.27, 5658.84, 5763.95, 5484.44, 5614.8, 5489.14, 5396.36, 5311.8,
        5352.32, 5610.69, 5561.95, 5482.09, 5360.54, 5549.62, 5362.89, 5451.55,
        5391.66, 5305.34, 5281.85, 5235.47, 5372.87, 5363.48, 4827.95, 4946.56,
        4835.0, 4731.07, 4560.78, 4792.72, 4635.35, 4880.22, 4951.85, 4662.94,
        4759.84, 4739.29, 4764.53, 4780.98, 4724.61, 4600.11, 4605.41, 5104.53,
        4985.32, 4751.03, 4569.59, 4611.28, 4577.22, 4691.72, 4715.21, 4616.56,
        4792.72, 4710.51, 4626.55, 4652.97, 4660.01, 4628.89, 4599.53, 4583.68,
        4627.71, 4697.6, 4684.08, 4783.32, 4783.32, 4767.47, 4837.94, 4767.47,
        5051.08, 4980.03, 5020.56, 4921.91, 5027.01, 5261.89, 5047.57, 4949.5,
        4972.4, 4957.14, 5055.2, 5062.25, 5218.43, 5827.95, 6805.05, 6847.92,
        8203.17, 8348.21, 8862.6, 10564.3, 9008.81, 9186.73, 9032.3, 9680.56,
        10529.66, 9775.69, 10393.42, 9749.86, 9886.08, 10385.2, 9529.65,
        8361.71, 9034.64, 8417.5, 7604.23, 8900.18, 9075.75, 8933.65, 9698.18,
        10352.32, 10385.79, 10656.49, 11216.09, 12906.63, 12463.88, 11721.67,
        12868.47, 12015.85, 10889.03, 10292.42, 10460.95, 10409.28, 10948.33,
        11537.87, 11946.57, 11155.02, 11728.71, 11447.45, 10200.24, 8918.38,
        9566.65, 9549.03, 9211.98, 8722.26, 8934.24, 9091.6, 9482.68, 9321.2,
        9524.37, 9381.68, 8856.14, 8907.81, 8654.15, 8626.54, 8678.8, 8452.14,
        7829.13, 7586.61, 7814.45, 8577.8, 9378.74, 9404.58, 9236.06, 8907.81,
        8376.39, 9002.94, 8424.54, 8424.54, 8274.8, 8685.26, 8544.33, 9109.81,
        8800.94, 9151.5, 8956.54, 8772.76, 9514.39, 9992.37, 9772.75, 9372.87,
        9620.08, 9958.31, 9799.76, 9923.67, 9723.43, 9676.45, 9362.89, 9368.18,
        9442.16, 9229.6, 9299.48, 9466.24, 9553.15, 9423.96, 8726.95, 8558.43,
        8622.43, 8498.54, 8133.3, 8591.31, 8325.89, 7925.42, 8031.12, 8041.69,
        7635.34, 7183.21, 7179.09, 7318.85, 7284.79, 7287.14, 7259.54, 6919.55,
        7409.28, 7257.19, 7506.17, 7241.92, 7140.93, 7339.99, 7263.65, 7335.29,
        6947.74, 6327.07, 6155.02, 6655.32, 6135.64, 6330.0, 6301.82, 6319.44,
        6268.94, 6312.98, 6622.44, 6747.51, 7279.51, 7283.62, 7155.61, 7026.42,
        7358.19, 7877.28, 8257.78, 8119.79, 7648.27, 7535.53, 7323.55, 7914.86,
        7807.99, 7587.2, 7540.22, 7402.24, 7195.54, 7024.66, 6524.37, 6345.27,
        6177.33, 5641.81, 5687.03, 5638.88, 6314.73, 6098.65, 6180.85, 5950.09,
        6254.26, 6253.67, 6035.23, 5876.69, 5894.89, 6032.3, 5950.67, 5916.62,
        6082.79, 6240.75, 6192.02, 6651.79, 6569.58, 6491.48, 6195.53, 5797.42,
        5847.33, 5845.57, 5953.61, 5948.33, 5543.75, 5818.56, 5722.85, 5774.51,
        5790.37, 5377.57, 5571.34, 5617.15, 6068.12, 6041.69, 5950.09, 6056.96,
        6301.82, 6191.43, 6097.47, 5821.5, 5930.12, 6005.28, 6174.39, 6207.28,
        6432.18, 6271.88, 6334.12, 6472.7, 6409.28, 6923.08, 7282.45, 7566.64,
        7390.48, 6805.64, 6258.95, 6196.71, 6174.39, 6421.61, 6440.98, 6514.97,
        6465.65, 6291.25, 6314.16, 6214.92, 6503.82, 6554.31, 6529.66, 6904.87,
        6763.36, 7455.08, 7546.68, 8115.09, 8422.2, 8380.5, 8503.23, 8267.77,
        8128.01, 8338.81, 7916.61, 8086.32, 8364.65, 9292.43, 10256.6, 9938.94,
        9511.46, 9275.4, 9359.95, 8887.25, 9263.66, 10032.29, 11667.65,
        11241.34, 11995.89, 12564.89, 16295.95, 19225.48, 23241.93, 22928.95,
        21833.24, 22185.56, 21322.38, 21183.21, 22062.83, 22724.01, 22251.91,
        22593.66, 24210.81, 25528.48, 25231.94, 23103.93, 22411.04, 23628.9,
        23722.25, 25039.93, 24738.7, 25982.39, 24782.73, 24105.11, 24576.63,
        25277.16, 25625.37, 26561.36, 27318.27, 24232.54, 23073.4, 24452.73,
        23914.27, 23658.84, 23130.36, 23540.22, 23709.92, 23189.67, 21113.91,
        18927.78, 18689.96, 18907.81, 18402.82, 19193.19, 19769.23, 19578.98,
        18392.84, 18293.01, 19102.18, 18431.0, 18422.78, 18662.95
      ],
      LINK: [
        10000.0, 10481.81, 11153.25, 11978.48, 12301.38, 12516.66, 13024.09,
        13413.64, 13316.25, 14213.23, 14038.96, 13859.56, 13710.91, 12803.69,
        13039.47, 13024.09, 13141.98, 12142.49, 11916.97, 11225.01, 10589.44,
        9015.89, 8016.4, 8057.4, 7939.52, 7898.51, 7703.74, 7626.86, 8165.05,
        8364.94, 8954.38, 8769.86, 8739.1, 8144.54, 8277.81, 8800.61, 9154.28,
        9000.52, 9733.47, 9410.56, 9579.7, 9108.15, 8226.55, 8231.68, 8144.54,
        8170.17, 8708.35, 8800.61, 8047.15, 7760.12, 7898.51, 7606.36, 7088.67,
        7129.68, 7006.67, 6683.75, 7416.71, 7534.6, 7032.29, 7637.11, 7831.88,
        7898.51, 7647.36, 7052.79, 7211.68, 7052.79, 6509.49, 6647.87, 7088.67,
        6781.13, 6724.76, 6786.27, 6504.35, 6776.01, 7011.79, 7426.96, 7452.59,
        7631.99, 7652.48, 7503.85, 7652.48, 7965.14, 8001.03, 8298.31, 7965.14,
        8067.66, 8549.46, 8605.84, 8631.47, 8810.87, 8744.24, 8841.62, 8990.27,
        9308.05, 8949.26, 8698.1, 7924.14, 8083.03, 7765.25, 7862.63, 7929.26,
        7068.17, 7104.05, 7273.19, 6981.04, 7252.7, 7201.44, 7191.19, 7196.31,
        7262.94, 7191.19, 6950.28, 6981.04, 6878.52, 6806.76, 6878.52, 6396.72,
        6488.98, 6468.48, 6130.19, 5802.15, 5725.27, 5745.77, 5689.39, 6217.32,
        5535.62, 5540.74, 5197.33, 5161.45, 4408.0, 4331.11, 3582.77, 3275.24,
        3741.68, 3731.42, 4013.32, 3844.19, 3931.32, 3582.77, 3628.91, 3531.53,
        3613.53, 3751.92, 3639.16, 3700.67, 3613.53, 3429.01, 3249.62, 3290.62,
        3429.01, 3823.68, 3951.82, 3526.4, 3675.04, 3516.14, 3762.17, 3962.08,
        4028.71, 4464.38, 4510.51, 4710.41, 4187.6, 3700.67, 3295.74, 2911.33,
        3300.87, 3685.29, 3264.99, 3244.5, 3054.84, 3408.51, 3541.77, 3567.4,
        3439.26, 3577.65, 3741.68, 3700.67, 3521.27, 3382.88, 3234.24, 3218.87,
        3131.73, 3116.35, 3198.36, 3167.61, 3259.87, 3275.24, 3264.99, 3377.76,
        3285.5, 3326.5, 3157.36, 3126.61, 3090.73, 3108.15, 3187.6, 3260.38,
        3376.73, 3305.48, 3547.41, 3752.95, 3523.83, 3567.4, 3506.41, 3515.12,
        3625.32, 3400.83, 3230.65, 3491.03, 3735.01, 4145.56, 4014.86, 3925.68,
        3862.12, 3729.88, 3769.86, 3744.24, 4010.25, 4036.39, 4232.19, 4395.19,
        4478.73, 4624.81, 4585.34, 4750.39, 4692.46, 4492.57, 4401.34, 4338.29,
        4108.15, 4084.05, 3549.97, 3521.78, 3640.7, 3552.53, 3701.69, 3674.52,
        3644.28, 3392.62, 3342.39, 3286.01, 3412.61, 3402.36, 3420.3, 3520.76,
        3559.2, 3499.75, 3631.47, 3755.51, 3442.85, 3632.5, 3804.21, 3958.99,
        4077.39, 4069.71, 3962.08, 3631.98, 3844.19, 3844.19, 3895.43, 4138.9,
        3858.02, 3759.61, 3529.99, 3400.83, 3640.7, 3822.65, 3924.65, 4002.05,
        4009.23, 4162.48, 4028.71, 4017.42, 3880.06, 3786.78, 3692.47, 3742.7,
        3969.25, 4046.64, 3924.65, 3891.85, 3787.29, 3882.62, 3880.57, 3659.15,
        3657.1, 3621.74, 3517.17, 3527.43, 3687.86, 3758.07, 3653.0, 3490.51,
        3416.71, 3494.11, 3519.22, 3615.07, 3558.18, 3616.6, 3668.37, 3547.92,
        3654.03, 3850.85, 3983.6, 4008.2, 3926.19, 3788.83, 3950.28, 4451.56,
        4467.45, 4237.31, 4455.66, 3766.78, 3187.6, 3736.03, 3564.33, 3230.14,
        3115.84, 3160.43, 3273.19, 3165.04, 3221.94, 3163.51, 3163.0, 2969.76,
        2984.62, 3258.33, 3435.17, 3512.04, 3501.28, 3645.82, 3632.5, 3683.76,
        3784.22, 3961.56, 3970.27, 3887.24, 3718.61, 3796.51, 3721.17, 3585.34,
        3531.53, 3600.21, 3512.04, 3510.51, 3449.51, 3425.42, 3533.58, 3454.64,
        3298.31, 3029.22, 3086.11, 3071.25, 2957.97, 3073.81, 3057.91, 3077.4,
        3039.47, 3025.11, 3060.49, 3082.52, 3015.89, 2895.95, 2844.7, 2783.7,
        2852.9, 2878.53, 2917.48, 2869.81, 2947.72, 2898.51, 2930.29, 2976.42,
        3003.08, 3105.59, 3146.08, 3171.71, 3263.45, 3355.71, 3503.85, 3430.54,
        3421.83, 3535.11, 3290.62, 3332.65, 3535.11, 3555.62, 3566.38, 3605.33,
        3426.44, 3524.34, 3663.25, 3747.31, 3670.94, 3776.02, 3525.89, 3562.28,
        3714.51, 3662.74, 3740.13, 3742.7, 3576.63, 3542.29, 3676.06, 3638.13,
        3545.36, 3515.63, 3589.96, 3495.14, 3382.37, 3485.39, 3676.57, 3597.13,
        3888.77, 4125.58, 4066.63, 4077.91, 3898.52, 3937.98, 4048.69, 3772.42,
        3768.32, 3813.94, 3714.0, 3715.02, 3835.46, 3722.2, 3561.25, 3494.62,
        3569.96, 3526.91, 3478.73, 3358.28, 3120.46, 3192.21, 3135.83, 3309.08,
        3483.34, 3555.62, 3331.11, 3410.05, 3677.6, 3576.63, 3661.71, 3637.11,
        3786.78, 3648.38, 3881.09, 3688.88, 3615.07, 3725.78, 3500.26, 3551.0,
        3786.78, 3687.34, 3924.65, 3869.81, 3729.88, 3710.91, 3792.41, 3752.44,
        3675.55, 3733.98, 3647.36, 3706.3, 3761.15, 3751.92, 3690.42, 3815.99,
        3994.37, 4116.36, 4165.04, 4208.62, 4391.08, 4022.05, 3863.14, 3622.25,
        3679.66, 3617.13, 3624.3, 3718.1, 3577.65, 3663.25, 3609.94, 3678.11,
        3620.71, 3529.48, 3580.73, 3658.64, 3572.53, 3704.76, 3554.58, 3553.05,
        3379.29, 3331.11, 3383.39, 3251.16, 3391.59, 3341.88, 3348.03, 3413.12,
        3406.46, 3457.71, 3358.79, 3338.28, 3336.23, 3294.21, 3348.54, 3338.81,
        3245.01, 3220.92, 3253.72, 3295.23, 3404.41, 3404.92, 3396.2, 3316.76,
        3262.43, 3310.1, 3287.55, 3338.81, 3094.31, 3197.85, 3006.67, 3078.93,
        3074.32, 2700.15, 2643.26, 2652.48, 2690.93, 2680.67, 2720.66, 2728.35,
        2711.94, 2648.38, 2618.65, 2692.98, 2852.39, 2840.08, 3111.22, 3133.27,
        3140.95, 3120.46, 3171.19, 3004.1, 3022.55, 3234.24, 3324.45, 3349.05,
        3384.93, 3367.5, 3222.45, 3153.77, 3154.8, 3170.17, 3155.82, 3155.31,
        3184.01, 3193.75, 3564.84, 3514.09, 3540.24, 3406.46, 3680.68, 3557.67,
        3531.53, 4222.96, 4180.94, 4086.11, 4032.29, 3894.41, 3805.23, 3972.32,
        4098.92, 4001.54, 4024.61, 3835.46, 3858.53, 3857.51, 3788.31, 3661.2,
        3653.51, 3706.81, 3651.47, 3753.97, 3813.94, 3971.81, 3900.57, 3830.34,
        3826.24, 3760.64, 3810.36, 3601.23, 3460.27, 3209.63, 3161.46, 3153.26,
        3181.44, 3177.34, 3102.0, 3243.97, 3105.07, 3075.35, 3075.35, 3090.22,
        3064.07, 3181.95, 3044.59, 3011.28, 3018.96, 3058.44, 3086.11, 3071.76,
        3150.69, 3210.66, 3281.91, 3205.53, 3163.0, 3084.06, 2973.35, 3059.97,
        3082.52, 3175.29, 3255.77, 3213.22, 3140.95, 3391.59, 3528.45, 3545.87,
        3438.75, 3565.87, 3697.08, 3603.28, 3822.65, 3756.02, 3908.25, 4012.81,
        4080.47, 4191.7, 4143.51, 3826.75, 3817.53, 3930.81, 3859.04, 3930.3,
        3870.84, 3942.08, 3736.03, 3730.39, 3799.59, 3700.15, 3729.37, 3754.49,
        3790.88, 3881.6, 3765.25, 3778.07, 3742.19, 3895.95, 4592.52, 5177.35,
        5597.13, 5248.08, 5584.32, 5654.02, 5693.49, 5604.81, 5759.61, 5761.14,
        5826.25, 5961.56, 5605.34, 5880.57, 5909.79, 6211.17, 6741.16, 6652.48,
        7592.51, 7525.88, 7871.34, 8364.94, 8260.89, 7425.93, 7169.66, 7779.08,
        7143.0, 7047.16, 7016.4, 7594.57, 7410.56, 6875.96, 7315.23, 7449.0,
        7369.04, 7534.09, 7604.82, 7219.37, 7426.96, 7453.61, 7341.87, 7649.41,
        8187.09, 8204.0, 7987.18, 7962.07, 7864.17, 7972.33, 8590.98, 8416.2,
        8405.95, 7592.0, 7339.82, 7563.31, 7919.02, 7408.0, 7381.86, 7225.02,
        7475.14, 7200.41, 7255.26, 7801.64, 7875.97, 8022.55, 7847.77, 8045.62,
        7726.81, 8520.24, 8288.06, 7876.99, 7733.47, 7636.6, 7927.21, 7776.52,
        7257.31, 7436.7, 7157.35, 6867.76, 6712.97, 7280.88, 6992.82, 7719.12,
        7677.09, 7140.44, 7385.45, 7694.52, 7801.64, 7831.37, 8037.42, 7506.41,
        8119.93, 8116.86, 7911.33, 7478.73, 7258.33, 7221.43, 7072.79, 7259.36,
        7368.01, 7354.69, 7678.62, 7917.48, 7800.61, 8786.78, 9117.38, 9071.76,
        9358.79, 9706.31, 9417.73, 9685.8, 9374.68, 9462.84, 9592.52, 10293.69,
        10555.1, 10170.18, 10385.95, 10153.77, 9974.37, 10333.17, 10400.82,
        10229.11, 9923.12, 9453.1, 9373.14, 9172.22, 9476.68, 9573.04, 9807.79,
        9809.84, 9840.6, 9755.51, 10233.73, 10904.66, 10531.52, 10507.43,
        9599.69, 10219.89, 10261.4, 10068.17, 10243.47, 10589.44, 10997.95,
        10520.76, 10536.13, 10832.4, 10027.68, 9356.74, 9563.3, 9416.2, 8675.55,
        9425.94, 9395.19, 9178.89, 9352.64, 9502.82, 9931.32, 10230.65, 9934.91,
        9848.8, 9747.31, 9688.88, 9763.71, 9434.65, 9277.3, 9062.53, 9054.34,
        8852.38, 9021.52, 9119.94, 9340.34, 8971.81, 8939.01, 8956.43, 7702.2,
        6802.15, 7252.18, 7002.56, 6942.6, 6791.39, 7124.55, 7176.83, 7628.4,
        7636.6, 8004.62, 7805.23, 7426.45, 7474.63, 7421.32, 7264.99, 7107.64,
        7195.29, 6743.21, 6800.62, 7003.07, 7212.2, 7330.09, 7344.96, 7486.42,
        7246.03, 7117.89, 7355.72, 6933.37, 6849.83, 6951.31, 6857.51, 6657.61,
        7114.81, 8073.3, 8332.65, 8327.52, 8538.7, 8814.46, 8566.89, 8390.06,
        8514.09, 8847.77, 8914.92, 8790.36, 9582.78, 9499.23, 9513.07, 9175.8,
        9443.87, 9460.79, 9314.2, 9017.42, 9073.81, 9070.73, 8886.21, 8378.78,
        8132.75, 8364.94, 8154.8, 7691.96, 8211.17, 7794.47, 7554.59, 7631.47,
        7761.15, 7501.8, 7116.35, 7330.09, 7336.75, 7132.76, 6926.7, 6810.86,
        6896.46, 7297.8, 7173.25, 7388.01, 7073.3, 6932.85, 7340.34, 7377.76,
        7404.4, 7031.26, 6622.76, 6297.29, 6769.86, 6332.14, 6720.14, 6559.2,
        6555.61, 6337.78, 6525.88, 6690.92, 6887.75, 7381.35, 7258.33, 7058.43,
        6984.62, 7178.88, 7280.37, 7651.97, 7155.3, 7109.18, 6837.52, 6617.12,
        6950.28, 7032.29, 6781.13, 6955.41, 6760.64, 6596.61, 6617.12, 6089.18,
        6012.31, 5607.39, 4925.68, 5171.71, 4915.43, 5525.37, 5397.23, 5412.61,
        5156.33, 5351.1, 5427.99, 5335.73, 5212.71, 5207.59, 5192.21, 5253.71,
        5187.08, 5258.84, 5756.02, 5868.79, 6186.57, 6278.83, 6248.08, 6099.43,
        5679.14, 5730.4, 5581.76, 5684.26, 5653.51, 5263.96, 5509.99, 5315.22,
        5325.47, 5135.82, 4900.05, 5110.2, 5274.22, 5412.61, 5427.99, 5340.85,
        5515.13, 5884.16, 5791.91, 5602.25, 5371.61, 5433.11, 5479.24, 5761.14,
        5863.66, 5853.4, 5730.4, 5827.78, 6263.46, 6227.58, 6437.72, 6519.73,
        6596.61, 6422.35, 6089.18, 5648.39, 5453.62, 5458.74, 5679.14, 5771.4,
        5750.9, 5740.65, 5556.13, 5397.23, 5392.11, 5602.25, 5674.02, 5509.99,
        5802.15, 5781.65, 5817.53, 5663.76, 5848.28, 5853.4, 6135.32, 6017.43,
        6186.57, 5761.14, 5914.91, 5884.16, 5643.26, 5653.51, 5740.65, 6022.55,
        6371.09, 5858.54, 5786.77, 5730.4, 5530.5, 5269.1, 5566.37, 6289.09,
        6432.6, 7006.67, 7047.67, 7288.57, 7534.6, 7324.45, 6858.02, 6750.38,
        7098.93, 7452.59, 7001.53, 7831.88, 7452.59, 7370.58, 7637.11, 8139.42,
        9015.89, 9267.04, 9046.64, 8887.75, 9600.2, 9251.67, 9343.93, 9764.22,
        9671.96, 12547.41, 12593.55, 12362.89, 11988.72, 13152.24, 12614.04,
        13382.88, 11455.67, 11368.53, 12368.02, 14771.92, 14536.14, 15033.32,
        14741.16, 14894.93, 14279.86, 13034.35, 11881.09, 11998.97, 11296.77,
        11394.16, 12706.3, 13090.73, 12465.4, 11665.82, 10968.73, 11383.91,
        10697.07, 10538.18, 10292.15
      ],
      DOT: [
        10000.0, 10618.9, 11249.06, 11399.1, 10948.99, 10000.0, 10093.77,
        9358.59, 9111.03, 9272.32, 8837.21, 9546.13, 10262.57, 9756.19,
        10348.84, 10502.63, 10401.35, 9598.65, 9463.61, 9111.03, 8844.71,
        7366.84, 6781.7, 6822.96, 6732.93, 6909.23, 6732.93, 6695.43, 6819.2,
        7025.5, 6770.44, 7299.33, 7389.34, 7081.77, 7018.01, 7543.14, 8053.26,
        7936.99, 8435.86, 8147.04, 8259.56, 7880.72, 7014.26, 7096.78, 7044.27,
        7044.27, 7468.11, 7460.62, 6849.21, 6654.16, 6654.16, 6436.61, 6121.53,
        6072.77, 6095.28, 5911.48, 6429.11, 6759.2, 6597.9, 6972.99, 7100.52,
        7025.5, 6744.19, 6286.58, 6507.88, 6496.62, 6129.04, 6316.58, 6657.91,
        6399.1, 6725.43, 6841.71, 6496.62, 6609.15, 6695.43, 7078.02, 7033.01,
        7100.52, 7261.81, 7033.01, 7029.26, 7524.38, 7801.95, 7970.74, 7700.68,
        7809.45, 8274.57, 8402.1, 8274.57, 8465.86, 8079.52, 8240.81, 8668.42,
        8750.94, 8510.88, 8285.82, 7468.11, 7663.17, 7306.83, 7359.34, 7333.09,
        6507.88, 6639.16, 6837.97, 6676.67, 6856.72, 6954.24, 6792.95, 6837.97,
        7066.77, 7201.8, 6845.46, 6882.97, 7096.78, 6849.21, 6815.45, 6327.83,
        6312.82, 6376.59, 6042.76, 5675.17, 5716.43, 5626.41, 5521.38, 6042.76,
        5405.1, 5367.6, 5093.77, 5033.76, 4163.54, 4159.8, 3345.84, 3158.29,
        3998.5, 4302.32, 4384.85, 4054.77, 4133.54, 3660.92, 3724.68, 3638.41,
        3743.43, 3803.45, 3765.95, 3810.95, 3747.18, 3462.11, 3469.62, 3597.15,
        3713.42, 3964.74, 3960.99, 3540.88, 3717.18, 3525.89, 3514.63, 3544.64,
        3548.39, 3458.36, 3394.6, 3458.36, 3270.81, 3072.02, 2839.46, 2546.89,
        2704.43, 3079.52, 2648.16, 2738.19, 2659.42, 2816.96, 2936.98, 2944.49,
        2816.96, 2888.22, 3094.52, 3034.5, 2974.5, 2933.23, 2741.94, 2651.91,
        2550.64, 2535.63, 2565.64, 2558.14, 2674.42, 2591.9, 2606.91, 2715.68,
        2659.42, 2726.93, 2573.14, 2460.61, 2389.35, 2381.84, 2501.88, 2580.65,
        2704.43, 2633.16, 2786.95, 2959.49, 2798.2, 2813.21, 2764.44, 2756.94,
        2779.44, 2595.65, 2516.88, 2753.19, 2963.24, 3128.28, 3102.03, 3289.58,
        3083.28, 3004.51, 3042.01, 3023.26, 3285.82, 3218.3, 3274.57, 3495.88,
        3338.34, 3555.88, 3447.12, 3522.13, 3484.62, 3364.59, 3270.81, 3312.08,
        3135.79, 3132.04, 2749.44, 2708.18, 2790.7, 2734.44, 2861.97, 2854.46,
        2831.96, 2640.66, 2644.41, 2621.9, 2711.93, 2648.16, 2659.42, 2689.43,
        2738.19, 2726.93, 2738.19, 2753.19, 2599.4, 2708.18, 2771.94, 2906.97,
        2918.23, 2869.47, 2869.47, 2659.42, 2708.18, 2595.65, 2576.9, 2629.41,
        2434.36, 2404.35, 2351.83, 2280.57, 2423.11, 2423.11, 2351.83, 2318.08,
        2445.61, 2393.1, 2396.85, 2393.1, 2359.34, 2355.59, 2336.83, 2389.35,
        2430.6, 2408.1, 2374.35, 2374.35, 2363.09, 2419.36, 2359.34, 2321.84,
        2310.58, 2303.07, 2261.82, 2273.06, 2325.58, 2344.34, 2303.07, 2303.07,
        2205.55, 2190.55, 2194.29, 2231.81, 2228.06, 2408.1, 2426.86, 2378.09,
        2449.37, 2475.62, 2479.37, 2490.62, 2434.36, 2325.58, 2419.36, 2651.91,
        2644.41, 2591.9, 2663.17, 2340.59, 2029.26, 2246.81, 2160.54, 2190.55,
        2160.54, 2183.05, 2213.06, 2145.53, 2141.78, 2108.03, 2100.53, 1980.5,
        1946.74, 1976.75, 2021.76, 2014.25, 1976.75, 1980.5, 2021.76, 1935.48,
        1999.25, 2055.52, 2021.76, 2111.78, 2066.76, 2089.27, 2055.52, 2040.51,
        1984.24, 2033.01, 1984.24, 1973.0, 1931.73, 1931.73, 1976.75, 1956.87,
        1959.49, 1763.31, 1765.94, 1749.81, 1677.79, 1726.93, 1672.92, 1682.67,
        1675.92, 1666.54, 1677.04, 1701.8, 1680.42, 1600.9, 1606.53, 1616.65,
        1620.4, 1640.28, 1682.67, 1693.55, 1729.94, 1733.69, 1761.82, 1747.19,
        1783.19, 1840.21, 1842.46, 1887.48, 1960.99, 2009.38, 2258.06, 2234.43,
        2183.79, 2246.81, 2145.53, 2172.92, 2320.71, 2333.84, 2319.95, 2499.25,
        2332.71, 2368.34, 2425.35, 2435.11, 2381.1, 2481.62, 2316.58, 2351.83,
        2422.35, 2468.12, 2626.03, 2609.15, 2492.5, 2466.25, 2584.77, 2526.64,
        2312.83, 2309.45, 2353.34, 2314.7, 2264.07, 2333.45, 2462.49, 2396.47,
        2754.31, 2717.55, 2804.57, 2817.33, 2705.93, 2681.55, 2649.66, 2459.49,
        2440.36, 2493.25, 2464.74, 2380.34, 2433.98, 2367.97, 2259.94, 2197.67,
        2246.06, 2225.8, 2175.54, 2087.4, 1992.87, 2084.02, 2027.76, 2170.29,
        2301.95, 2339.46, 2190.55, 2279.07, 2450.11, 2387.85, 2437.74, 2298.58,
        2382.97, 2265.19, 2359.34, 2251.69, 2205.55, 2270.82, 2192.05, 2271.95,
        2319.21, 2281.32, 2373.22, 2375.85, 2354.09, 2397.6, 2420.48, 2401.72,
        2359.71, 2320.71, 2302.33, 2340.96, 2356.71, 2411.48, 2392.73, 2475.99,
        2531.51, 2545.76, 2571.64, 2520.26, 2590.77, 2390.1, 2285.08, 2182.29,
        2233.31, 2210.8, 2208.93, 2241.94, 2188.3, 2233.31, 2223.56, 2256.19,
        2220.55, 2119.28, 2148.16, 2176.3, 2119.28, 2196.55, 2116.28, 2126.03,
        2011.63, 1991.37, 2052.89, 1977.12, 1999.25, 2012.38, 2012.38, 2002.62,
        1985.37, 2024.75, 2007.87, 2016.13, 2027.76, 1981.62, 1993.25, 2024.38,
        1971.11, 1967.74, 1966.62, 2001.51, 2075.4, 2049.14, 2045.39, 1991.0,
        1957.99, 1984.24, 1986.87, 2008.63, 1897.6, 1957.24, 1878.1, 1888.22,
        1871.34, 1698.8, 1691.3, 1697.3, 1725.43, 1711.17, 1642.53, 1656.42,
        1705.55, 1699.55, 1692.05, 1749.81, 1817.33, 1806.82, 1902.47, 1872.47,
        1942.61, 1899.85, 1908.1, 1835.33, 1862.35, 1937.73, 1995.13, 2057.02,
        2025.88, 2011.25, 1947.48, 1902.47, 1913.73, 1921.61, 1913.73, 1919.36,
        1950.86, 1925.36, 2072.02, 2023.25, 2033.01, 2002.62, 1977.49, 1941.49,
        1943.36, 2007.5, 2059.27, 2009.0, 2021.76, 1956.87, 1939.23, 1964.36,
        1963.24, 1953.49, 1965.86, 1935.48, 1915.98, 1930.23, 1897.22, 1870.59,
        1870.59, 1867.97, 1878.84, 1861.96, 1895.35, 1893.47, 1874.35, 1878.47,
        1890.85, 1867.97, 1872.85, 1803.45, 1748.32, 1680.79, 1696.55, 1690.17,
        1694.3, 1665.79, 1640.28, 1684.17, 1653.04, 1681.55, 1691.68, 1687.17,
        1725.43, 1758.06, 1699.93, 1601.65, 1575.77, 1597.9, 1597.9, 1582.9,
        1598.65, 1598.65, 1608.77, 1592.27, 1591.52, 1557.01, 1493.24, 1500.0,
        1502.63, 1529.63, 1562.27, 1566.02, 1527.76, 1548.77, 1557.77, 1552.89,
        1508.63, 1508.25, 1508.63, 1510.12, 1523.64, 1502.25, 1497.75, 1528.5,
        1529.63, 1540.89, 1604.28, 1544.64, 1531.13, 1516.13, 1510.51, 1527.76,
        1519.88, 1503.75, 1447.11, 1433.24, 1399.48, 1376.6, 1397.6, 1400.23,
        1407.35, 1429.86, 1374.72, 1373.59, 1362.34, 1397.6, 1467.37, 1484.25,
        1621.16, 1577.65, 1605.03, 1591.52, 1547.27, 1568.27, 1627.91, 1680.05,
        1662.8, 1768.19, 1729.18, 1732.18, 1764.07, 1791.45, 1870.22, 1840.96,
        1900.98, 1866.09, 1965.49, 2090.02, 2155.29, 2007.87, 1957.24, 2087.77,
        2023.25, 1972.62, 1978.25, 2027.38, 1995.87, 1824.08, 1937.36, 1926.48,
        1967.74, 1995.13, 1995.13, 1921.98, 1969.61, 1936.24, 2055.13, 2052.89,
        2082.52, 2093.02, 2089.65, 2174.42, 2267.44, 2326.7, 2532.63, 2687.17,
        2725.06, 2525.13, 2606.91, 2798.95, 2840.96, 2626.78, 2665.04, 2560.77,
        2567.52, 2515.75, 2596.02, 3080.65, 2984.25, 3154.91, 3232.56, 3448.24,
        3313.95, 3239.31, 3169.17, 3082.15, 3126.79, 3048.39, 3196.55, 3166.54,
        2878.47, 2944.86, 2761.44, 2671.42, 2587.4, 2809.45, 2672.16, 2971.12,
        3051.39, 2817.33, 2871.34, 2791.83, 2813.58, 2828.59, 2741.94, 2641.04,
        2571.64, 2592.65, 2567.89, 2394.23, 2361.22, 2406.6, 2417.1, 2505.63,
        2504.5, 2537.51, 2704.05, 2590.77, 2494.37, 2543.89, 2610.28, 2570.52,
        2510.5, 2506.75, 2557.39, 2600.9, 2639.91, 2703.67, 2710.05, 2661.66,
        2756.19, 2743.81, 2878.85, 2900.22, 2845.84, 2884.47, 2948.98, 3035.63,
        2927.98, 2783.2, 2822.95, 2823.34, 2936.98, 2949.74, 3035.26, 3134.28,
        3121.53, 3054.01, 3235.18, 3496.25, 3669.17, 3754.32, 3458.73, 3916.73,
        3888.97, 3962.12, 3932.48, 3764.82, 4174.04, 3998.5, 4132.03, 4318.45,
        4033.75, 3656.04, 3786.2, 3688.68, 3291.82, 3578.4, 3462.49, 3301.21,
        3409.23, 3525.89, 3674.05, 3636.91, 3560.76, 3576.9, 3583.65, 3530.38,
        3598.28, 3438.48, 3244.19, 3166.54, 3169.54, 3135.41, 3192.04, 3246.44,
        3397.97, 3280.57, 3169.92, 3130.54, 2698.8, 2389.35, 2571.64, 2531.13,
        2523.63, 2482.74, 2550.64, 2519.5, 2691.67, 2677.42, 2829.7, 2716.06,
        2595.65, 2571.27, 2525.51, 2528.51, 2542.01, 2485.37, 2398.35, 2576.52,
        2752.06, 2729.56, 2675.17, 2747.94, 2689.43, 2649.66, 2626.78, 2654.17,
        2527.38, 2507.87, 2483.12, 2499.63, 2436.61, 2606.52, 2630.91, 2701.43,
        2679.67, 2610.28, 2821.08, 2861.97, 2795.95, 2703.67, 2713.43, 2770.44,
        2841.33, 2852.59, 2764.06, 2692.43, 2627.16, 2622.28, 2658.66, 2618.15,
        2640.28, 2698.8, 2709.68, 2676.67, 2499.63, 2393.47, 2445.99, 2441.49,
        2397.22, 2524.76, 2397.22, 2315.46, 2334.21, 2394.6, 2298.58, 2167.67,
        2177.42, 2126.03, 2106.9, 2160.16, 2116.28, 2131.66, 2202.17, 2181.17,
        2360.84, 2318.46, 2279.82, 2336.08, 2393.1, 2463.99, 2270.07, 2139.54,
        2140.28, 2333.84, 2223.18, 2221.31, 2282.82, 2280.94, 2221.68, 2279.07,
        2350.72, 2371.72, 2420.48, 2370.97, 2354.84, 2290.7, 2373.97, 2369.84,
        2399.85, 2298.95, 2220.55, 2144.79, 2152.28, 2196.92, 2204.05, 2139.54,
        2115.91, 2072.02, 2027.01, 2008.26, 1914.86, 1890.1, 1785.82, 1591.52,
        1694.68, 1710.8, 1833.08, 1789.2, 1784.69, 1692.79, 1704.05, 1705.92,
        1648.54, 1606.9, 1613.28, 1633.16, 1689.05, 1674.41, 1699.55, 1749.44,
        1743.06, 1839.46, 1854.47, 1824.08, 1717.18, 1635.03, 1597.53, 1583.64,
        1602.02, 1594.53, 1518.76, 1579.15, 1540.89, 1548.77, 1503.37, 1475.99,
        1524.38, 1557.77, 1620.78, 1597.15, 1571.64, 1611.4, 1663.91, 1658.29,
        1660.17, 1558.89, 1558.51, 1543.51, 1597.15, 1624.53, 1671.42, 1624.53,
        1664.67, 1754.31, 1751.69, 1804.58, 1839.83, 1800.45, 1792.2, 1676.3,
        1559.27, 1539.39, 1547.27, 1573.14, 1552.89, 1566.76, 1564.89, 1534.88,
        1506.0, 1523.64, 1564.14, 1582.14, 1555.14, 1650.04, 1641.04, 1629.78,
        1575.77, 1605.4, 1662.8, 1719.05, 1648.54, 1628.66, 1574.27, 1576.52,
        1531.88, 1512.38, 1545.01, 1552.89, 1572.01, 1572.01, 1482.0, 1463.62,
        1462.86, 1424.98, 1409.22, 1454.61, 1546.88, 1552.14, 1627.15, 1708.55,
        1960.61, 2037.51, 2036.01, 1896.48, 1805.33, 1930.98, 2126.78, 2015.38,
        2268.94, 2155.66, 2157.16, 2223.56, 2433.98, 3241.56, 3370.22, 3130.54,
        3019.51, 3153.04, 3246.44, 3275.69, 3373.97, 3462.49, 3675.92, 3689.42,
        4013.13, 3942.61, 4031.51, 3955.74, 3906.98, 3189.42, 3141.04, 3400.23,
        3373.21, 3360.47, 3199.92, 3327.46, 3323.71, 3189.8, 2924.98, 2665.04,
        2749.82, 2606.91, 2576.14, 2789.2, 2835.34, 2803.83, 2626.41, 2587.77,
        2683.05, 2566.77, 2507.5, 2510.5
      ],
      AVAX: [
        10000.0, 10368.27, 10373.75, 9904.05, 9565.01, 8748.06, 8748.97,
        7912.82, 7744.67, 8177.83, 7667.92, 8137.62, 8653.93, 8217.13, 8250.94,
        8544.27, 8462.03, 7901.86, 7893.63, 7652.38, 7427.58, 6010.23, 5405.28,
        5641.96, 5742.48, 6119.89, 5988.3, 5820.15, 6052.27, 6541.17, 6211.28,
        6367.54, 6622.5, 6281.64, 6198.48, 6925.88, 7090.38, 6930.45, 7636.85,
        7847.93, 8237.23, 8398.97, 7349.91, 7461.39, 7194.55, 7456.82, 8271.04,
        8777.3, 8144.94, 7609.43, 7689.85, 7179.02, 6564.93, 6634.37, 6921.32,
        6802.52, 7360.87, 7479.66, 6873.8, 7617.66, 7872.62, 7647.81, 7243.9,
        6924.06, 7083.07, 6739.47, 6576.81, 6622.5, 7025.5, 6756.83, 6566.75,
        6520.15, 6178.38, 6175.64, 6417.8, 6663.62, 7289.59, 7824.17, 8217.13,
        7915.56, 7928.36, 7815.04, 7814.12, 7954.86, 7594.81, 7821.43, 8051.72,
        8403.54, 8383.44, 8750.8, 8827.56, 8855.89, 8899.75, 9050.54, 8715.16,
        8410.85, 7693.5, 8014.25, 7636.85, 7641.42, 7573.79, 6807.09, 6896.64,
        7313.35, 7005.39, 7052.0, 7093.12, 7020.02, 7102.26, 7274.05, 7194.55,
        6871.06, 6793.38, 6725.76, 6585.94, 6625.24, 6289.86, 6341.95, 6142.74,
        5793.65, 5206.06, 5320.3, 5503.97, 5450.06, 6074.2, 5363.25, 5212.46,
        4920.95, 4740.02, 4023.58, 4011.7, 2807.28, 2686.64, 3032.99, 3102.44,
        3291.61, 3045.79, 3121.63, 2785.34, 2687.56, 2652.83, 2706.75, 2918.76,
        2665.63, 2648.27, 2527.64, 2194.1, 2078.04, 2253.5, 2392.39, 2526.73,
        2428.95, 2184.96, 2247.1, 2110.93, 2206.89, 2250.75, 2357.67, 2254.41,
        2230.65, 2227.0, 2059.76, 1860.55, 1629.35, 1420.09, 1452.98, 1633.01,
        1442.02, 1466.69, 1365.26, 1477.65, 1537.97, 1567.21, 1504.15, 1654.94,
        1887.05, 1941.88, 1833.13, 1782.87, 1678.7, 1635.75, 1497.76, 1474.91,
        1507.81, 1511.47, 1637.58, 1669.56, 1745.41, 1834.04, 1824.91, 1825.82,
        1749.98, 1596.46, 1567.21, 1671.39, 1791.1, 1800.23, 1947.37, 1911.73,
        2124.64, 2268.12, 2169.43, 2274.51, 2183.13, 2163.03, 2180.39, 1964.73,
        1864.21, 2130.12, 2222.43, 2275.43, 2224.25, 2168.51, 2158.46, 2122.81,
        2136.52, 2125.55, 2264.46, 2425.29, 2543.18, 2575.16, 2511.2, 2642.78,
        2619.02, 2663.8, 2654.66, 2581.56, 2501.14, 2482.87, 2324.77, 2295.53,
        2067.07, 2022.3, 2098.14, 2026.87, 2142.01, 2109.11, 2099.97, 1917.21,
        1832.22, 1707.03, 1754.54, 1792.92, 1767.34, 1761.85, 1725.3, 1724.39,
        1725.3, 1731.7, 1679.61, 1737.18, 1739.92, 1856.89, 1887.97, 1879.74,
        1945.54, 1735.35, 1743.58, 1667.73, 1649.46, 1692.41, 1543.45, 1581.84,
        1531.57, 1503.24, 1617.48, 1642.15, 1613.82, 1584.58, 1585.49, 1582.75,
        1577.27, 1569.95, 1565.38, 1556.24, 1527.91, 1554.41, 1589.15, 1586.41,
        1557.15, 1544.36, 1529.74, 1544.36, 1486.79, 1461.21, 1460.29, 1438.36,
        1429.22, 1415.52, 1440.19, 1474.91, 1449.33, 1389.93, 1392.67, 1437.45,
        1431.97, 1485.88, 1443.84, 1517.86, 1558.07, 1551.67, 1674.13, 1664.99,
        1664.99, 1760.03, 1709.77, 1626.61, 1650.37, 1768.25, 1810.29, 1710.68,
        1641.23, 1436.53, 1172.44, 1414.6, 1276.61, 1214.47, 1187.06, 1187.97,
        1231.83, 1196.2, 1188.89, 1182.49, 1178.83, 1115.78, 1079.23, 1125.83,
        1173.35, 1182.49, 1172.44, 1158.73, 1203.51, 1141.37, 1146.85, 1198.02,
        1177.92, 1240.97, 1235.49, 1261.08, 1240.97, 1234.58, 1208.99, 1224.52,
        1226.35, 1230.01, 1200.77, 1187.06, 1254.68, 1255.59, 1198.94, 1096.59,
        1088.37, 1091.11, 1036.28, 1092.02, 1063.7, 1078.32, 1073.75, 1066.44,
        1065.52, 1060.95, 1061.87, 1020.75, 996.07, 988.76, 994.24, 996.07,
        1017.09, 1037.2, 1095.68, 1074.66, 1075.58, 1069.18, 1095.68, 1117.61,
        1163.3, 1421.91, 1397.24, 1452.07, 1541.62, 1546.19, 1527.91, 1564.47,
        1468.52, 1473.09, 1584.58, 1562.64, 1581.84, 1653.11, 1564.47, 1609.25,
        1653.11, 1855.98, 1877.92, 1905.33, 1792.01, 1819.42, 1899.85, 1956.5,
        1951.02, 1945.54, 1845.01, 1823.99, 1892.54, 1829.48, 1620.22, 1614.73,
        1643.98, 1628.44, 1588.23, 1652.2, 1796.58, 1707.94, 1782.87, 1789.27,
        1822.17, 1900.76, 1907.16, 1853.23, 1793.84, 1667.73, 1636.67, 1675.04,
        1639.41, 1568.12, 1612.91, 1594.63, 1516.03, 1468.52, 1484.97, 1508.72,
        1465.78, 1381.71, 1303.12, 1357.95, 1313.17, 1461.21, 1508.72, 1560.81,
        1418.26, 1443.84, 1587.32, 1564.47, 1614.73, 1534.31, 1588.23, 1530.65,
        1597.37, 1552.59, 1527.91, 1564.47, 1502.33, 1524.26, 1582.75, 1566.29,
        1618.39, 1628.44, 1577.27, 1565.38, 1608.34, 1643.06, 1643.06, 1612.91,
        1593.72, 1610.17, 1634.84, 1660.42, 1657.68, 1717.99, 1739.92, 1747.23,
        1866.04, 1889.8, 1934.57, 1747.23, 1675.96, 1540.71, 1578.18, 1546.19,
        1546.19, 1605.6, 1561.72, 1618.39, 1602.85, 1591.89, 1575.44, 1516.95,
        1543.45, 1580.01, 1541.62, 1587.32, 1519.69, 1511.47, 1406.38, 1395.41,
        1421.0, 1366.17, 1383.53, 1367.09, 1373.48, 1388.1, 1359.78, 1385.36,
        1347.9, 1343.33, 1336.93, 1309.52, 1341.5, 1345.15, 1290.33, 1287.58,
        1310.43, 1329.62, 1368.0, 1320.48, 1325.97, 1289.41, 1293.07, 1322.31,
        1330.53, 1357.95, 1266.56, 1336.93, 1279.35, 1278.44, 1262.9, 1064.61,
        1056.39, 1054.56, 1071.01, 1034.45, 1041.77, 1054.56, 1066.44, 1040.85,
        1048.16, 1102.99, 1187.06, 1155.08, 1207.16, 1187.06, 1229.09, 1201.68,
        1204.42, 1130.4, 1156.9, 1189.8, 1184.32, 1199.85, 1207.16, 1198.94,
        1155.08, 1147.77, 1161.47, 1244.63, 1247.37, 1221.78, 1208.99, 1189.8,
        1277.52, 1322.31, 1354.29, 1311.34, 1311.34, 1256.51, 1278.44, 1268.39,
        1279.35, 1248.28, 1236.4, 1194.37, 1219.04, 1214.47, 1199.85, 1208.99,
        1208.08, 1193.45, 1171.52, 1164.21, 1146.85, 1138.63, 1133.14, 1136.8,
        1149.59, 1135.89, 1158.73, 1152.33, 1142.28, 1136.8, 1132.23, 1118.52,
        1127.66, 1077.4, 1037.2, 970.48, 987.84, 983.27, 996.07, 947.63, 917.48,
        943.98, 921.13, 920.22, 922.96, 931.19, 944.89, 992.41, 950.38, 915.65,
        903.77, 905.6, 901.03, 890.98, 906.51, 910.17, 922.05, 906.51, 901.03,
        865.39, 842.55, 845.29, 850.77, 861.74, 863.57, 857.17, 829.75, 838.89,
        842.55, 825.19, 807.82, 811.48, 810.57, 811.48, 816.96, 822.44, 816.96,
        843.46, 836.15, 844.38, 897.38, 850.77, 864.48, 929.36, 925.7, 978.7,
        944.89, 923.88, 866.31, 872.7, 846.2, 833.41, 837.98, 836.15, 837.07,
        861.74, 832.5, 816.96, 827.93, 843.46, 884.58, 917.48, 970.48, 951.29,
        956.77, 1010.7, 961.34, 1002.47, 1037.2, 1045.42, 1026.23, 1108.47,
        1079.23, 1106.64, 1115.78, 1124.92, 1195.28, 1166.95, 1196.2, 1173.35,
        1338.76, 1539.79, 1761.85, 1484.05, 1569.04, 1879.74, 2017.73, 2020.47,
        2010.42, 2016.81, 1917.21, 1753.63, 1908.99, 1901.68, 1902.59, 1932.74,
        1930.92, 1847.75, 1882.49, 1923.61, 1951.02, 1992.14, 2020.47, 2024.12,
        2025.95, 2247.1, 2394.22, 2400.62, 2740.57, 2902.31, 3400.35, 3564.83,
        3364.71, 3619.67, 3605.05, 3723.84, 3895.64, 3726.58, 3747.6, 3630.63,
        3920.32, 4226.45, 4082.97, 4305.03, 4316.0, 4414.69, 4047.33, 3857.26,
        3698.25, 3671.75, 3592.25, 3495.38, 3774.1, 3724.75, 3354.66, 3523.71,
        3301.66, 3112.49, 3053.1, 3300.74, 3170.98, 3573.98, 3604.13, 3264.18,
        3342.78, 3293.43, 3243.16, 3286.12, 3288.86, 3086.91, 2985.47, 3008.32,
        2988.21, 2723.21, 2736.91, 2788.09, 2786.26, 2948.0, 3019.29, 3158.18,
        3296.17, 3246.82, 3068.63, 3084.16, 3355.57, 3297.09, 3200.22, 3138.08,
        3136.25, 3223.98, 3239.51, 3494.47, 3722.01, 3619.67, 3751.25, 3622.41,
        3859.09, 3776.84, 3656.22, 3639.77, 3716.53, 3637.94, 3523.71, 3415.88,
        3387.55, 3277.89, 3354.66, 3414.97, 3586.77, 3584.03, 3644.34, 3669.93,
        3883.76, 3969.66, 3905.7, 3914.83, 3544.73, 3781.41, 3957.78, 3880.11,
        3934.02, 3771.36, 4473.18, 5019.65, 4952.93, 4929.17, 5189.62, 4864.3,
        5217.94, 5573.43, 4945.62, 5229.82, 4902.67, 4771.09, 4931.92, 5056.2,
        5315.73, 5125.65, 4960.24, 5000.46, 4891.71, 4843.28, 4924.61, 4708.03,
        4336.1, 4201.77, 4236.5, 4135.06, 4421.09, 4404.64, 4586.49, 4299.55,
        4343.41, 4213.65, 3569.4, 3104.27, 3444.21, 3249.56, 3193.82, 3099.7,
        3194.73, 3193.82, 3478.02, 3394.86, 3617.84, 3495.38, 3319.93, 3245.91,
        3139.91, 3125.29, 3122.54, 3202.04, 2997.35, 3020.2, 3088.73, 3247.73,
        3391.21, 3416.8, 3419.54, 3255.96, 3095.13, 3223.06, 3041.22, 3065.89,
        3032.08, 2961.71, 2889.52, 3158.18, 3143.56, 3335.47, 3391.21, 3276.06,
        3600.48, 3772.27, 3659.87, 3501.78, 3469.8, 3490.81, 3375.68, 3495.38,
        3391.21, 3337.3, 3293.43, 3304.4, 3282.46, 3198.39, 3229.46, 3278.8,
        3319.93, 3294.35, 3074.11, 2942.52, 3003.75, 2964.45, 2879.46, 3045.79,
        2848.4, 2774.38, 2745.14, 2744.22, 2619.02, 2428.95, 2460.02, 2524.9,
        2513.02, 2343.96, 2299.19, 2250.75, 2331.17, 2377.77, 2558.71, 2565.11,
        2534.04, 2687.56, 2644.61, 2625.42, 2434.44, 2281.82, 2297.36, 2510.28,
        2349.44, 2316.55, 2391.48, 2349.44, 2312.89, 2375.03, 2379.6, 2414.32,
        2533.13, 2553.23, 2497.49, 2495.66, 2566.94, 2651.01, 2988.21, 2924.24,
        2733.26, 2608.06, 2483.78, 2608.06, 2592.52, 2489.26, 2492.01, 2413.41,
        2364.98, 2343.05, 2154.81, 2068.9, 1972.95, 1808.46, 1924.52, 1826.73,
        2074.38, 1982.09, 1976.61, 1877.92, 1919.04, 1957.42, 1920.86, 1837.7,
        1868.78, 1878.83, 1940.97, 1927.26, 2050.62, 2152.06, 2271.77, 2436.26,
        2462.76, 2503.89, 2374.12, 2205.98, 2145.67, 2093.57, 2125.55, 2079.87,
        1940.97, 2053.37, 1965.64, 2013.16, 1961.07, 1926.35, 1978.43, 2109.11,
        2190.44, 2236.13, 2135.61, 2195.93, 2289.13, 2302.84, 2189.53, 2131.95,
        2174.0, 2192.27, 2439.92, 2521.25, 2532.21, 2507.54, 2496.58, 2585.21,
        2506.63, 2666.54, 2778.03, 2673.85, 2657.4, 2540.44, 2372.29, 2336.65,
        2258.06, 2396.05, 2341.22, 2445.4, 2440.83, 2411.58, 2362.24, 2352.19,
        2511.2, 2615.37, 2639.13, 2687.56, 2549.58, 2570.59, 2498.4, 2555.97,
        2571.51, 2634.56, 2552.32, 2534.95, 2435.35, 2454.54, 2344.88, 2313.81,
        2356.75, 2406.1, 2449.06, 2397.88, 2286.39, 2265.38, 2204.15, 2155.72,
        2086.26, 2195.01, 2487.44, 2491.09, 2613.54, 2719.55, 2930.64, 3202.04,
        3208.44, 3001.92, 2895.91, 3032.08, 3181.03, 3176.46, 3216.66, 3094.22,
        3087.82, 3276.06, 3829.84, 3817.96, 3827.1, 3847.2, 3897.47, 4013.52,
        3940.42, 4075.66, 4115.87, 4102.16, 4697.97, 4703.46, 4840.54, 4646.8,
        4811.3, 4712.6, 4935.57, 4082.97, 4087.54, 4398.24, 4850.59, 4753.73,
        4547.2, 4490.54, 4539.89, 4392.76, 3960.52, 3623.32, 3635.2, 3414.97,
        3349.18, 3615.1, 3760.39, 3665.36, 3420.45, 3330.9, 3469.8, 3257.79,
        3287.03, 3283.37
      ],
      LTC: [
        10000.0, 10252.91, 10328.1, 10123.03, 10061.52, 9227.61, 9357.49,
        8933.7, 8926.87, 9008.89, 8626.11, 8981.54, 9637.73, 9316.47, 9911.14,
        10102.53, 10020.51, 10375.94, 9767.6, 9425.83, 9125.09, 7778.54,
        7354.75, 7409.43, 7436.78, 7382.09, 7265.89, 7238.56, 7409.43, 7580.31,
        7375.26, 7491.45, 7860.57, 7429.94, 7498.29, 8168.15, 8352.7, 8489.41,
        9371.16, 9145.59, 9583.05, 9336.98, 8571.43, 8660.29, 8646.62, 8516.75,
        8872.18, 8803.83, 8004.1, 7867.4, 7887.9, 7655.5, 7259.06, 7245.39,
        7341.08, 7019.82, 7525.64, 7388.93, 7060.83, 7648.67, 7662.34, 7559.81,
        7587.16, 6951.47, 7183.87, 7074.51, 6739.58, 6821.6, 7293.23, 7040.33,
        7163.37, 7259.06, 6978.82, 7170.2, 7306.9, 7552.97, 7539.3, 7621.33,
        7737.52, 7928.91, 7942.59, 8352.7, 8325.36, 8632.94, 8441.56, 8530.42,
        8769.65, 8851.68, 8831.17, 8974.71, 8516.75, 8564.6, 8557.76, 8783.32,
        8537.25, 8489.41, 7764.87, 7799.05, 7607.66, 7676.0, 7614.49, 7074.51,
        7129.19, 7559.81, 7341.08, 7587.16, 7710.19, 7566.64, 7614.49, 7771.71,
        7669.17, 7313.74, 7224.88, 7252.23, 7190.71, 7149.7, 6760.08, 6848.94,
        7047.16, 6869.44, 6739.58, 6725.91, 6896.79, 6780.59, 7218.04, 6575.53,
        6623.38, 6390.98, 6473.0, 5454.54, 5420.37, 4572.8, 4292.55, 4695.84,
        4723.17, 4818.87, 4620.65, 4955.58, 4620.65, 4859.87, 4695.84, 4764.18,
        4907.73, 4757.35, 4812.03, 4695.84, 4408.75, 4244.7, 4278.88, 4354.07,
        4730.01, 4716.34, 4278.88, 4395.08, 4299.39, 4360.91, 4340.39, 4388.24,
        4347.24, 4237.87, 4114.83, 3896.1, 3622.69, 3349.28, 2898.15, 3082.71,
        3439.51, 3046.48, 3252.22, 3154.47, 3702.67, 3591.93, 3696.51, 3613.12,
        3843.47, 3831.17, 4016.41, 3922.76, 3840.06, 3628.16, 3670.54, 3544.09,
        3496.93, 3457.28, 3472.32, 3552.98, 3445.66, 3445.66, 3528.36, 3536.57,
        3648.67, 3580.32, 3327.41, 3263.16, 3326.04, 3486.67, 3557.76, 3740.94,
        3881.75, 3948.05, 4000.68, 3921.39, 3958.99, 3850.99, 3865.35, 4000.0,
        3805.2, 3655.51, 4008.21, 4365.01, 4219.42, 4158.57, 4105.94, 4033.49,
        4014.35, 3973.35, 4058.1, 4261.11, 4198.9, 4183.19, 4284.35, 4045.8,
        4215.32, 4226.93, 4273.41, 4353.38, 4313.05, 4113.47, 4195.49, 4108.68,
        4162.0, 3679.42, 3699.25, 3795.63, 3872.18, 3898.16, 3866.72, 3891.32,
        3676.69, 3623.38, 3745.73, 3789.47, 3657.55, 3714.97, 3897.47, 4190.7,
        4102.53, 4095.01, 4132.6, 3741.63, 3963.78, 3980.18, 4168.15, 4358.17,
        4244.02, 4172.93, 4034.18, 4112.78, 3850.3, 3799.04, 3934.38, 3645.25,
        3593.99, 3589.2, 3467.53, 3652.08, 3743.68, 3669.18, 3611.76, 3635.68,
        3606.98, 3639.78, 3656.87, 3644.57, 3619.96, 3595.35, 3676.69, 3783.33,
        3728.64, 3656.18, 3618.59, 3596.03, 3655.51, 3606.98, 3555.71, 3562.55,
        3518.8, 3511.96, 3453.86, 3529.73, 3537.93, 3539.3, 3483.26, 3498.97,
        3529.05, 3566.65, 3669.85, 3600.14, 3813.4, 3852.36, 3747.1, 3760.77,
        3850.99, 3779.23, 3764.18, 3770.34, 4068.35, 4224.2, 4639.09, 4803.83,
        4738.21, 4613.12, 3847.57, 3388.93, 4133.29, 4177.03, 4109.37, 3924.82,
        3819.55, 3965.14, 3915.25, 4250.86, 4278.88, 4374.57, 4231.03, 4185.92,
        4766.91, 5444.3, 5327.41, 5029.39, 5210.53, 5209.84, 4999.32, 5237.87,
        5398.5, 5320.58, 5296.65, 5213.94, 5243.33, 5498.3, 5369.11, 5267.26,
        5330.15, 5227.62, 5213.94, 5249.49, 5209.84, 5287.08, 5149.01, 4956.26,
        4495.56, 4421.74, 4414.21, 4269.31, 4484.63, 4466.85, 4503.07, 4479.84,
        4486.67, 4693.09, 4788.11, 4680.79, 4511.96, 4548.19, 4642.52, 4790.84,
        4840.74, 5114.15, 5146.96, 5129.18, 5086.81, 5191.39, 5211.21, 5231.03,
        5574.85, 5522.21, 5656.19, 5876.28, 5875.6, 6012.31, 5989.75, 5874.91,
        5949.42, 5717.02, 5742.31, 6065.62, 6097.75, 5974.71, 6177.71, 5928.92,
        6054.0, 5971.29, 6082.71, 6055.37, 6555.71, 6185.24, 6405.34, 6883.8,
        6779.22, 6817.5, 6764.87, 6574.16, 6615.17, 6882.43, 6755.98, 6203.69,
        6353.39, 6446.34, 6303.49, 6152.43, 6498.3, 6991.12, 6825.02, 6863.98,
        6818.87, 6712.92, 6500.34, 6359.53, 6462.75, 6448.4, 6220.78, 6364.32,
        6477.78, 6408.75, 6429.94, 6657.56, 6514.01, 6153.8, 6045.79, 6163.37,
        6000.69, 5855.1, 5628.85, 5182.5, 4906.36, 4668.49, 5042.38, 5632.26,
        5719.75, 5178.4, 5380.72, 5771.71, 5699.93, 5812.03, 5462.75, 5661.65,
        5904.3, 6372.52, 6326.73, 6235.13, 6438.82, 6053.32, 6052.64, 6241.97,
        6052.64, 6153.11, 6352.02, 6344.5, 6366.38, 6331.51, 6316.48, 6194.13,
        6194.81, 6144.23, 6218.04, 6365.69, 6427.21, 6281.62, 6426.52, 6602.19,
        6615.17, 6892.69, 6753.25, 6930.29, 6358.16, 6120.98, 5840.06, 5956.26,
        5915.93, 6041.69, 6214.63, 6014.35, 6117.57, 6128.51, 6211.21, 6064.25,
        5915.24, 6047.16, 6095.69, 6016.41, 6073.82, 5704.04, 5735.48, 5319.21,
        5445.66, 5537.94, 5483.94, 5473.0, 5500.34, 5706.08, 5983.59, 6116.88,
        6409.44, 6248.8, 6262.47, 6287.76, 6318.52, 6220.1, 6241.29, 5877.64,
        5861.93, 5968.56, 6055.37, 6262.47, 6225.57, 6301.43, 6137.39, 6460.7,
        6501.03, 6582.37, 6518.8, 5961.72, 6215.31, 6032.81, 6054.0, 6095.69,
        5306.22, 5303.49, 5281.62, 5255.64, 4990.43, 5082.71, 5213.26, 5272.73,
        5265.89, 5287.08, 5466.17, 5835.96, 5829.8, 6209.16, 6058.11, 6021.88,
        5955.57, 5989.06, 5684.21, 5779.22, 7368.42, 7219.41, 7710.19, 7272.04,
        7256.33, 6978.13, 6630.9, 6725.22, 6682.16, 6548.87, 6579.63, 6628.16,
        6576.21, 6906.36, 6442.92, 6471.64, 6440.88, 6296.65, 6242.66, 6295.29,
        6324.68, 6452.5, 6341.76, 6325.36, 6077.92, 6124.4, 6155.17, 6162.0,
        6246.07, 6399.18, 6386.19, 6286.4, 6386.88, 5962.4, 5710.87, 5602.87,
        5692.41, 5645.93, 5634.32, 5736.84, 5737.52, 5675.32, 5699.93, 5714.97,
        5591.25, 5611.07, 5434.04, 5085.44, 4470.27, 4386.88, 4357.48, 4459.33,
        4558.44, 4393.03, 4479.84, 4425.84, 4459.33, 4447.71, 4457.97, 4481.2,
        4723.85, 4611.76, 4382.09, 4318.52, 4408.75, 4362.27, 4335.61, 4308.95,
        4285.03, 4362.27, 4269.99, 4319.21, 4174.99, 4006.15, 4111.41, 4242.65,
        4310.32, 4507.86, 4448.4, 4341.76, 4534.52, 4605.61, 4407.38, 4408.75,
        4402.6, 4434.73, 4388.93, 4392.34, 4339.03, 4344.5, 4464.11, 4486.67,
        4529.73, 4692.41, 4495.56, 4485.3, 4403.97, 4435.41, 4482.57, 4470.27,
        4449.08, 4303.49, 4358.17, 4198.22, 4177.72, 4202.33, 4213.95, 4207.79,
        4339.71, 4245.39, 4149.7, 4208.47, 4341.08, 4435.41, 4436.77, 4753.25,
        4725.22, 4699.25, 4705.4, 4552.29, 4637.05, 4724.54, 4719.75, 4697.88,
        4776.48, 4728.64, 4749.15, 4773.75, 4883.12, 5119.61, 5009.57, 5017.09,
        4940.53, 5015.72, 5131.24, 5141.49, 4874.92, 4805.88, 5055.37, 4859.2,
        4801.78, 4763.5, 4784.01, 4745.73, 4500.34, 4686.95, 4756.67, 4822.28,
        4906.36, 4799.73, 4717.02, 4757.35, 4780.58, 4743.0, 4881.07, 4936.43,
        4954.89, 4941.22, 5004.11, 4975.39, 5054.68, 5289.82, 5278.19, 5278.88,
        4952.83, 4926.86, 5006.84, 4992.48, 4870.13, 4932.33, 4900.89, 4829.8,
        4819.55, 4794.26, 4842.79, 5023.92, 4935.06, 4842.1, 4948.05, 4982.23,
        5124.4, 5237.87, 4997.95, 4986.33, 4951.47, 5084.75, 4995.22, 4462.07,
        4536.57, 4477.1, 4473.0, 4384.82, 4660.29, 4543.4, 4785.37, 4900.89,
        4925.49, 4936.43, 4818.18, 4743.0, 4766.24, 4747.78, 4665.75, 4880.39,
        4868.09, 4932.33, 4609.02, 4442.93, 4499.66, 4481.2, 4583.73, 4658.24,
        4663.71, 4679.42, 4643.2, 4557.76, 4603.56, 4654.14, 4719.75, 4570.06,
        4617.91, 4671.22, 4679.42, 4712.92, 4832.54, 4838.69, 4881.75, 5010.25,
        4713.61, 4789.47, 4753.25, 4799.73, 4781.96, 4848.94, 4890.63, 4760.08,
        4704.04, 4724.54, 4699.25, 4816.13, 4786.74, 4946.0, 5088.86, 5091.6,
        5393.02, 5799.73, 6274.78, 6215.31, 6078.61, 5557.76, 5806.57, 6063.57,
        5985.65, 6168.15, 5873.54, 7142.18, 6666.45, 6598.09, 6462.75, 6112.78,
        5799.04, 5875.6, 5814.08, 5365.01, 5793.58, 5868.08, 5632.26, 5905.67,
        6132.61, 6195.49, 6524.27, 6463.44, 6453.18, 7347.23, 6952.16, 7171.57,
        6794.26, 7382.78, 6726.59, 6607.66, 6710.19, 6881.07, 6882.43, 7120.98,
        6707.45, 6630.22, 6719.75, 5859.2, 5294.6, 5460.7, 5368.42, 5468.21,
        5518.11, 5543.4, 5552.97, 5792.21, 5769.65, 5879.7, 5801.78, 5695.83,
        5739.58, 5978.82, 5704.04, 5779.91, 5690.36, 5444.98, 5447.71, 5485.99,
        5594.67, 5587.83, 5560.49, 5539.3, 5539.99, 5560.49, 5669.86, 5460.7,
        5576.22, 5564.59, 5512.64, 5397.81, 5616.55, 5625.43, 5745.72, 5723.85,
        5630.89, 6025.98, 6038.96, 5866.03, 5823.65, 5797.0, 5794.94, 5751.2,
        5835.96, 5712.24, 5732.05, 5770.34, 5691.73, 5710.18, 5680.79, 5679.42,
        5704.72, 5810.67, 5775.12, 5490.77, 5467.54, 5501.71, 5439.51, 5293.92,
        5371.15, 5408.75, 5291.86, 5416.95, 5395.77, 5252.22, 4968.56, 5045.11,
        5108.0, 5064.94, 5099.11, 5058.1, 4727.95, 4879.02, 4862.61, 5004.79,
        4990.43, 5145.6, 5163.37, 5086.81, 5196.86, 4943.26, 4576.9, 4218.73,
        4475.74, 4250.86, 4432.67, 4468.21, 4568.02, 4624.75, 4733.42, 4781.96,
        4784.69, 4927.55, 4971.29, 4896.1, 4883.12, 5014.35, 4992.48, 5045.8,
        4873.55, 4939.85, 4861.93, 4708.14, 4878.33, 4894.06, 4853.04, 5049.21,
        4901.57, 4842.1, 4775.8, 4436.77, 4397.81, 4323.31, 3881.75, 4002.05,
        3855.09, 4215.99, 4127.82, 4181.13, 4071.08, 4172.25, 4319.21, 4399.87,
        4427.21, 4536.57, 4628.16, 4533.16, 4527.69, 4421.74, 4411.48, 4364.32,
        4520.16, 4531.79, 4458.64, 4343.14, 4131.24, 4239.23, 4234.45, 4447.03,
        4449.08, 4309.64, 4471.63, 4444.98, 4475.06, 4518.12, 4269.31, 4228.3,
        4142.86, 4200.27, 4209.84, 4257.01, 4293.23, 4434.04, 4509.91, 4355.44,
        4256.32, 4345.87, 4385.51, 4483.26, 4455.91, 4562.55, 4665.75, 4582.36,
        4574.16, 4576.9, 4676.01, 4845.53, 4770.34, 4762.81, 4582.36, 4330.15,
        4298.71, 4336.29, 4439.51, 4527.69, 4600.13, 4428.57, 4494.19, 4403.97,
        4381.41, 4480.52, 4531.79, 4436.77, 4598.77, 4797.0, 4791.53, 5011.62,
        5036.91, 5129.87, 5071.08, 4853.04, 4789.47, 4797.67, 4845.53, 4794.94,
        4676.01, 4852.36, 4848.26, 5067.67, 4915.93, 4738.9, 4802.46, 4734.79,
        4578.26, 4488.73, 4529.05, 4883.8, 4887.22, 4948.73, 4998.63, 5203.69,
        5412.17, 5352.02, 5137.39, 5715.65, 5747.78, 6508.54, 5946.0, 6074.51,
        5881.76, 5752.57, 6088.18, 6213.26, 6771.02, 6649.35, 6370.48, 6270.67,
        6673.28, 6565.96, 7045.12, 6963.77, 8196.85, 9336.3, 9011.62, 9113.46,
        9228.98, 9328.1, 9084.08, 9208.48, 7464.8, 7529.74, 8066.99, 8129.19,
        8416.26, 8047.85, 8083.39, 8157.9, 8466.85, 7497.61, 6943.96, 6957.62,
        6905.67, 6826.38, 7335.62, 7405.33, 7453.86, 7006.15, 6829.12, 6916.61,
        6669.18, 6797.68, 7098.42
      ],
      SHIB: [
        10000.0, 10194.79, 10188.79, 9892.11, 9790.23, 9127.96, 9265.81,
        8684.45, 8345.82, 8537.61, 7992.21, 8279.89, 9640.4, 8942.16, 9313.76,
        9235.84, 9148.94, 8606.53, 8483.67, 8261.92, 8091.1, 6703.63, 5996.4,
        6508.84, 6290.09, 6308.06, 6302.07, 6382.98, 6314.05, 6490.86, 6308.06,
        6406.95, 6514.83, 6194.18, 6170.22, 6574.77, 6820.5, 8136.05, 9799.22,
        9223.85, 9877.13, 9415.64, 8468.69, 8762.36, 9103.98, 8882.23, 9274.8,
        9355.71, 8522.63, 8390.78, 8190.0, 7737.49, 7410.85, 7347.92, 7644.59,
        6973.33, 7434.82, 7359.91, 7051.24, 7596.65, 8121.06, 7815.41, 7497.75,
        7045.25, 7407.86, 7147.14, 6877.44, 6811.51, 7114.17, 6802.51, 6667.66,
        6676.66, 6409.94, 6568.78, 6508.84, 6679.66, 6673.65, 6670.66, 7186.09,
        6919.39, 6886.43, 7087.21, 7311.96, 7389.87, 7189.09, 7284.99, 7572.67,
        8225.95, 8031.16, 8213.97, 7824.39, 7845.37, 7833.38, 8058.14, 7953.25,
        8055.13, 7350.92, 7431.83, 7120.17, 7305.96, 7344.92, 6664.67, 7800.42,
        7995.21, 7701.53, 7749.48, 7692.54, 7668.57, 7539.71, 7569.67, 7455.8,
        7252.02, 7273.0, 7290.98, 7144.15, 7299.97, 6934.37, 6931.37, 6877.44,
        6628.71, 6320.05, 6373.99, 6176.21, 6077.31, 6436.92, 6008.39, 6002.39,
        5678.75, 5549.89, 4486.06, 4788.73, 3635.0, 3179.5, 3901.7, 3733.9,
        3862.75, 3617.02, 3757.87, 3515.13, 3512.13, 3482.17, 3515.13, 3563.08,
        3527.12, 3521.13, 3497.15, 3218.46, 3146.54, 3212.47, 3440.21, 3673.96,
        3542.11, 3266.41, 3362.3, 3245.43, 3248.42, 3251.42, 3290.38, 3224.45,
        3194.49, 3164.52, 3062.64, 2846.87, 2625.11, 2382.38, 2403.36, 2592.14,
        2379.38, 2445.31, 2319.45, 2454.3, 2421.34, 3185.49, 2951.75, 3044.65,
        3287.38, 3497.15, 3392.27, 3320.34, 2999.7, 3098.59, 3011.69, 2993.71,
        3029.67, 3002.7, 3119.56, 3146.54, 3137.55, 3410.25, 3371.29, 3476.18,
        3365.3, 3092.6, 3017.68, 3095.59, 3161.52, 3275.4, 3302.37, 3260.42,
        3497.15, 3685.95, 3590.06, 3593.05, 3530.12, 3476.18, 3497.15, 3296.37,
        3218.46, 3422.24, 3575.06, 3557.09, 3581.06, 3533.12, 3581.06, 3563.08,
        3548.1, 3530.12, 3623.01, 3608.03, 3608.03, 3673.96, 3614.03, 3694.93,
        3685.95, 3784.84, 3778.84, 5028.46, 4665.87, 4749.77, 4432.12, 4201.38,
        3958.64, 3853.77, 4072.52, 3892.72, 3967.63, 3922.69, 4135.45, 3802.82,
        3629.01, 3635.0, 3730.9, 3679.95, 3646.98, 3688.94, 3643.99, 3685.95,
        3808.81, 3742.88, 3587.06, 3673.96, 3661.98, 3868.75, 3982.62, 3874.74,
        3841.77, 3638.0, 3655.98, 3509.14, 3467.19, 3566.08, 3206.48, 3302.37,
        3176.5, 3080.61, 3251.42, 3329.34, 3386.27, 3296.37, 3326.35, 3326.35,
        3335.33, 3317.35, 3404.26, 3344.32, 3302.37, 3323.35, 3470.19, 3425.23,
        3344.32, 3323.35, 3287.38, 3296.37, 3104.58, 3074.62, 3047.64, 3146.54,
        3035.66, 3062.64, 3092.6, 3104.58, 3041.65, 2960.74, 2981.72, 3008.69,
        2999.7, 3038.66, 2981.72, 3047.64, 3257.42, 3200.48, 3554.09, 3814.8,
        3563.08, 3733.9, 3853.77, 3509.14, 3527.12, 3742.88, 3781.85, 3670.96,
        3503.14, 3002.7, 2604.14, 3074.62, 2945.76, 2891.82, 2715.01, 2691.04,
        2771.95, 2741.99, 2727.0, 2718.01, 2741.99, 2631.11, 2526.22, 2634.1,
        2688.04, 2712.01, 2756.97, 2688.04, 2849.86, 2724.0, 2741.99, 2801.92,
        2756.97, 2792.92, 2756.97, 2795.93, 2819.9, 2765.96, 2724.0, 2777.94,
        2756.97, 2747.98, 2730.0, 2679.06, 2744.98, 2694.04, 2643.09, 2457.29,
        2472.28, 2574.17, 2442.31, 2520.22, 2463.29, 2490.26, 2481.27, 2490.26,
        2478.28, 2478.28, 2451.3, 2361.4, 2457.29, 2406.36, 2424.33, 2430.33,
        2454.3, 2433.32, 2526.22, 2547.2, 2529.21, 2520.22, 2568.17, 2610.13,
        2762.96, 2706.02, 2843.87, 2996.71, 3107.58, 3242.43, 3158.53, 3161.52,
        3386.27, 3431.22, 3626.01, 3599.04, 3575.06, 3608.03, 3368.29, 3455.2,
        3470.19, 3500.15, 3524.13, 3611.03, 3428.23, 3530.12, 3563.08, 3700.93,
        3781.85, 4510.04, 4333.23, 4303.26, 4309.27, 4078.51, 3566.08, 3877.74,
        3853.77, 3844.77, 3691.94, 3793.83, 4087.5, 3811.81, 3955.65, 3946.66,
        4042.55, 4012.59, 3904.7, 3877.74, 3934.67, 3733.9, 3709.91, 3826.79,
        3742.88, 3620.02, 3652.99, 3620.02, 3401.26, 3314.35, 3335.33, 3347.32,
        3263.41, 3263.41, 3008.69, 3071.62, 3050.64, 3176.5, 3329.34, 3356.31,
        3101.59, 3131.56, 3311.35, 3233.44, 3293.38, 3149.54, 3272.4, 3155.53,
        3236.44, 3182.5, 3140.55, 3182.5, 3113.57, 3122.56, 3203.48, 3158.53,
        3263.41, 3359.3, 3242.43, 3365.3, 3374.29, 3359.3, 3275.4, 3278.4,
        3245.43, 3293.38, 3326.35, 3317.35, 3278.4, 3326.35, 3380.28, 3416.24,
        3476.18, 3425.23, 3467.19, 3275.4, 3167.51, 3068.63, 3128.56, 3089.6,
        3077.62, 3110.58, 3032.66, 3077.62, 3071.62, 3077.62, 3029.67, 2984.71,
        3002.7, 3047.64, 2963.74, 2990.71, 2834.88, 2819.9, 2652.08, 2691.04,
        2706.02, 2601.14, 2640.09, 2622.12, 2631.11, 2649.08, 2622.12, 2634.1,
        2622.12, 2604.14, 2613.13, 2559.19, 2628.11, 2667.07, 2550.2, 2547.2,
        2562.18, 2583.16, 2652.08, 2610.13, 2607.14, 2544.2, 2541.21, 2577.16,
        2577.16, 2592.14, 2388.37, 2463.29, 2352.41, 2376.38, 2361.4, 2088.71,
        2001.79, 1998.8, 2025.77, 1962.84, 2022.78, 2040.75, 2088.71, 2148.64,
        2169.61, 2172.61, 2268.5, 2349.42, 2379.38, 2343.43, 2361.4, 2259.51,
        2265.5, 2175.61, 2196.58, 2277.5, 2289.48, 2298.47, 2322.44, 2313.45,
        2232.54, 2169.61, 2190.59, 2298.47, 2316.45, 2253.52, 2253.52, 2244.53,
        2379.38, 2373.39, 2430.33, 2373.39, 2340.43, 2310.46, 2319.45, 2313.45,
        2349.42, 2343.43, 2349.42, 2295.48, 2370.39, 2352.41, 2331.43, 2460.29,
        2565.18, 2475.28, 2535.22, 2496.25, 2454.3, 2481.27, 2598.15, 3026.67,
        2804.92, 2691.04, 2822.9, 2891.82, 2969.73, 3143.54, 3233.44, 3062.64,
        3065.63, 2981.72, 2813.91, 2430.33, 2577.16, 2514.23, 2505.24, 2391.37,
        2370.39, 2499.25, 2442.31, 2436.32, 2430.33, 2412.35, 2463.29, 2514.23,
        2439.32, 2400.37, 2352.41, 2349.42, 2295.48, 2241.53, 2292.48, 2295.48,
        2301.47, 2268.5, 2265.5, 2211.57, 2109.68, 2151.64, 2172.61, 2214.57,
        2235.54, 2211.57, 2145.64, 2178.6, 2220.56, 2226.55, 2181.6, 2190.59,
        2238.54, 2202.57, 2178.6, 2163.62, 2163.62, 2184.59, 2190.59, 2202.57,
        2259.51, 2187.59, 2181.6, 2172.61, 2148.64, 2166.62, 2169.61, 2154.63,
        2067.72, 2070.72, 2028.77, 2040.75, 2073.72, 2097.69, 2091.7, 2133.65,
        2070.72, 2040.75, 2040.75, 2082.71, 2121.66, 2127.66, 2235.54, 2229.55,
        2355.41, 2370.39, 2298.47, 2403.36, 2412.35, 2388.37, 2331.43, 2367.4,
        2352.41, 2343.43, 2382.38, 2433.32, 2538.21, 2451.3, 2493.26, 2460.29,
        2553.19, 2703.03, 2691.04, 2568.17, 2502.25, 2637.1, 2553.19, 2604.14,
        2556.19, 2565.18, 2538.21, 2316.45, 2427.33, 2433.32, 2451.3, 2532.22,
        2487.27, 2442.31, 2478.28, 2463.29, 2481.27, 2508.24, 2550.2, 2733.0,
        2712.01, 2807.91, 2924.78, 2924.78, 3065.63, 3023.67, 3101.59, 2846.87,
        2852.86, 2933.78, 3023.67, 2891.82, 3299.37, 3107.58, 3125.57, 3026.67,
        3047.64, 3125.57, 3254.42, 3293.38, 3182.5, 3305.36, 3161.52, 3239.43,
        3179.5, 3104.58, 3125.57, 3086.61, 3188.49, 3167.51, 2861.86, 2999.7,
        2939.77, 2870.85, 2706.02, 2855.85, 2885.83, 2981.72, 3032.66, 2870.85,
        2981.72, 2915.79, 2864.85, 2903.8, 2852.86, 2762.96, 2783.93, 2894.82,
        2804.92, 2679.06, 2622.12, 2652.08, 2652.08, 2706.02, 2744.98, 2688.04,
        2756.97, 2730.0, 2685.05, 2697.03, 2718.01, 2735.99, 2673.06, 2658.07,
        2664.07, 2709.02, 2753.97, 2819.9, 2828.89, 2816.9, 2888.82, 2834.88,
        2924.78, 2963.74, 2942.77, 2891.82, 2927.78, 2966.74, 2921.78, 2846.87,
        2873.84, 2840.87, 2885.83, 2900.81, 3047.64, 3278.4, 3500.15, 3694.93,
        4965.54, 6778.54, 6664.67, 10524.42, 9970.04, 10023.97, 9232.84,
        10509.44, 10635.3, 9604.44, 9958.04, 9565.47, 9583.46, 9679.35, 8900.21,
        7443.81, 8831.28, 8079.12, 7482.77, 8285.89, 8112.07, 7848.36, 8234.94,
        8411.75, 8720.41, 9253.82, 9106.98, 9538.51, 9244.84, 8894.21, 9148.94,
        8576.56, 8004.2, 7926.29, 8163.02, 8019.18, 8243.93, 8405.76, 8675.46,
        8309.86, 8384.78, 8264.91, 7338.93, 6281.1, 6811.51, 6589.75, 6832.49,
        6673.65, 6844.47, 6856.46, 7851.36, 7914.29, 8115.07, 7989.21, 7671.57,
        7749.48, 7533.72, 7377.88, 7273.0, 7231.05, 6742.59, 6721.6, 6928.38,
        7299.97, 7500.75, 7362.9, 7201.08, 6934.37, 6739.58, 7090.2, 6697.63,
        6751.58, 6685.65, 6958.35, 6901.41, 7509.74, 7323.94, 7518.73, 7428.83,
        7162.12, 7593.64, 7749.48, 7596.65, 7344.92, 7320.94, 7422.84, 7317.95,
        7665.57, 7989.21, 8339.83, 7755.47, 7641.59, 7605.64, 7389.87, 7326.94,
        7365.9, 7692.54, 7590.65, 7117.17, 6964.34, 7042.25, 6862.45, 6565.78,
        6649.68, 6362.0, 6089.31, 6221.16, 6284.09, 5849.57, 5454.0, 5430.03,
        5439.02, 5352.11, 5409.05, 5280.19, 5055.44, 5310.16, 5157.32, 5268.2,
        5076.41, 5064.43, 5196.28, 5133.35, 5172.31, 4902.61, 4507.05, 4408.15,
        5181.3, 4641.9, 4857.66, 4881.63, 4902.61, 4863.65, 4929.58, 5169.32,
        5241.24, 5663.77, 5762.67, 5591.85, 5211.27, 5325.14, 5421.03, 5457.0,
        5277.19, 5112.38, 5028.46, 4908.6, 5091.4, 5157.32, 5013.48, 5001.5,
        4914.6, 4791.73, 4737.79, 4396.17, 4255.32, 4051.55, 4045.56, 4069.53,
        3874.74, 4291.28, 4201.38, 4237.33, 4033.56, 4117.48, 4165.41, 4084.51,
        3973.62, 3964.64, 4003.6, 4027.57, 3976.62, 4114.48, 4237.33, 4216.36,
        4587.96, 4650.89, 4575.97, 4393.17, 4117.48, 4159.42, 4198.38, 4195.39,
        4138.45, 3922.69, 4099.49, 3958.64, 4042.55, 3961.64, 3817.8, 3826.79,
        3904.7, 4054.54, 4063.53, 3973.62, 4036.56, 4201.38, 4120.47, 4060.54,
        3934.67, 3988.62, 4039.55, 4237.33, 4315.26, 4441.12, 4387.18, 4339.23,
        4465.09, 4599.94, 5609.83, 6080.31, 5873.54, 5807.61, 5334.13, 4887.62,
        4899.62, 4833.69, 5211.27, 5208.27, 5409.05, 5313.16, 5145.34, 5073.42,
        4995.51, 5280.19, 5382.08, 5244.23, 5681.75, 5394.07, 5597.84, 5457.0,
        5609.83, 5687.74, 5726.7, 5546.9, 5468.98, 5262.21, 5400.06, 5145.34,
        5103.39, 5163.33, 5424.03, 5684.74, 5567.88, 5373.09, 5238.24, 5223.25,
        5052.44, 5043.46, 5355.11, 5762.67, 5663.77, 5729.7, 6011.38, 7749.48,
        8213.97, 8183.99, 7623.62, 7228.05, 7620.62, 7264.01, 7326.94, 7422.84,
        7368.89, 7207.08, 7398.87, 7545.7, 7818.4, 7695.54, 7515.73, 7252.02,
        7674.56, 7653.58, 7842.37, 8022.18, 9415.64, 8819.3, 8609.53, 9208.87,
        9205.87, 9313.76, 9559.48, 9634.41, 8139.05, 8181.0, 8675.46, 8444.71,
        8450.71, 8198.99, 8169.01, 8228.95, 7887.33, 7276.0, 6601.73, 6727.6,
        6517.83, 6463.89, 6820.5, 6970.33, 6820.5, 6457.89, 6475.87, 6673.65,
        6412.95, 6335.03, 6430.93
      ],
      POL: [
        10000.0, 10110.94, 10031.7, 9607.77, 9457.21, 8613.31, 8989.7, 8177.5,
        7987.32, 8383.52, 8129.96, 9278.92, 9354.21, 9005.55, 9274.96, 9350.24,
        9195.72, 8783.68, 8545.96, 8169.58, 7991.29, 6683.84, 5990.49, 6125.2,
        5907.29, 6152.93, 6164.82, 6212.37, 6596.68, 6727.41, 6319.34, 6505.55,
        6513.47, 6129.16, 6077.66, 6616.48, 6790.81, 6707.61, 7971.48, 7662.44,
        8058.63, 7626.78, 6695.73, 6763.07, 6664.03, 6584.78, 7218.7, 7313.79,
        6667.99, 6303.49, 6398.58, 6105.39, 5693.35, 5685.43, 5867.67, 5570.52,
        6168.78, 6030.11, 5800.32, 6271.79, 6378.76, 6525.36, 6271.79, 5855.78,
        5990.49, 5824.09, 5625.99, 5693.35, 5907.29, 5740.89, 5578.45, 5566.56,
        5408.09, 5400.16, 5495.25, 5717.12, 5768.62, 5891.44, 6022.19, 5867.67,
        5816.16, 5982.57, 6133.12, 6410.46, 6240.09, 6362.92, 6652.14, 6683.84,
        6572.9, 6707.61, 6461.97, 6723.45, 6656.1, 6703.65, 6545.17, 6366.88,
        5824.09, 5982.57, 5713.15, 5764.66, 5709.19, 5305.08, 5475.44, 5625.99,
        5427.89, 5519.02, 5499.21, 5396.19, 5629.95, 5637.88, 5649.77, 5455.63,
        5522.98, 5455.63, 5328.84, 5348.65, 4964.34, 4948.5, 4889.07, 4524.56,
        4259.12, 4322.51, 4271.0, 4263.08, 4615.69, 4156.1, 4148.18, 3981.78,
        3890.65, 3359.75, 3490.49, 2634.7, 2341.52, 2725.83, 2690.18, 2892.24,
        2717.91, 2872.42, 2559.43, 2563.39, 2519.81, 2575.28, 2674.32, 2575.28,
        2626.78, 2587.16, 2400.95, 2305.86, 2309.83, 2369.26, 2603.01, 2638.66,
        2373.22, 2444.53, 2341.52, 2353.4, 2381.14, 2488.11, 2440.57, 2420.76,
        2503.97, 2389.06, 2179.08, 2024.57, 1664.03, 1600.64, 1687.79, 1509.51,
        1576.86, 1378.76, 1509.51, 1572.9, 1691.76, 1834.39, 2202.85, 2396.99,
        2357.37, 2278.13, 2131.54, 2011.89, 1986.92, 1805.08, 1833.99, 1940.97,
        1824.09, 1931.46, 2059.03, 2060.61, 2209.98, 2408.88, 2333.6, 2281.3,
        2228.6, 2169.18, 2378.37, 2737.72, 2837.56, 2878.37, 3007.92, 3698.89,
        3684.62, 3304.68, 3541.6, 3421.15, 3429.09, 3516.65, 3202.46, 3036.84,
        3502.78, 3754.36, 3772.59, 3710.78, 3666.0, 3547.94, 3529.32, 3515.06,
        3521.4, 3663.63, 3632.33, 3615.3, 3641.45, 3525.36, 3689.39, 3643.42,
        3673.94, 4025.36, 3965.92, 3758.32, 3702.85, 3490.49, 3529.71, 3088.35,
        3093.11, 3244.45, 3164.42, 3275.76, 3232.17, 3233.75, 3101.43, 3175.91,
        3195.72, 3215.92, 3257.93, 3329.63, 3485.34, 3452.46, 3513.47, 3522.98,
        3485.34, 3283.28, 3332.01, 3332.01, 3528.53, 3594.3, 3519.41, 3665.61,
        3368.07, 3410.86, 3292.0, 3193.34, 3268.23, 3006.73, 3045.17, 2934.62,
        2770.61, 2982.17, 3080.43, 2995.25, 2928.29, 2972.27, 2943.35, 2964.35,
        2997.63, 3062.99, 3042.4, 3066.56, 3160.46, 3297.15, 3396.2, 3307.45,
        3293.59, 3211.96, 3243.66, 3269.42, 3142.23, 3117.67, 3087.95, 3109.35,
        3137.09, 3185.03, 3343.9, 3434.63, 3396.6, 3211.17, 3290.02, 3303.88,
        3528.53, 3562.99, 3632.33, 3729.01, 3587.56, 3730.98, 3688.6, 3600.23,
        3580.03, 3481.78, 3380.74, 3781.7, 4676.31, 4739.31, 4672.75, 4898.98,
        4087.56, 3174.72, 4396.59, 4141.83, 3775.75, 3505.55, 3579.64, 3706.41,
        3556.26, 3497.23, 3473.46, 3477.02, 3212.36, 3137.09, 3360.15, 3376.39,
        3371.63, 3337.96, 3339.94, 3411.65, 3256.34, 3343.11, 3685.82, 3610.54,
        3702.06, 3585.18, 3641.05, 3599.84, 3595.88, 3528.13, 3674.73, 3605.0,
        3602.22, 3535.27, 3605.39, 3635.5, 3585.18, 3467.91, 3173.54, 3220.69,
        3226.23, 3048.33, 3169.97, 3127.18, 3133.52, 3160.06, 3154.52, 3139.46,
        3210.78, 3192.95, 3090.33, 3062.6, 3003.96, 2997.23, 3002.77, 3094.69,
        3077.66, 3178.68, 3129.17, 3162.84, 3193.34, 3248.41, 3345.49, 3383.52,
        3455.23, 3622.42, 3679.08, 3933.45, 3911.65, 4041.21, 3986.53, 3778.13,
        3761.09, 4014.27, 3973.06, 3916.01, 3944.93, 3803.88, 3888.27, 4387.09,
        4562.6, 4507.93, 4641.05, 4268.23, 4394.21, 4713.55, 4740.89, 4910.46,
        4992.87, 4783.68, 4774.17, 4976.63, 5168.38, 4833.6, 4906.9, 4953.64,
        4924.32, 4641.44, 4956.42, 5227.02, 5477.42, 6037.64, 5902.54, 5905.31,
        5797.94, 5477.82, 5449.29, 5332.01, 4984.55, 4897.78, 5041.2, 4887.48,
        4763.87, 4919.97, 4858.96, 4626.39, 4439.78, 4519.81, 4489.7, 4490.89,
        4164.82, 3985.74, 4204.84, 4136.69, 4428.29, 4727.82, 4694.93, 4396.2,
        4529.32, 4808.64, 4676.71, 4650.56, 4402.14, 4540.41, 4379.16, 4461.57,
        4328.05, 4250.79, 4388.28, 4126.78, 4302.69, 4433.04, 4300.32, 4429.48,
        4416.41, 4349.44, 4345.88, 4525.75, 4517.83, 4401.34, 4388.28, 4368.06,
        4383.92, 4434.23, 4412.44, 4356.18, 4486.53, 4617.68, 4654.13, 4698.5,
        4585.19, 4628.37, 4341.13, 4154.12, 3985.34, 4063.79, 3966.32, 3920.76,
        3962.36, 3890.26, 3996.83, 3956.03, 3977.42, 3909.67, 3795.96, 3877.97,
        4007.93, 3880.35, 3959.59, 3868.07, 3850.24, 3565.77, 3459.19, 3473.46,
        3318.14, 3420.36, 3369.25, 3401.74, 3424.72, 3346.67, 3506.34, 3474.64,
        3447.71, 3465.54, 3400.16, 3452.06, 3529.32, 3460.38, 3521.4, 3607.76,
        3646.59, 3757.93, 3593.51, 3580.43, 3532.89, 3524.57, 3568.15, 3563.78,
        3569.34, 3296.75, 3251.58, 3021.39, 3101.43, 2888.28, 2431.86, 2501.98,
        2549.52, 2532.89, 2441.76, 2343.9, 2343.9, 2443.34, 2363.71, 2425.12,
        2489.7, 2662.44, 2598.25, 2667.98, 2576.87, 2649.36, 2559.04, 2636.29,
        2442.95, 2469.1, 2629.56, 2662.44, 2708.0, 2827.26, 2771.0, 2644.22,
        2654.52, 2679.48, 2689.77, 2739.7, 2902.54, 2937.0, 2873.62, 3341.92,
        3169.58, 3160.85, 3078.45, 3083.99, 2926.31, 2946.91, 3057.84, 3051.9,
        3002.38, 2992.47, 2881.93, 2801.9, 2857.37, 2836.77, 2812.6, 2845.48,
        2750.4, 2722.66, 2725.83, 2702.46, 2648.18, 2629.56, 2644.22, 2642.64,
        2636.29, 2711.57, 2733.36, 2713.16, 2697.31, 2702.85, 2682.65, 2692.95,
        2501.59, 2462.36, 2298.74, 2297.15, 2276.95, 2299.92, 2209.19, 2159.67,
        2199.28, 2156.11, 2163.23, 2173.14, 2203.65, 2224.25, 2365.3, 2273.78,
        2177.49, 2138.28, 2142.24, 2139.46, 2192.95, 2213.15, 2212.76, 2205.63,
        2147.78, 2141.04, 2074.09, 1986.13, 2015.06, 2036.45, 2079.64, 2106.97,
        2091.92, 2048.73, 2092.31, 2152.94, 2148.97, 2065.38, 2063.0, 2057.05,
        2053.48, 2053.48, 2015.85, 1996.04, 2050.72, 2081.62, 2115.69, 2261.49,
        2171.56, 2257.92, 2233.76, 2174.73, 2241.29, 2238.51, 2215.93, 2096.67,
        2054.28, 2035.26, 2013.48, 2043.19, 2047.94, 2039.23, 2119.25, 2056.26,
        2032.49, 2044.77, 2130.34, 2276.54, 2373.61, 2551.11, 2494.06, 2501.98,
        2507.53, 2404.92, 2465.14, 2538.43, 2560.22, 2511.89, 2624.01, 2611.73,
        2650.95, 2685.81, 2730.59, 2904.52, 2928.69, 3120.05, 3306.66, 3369.25,
        3195.33, 3592.71, 3536.06, 3644.21, 3677.9, 3382.33, 3310.22, 3283.68,
        3328.05, 3224.65, 2881.14, 3074.88, 3081.22, 3005.55, 3070.53, 3031.3,
        2928.29, 2968.31, 3003.17, 3014.66, 3143.82, 3219.49, 3249.2, 3174.33,
        3189.38, 3258.72, 3304.27, 3568.94, 3549.13, 3662.84, 3329.63, 3438.59,
        3507.13, 3494.45, 3343.11, 3365.29, 3272.59, 3169.97, 3026.95, 3057.45,
        3213.55, 3435.02, 3376.39, 3363.71, 3635.89, 4036.06, 4114.11, 3965.13,
        3808.24, 3774.56, 3830.03, 4007.14, 3854.6, 3389.46, 3493.26, 3348.25,
        3265.85, 3171.55, 3329.24, 3192.16, 3560.62, 3650.16, 3393.43, 3513.47,
        3368.86, 3361.73, 3366.08, 3203.65, 3127.97, 2989.7, 3104.2, 3114.9,
        2969.89, 2848.26, 2898.58, 2905.7, 3018.62, 3108.95, 3112.13, 3219.89,
        3190.17, 3122.43, 3200.08, 3171.55, 3113.71, 3070.53, 3082.02, 3200.87,
        3304.68, 3338.75, 3393.43, 3376.78, 3298.34, 3503.17, 3368.86, 3533.28,
        3491.29, 3752.77, 3683.44, 3953.65, 3969.89, 4079.63, 3730.59, 3989.7,
        3912.05, 3938.59, 3904.92, 4212.36, 4082.41, 3992.87, 3930.27, 4047.55,
        4299.12, 4330.03, 4540.01, 4024.97, 4354.21, 4607.37, 4448.89, 4519.81,
        4764.27, 4912.44, 4618.86, 4998.42, 4816.56, 4498.81, 4152.53, 4312.99,
        4067.35, 3692.95, 4014.66, 3942.95, 3801.11, 3923.93, 4001.98, 4164.43,
        4153.33, 4022.19, 3986.53, 3920.76, 3884.71, 3963.55, 3765.85, 3586.77,
        3548.33, 3568.55, 3499.61, 3577.26, 3611.33, 3754.36, 3584.79, 3519.02,
        3480.2, 3017.43, 2567.35, 2819.73, 2830.43, 2788.03, 2671.55, 2687.01,
        2681.06, 2910.07, 2833.6, 2959.19, 2880.35, 2788.43, 2847.47, 2783.68,
        2896.59, 2892.63, 2806.65, 2641.05, 2727.82, 2866.88, 2907.69, 2885.5,
        2898.97, 2830.03, 2768.62, 2701.27, 2761.49, 2654.12, 2692.95, 2661.65,
        2626.78, 2573.7, 2714.34, 2747.62, 2834.0, 2805.86, 2719.89, 2966.72,
        2898.17, 2857.37, 2957.22, 2863.31, 2886.29, 2851.83, 2947.31, 2903.33,
        2848.26, 2763.87, 2761.09, 2779.72, 2728.61, 2799.52, 2831.61, 2862.13,
        2881.14, 2646.6, 2568.93, 2594.29, 2574.09, 2451.27, 2555.08, 2398.18,
        2336.37, 2435.82, 2465.53, 2331.22, 2197.7, 2286.84, 2282.09, 2263.47,
        2266.25, 2230.59, 2208.01, 2254.76, 2193.35, 2251.59, 2196.52, 2171.95,
        2228.6, 2218.31, 2256.74, 2108.16, 2009.91, 1849.45, 2006.74, 1898.57,
        1983.36, 1979.79, 2024.96, 1977.42, 2007.53, 2112.92, 2111.72, 2164.03,
        2142.24, 2147.78, 2049.13, 2110.14, 2139.86, 2163.23, 2106.58, 2118.07,
        2051.51, 1961.96, 2037.64, 2063.39, 2006.34, 2022.98, 2008.71, 1970.68,
        1950.08, 1850.63, 1792.0, 1688.99, 1538.83, 1628.76, 1563.79, 1708.0,
        1668.78, 1685.03, 1604.99, 1663.24, 1675.12, 1671.55, 1588.35, 1604.6,
        1619.26, 1650.95, 1700.08, 1835.58, 2072.51, 2117.67, 2125.2, 2240.89,
        2100.24, 1994.45, 1849.84, 1725.04, 1673.13, 1669.97, 1660.46, 1577.26,
        1631.93, 1588.74, 1513.47, 1473.85, 1436.61, 1458.8, 1486.93, 1517.04,
        1503.17, 1503.17, 1503.17, 1656.5, 1605.39, 1568.54, 1486.53, 1512.28,
        1525.36, 1572.5, 1587.16, 1615.3, 1591.92, 1606.57, 1622.03, 1637.48,
        1687.4, 1722.27, 1676.3, 1671.16, 1581.22, 1485.73, 1489.7, 1493.26,
        1522.19, 1505.16, 1513.08, 1487.72, 1487.72, 1454.84, 1454.44, 1480.98,
        1476.63, 1446.91, 1496.04, 1460.77, 1476.63, 1450.07, 1456.81, 1473.85,
        1519.81, 1479.4, 1454.84, 1405.31, 1412.04, 1317.35, 1303.09, 1307.05,
        1294.77, 1327.65, 1316.96, 1270.2, 1255.94, 1240.09, 1191.76, 1154.52,
        1204.43, 1332.41, 1364.5, 1555.07, 1515.84, 1612.92, 1666.8, 1618.47,
        1481.38, 1437.4, 1468.71, 1678.29, 1568.15, 1783.29, 1697.31, 1721.48,
        1779.72, 1917.98, 2254.35, 2278.92, 2208.01, 2079.64, 2297.15, 2243.26,
        2315.37, 2360.14, 2379.96, 2560.22, 2835.97, 2818.55, 2715.93, 2855.39,
        2765.06, 2788.43, 2273.38, 2299.53, 2510.3, 2467.91, 2425.91, 2370.84,
        2382.33, 2374.8, 2237.32, 2072.11, 1913.23, 1982.57, 1867.67, 1880.74,
        1998.02, 2077.26, 2030.51, 1894.22, 1893.43, 1949.29, 1837.16, 1823.7,
        1804.28
      ],
      UNI: [
        10000.0, 10158.83, 10735.3, 10694.11, 10858.82, 10158.83, 10064.71,
        9270.59, 8894.11, 9323.53, 8870.58, 9358.82, 9517.65, 9123.53, 9317.65,
        9735.29, 10500.0, 9711.76, 9511.76, 9058.83, 8741.17, 7441.17, 6476.47,
        6558.83, 6229.42, 6182.36, 6170.59, 5964.71, 6182.36, 6564.7, 6305.88,
        6882.35, 6558.83, 6200.0, 6058.82, 6435.29, 6676.47, 6617.65, 7270.59,
        6964.71, 7252.94, 6858.82, 6147.06, 6264.71, 6129.41, 6252.95, 6647.06,
        6670.58, 6170.59, 5929.41, 5870.59, 5588.24, 5200.0, 5152.95, 5317.65,
        5070.59, 5523.53, 5347.06, 5758.82, 6100.0, 6052.95, 5941.18, 5629.41,
        5188.24, 5305.89, 5123.53, 4905.88, 5152.95, 5358.82, 5129.42, 5058.83,
        5017.65, 4811.76, 4941.17, 5100.0, 5311.76, 5400.0, 5629.41, 5652.94,
        5488.23, 5464.7, 5770.58, 5811.77, 6300.0, 6223.53, 6188.23, 6652.94,
        6570.59, 6541.17, 6888.24, 6682.35, 6852.94, 6947.06, 6982.35, 6764.7,
        6600.0, 5947.05, 6135.3, 5735.29, 5870.59, 5852.94, 5317.65, 5429.41,
        5682.35, 5570.59, 5729.41, 5670.59, 5564.71, 5517.65, 5576.47, 5529.41,
        5194.12, 5217.65, 5305.89, 5152.95, 5123.53, 4770.59, 4800.0, 4782.35,
        4476.47, 4317.65, 4123.53, 4082.35, 4017.65, 4647.06, 4229.42, 4405.89,
        4158.83, 4241.18, 3652.94, 3523.53, 2870.58, 2694.11, 3023.53, 3029.41,
        3158.82, 2988.24, 3194.12, 2935.29, 3000.01, 2988.24, 3070.59, 3223.53,
        3317.65, 3282.36, 3329.42, 2952.94, 2770.59, 2911.77, 2888.24, 3317.65,
        3358.82, 3023.53, 3105.89, 3011.77, 3005.88, 3011.77, 3117.65, 3076.47,
        3005.88, 3047.06, 2858.82, 2623.53, 2464.71, 2076.48, 2176.47, 2552.94,
        2229.42, 2305.88, 2129.41, 2441.18, 2464.71, 2835.29, 3000.01, 3252.94,
        3247.06, 3335.29, 3376.47, 3164.71, 2858.82, 3011.77, 2829.41, 2864.71,
        2911.77, 2888.24, 3029.41, 3170.59, 3164.71, 3370.59, 3435.3, 3805.88,
        3735.29, 3264.7, 3305.89, 3511.76, 4064.71, 4052.95, 4329.41, 4176.47,
        4211.76, 4382.36, 4052.95, 4229.42, 4135.3, 4105.88, 4164.7, 4341.17,
        3847.06, 4717.64, 5470.59, 5364.71, 5076.47, 4988.24, 4852.94, 4941.17,
        5235.3, 5141.18, 5235.3, 5247.06, 5205.88, 5176.48, 4952.94, 5388.24,
        5247.06, 5352.94, 5282.36, 5235.3, 4935.29, 4888.24, 4658.82, 4605.88,
        4070.59, 4129.41, 4282.35, 4035.29, 4170.59, 4176.47, 4123.53, 3705.88,
        3558.82, 3458.83, 3700.0, 3664.7, 3647.06, 3711.76, 3764.71, 3700.0,
        3752.94, 3864.7, 3458.83, 3617.64, 3635.3, 3805.88, 3923.52, 3800.0,
        3900.0, 3576.47, 3641.17, 3470.59, 3411.77, 3517.65, 3252.94, 3229.41,
        3147.06, 3105.89, 3376.47, 3494.12, 3417.64, 3335.29, 3447.06, 3776.47,
        3852.94, 3717.65, 3741.18, 3788.24, 3711.76, 3900.0, 3917.65, 3994.12,
        4041.18, 3988.24, 3817.64, 3876.47, 3729.41, 3535.29, 3564.71, 3594.11,
        3647.06, 3552.94, 3611.77, 3758.82, 3876.47, 3900.0, 3570.59, 3547.06,
        3582.35, 3688.23, 3629.41, 3858.82, 3947.05, 4047.06, 4041.18, 4088.24,
        4070.59, 4082.35, 4205.89, 4135.3, 4135.3, 4429.41, 4517.64, 4270.59,
        4111.77, 3488.23, 2858.82, 3394.12, 3517.65, 3282.36, 3429.41, 3517.65,
        3688.23, 3517.65, 3405.88, 3417.64, 3405.88, 3194.12, 3035.3, 3117.65,
        3229.41, 3200.0, 3182.35, 3170.59, 3264.7, 3111.76, 3223.53, 3452.94,
        3470.59, 3747.05, 3594.11, 3670.59, 3635.3, 3576.47, 3535.29, 3617.64,
        3605.88, 3576.47, 3494.12, 3482.36, 3570.59, 3501.77, 3405.88, 3156.47,
        3172.94, 3147.06, 2986.47, 3135.29, 3098.24, 3138.23, 3084.71, 3052.94,
        3027.06, 3043.52, 3032.35, 2923.53, 2961.17, 2990.0, 3035.3, 3090.58,
        3150.59, 3156.47, 3228.23, 3167.65, 3244.7, 3180.0, 3235.88, 3398.23,
        3394.12, 3447.06, 3625.29, 3693.53, 3881.76, 3928.23, 3870.0, 3751.76,
        3585.88, 3602.94, 3878.24, 3969.41, 4017.65, 3947.64, 3722.36, 3837.64,
        3962.94, 3999.41, 3868.82, 4067.65, 3793.53, 3874.71, 4012.94, 4238.82,
        4154.71, 4286.47, 4058.82, 3961.18, 4094.12, 3992.35, 3677.65, 3730.59,
        3814.11, 3735.88, 3654.71, 3866.47, 4062.35, 3852.36, 3952.35, 4117.65,
        4272.95, 4310.0, 4122.35, 4070.59, 4072.36, 3841.17, 3788.24, 3862.35,
        3786.47, 3804.12, 4012.35, 3901.76, 3676.47, 3594.11, 3750.58, 3734.12,
        3760.59, 3602.35, 3342.94, 3315.29, 3177.05, 3424.12, 3601.76, 3723.53,
        3447.65, 3470.59, 3728.23, 3798.82, 3845.29, 3657.65, 3764.71, 3564.71,
        3661.17, 3460.59, 3358.23, 3402.35, 3301.77, 3412.94, 3501.18, 3446.47,
        3572.35, 3580.59, 3495.88, 3498.24, 3630.0, 3661.76, 3568.83, 3539.41,
        3477.65, 3496.47, 3544.71, 3498.24, 3481.77, 3645.88, 3708.82, 3693.53,
        3727.64, 3620.0, 3720.59, 3446.47, 3400.59, 3195.89, 3232.35, 3198.23,
        3148.24, 3195.89, 3128.83, 3270.0, 3244.7, 3305.3, 3233.53, 3128.83,
        3136.47, 3168.83, 3082.36, 3262.95, 3120.0, 3127.06, 2921.76, 2908.24,
        2991.18, 2977.64, 3052.35, 3011.18, 3018.83, 3031.18, 3027.64, 3091.17,
        3123.53, 3125.88, 3107.65, 3012.35, 2985.29, 2990.59, 2924.71, 2910.59,
        2924.71, 2960.59, 3032.94, 3008.23, 3023.53, 2939.41, 2942.35, 2984.12,
        2983.53, 2968.24, 2778.24, 2815.88, 2660.59, 2727.06, 2698.82, 2405.89,
        2382.36, 2531.18, 2509.41, 2602.95, 2607.06, 2674.11, 2668.24, 2638.24,
        2642.94, 2679.41, 2819.41, 2778.83, 2857.65, 2770.59, 3206.47, 3094.7,
        3091.76, 2847.64, 2894.7, 3115.88, 3272.35, 3293.53, 3243.53, 3240.0,
        3175.89, 3161.76, 3216.47, 3073.53, 3092.94, 3071.17, 3085.3, 3088.82,
        3418.23, 3410.59, 3398.23, 3368.83, 3475.88, 3471.76, 3457.65, 3519.41,
        3651.77, 3599.41, 3611.18, 3395.89, 3406.47, 3435.3, 3445.88, 3532.94,
        3712.94, 3728.82, 3837.05, 3887.06, 3623.53, 3591.18, 3563.53, 3575.29,
        3512.35, 3442.94, 3565.88, 3654.12, 3651.77, 3636.48, 3645.88, 3577.06,
        3694.71, 3505.89, 3184.12, 2945.88, 2901.77, 2874.12, 2887.65, 2822.95,
        2731.77, 2795.3, 2724.71, 2674.7, 2712.36, 2762.94, 2752.36, 2840.0,
        2735.89, 2569.41, 2504.71, 2575.3, 2585.29, 2596.47, 2571.76, 2618.24,
        2655.29, 2570.0, 2551.18, 2482.35, 2406.48, 2473.52, 2505.29, 2525.88,
        2564.11, 2554.71, 2506.47, 2578.24, 2592.94, 2564.7, 2497.64, 2507.06,
        2504.71, 2512.36, 2508.24, 2490.59, 2498.23, 2600.59, 2588.23, 2624.71,
        2748.24, 2630.0, 2569.41, 2545.88, 2524.12, 2582.95, 2572.94, 2552.94,
        2427.65, 2425.3, 2423.53, 2345.88, 2368.82, 2402.94, 2411.76, 2433.53,
        2288.83, 2318.83, 2307.06, 2355.88, 2444.71, 2457.65, 2591.76, 2492.36,
        2447.65, 2428.24, 2353.53, 2405.3, 2467.65, 2448.24, 2434.12, 2797.65,
        2751.18, 2744.12, 2791.18, 2816.47, 3028.23, 2896.47, 3044.11, 2944.71,
        3101.18, 3190.0, 3214.71, 3075.29, 2980.0, 3152.36, 3068.82, 2993.53,
        2975.89, 3025.88, 3050.59, 2861.77, 3582.35, 3774.7, 3625.29, 3623.53,
        3608.24, 3653.53, 3604.12, 3541.77, 3475.3, 3547.06, 3602.35, 3588.83,
        3536.47, 3664.11, 3536.47, 3674.12, 3804.12, 4070.0, 3893.53, 3587.06,
        3621.17, 3710.59, 3715.88, 3547.65, 3625.88, 3613.53, 3490.0, 3432.35,
        3471.18, 3627.65, 3770.58, 3703.52, 4056.47, 4094.71, 4316.47, 4255.88,
        4702.94, 4387.06, 4331.77, 4262.94, 4452.35, 4231.17, 3778.23, 3847.65,
        3730.0, 3732.35, 3532.35, 3662.94, 3506.47, 3891.77, 3969.41, 3810.0,
        3932.94, 3759.41, 3914.71, 4042.94, 3960.0, 3739.41, 3734.71, 3828.24,
        3771.76, 3558.82, 3398.23, 3437.06, 3388.83, 3492.94, 3518.82, 3479.42,
        3601.18, 3697.06, 3529.41, 3552.35, 3612.95, 3587.65, 3525.89, 3512.94,
        3707.06, 3770.58, 3717.65, 3932.94, 3905.88, 3947.64, 4027.06, 3932.35,
        4041.76, 4381.77, 4358.24, 4554.71, 4540.58, 4553.53, 4449.99, 4324.12,
        4361.77, 6521.76, 6587.64, 6408.23, 6230.58, 6324.7, 6358.24, 6457.06,
        6805.29, 7357.65, 7467.06, 7291.76, 7494.12, 9125.3, 8692.36, 8574.12,
        8352.35, 8077.64, 8540.0, 8228.24, 8317.06, 8202.94, 7668.23, 7104.12,
        7352.94, 6958.23, 6288.24, 7011.77, 7080.59, 6803.53, 6917.64, 7070.0,
        7347.65, 7480.59, 7260.59, 7537.06, 7420.58, 7415.3, 7591.77, 7301.77,
        6705.88, 6429.41, 6412.35, 6425.89, 6656.47, 6653.53, 6897.65, 6604.7,
        5885.88, 5381.76, 4674.7, 3966.48, 4405.89, 4320.58, 4244.12, 4077.05,
        4296.48, 4418.82, 4589.41, 4553.53, 4861.77, 4699.41, 4507.06, 4660.59,
        4505.88, 4681.18, 4651.17, 4535.89, 4142.95, 4122.35, 4208.24, 4472.36,
        4421.18, 4435.88, 4425.89, 4388.82, 4312.94, 4429.41, 4164.12, 4152.35,
        4151.76, 4108.23, 3992.94, 4330.0, 4288.24, 4424.12, 4623.53, 4512.94,
        5396.47, 5646.47, 5496.47, 5358.23, 5898.83, 6580.59, 6594.12, 6507.65,
        6708.24, 6185.89, 6305.88, 5898.83, 5828.24, 5551.17, 5610.0, 6528.24,
        6548.23, 6272.36, 5767.65, 5867.65, 5771.17, 6045.88, 5277.65, 5937.06,
        5955.29, 6145.88, 6712.95, 6844.12, 6317.65, 5715.88, 5854.71, 5914.12,
        5755.29, 5795.88, 5790.0, 5370.0, 5577.65, 5575.88, 5597.64, 5288.82,
        5211.18, 5492.94, 5400.0, 5285.89, 5010.59, 4618.24, 4545.88, 4840.0,
        4521.76, 4733.53, 4757.06, 4732.36, 4616.47, 4754.12, 4830.59, 4959.41,
        5019.42, 4785.29, 4700.59, 4612.35, 4678.82, 4692.95, 4778.24, 4598.24,
        4542.94, 4357.06, 4286.47, 4522.35, 4543.53, 4406.48, 4518.82, 4355.3,
        4247.65, 4228.24, 3885.29, 3880.58, 3674.71, 3171.77, 3405.29, 3331.17,
        3704.11, 3603.53, 3645.88, 3484.7, 3623.53, 3744.12, 3741.77, 3687.06,
        3828.83, 3762.35, 3665.29, 3634.12, 3697.65, 4167.05, 4071.18, 4119.99,
        4049.41, 3929.41, 3711.76, 3380.59, 3383.53, 3425.88, 3460.59, 3481.77,
        3270.59, 3627.65, 3583.53, 3818.23, 3708.23, 3571.17, 3764.71, 3708.82,
        3954.71, 3953.53, 4001.18, 4020.59, 4066.47, 3919.42, 3871.18, 3757.06,
        3992.35, 3904.11, 4012.94, 3991.17, 4051.18, 3922.36, 4018.82, 4094.71,
        4001.18, 4343.53, 4500.0, 4461.18, 4372.94, 4385.88, 4055.29, 3957.06,
        3869.41, 3952.35, 4025.88, 4118.24, 4267.65, 4199.41, 4258.23, 4743.53,
        4644.71, 4835.29, 4475.88, 4805.88, 4591.76, 4480.59, 4335.89, 4355.3,
        4324.12, 4559.99, 4627.05, 4755.89, 4590.0, 4704.12, 4554.12, 4360.59,
        4440.59, 4563.53, 4667.65, 4803.53, 4506.47, 4451.76, 4318.24, 4162.36,
        3974.12, 4215.88, 5630.59, 5232.94, 5142.35, 5481.18, 5405.29, 5362.94,
        5354.7, 5057.06, 4889.42, 5011.18, 5348.24, 5085.3, 5408.24, 5448.82,
        5187.06, 5459.42, 5571.17, 6309.41, 6392.35, 6695.88, 6351.76, 8077.64,
        7573.53, 7442.94, 7521.18, 7724.12, 8081.76, 8774.12, 9248.82, 8840.59,
        10301.17, 10491.77, 10900.0, 9158.82, 8938.82, 9987.06, 10310.59,
        10106.47, 9882.35, 9747.64, 9670.59, 9625.29, 8757.06, 7870.59, 8060.59,
        7842.94, 8210.0, 8592.94, 8592.35, 8091.77, 7881.17, 7755.88, 8094.12,
        7602.35, 7822.35, 7904.7
      ],
      USDT: [
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000,
        10000, 10000, 10000, 10000, 10000, 10000, 10000
      ],
      HODLIE: [
        10000, 10097.35, 10101.75, 10152.92, 10222.42, 10278.39, 10378.39,
        10446.21, 10425.71, 10601.17, 10578.18, 10554.62, 10509.58, 10351.89,
        10530.24, 10502.42, 10478.25, 10728.97, 10652.43, 10357.06, 10323.25,
        10316.13, 10312.53, 10314.73, 10314.27, 10273.68, 10087.23, 9957.4,
        10155.46, 10208.98, 10205.29, 10160.47, 10435.42, 10152.75, 10134.02,
        10494.52, 10607.29, 10555.39, 10791.82, 10640.2, 10661.21, 10588.9,
        10234.14, 10271.36, 10270.83, 10271.2, 10414.08, 10460.56, 10264.91,
        10193.87, 10194.0, 10192.27, 10161.23, 10162.2, 10006.46, 9895.74,
        10092.31, 9939.02, 9674.64, 9766.7, 9907.98, 9898.6, 9723.47, 9679.68,
        9680.88, 9680.3, 9612.85, 9597.37, 9569.39, 9280.33, 9309.1, 9245.11,
        9154.94, 9192.12, 9074.42, 9168.69, 9264.27, 9445.72, 9540.13, 9456.85,
        9465.69, 9605.76, 9905.46, 10104.8, 10002.95, 10119.96, 10323.02,
        10424.11, 10457.04, 10462.93, 10568.99, 10897.07, 10834.59, 10926.68,
        10923.06, 11142.34, 10785.64, 10789.3, 10787.46, 10787.86, 10776.67,
        10625.78, 10720.12, 10779.28, 10699.16, 10721.08, 10717.57, 10677.11,
        10581.67, 10587.17, 10525.61, 10376.8, 10250.95, 10252.65, 10252.2,
        10467.77, 10297.25, 10262.33, 10289.59, 10224.51, 10147.14, 10098.26,
        10098.31, 9968.8, 10155.85, 9781.56, 9696.33, 9537.4, 9565.2, 9558.55,
        9559.14, 9552.79, 9551.82, 9434.19, 9625.3, 9812.16, 9630.3, 9756.78,
        9432.59, 9442.11, 9291.65, 9330.95, 9395.98, 9286.35, 9255.58, 9206.34,
        8960.75, 8907.29, 8908.07, 8915.29, 9036.73, 8932.42, 8545.51, 8721.07,
        8593.24, 8593.84, 8564.91, 8508.71, 8520.48, 8472.83, 8440.82, 8209.63,
        8175.58, 8173.33, 8167.89, 8167.66, 8403.66, 8076.33, 8025.26, 8084.57,
        8252.04, 8310.18, 8451.26, 8312.51, 8747.56, 8940.77, 8926.35, 8848.06,
        8780.53, 8614.13, 8620.87, 8619.27, 8619.9, 8637.16, 8492.58, 8685.11,
        8720.71, 8866.0, 9009.18, 9014.71, 8912.99, 8872.2, 8621.91, 8625.75,
        8626.53, 8800.91, 8929.74, 8854.16, 8869.77, 9360.86, 9514.23, 9405.87,
        9373.6, 9317.74, 9282.85, 9290.75, 9092.37, 8967.16, 8971.54, 9267.77,
        9309.02, 9194.36, 9135.47, 9033.78, 8998.09, 8905.0, 8937.23, 8983.33,
        8988.29, 9074.95, 9113.62, 9056.86, 9121.22, 9172.24, 9312.66, 9424.21,
        9603.86, 9467.16, 9497.49, 9452.32, 9356.66, 9299.42, 9299.67, 9302.09,
        9281.71, 9270.22, 9255.12, 9348.49, 9080.96, 9074.7, 9074.79, 9075.52,
        8981.71, 9049.78, 9100.66, 9061.46, 9028.57, 8997.43, 8987.96, 8759.46,
        8580.66, 8582.54, 8811.59, 8942.93, 8913.0, 9050.62, 8749.0, 8716.74,
        8715.18, 8715.7, 8831.51, 8770.89, 8894.33, 9068.33, 8932.13, 9468.19,
        9636.32, 9447.84, 9466.86, 9451.73, 9301.84, 9341.74, 9277.08, 9276.88,
        9253.12, 9154.84, 9210.3, 9307.35, 9275.72, 9283.6, 9404.29, 9381.07,
        9470.96, 9315.88, 9291.37, 9291.36, 9291.59, 9229.23, 9145.12, 9138.74,
        9250.7, 9313.45, 9289.87, 9112.14, 9116.54, 9109.14, 9167.46, 9125.71,
        9171.23, 9498.39, 9345.87, 9390.35, 10051.41, 10019.61, 10156.84,
        10402.1, 10290.8, 10378.48, 10633.71, 10670.15, 10635.23, 10760.15,
        10176.89, 10273.58, 10280.76, 10277.53, 10210.82, 10081.11, 10128.59,
        10237.96, 10156.54, 10183.75, 10163.27, 10210.91, 10141.28, 10143.57,
        10146.21, 10428.64, 10464.24, 10348.83, 10334.26, 10715.84, 10517.63,
        10681.61, 10858.51, 10768.85, 10822.68, 10704.04, 10724.47, 10703.54,
        10690.06, 10579.21, 10522.33, 10451.62, 10461.52, 10433.77, 10430.06,
        10601.26, 10567.33, 10496.81, 10111.39, 10052.7, 10054.65, 10052.05,
        10140.65, 10115.81, 10141.19, 10143.64, 10160.41, 10132.07, 10165.55,
        10118.21, 10055.84, 9955.07, 9930.8, 9917.08, 9920.51, 10113.45,
        10696.75, 10738.14, 10707.28, 10689.0, 10703.64, 10834.75, 11138.76,
        11122.08, 11095.72, 11022.72, 11124.98, 11548.27, 11476.03, 11539.9,
        11460.7, 11230.02, 11271.88, 11399.86, 11314.02, 11221.34, 11238.83,
        11038.92, 11058.69, 11045.86, 11112.86, 11059.26, 11143.03, 10974.83,
        11046.72, 11007.29, 10942.42, 10980.41, 11047.37, 10966.97, 10949.14,
        10951.97, 10989.92, 10815.63, 10855.13, 10832.79, 10833.25, 10618.6,
        10789.85, 11120.51, 11058.47, 11168.62, 11092.22, 11062.33, 11249.16,
        11049.34, 10863.57, 10815.99, 10637.42, 10670.54, 10691.6, 10669.35,
        10604.18, 10745.96, 10693.98, 10550.74, 10531.63, 10531.98, 10531.71,
        10553.24, 10556.29, 10470.05, 10385.89, 10385.13, 10526.98, 10751.71,
        10850.81, 10777.77, 10914.48, 11452.66, 11329.28, 11576.76, 11439.63,
        11434.49, 11149.25, 11308.66, 11226.48, 11190.32, 11285.47, 11435.41,
        11697.11, 11833.26, 11776.33, 11814.13, 11725.86, 11634.42, 11625.13,
        11616.94, 11557.4, 11374.85, 11305.33, 11279.86, 11281.07, 11497.76,
        11625.98, 11722.31, 11827.71, 11895.78, 11870.22, 11959.22, 11857.06,
        11912.72, 11618.64, 11595.26, 11595.3, 11597.15, 11584.46, 11590.74,
        11651.96, 11541.64, 11757.24, 11748.18, 11694.58, 11709.18, 11504.82,
        11462.75, 11486.47, 11375.35, 11668.29, 11398.59, 11395.74, 11393.94,
        11393.58, 11394.13, 11365.94, 11355.18, 11285.96, 11294.78, 11370.7,
        11422.08, 11538.42, 11491.91, 11536.05, 11545.18, 11474.87, 11412.42,
        11523.73, 11354.78, 11397.33, 11467.17, 11496.33, 11589.21, 11481.4,
        11609.05, 11593.27, 11541.33, 11598.54, 11613.14, 11672.77, 11424.71,
        11442.57, 11370.33, 11369.44, 11362.4, 11126.12, 11125.07, 11124.3,
        10981.77, 11035.25, 11034.52, 11032.14, 11053.52, 11010.05, 11096.51,
        11341.3, 11713.77, 11675.98, 11813.18, 11801.88, 11817.13, 11745.78,
        11739.88, 11648.77, 11616.34, 11873.54, 11738.14, 11913.6, 11765.09,
        11720.84, 11582.49, 11666.56, 11899.09, 11978.72, 11874.76, 11758.62,
        11783.4, 11722.7, 12180.49, 11842.97, 11826.24, 11989.24, 11909.49,
        11874.0, 12054.62, 11985.67, 11958.03, 11891.6, 11904.29, 11676.97,
        11898.25, 11798.44, 11790.69, 11756.58, 11807.46, 11797.94, 11871.65,
        11911.63, 11728.6, 11722.75, 11718.05, 12082.54, 11904.49, 11819.07,
        11946.17, 11982.85, 11985.5, 12123.27, 12196.25, 12066.88, 12080.32,
        11856.38, 11860.81, 11857.95, 11856.84, 11856.88, 11856.78, 11780.63,
        11674.08, 11717.28, 11548.67, 11543.45, 11540.4, 11530.82, 11547.49,
        11649.91, 11531.72, 11284.79, 11207.34, 11228.65, 11248.75, 11231.83,
        11146.93, 11024.46, 11071.03, 11005.34, 10986.39, 10923.82, 10914.71,
        10884.35, 10856.13, 10907.18, 10882.74, 10860.57, 10815.57, 10795.91,
        10924.37, 10934.93, 10861.35, 10968.05, 11069.67, 10996.42, 11156.79,
        11111.35, 11220.86, 11293.32, 11326.66, 11345.07, 11846.27, 11675.27,
        11713.03, 11601.78, 11601.32, 11780.08, 11684.77, 11611.8, 11516.28,
        11516.7, 11516.0, 11515.67, 11515.76, 11494.41, 11482.27, 11870.01,
        11834.25, 11780.72, 11971.47, 12363.87, 12717.1, 12851.68, 13104.08,
        12908.25, 12990.67, 12921.41, 12849.4, 12925.4, 12999.61, 13024.61,
        13381.93, 13869.84, 13646.18, 13608.87, 13558.31, 13719.15, 13870.42,
        13727.04, 13937.07, 13733.2, 14150.28, 14280.34, 14193.65, 13616.44,
        13813.23, 13827.32, 13530.12, 13539.01, 13239.14, 13127.93, 12912.09,
        12656.34, 12769.83, 12894.93, 12779.08, 12776.06, 12655.41, 12494.93,
        12449.16, 12375.74, 12379.81, 12434.98, 12456.68, 12570.45, 12708.29,
        13054.9, 12978.82, 12977.29, 13292.6, 13572.27, 13651.99, 13808.09,
        13627.56, 13887.41, 13684.25, 13384.16, 13332.45, 12976.34, 12914.31,
        12843.58, 13113.15, 13774.86, 13889.74, 14441.27, 14572.38, 14902.68,
        14580.26, 14676.25, 14545.04, 14304.61, 14260.28, 14298.91, 14428.74,
        14550.09, 14195.46, 14449.74, 14369.98, 14260.95, 14240.46, 14606.59,
        14491.2, 14797.56, 14994.08, 14665.91, 14892.47, 14864.98, 14765.83,
        14795.05, 14843.95, 14527.51, 14602.02, 14640.47, 14545.71, 14405.14,
        14275.19, 14274.16, 14176.44, 14448.29, 14518.42, 14433.41, 14694.89,
        14821.14, 14487.16, 14927.89, 15067.89, 15051.21, 15169.63, 15317.55,
        15207.09, 15349.34, 15348.1, 15654.53, 15744.15, 15793.35, 15775.38,
        15673.46, 16069.71, 16189.87, 16123.15, 16160.16, 16047.43, 16020.24,
        16122.06, 15967.97, 16101.42, 16001.07, 15966.89, 15872.87, 15787.23,
        16020.59, 16621.16, 16616.82, 16516.3, 17514.85, 17467.09, 19266.75,
        19147.5, 19160.85, 18868.11, 18778.4, 18817.91, 18753.17, 18875.72,
        18595.08, 18500.55, 18482.84, 18744.37, 18649.31, 19310.18, 19220.9,
        18564.42, 18546.28, 18614.41, 18555.73, 18868.26, 19116.37, 19087.73,
        19061.16, 18952.85, 19152.76, 19028.26, 18801.68, 18904.58, 18833.91,
        18564.81, 18287.67, 18247.89, 18243.02, 18379.19, 18396.84, 18763.0,
        18257.32, 18310.79, 18256.85, 18192.24, 17921.07, 17907.59, 17905.86,
        17903.19, 17719.31, 17715.03, 17753.47, 17979.42, 17984.18, 18076.52,
        18029.07, 17641.8, 17683.19, 17707.26, 17605.44, 17690.34, 17493.77,
        16929.94, 16927.27, 17149.51, 17124.54, 17189.1, 17178.59, 17116.53,
        16989.53, 16814.08, 16831.86, 16632.98, 16561.75, 16446.2, 16420.24,
        16131.25, 16442.28, 16322.09, 16523.67, 16591.13, 16676.45, 17136.36,
        17512.03, 17357.74, 17510.44, 17208.97, 17552.71, 17559.36, 17508.2,
        17430.17, 17486.14, 17227.72, 16912.23, 16885.98, 16805.01, 16841.61,
        17158.29, 17040.06, 16886.06, 16560.66, 16561.73, 16560.79, 16555.01,
        16178.59, 16181.08, 16188.79, 16073.97, 16389.87, 16457.62, 16294.35,
        16166.01, 16176.8, 16052.42, 15966.79, 15688.79, 15664.85, 15620.96,
        15680.81, 15609.68, 16073.5, 15827.77, 15775.86, 15998.88, 15934.56,
        16068.9, 15694.76, 15494.45, 15490.76, 15495.64, 15046.99, 15047.78,
        15014.1, 15093.44, 15056.48, 15081.81, 15203.56, 15236.4, 15522.44,
        15554.73, 15678.63, 15281.23, 15359.53, 15497.43, 15806.95, 15673.49,
        15412.55, 15325.6, 15241.27, 15375.36, 15443.54, 15324.06, 15324.63,
        15291.58, 15311.29, 15100.86, 15069.76, 15067.59, 15063.87, 15059.39,
        15062.84, 15110.76, 15390.05, 15246.39, 15243.06, 15096.54, 15110.2,
        15159.51, 15042.86, 14924.41, 15005.5, 15076.09, 14980.6, 14892.31,
        14985.65, 15448.82, 15537.51, 15552.69, 15625.75, 15628.59, 15500.52,
        15389.75, 15390.19, 15389.9, 15467.05, 15472.59, 15363.63, 15493.59,
        15454.44, 15673.87, 15574.61, 15523.32, 15640.61, 15819.47, 15976.94,
        15970.32, 15934.41, 16027.49, 16140.23, 15993.76, 15857.14, 15655.96,
        15684.18, 15534.35, 15673.44, 15743.91, 15822.69, 15865.89, 16037.43,
        16096.64, 15977.13, 16203.92, 16485.26, 16367.16, 16324.64, 16165.69,
        16015.07, 16020.73, 16020.59, 16022.16, 16031.0, 16017.75, 15968.92,
        15913.46, 15967.13, 16354.89, 16282.17, 16425.76, 16408.12, 16663.71,
        16500.62, 16620.32, 16633.02, 16812.13, 16935.74, 16920.79, 16910.7,
        16947.48, 16884.96, 17135.29, 16657.68, 16679.04, 16788.28, 17060.77,
        17402.2, 17297.53, 17150.64, 17091.29, 16946.05, 16957.24, 16956.26,
        16941.85, 17497.04, 17565.13, 17795.79, 18312.13, 19240.22, 19320.77,
        19912.89, 19859.94, 19682.55, 20336.84, 20919.18, 20750.84, 21023.64,
        20603.79, 20612.11, 20399.09, 20444.38, 20632.37, 20831.94, 20502.06,
        20268.1, 21079.71, 20780.83, 21039.48, 21502.73, 22213.31, 23650.79,
        23359.25, 22861.56, 22571.71, 22951.51, 22869.0, 22992.17, 22043.85,
        22015.52, 22021.11, 22112.53, 22031.54, 22223.11, 22178.04, 22446.13,
        22261.04, 21660.43, 21291.05, 21189.46, 21186.46, 21184.61, 21396.33,
        21308.24, 21067.25, 20679.41, 20507.02, 20487.33, 20178.36, 20194.11,
        20254.32
      ]
    },
    2022: {
      TIMESTAMP: [
        '2022-01-01',
        '2022-01-02',
        '2022-01-03',
        '2022-01-04',
        '2022-01-05',
        '2022-01-06',
        '2022-01-07',
        '2022-01-08',
        '2022-01-09',
        '2022-01-10',
        '2022-01-11',
        '2022-01-12',
        '2022-01-13',
        '2022-01-14',
        '2022-01-15',
        '2022-01-16',
        '2022-01-17',
        '2022-01-18',
        '2022-01-19',
        '2022-01-20',
        '2022-01-21',
        '2022-01-22',
        '2022-01-23',
        '2022-01-24',
        '2022-01-25',
        '2022-01-26',
        '2022-01-27',
        '2022-01-28',
        '2022-01-29',
        '2022-01-30',
        '2022-01-31',
        '2022-02-01',
        '2022-02-02',
        '2022-02-03',
        '2022-02-04',
        '2022-02-05',
        '2022-02-06',
        '2022-02-07',
        '2022-02-08',
        '2022-02-09',
        '2022-02-10',
        '2022-02-11',
        '2022-02-12',
        '2022-02-13',
        '2022-02-14',
        '2022-02-15',
        '2022-02-16',
        '2022-02-17',
        '2022-02-18',
        '2022-02-19',
        '2022-02-20',
        '2022-02-21',
        '2022-02-22',
        '2022-02-23',
        '2022-02-24',
        '2022-02-25',
        '2022-02-26',
        '2022-02-27',
        '2022-02-28',
        '2022-03-01',
        '2022-03-02',
        '2022-03-03',
        '2022-03-04',
        '2022-03-05',
        '2022-03-06',
        '2022-03-07',
        '2022-03-08',
        '2022-03-09',
        '2022-03-10',
        '2022-03-11',
        '2022-03-12',
        '2022-03-13',
        '2022-03-14',
        '2022-03-15',
        '2022-03-16',
        '2022-03-17',
        '2022-03-18',
        '2022-03-19',
        '2022-03-20',
        '2022-03-21',
        '2022-03-22',
        '2022-03-23',
        '2022-03-24',
        '2022-03-25',
        '2022-03-26',
        '2022-03-27',
        '2022-03-28',
        '2022-03-29',
        '2022-03-30',
        '2022-03-31',
        '2022-04-01',
        '2022-04-02',
        '2022-04-03',
        '2022-04-04',
        '2022-04-05',
        '2022-04-06',
        '2022-04-07',
        '2022-04-08',
        '2022-04-09',
        '2022-04-10',
        '2022-04-11',
        '2022-04-12',
        '2022-04-13',
        '2022-04-14',
        '2022-04-15',
        '2022-04-16',
        '2022-04-17',
        '2022-04-18',
        '2022-04-19',
        '2022-04-20',
        '2022-04-21',
        '2022-04-22',
        '2022-04-23',
        '2022-04-24',
        '2022-04-25',
        '2022-04-26',
        '2022-04-27',
        '2022-04-28',
        '2022-04-29',
        '2022-04-30',
        '2022-05-01',
        '2022-05-02',
        '2022-05-03',
        '2022-05-04',
        '2022-05-05',
        '2022-05-06',
        '2022-05-07',
        '2022-05-08',
        '2022-05-09',
        '2022-05-10',
        '2022-05-11',
        '2022-05-12',
        '2022-05-13',
        '2022-05-14',
        '2022-05-15',
        '2022-05-16',
        '2022-05-17',
        '2022-05-18',
        '2022-05-19',
        '2022-05-20',
        '2022-05-21',
        '2022-05-22',
        '2022-05-23',
        '2022-05-24',
        '2022-05-25',
        '2022-05-26',
        '2022-05-27',
        '2022-05-28',
        '2022-05-29',
        '2022-05-30',
        '2022-05-31',
        '2022-06-01',
        '2022-06-02',
        '2022-06-03',
        '2022-06-04',
        '2022-06-05',
        '2022-06-06',
        '2022-06-07',
        '2022-06-08',
        '2022-06-09',
        '2022-06-10',
        '2022-06-11',
        '2022-06-12',
        '2022-06-13',
        '2022-06-14',
        '2022-06-15',
        '2022-06-16',
        '2022-06-17',
        '2022-06-18',
        '2022-06-19',
        '2022-06-20',
        '2022-06-21',
        '2022-06-22',
        '2022-06-23',
        '2022-06-24',
        '2022-06-25',
        '2022-06-26',
        '2022-06-27',
        '2022-06-28',
        '2022-06-29',
        '2022-06-30',
        '2022-07-01',
        '2022-07-02',
        '2022-07-03',
        '2022-07-04',
        '2022-07-05',
        '2022-07-06',
        '2022-07-07',
        '2022-07-08',
        '2022-07-09',
        '2022-07-10',
        '2022-07-11',
        '2022-07-12',
        '2022-07-13',
        '2022-07-14',
        '2022-07-15',
        '2022-07-16',
        '2022-07-17',
        '2022-07-18',
        '2022-07-19',
        '2022-07-20',
        '2022-07-21',
        '2022-07-22',
        '2022-07-23',
        '2022-07-24',
        '2022-07-25',
        '2022-07-26',
        '2022-07-27',
        '2022-07-28',
        '2022-07-29',
        '2022-07-30',
        '2022-07-31',
        '2022-08-01',
        '2022-08-02',
        '2022-08-03',
        '2022-08-04',
        '2022-08-05',
        '2022-08-06',
        '2022-08-07',
        '2022-08-08',
        '2022-08-09',
        '2022-08-10',
        '2022-08-11',
        '2022-08-12',
        '2022-08-13',
        '2022-08-14',
        '2022-08-15',
        '2022-08-16',
        '2022-08-17',
        '2022-08-18',
        '2022-08-19',
        '2022-08-20',
        '2022-08-21',
        '2022-08-22',
        '2022-08-23',
        '2022-08-24',
        '2022-08-25',
        '2022-08-26',
        '2022-08-27',
        '2022-08-28',
        '2022-08-29',
        '2022-08-30',
        '2022-08-31',
        '2022-09-01',
        '2022-09-02',
        '2022-09-03',
        '2022-09-04',
        '2022-09-05',
        '2022-09-06',
        '2022-09-07',
        '2022-09-08',
        '2022-09-09',
        '2022-09-10',
        '2022-09-11',
        '2022-09-12',
        '2022-09-13',
        '2022-09-14',
        '2022-09-15',
        '2022-09-16',
        '2022-09-17',
        '2022-09-18',
        '2022-09-19',
        '2022-09-20',
        '2022-09-21',
        '2022-09-22',
        '2022-09-23',
        '2022-09-24',
        '2022-09-25',
        '2022-09-26',
        '2022-09-27',
        '2022-09-28',
        '2022-09-29',
        '2022-09-30',
        '2022-10-01',
        '2022-10-02',
        '2022-10-03',
        '2022-10-04',
        '2022-10-05',
        '2022-10-06',
        '2022-10-07',
        '2022-10-08',
        '2022-10-09',
        '2022-10-10',
        '2022-10-11',
        '2022-10-12',
        '2022-10-13',
        '2022-10-14',
        '2022-10-15',
        '2022-10-16',
        '2022-10-17',
        '2022-10-18',
        '2022-10-19',
        '2022-10-20',
        '2022-10-21',
        '2022-10-22',
        '2022-10-23',
        '2022-10-24',
        '2022-10-25',
        '2022-10-26',
        '2022-10-27',
        '2022-10-28',
        '2022-10-29',
        '2022-10-30',
        '2022-10-31',
        '2022-11-01',
        '2022-11-02',
        '2022-11-03',
        '2022-11-04',
        '2022-11-05',
        '2022-11-06',
        '2022-11-07',
        '2022-11-08',
        '2022-11-09',
        '2022-11-10',
        '2022-11-11',
        '2022-11-12',
        '2022-11-13',
        '2022-11-14',
        '2022-11-15',
        '2022-11-16',
        '2022-11-17',
        '2022-11-18',
        '2022-11-19',
        '2022-11-20',
        '2022-11-21',
        '2022-11-22',
        '2022-11-23',
        '2022-11-24',
        '2022-11-25',
        '2022-11-26',
        '2022-11-27',
        '2022-11-28',
        '2022-11-29',
        '2022-11-30',
        '2022-12-01',
        '2022-12-02',
        '2022-12-03',
        '2022-12-04',
        '2022-12-05',
        '2022-12-06',
        '2022-12-07',
        '2022-12-08',
        '2022-12-09',
        '2022-12-10',
        '2022-12-11',
        '2022-12-12',
        '2022-12-13',
        '2022-12-14',
        '2022-12-15',
        '2022-12-16',
        '2022-12-17',
        '2022-12-18',
        '2022-12-19',
        '2022-12-20',
        '2022-12-21',
        '2022-12-22',
        '2022-12-23',
        '2022-12-24',
        '2022-12-25',
        '2022-12-26',
        '2022-12-27',
        '2022-12-28',
        '2022-12-29',
        '2022-12-30',
        '2022-12-31',
        '2023-01-01'
      ],
      BTC: [
        10000.0, 10264.8, 10224.69, 10000.21, 9987.66, 9398.52, 9343.11,
        8988.44, 9068.35, 9132.01, 9002.11, 9241.87, 9486.34, 9216.19, 9358.16,
        9355.32, 9345.06, 9104.28, 9191.64, 9082.58, 8907.4, 7814.79, 7545.94,
        7683.0, 7869.29, 7960.54, 7899.58, 7941.96, 8147.44, 8252.39, 8175.75,
        8311.34, 8373.02, 8007.23, 7991.26, 8793.69, 8996.67, 8999.03, 9535.25,
        9533.93, 9641.0, 9511.14, 9121.12, 9125.56, 9167.97, 9234.29, 9556.92,
        9522.59, 8808.83, 8641.86, 8658.17, 8398.69, 8123.27, 8204.21, 8145.98,
        8193.98, 8530.24, 8469.88, 8182.82, 9309.67, 9554.24, 9534.9, 9194.05,
        8439.49, 8539.06, 8432.87, 8267.85, 8341.59, 9064.22, 8534.11, 8420.44,
        8460.53, 8161.71, 8509.02, 8539.52, 8845.89, 8868.57, 9019.89, 9077.73,
        8965.54, 8897.17, 9202.07, 9193.31, 9509.28, 9585.12, 9599.11, 10085.7,
        10286.41, 10241.08, 10192.05, 9907.16, 10032.77, 9977.03, 10103.47,
        10097.31, 9921.58, 9414.59, 9440.2, 9152.7, 9205.15, 9111.8, 8604.29,
        8617.61, 8931.59, 8621.02, 8755.98, 8742.65, 8654.84, 8854.71, 8956.76,
        8972.4, 8743.98, 8592.35, 8615.1, 8557.11, 8729.91, 8269.92, 8454.55,
        8610.46, 8353.79, 8276.28, 8288.44, 8349.98, 8165.77, 8585.99, 7840.99,
        7789.19, 7582.95, 7416.17, 6726.51, 6661.01, 6245.13, 6236.14, 6449.91,
        6542.91, 6727.12, 6495.12, 6567.17, 6269.59, 6531.2, 6307.85, 6364.39,
        6550.76, 6320.46, 6405.79, 6444.72, 6384.86, 6236.45, 6273.17, 6367.11,
        6873.77, 6895.59, 6436.42, 6595.62, 6432.79, 6449.59, 6496.04, 6793.08,
        6763.75, 6558.32, 6505.76, 6313.3, 6195.95, 5841.03, 4887.36, 4673.38,
        4862.81, 4406.4, 4425.04, 4151.49, 4477.84, 4433.36, 4521.5, 4349.77,
        4542.01, 4611.08, 4637.12, 4574.9, 4503.63, 4394.06, 4373.64, 4088.23,
        4176.09, 4182.74, 4175.95, 4362.83, 4421.59, 4438.58, 4679.7, 4711.05,
        4679.56, 4509.13, 4328.99, 4192.26, 4294.31, 4435.94, 4520.16, 4607.98,
        4544.26, 4715.27, 5085.1, 5043.4, 4980.23, 4917.31, 4861.65, 4902.36,
        4686.24, 4567.66, 4949.14, 5184.66, 5180.54, 5128.2, 5058.2, 5012.19,
        4998.37, 4961.86, 4894.86, 5029.2, 5014.1, 5029.46, 5152.53, 5013.39,
        5158.88, 5169.81, 5244.66, 5276.65, 5248.87, 5195.87, 5160.0, 5051.58,
        5051.05, 4538.41, 4573.29, 4666.25, 4581.86, 4646.67, 4650.62, 4668.48,
        4459.95, 4331.76, 4306.62, 4371.53, 4307.31, 4360.96, 4344.37, 4325.15,
        4282.78, 4291.44, 4285.13, 4091.39, 4184.01, 4177.29, 4618.89, 4707.8,
        4681.38, 4830.16, 4375.92, 4391.49, 4268.8, 4268.75, 4354.73, 4207.74,
        4241.55, 4102.52, 4000.46, 4197.07, 4183.13, 4086.45, 4065.26, 4140.99,
        4128.4, 4216.14, 4209.73, 4194.32, 4181.21, 4147.97, 4223.34, 4392.25,
        4368.62, 4306.07, 4229.98, 4189.1, 4197.13, 4139.24, 4119.73, 4144.67,
        4204.31, 4145.63, 4124.27, 4176.41, 4222.31, 4180.4, 4141.42, 4118.41,
        4152.02, 4152.66, 4239.34, 4189.19, 4341.12, 4504.21, 4385.75, 4463.73,
        4491.38, 4454.35, 4431.17, 4430.95, 4351.18, 4369.7, 4582.31, 4608.04,
        4573.91, 4427.26, 3994.14, 3434.81, 3856.43, 3661.2, 3642.75, 3556.78,
        3550.76, 3652.44, 3606.82, 3615.76, 3605.98, 3621.66, 3526.18, 3405.73,
        3501.94, 3572.83, 3594.46, 3576.43, 3553.96, 3585.92, 3509.7, 3569.17,
        3710.39, 3670.79, 3687.38, 3656.95, 3700.7, 3666.19, 3672.65, 3644.09,
        3721.98, 3707.07, 3708.22, 3700.25, 3712.49, 3846.4, 3859.41, 3751.85,
        3618.48, 3621.91, 3626.74, 3552.12, 3650.02, 3627.44, 3634.69, 3633.5,
        3640.63, 3639.75, 3643.41, 3611.44, 3574.03, 3595.99, 3588.14, 3574.62
      ],
      ETH: [
        10000.0, 10222.13, 10412.02, 10206.08, 10381.89, 9613.79, 9328.99,
        8654.98, 8482.74, 8648.54, 8331.17, 8792.1, 9147.69, 8832.72, 9013.28,
        9078.87, 9108.21, 8684.36, 8648.02, 8506.16, 8326.52, 6924.57, 6472.31,
        6652.19, 6562.41, 6630.65, 6632.29, 6480.53, 6842.15, 7072.71, 7006.31,
        7290.08, 7584.0, 7287.52, 7236.27, 8040.33, 8213.04, 8131.16, 8573.16,
        8470.42, 8843.35, 8475.59, 7872.17, 7930.95, 7894.34, 8000.62, 8502.46,
        8531.21, 7886.81, 7547.27, 7461.67, 7214.46, 7122.62, 7084.44, 7172.89,
        6980.58, 7593.6, 7603.91, 7154.31, 7881.01, 8090.9, 8053.9, 7708.99,
        7104.32, 7263.04, 7151.6, 6811.92, 6986.48, 7389.86, 7106.23, 6992.0,
        7037.89, 6813.58, 6992.95, 7131.6, 7472.14, 7669.03, 7974.72, 7985.36,
        7825.41, 7910.53, 8159.99, 8172.94, 8460.84, 8429.62, 8525.8, 8894.5,
        9169.89, 9211.28, 9220.38, 8948.66, 9388.18, 9430.2, 9641.62, 9594.54,
        9368.27, 8673.78, 8802.61, 8679.33, 8833.67, 8765.91, 8161.05, 8198.1,
        8494.87, 8200.45, 8250.27, 8326.44, 8284.6, 8338.06, 8427.84, 8393.2,
        8115.13, 8047.73, 8039.4, 7967.38, 8185.65, 7675.36, 7820.73, 7966.48,
        7668.89, 7565.39, 7635.49, 7784.91, 7575.43, 7957.34, 7444.66, 7331.75,
        7080.49, 6907.78, 6238.37, 6311.25, 5668.11, 5234.12, 5600.33, 5592.25,
        5794.98, 5531.0, 5662.25, 5299.21, 5458.12, 5313.52, 5371.78, 5536.71,
        5380.78, 5393.79, 5345.59, 4952.42, 4738.93, 4853.72, 4925.32, 5438.78,
        5323.63, 4946.4, 4989.22, 4849.95, 4893.23, 4938.7, 5046.75, 4920.18,
        4896.57, 4865.94, 4557.2, 4211.19, 3961.7, 3246.78, 3201.13, 3312.17,
        2902.21, 2949.36, 2695.75, 3105.25, 3012.57, 3094.94, 2888.61, 3105.27,
        3346.63, 3379.55, 3302.92, 3270.45, 3123.44, 3016.87, 2784.76, 2896.12,
        2912.04, 2926.64, 3109.0, 3132.72, 3238.65, 3345.35, 3349.68, 3308.53,
        3178.91, 2988.72, 2837.07, 2955.09, 3229.73, 3397.64, 3694.22, 3700.26,
        4119.47, 4252.37, 4152.0, 4286.95, 4188.15, 4194.11, 4366.86, 4031.39,
        3838.12, 4422.74, 4748.72, 4738.14, 4621.39, 4582.66, 4430.35, 4481.11,
        4412.35, 4343.74, 4666.28, 4660.97, 4637.12, 4837.29, 4634.64, 5032.3,
        5095.14, 5254.43, 5382.34, 5257.42, 5155.42, 5091.82, 4975.53, 5078.3,
        4406.82, 4280.23, 4402.88, 4318.93, 4499.42, 4517.4, 4608.94, 4213.5,
        4037.37, 3983.59, 4191.14, 4204.34, 4266.79, 4315.64, 4299.3, 4230.34,
        4261.6, 4369.48, 4295.63, 4448.52, 4439.66, 4677.29, 4851.16, 4749.07,
        4640.82, 4317.77, 4458.1, 4012.37, 3896.03, 3985.98, 3647.18, 3769.54,
        3620.89, 3383.6, 3600.26, 3617.24, 3575.65, 3511.59, 3610.88, 3617.87,
        3639.71, 3615.88, 3597.41, 3571.02, 3509.03, 3578.51, 3696.92, 3687.09,
        3674.53, 3627.33, 3568.41, 3581.53, 3515.07, 3484.28, 3521.3, 3515.64,
        3524.75, 3457.35, 3558.86, 3620.86, 3570.5, 3499.16, 3489.89, 3540.04,
        3577.71, 3714.43, 3662.28, 3966.21, 4266.38, 4127.6, 4239.73, 4382.21,
        4317.44, 4259.34, 4293.83, 4111.55, 4165.16, 4487.37, 4440.2, 4357.23,
        4241.36, 3586.78, 3040.2, 3601.89, 3482.89, 3429.27, 3341.17, 3318.65,
        3388.99, 3307.65, 3282.41, 3291.49, 3330.02, 3108.81, 3013.88, 3094.59,
        3194.52, 3273.65, 3262.2, 3267.81, 3308.42, 3184.82, 3327.25, 3533.27,
        3475.03, 3510.61, 3378.49, 3469.83, 3424.97, 3408.06, 3346.88, 3493.53,
        3438.66, 3452.24, 3433.71, 3461.29, 3590.17, 3568.31, 3436.65, 3206.33,
        3225.69, 3230.41, 3162.27, 3306.35, 3290.51, 3307.55, 3314.35, 3314.73,
        3311.3, 3314.73, 3294.68, 3225.34, 3258.26, 3259.32, 3253.85
      ],
      BNB: [
        10000.0, 10275.72, 10387.17, 10023.47, 9974.58, 9305.83, 9317.56,
        8760.27, 8506.07, 8609.7, 8273.37, 9026.21, 9485.73, 9325.38, 9587.41,
        9696.91, 9763.4, 9256.94, 9247.16, 9159.17, 8756.35, 7465.78, 6941.73,
        7260.46, 7195.94, 7471.64, 7291.75, 7573.33, 7508.81, 7622.21, 7356.27,
        7354.32, 7543.99, 7217.44, 7217.44, 7737.58, 8101.3, 8046.54, 8502.15,
        8023.08, 8277.28, 8201.02, 7706.29, 7884.24, 7815.8, 7888.15, 8410.25,
        8371.14, 7919.44, 7774.74, 7800.16, 7524.44, 7104.02, 7252.64, 7254.59,
        6937.82, 7326.94, 7303.49, 7102.07, 7692.61, 7970.28, 8007.43, 7868.6,
        7338.68, 7520.53, 7442.32, 7469.69, 7436.45, 7661.32, 7280.02, 7303.49,
        7332.81, 7082.52, 7227.22, 7276.11, 7487.29, 7653.5, 7735.63, 7802.11,
        7686.74, 7755.18, 7940.95, 7948.76, 8093.47, 8025.03, 8103.25, 8388.73,
        8498.24, 8470.87, 8664.45, 8437.62, 8705.52, 8629.25, 8818.92, 8756.35,
        8779.82, 8275.32, 8537.35, 8246.0, 8289.01, 8214.7, 7747.36, 8066.1,
        8283.15, 8103.25, 8142.35, 8152.13, 8093.47, 8169.73, 8228.4, 8201.02,
        7915.53, 7960.5, 7903.79, 7831.44, 7894.01, 7545.96, 7622.21, 7983.96,
        7690.66, 7538.13, 7598.74, 7600.71, 7487.29, 7862.73, 7356.27, 7430.59,
        7088.39, 6978.88, 6028.55, 6138.06, 5295.27, 5215.1, 5784.12, 5766.52,
        6052.01, 5840.83, 5960.11, 5725.46, 5958.16, 5917.09, 6120.46, 6224.09,
        6261.25, 6415.72, 6437.23, 6063.74, 5934.69, 6007.04, 5981.62, 6296.44,
        6300.35, 5883.84, 6024.64, 5827.14, 5897.54, 5860.39, 5789.99, 5658.97,
        5668.76, 5658.97, 5621.82, 5342.2, 5039.11, 4301.91, 4266.72, 4518.96,
        4098.56, 4227.61, 3887.37, 4211.97, 4219.79, 4342.98, 4227.61, 4470.08,
        4710.6, 4669.53, 4608.91, 4583.5, 4440.75, 4323.43, 4170.91, 4247.16,
        4280.41, 4274.54, 4501.36, 4560.03, 4665.63, 4710.6, 4732.11, 4751.66,
        4579.58, 4417.29, 4344.94, 4429.01, 4636.29, 4693.0, 4904.18, 4892.45,
        5078.22, 5281.58, 5043.02, 5172.08, 5164.25, 5066.48, 5117.32, 4898.32,
        4829.88, 5263.98, 5434.1, 5809.54, 5658.97, 5543.61, 5531.87, 5565.12,
        5864.29, 6079.39, 6184.98, 6198.68, 6341.41, 6337.51, 6378.57, 6400.08,
        6317.95, 6353.15, 6360.97, 6233.86, 6212.36, 6179.11, 5977.71, 5915.14,
        5482.99, 5520.14, 5909.27, 5866.24, 5844.74, 5799.76, 5897.54, 5529.92,
        5408.69, 5461.47, 5561.21, 5531.87, 5482.99, 5434.1, 5426.29, 5426.29,
        5424.32, 5396.95, 5187.72, 5434.1, 5494.72, 5727.42, 5817.36, 5739.14,
        5705.91, 5420.42, 5469.3, 5281.58, 5365.67, 5461.47, 5207.27, 5305.05,
        5222.92, 5136.88, 5371.53, 5404.77, 5400.86, 5334.37, 5371.53, 5332.42,
        5502.54, 5512.32, 5514.27, 5543.61, 5584.67, 5588.59, 5782.17, 5746.97,
        5555.34, 5526.01, 5402.82, 5426.29, 5326.55, 5297.22, 5295.27, 5340.24,
        5262.02, 5248.34, 5334.37, 5379.35, 5342.2, 5314.82, 5260.07, 5275.72,
        5285.49, 5396.95, 5350.02, 5578.8, 5694.17, 5592.49, 5838.87, 5917.09,
        6106.76, 6423.55, 6359.01, 6253.43, 6501.76, 6888.93, 6840.05, 6824.4,
        6507.63, 6175.21, 5263.98, 5979.66, 5647.24, 5533.82, 5406.72, 5391.09,
        5387.17, 5316.77, 5250.29, 5344.15, 5338.29, 5170.12, 4966.76, 5211.19,
        5795.86, 5877.98, 5868.21, 6069.61, 6138.06, 5727.42, 5809.54, 5876.03,
        5725.46, 5707.86, 5684.39, 5696.13, 5635.51, 5647.24, 5555.34, 5664.84,
        5590.54, 5629.64, 5582.72, 5410.64, 5312.87, 5238.57, 5029.33, 4501.36,
        4696.91, 4913.96, 4698.86, 4908.1, 4822.06, 4802.5, 4812.28, 4779.03,
        4749.71, 4743.84, 4814.23, 4759.48, 4804.46, 4794.68, 4816.2
      ],
      SOL: [
        10000.0, 10427.68, 10402.38, 10005.89, 9937.06, 8917.0, 8940.53,
        8045.77, 8486.39, 8319.9, 7936.95, 8250.49, 8948.76, 8628.16, 8644.04,
        8735.22, 8695.81, 8183.43, 8286.96, 8061.06, 7768.11, 6486.27, 5483.26,
        5653.87, 5419.74, 5500.91, 5393.85, 5196.78, 5315.61, 5611.51, 5466.79,
        5875.65, 6465.09, 5952.71, 5917.99, 6470.39, 6749.81, 6635.1, 6946.88,
        6652.74, 6763.34, 6359.79, 5622.68, 5655.63, 5514.45, 5696.81, 6038.59,
        6033.89, 5533.86, 5285.6, 5333.26, 5470.91, 5015.0, 5005.59, 5093.24,
        5129.13, 5573.27, 5336.79, 5029.12, 5787.4, 5759.16, 5945.65, 5631.51,
        5203.24, 5297.96, 5043.24, 4838.52, 4809.1, 5169.13, 4906.76, 4775.58,
        4815.58, 4605.57, 4752.63, 4900.88, 5055.59, 5205.01, 5229.13, 5438.56,
        5260.9, 5219.14, 5382.67, 5549.74, 6022.71, 5766.22, 5962.7, 6203.31,
        6442.74, 6517.44, 7066.3, 7348.08, 8034.01, 7896.35, 8092.25, 7771.64,
        7567.51, 6775.69, 6973.35, 6502.74, 6525.1, 6556.27, 5879.17, 6001.53,
        6194.48, 5914.47, 5943.88, 6005.06, 5999.18, 6026.24, 6345.08, 6263.31,
        5971.53, 5927.41, 5965.65, 5854.47, 5923.88, 5668.57, 5762.1, 5760.34,
        5533.86, 5021.48, 5241.49, 5151.48, 5049.12, 5417.97, 4934.41, 4829.69,
        4579.68, 4461.44, 3858.46, 3832.0, 2810.75, 2543.1, 2949.0, 3073.12,
        3396.09, 3197.25, 3326.67, 2993.71, 3021.95, 2925.46, 2957.82, 3089.01,
        2899.0, 2919.0, 2864.28, 2588.97, 2431.91, 2588.97, 2638.39, 2793.69,
        2710.16, 2373.08, 2410.73, 2253.66, 2254.25, 2303.67, 2501.33, 2323.67,
        2294.84, 2350.13, 2217.19, 2036.0, 1841.87, 1598.91, 1678.33, 2007.77,
        1780.69, 1819.52, 1863.05, 2040.71, 2050.71, 2156.01, 2051.88, 2220.13,
        2484.27, 2471.91, 2338.96, 2276.6, 2076.0, 2008.36, 1911.29, 1937.17,
        1970.12, 1958.94, 2146.01, 2117.77, 2165.43, 2253.07, 2266.02, 2246.61,
        2163.66, 1972.46, 1942.47, 2008.36, 2161.89, 2224.25, 2341.91, 2304.85,
        2412.49, 2663.1, 2496.03, 2516.62, 2414.85, 2350.72, 2416.61, 2223.66,
        2113.65, 2344.85, 2547.2, 2514.85, 2597.8, 2501.33, 2433.67, 2422.5,
        2281.9, 2268.96, 2377.2, 2379.55, 2397.79, 2483.68, 2374.84, 2468.38,
        2508.38, 2633.68, 2736.05, 2654.27, 2546.04, 2533.09, 2374.26, 2394.85,
        2129.54, 2064.24, 2151.89, 2050.13, 2086.6, 2083.65, 2075.41, 1900.11,
        1847.17, 1841.28, 1886.58, 1869.53, 1878.93, 1850.11, 1833.63, 1829.52,
        1875.99, 1894.23, 1832.47, 1924.82, 1972.46, 2041.3, 2076.59, 2048.95,
        2193.66, 1964.24, 2006.0, 1943.65, 1887.76, 1976.58, 1840.11, 1932.46,
        1865.99, 1802.46, 1904.82, 1985.41, 1969.53, 1899.52, 1966.59, 1931.88,
        1956.0, 1988.36, 1930.71, 1915.4, 1895.4, 1933.05, 2000.71, 2003.65,
        1961.88, 1947.18, 1911.88, 1924.82, 1886.58, 1840.7, 1831.29, 1801.87,
        1761.28, 1744.81, 1780.69, 1824.23, 1775.4, 1716.57, 1661.27, 1658.33,
        1647.75, 1717.16, 1673.04, 1823.05, 1844.23, 1804.22, 1891.88, 1926.0,
        1935.41, 1918.35, 1904.82, 1797.75, 1817.75, 1993.05, 2173.65, 1954.82,
        1730.69, 1383.02, 836.52, 1057.71, 940.06, 877.11, 804.75, 807.69,
        835.93, 842.4, 805.93, 774.75, 757.69, 724.74, 694.16, 721.81, 830.05,
        858.29, 831.81, 827.11, 847.11, 785.34, 789.46, 817.11, 794.75, 807.1,
        783.57, 802.98, 815.34, 828.87, 797.11, 804.16, 797.7, 798.87, 792.99,
        782.4, 810.64, 835.93, 823.57, 734.16, 731.22, 731.81, 693.57, 722.4,
        707.69, 693.57, 692.39, 671.8, 668.86, 657.69, 649.45, 571.8, 538.86,
        577.68, 585.32
      ],
      XRP: [
        10000.0, 10213.33, 10315.77, 10045.8, 10002.42, 9181.63, 9445.58,
        9180.43, 9082.81, 9141.86, 8848.98, 9257.56, 9610.7, 9258.77, 9369.65,
        9419.07, 9390.15, 9152.71, 9121.37, 8959.87, 8842.95, 7636.49, 7064.0,
        7344.83, 7331.57, 7500.3, 7425.58, 7259.25, 7318.31, 7444.86, 7246.0,
        7337.59, 7572.62, 7262.87, 7295.41, 7720.86, 8039.05, 8096.9, 9948.18,
        10386.89, 10800.29, 10178.38, 9071.96, 9973.49, 9787.88, 9675.79,
        9985.53, 10121.73, 9363.63, 9462.46, 9852.96, 9533.57, 8754.97, 8557.31,
        8498.25, 8184.89, 9080.39, 9046.65, 8692.3, 9384.12, 9484.16, 9269.62,
        9053.88, 8595.88, 9081.6, 8921.3, 8720.02, 8665.79, 9174.4, 8934.56,
        9693.87, 9533.57, 9157.53, 9310.6, 9223.82, 9463.66, 9572.14, 9597.44,
        9824.04, 9752.92, 10078.35, 10116.91, 10063.88, 10169.94, 9930.1,
        10021.69, 10277.21, 10646.02, 10289.26, 10354.35, 9874.65, 9987.95,
        10009.64, 10194.05, 9983.13, 9910.81, 9249.12, 9492.59, 9117.76,
        9162.35, 9116.55, 8453.66, 8525.97, 8726.04, 8722.43, 9399.79, 9387.73,
        9243.11, 9234.67, 9304.57, 9086.42, 8763.41, 8673.01, 8580.21, 8452.46,
        8398.22, 7785.95, 7800.42, 7743.76, 7366.52, 7294.2, 7279.74, 7405.09,
        7278.53, 7781.13, 7118.23, 7280.95, 6929.01, 6880.8, 6074.49, 6088.95,
        4945.16, 4559.48, 5183.8, 5146.44, 5347.72, 5129.57, 5260.94, 4959.63,
        5031.94, 4963.24, 5011.45, 5081.35, 4911.41, 4934.31, 4919.86, 4770.4,
        4640.23, 4637.83, 4678.8, 5021.09, 5123.54, 4788.48, 4881.28, 4710.13,
        4727.01, 4780.04, 4840.3, 4901.78, 4849.94, 4828.25, 4649.87, 4400.39,
        4196.69, 3700.14, 3803.78, 4111.13, 3751.96, 3871.28, 3737.49, 3933.95,
        3888.16, 3999.04, 3923.11, 4023.14, 4424.49, 4410.03, 4342.54, 4270.22,
        4078.58, 3988.19, 3897.79, 3773.65, 3811.02, 3847.18, 3952.03, 3942.39,
        3999.04, 4120.76, 4121.97, 4155.72, 3941.19, 3780.89, 3756.78, 3862.84,
        3970.11, 4059.3, 4244.91, 4208.75, 4337.71, 4508.86, 4353.38, 4383.52,
        4334.1, 4329.28, 4330.48, 4146.08, 4044.83, 4317.22, 4513.68, 4476.32,
        4688.44, 4607.69, 4553.46, 4522.12, 4458.24, 4459.45, 4530.56, 4507.66,
        4508.86, 4552.25, 4431.72, 4582.38, 4569.12, 4558.27, 4547.43, 4536.58,
        4512.48, 4524.53, 4542.61, 4551.04, 4046.04, 4068.94, 4159.34, 4108.71,
        4171.39, 4166.57, 4197.9, 4096.66, 4031.57, 3984.57, 3986.98, 3964.09,
        3973.72, 4003.86, 3986.98, 3966.49, 3980.96, 4001.45, 3892.97, 4071.35,
        4120.76, 4261.78, 4332.89, 4267.81, 4297.94, 4012.3, 4124.39, 3933.95,
        4275.04, 4523.32, 4401.6, 4645.05, 5018.68, 4723.4, 5927.45, 6354.1,
        5869.59, 5922.62, 5615.29, 5406.78, 5417.62, 5769.55, 5758.71, 5700.86,
        5453.78, 5553.82, 5787.63, 5961.19, 5929.85, 6251.65, 6191.4, 6432.45,
        6013.02, 5904.54, 5865.98, 5861.16, 5888.88, 5805.71, 5767.15, 5767.15,
        5632.16, 5483.91, 5418.83, 5526.1, 5611.67, 5650.24, 5499.58, 5542.97,
        5644.21, 5571.9, 5711.7, 5645.41, 5530.92, 5573.1, 5611.67, 5447.75,
        5480.29, 6025.07, 5999.76, 5765.94, 5569.48, 4822.22, 3993.01, 4781.25,
        4607.69, 4414.85, 4095.46, 4489.58, 4686.03, 4545.01, 4625.77, 4610.1,
        4631.8, 4389.54, 4370.26, 4557.07, 4579.97, 4843.93, 4940.34, 4757.14,
        4865.62, 4705.32, 4802.94, 4947.57, 4784.87, 4753.53, 4670.36, 4688.44,
        4694.47, 4684.83, 4614.92, 4752.32, 4692.06, 4655.9, 4583.59, 4661.92,
        4749.91, 4649.87, 4547.43, 4244.91, 4243.7, 4220.8, 4078.58, 4252.14,
        4168.98, 4201.52, 4264.19, 4237.67, 4167.77, 4414.85, 4467.88, 4302.76,
        4114.74, 4146.08, 4106.31
      ],
      ADA: [
        10000.0, 10496.94, 10481.65, 10114.68, 10152.9, 9441.89, 9816.5,
        9250.77, 9258.41, 9036.69, 8646.79, 9029.05, 9954.12, 9480.12, 9824.16,
        9915.9, 10779.82, 11735.46, 11437.3, 10336.39, 9831.8, 8631.49, 8088.68,
        8256.88, 8081.04, 7920.49, 8142.2, 7943.43, 7981.65, 8142.2, 7920.49,
        8027.52, 8218.65, 7905.2, 8042.8, 8555.04, 8646.79, 8577.98, 9204.89,
        8975.53, 9189.6, 8944.95, 8203.36, 8103.97, 8004.58, 8035.16, 8379.2,
        8302.75, 7882.26, 7584.1, 7599.39, 7270.64, 6773.7, 6674.31, 6827.21,
        6383.79, 6911.32, 6842.51, 6498.47, 7240.06, 7370.03, 7209.48, 6918.96,
        6444.95, 6643.73, 6406.72, 6123.85, 6085.62, 6429.66, 6185.01, 6070.33,
        6055.05, 6009.17, 6116.2, 6139.14, 6299.69, 6414.37, 6483.18, 6842.51,
        6773.7, 6934.24, 7438.83, 8218.65, 8769.11, 8417.43, 8723.24, 8952.59,
        9120.79, 9059.63, 9105.5, 8830.28, 8960.24, 8929.65, 9097.85, 9243.11,
        9044.34, 8180.42, 8348.62, 7851.68, 7928.13, 7912.84, 7133.03, 7247.7,
        7438.83, 7102.44, 7285.93, 7301.22, 7155.97, 7140.67, 7270.64, 7186.54,
        6941.89, 6934.24, 6850.15, 6781.34, 6856.26, 6388.38, 6406.72, 6457.19,
        6180.42, 5861.62, 5992.35, 5976.3, 5886.85, 6805.04, 6006.11, 5990.83,
        5772.93, 5718.65, 4857.03, 4779.81, 3967.12, 3532.1, 4129.97, 4108.56,
        4536.7, 4308.1, 4412.08, 3988.53, 4020.64, 3954.89, 4029.05, 4130.74,
        3941.13, 3974.77, 3960.25, 3699.54, 3511.46, 3533.64, 3662.84, 4324.92,
        4892.2, 4160.55, 4474.76, 4307.33, 4328.75, 4372.32, 4662.08, 4713.3,
        4955.65, 4832.57, 4458.71, 4322.63, 3872.32, 3444.95, 3594.8, 4023.7,
        3618.5, 3731.65, 3504.59, 3726.3, 3736.24, 3697.25, 3527.53, 3642.97,
        3834.86, 3802.75, 3776.0, 3740.83, 3594.04, 3585.63, 3413.61, 3434.25,
        3500.0, 3479.36, 3568.81, 3508.41, 3532.87, 3640.67, 3592.5, 3672.79,
        3548.17, 3343.28, 3232.41, 3259.17, 3350.92, 3399.84, 3501.53, 3458.72,
        3684.25, 3947.25, 3767.58, 3790.52, 3711.01, 3926.6, 3932.72, 3732.42,
        3552.75, 3879.96, 3948.77, 4064.98, 4024.47, 3967.12, 3906.73, 3831.81,
        3840.22, 3805.81, 3951.83, 3943.42, 4049.7, 4097.1, 3934.25, 4091.74,
        4056.57, 4123.09, 4272.94, 4357.8, 4182.72, 4274.46, 4084.1, 4026.76,
        3470.18, 3431.19, 3545.87, 3477.83, 3535.17, 3501.53, 3550.46, 3360.09,
        3423.55, 3340.21, 3420.49, 3470.95, 3454.13, 3487.77, 3476.29, 3681.95,
        3812.69, 3802.75, 3573.39, 3648.31, 3657.49, 3788.23, 3943.42, 3864.68,
        3849.38, 3569.57, 3676.61, 3551.22, 3587.16, 3699.54, 3446.48, 3472.47,
        3377.68, 3327.21, 3518.35, 3548.93, 3457.19, 3396.02, 3410.55, 3382.26,
        3347.85, 3328.75, 3306.57, 3288.22, 3238.53, 3262.23, 3325.69, 3298.93,
        3272.17, 3240.82, 3215.59, 3223.24, 3097.09, 3003.82, 2912.84, 2902.14,
        2797.4, 2774.46, 2834.09, 2837.16, 2758.41, 2685.01, 2603.97, 2665.9,
        2682.72, 2758.41, 2754.59, 3087.92, 3084.1, 2981.65, 3108.56, 3172.78,
        3091.74, 3102.45, 3073.4, 2935.02, 2980.88, 3222.48, 3290.52, 3152.9,
        3066.51, 2818.81, 2411.31, 2837.16, 2694.19, 2617.74, 2523.7, 2502.29,
        2574.16, 2533.64, 2493.12, 2483.94, 2518.35, 2388.37, 2326.45, 2387.62,
        2419.72, 2413.61, 2406.72, 2389.14, 2437.31, 2344.04, 2373.85, 2444.18,
        2410.55, 2437.31, 2436.54, 2461.77, 2443.43, 2415.9, 2365.44, 2408.26,
        2379.96, 2386.85, 2344.04, 2339.45, 2380.73, 2357.04, 2285.93, 2027.53,
        2032.87, 2038.99, 1922.78, 1977.06, 1927.37, 1957.95, 1979.36, 1978.59,
        1972.48, 2012.23, 1989.3, 1899.85, 1854.74, 1868.5, 1882.27
      ],
      DOGE: [
        10000.0, 10146.8, 10252.5, 9976.51, 9935.41, 9266.0, 9418.68, 9060.49,
        8960.66, 8878.45, 8379.33, 8925.43, 9418.68, 9806.23, 11015.85,
        10845.57, 10481.5, 9876.69, 9765.12, 9624.19, 9277.75, 8402.82, 7674.69,
        8132.71, 8050.5, 8349.97, 8367.58, 8209.04, 8279.51, 8379.33, 8185.55,
        8308.87, 8361.71, 8091.61, 8038.75, 8549.61, 8672.93, 8896.07, 9806.23,
        9301.23, 9371.69, 9001.76, 8408.69, 8461.54, 9031.12, 8596.6, 8807.99,
        8761.01, 8226.66, 8126.84, 8267.77, 8103.35, 7704.06, 7627.72, 7592.49,
        7105.11, 7522.02, 7480.91, 7251.91, 7751.03, 7862.59, 7833.23, 7598.36,
        7193.18, 7351.73, 7152.08, 6870.23, 6829.12, 7099.24, 6876.11, 6811.51,
        6776.28, 6494.42, 6670.58, 6617.73, 6799.76, 6858.49, 6952.44, 7228.42,
        7022.9, 7017.02, 7204.94, 7610.1, 7997.65, 7657.07, 7915.45, 8379.33,
        8643.57, 8438.05, 8408.69, 8173.81, 8326.48, 8244.28, 8661.19, 8778.63,
        10005.87, 8396.95, 8561.37, 8379.33, 8432.18, 8854.96, 7968.29, 8032.88,
        8238.41, 8367.58, 8578.98, 8432.18, 8332.35, 8226.66, 8332.35, 8297.12,
        7985.91, 7997.65, 7921.32, 7756.9, 9066.36, 8279.51, 8115.09, 8032.88,
        7909.57, 7621.84, 7721.67, 7692.3, 7574.87, 7927.19, 7492.66, 7527.89,
        7375.23, 7328.24, 6382.86, 6306.52, 4973.57, 4756.31, 5273.05, 5249.56,
        5384.62, 5161.48, 5284.79, 4991.19, 5055.79, 4932.47, 4950.09, 5049.91,
        4891.37, 4897.24, 4903.11, 4644.74, 4797.41, 4773.93, 4850.27, 5166.18,
        5073.99, 4754.55, 4859.66, 4719.31, 4788.02, 4803.28, 4824.42, 4721.67,
        4694.07, 4650.61, 4451.56, 4170.87, 3820.9, 3142.1, 3162.65, 3573.69,
        3228.42, 3336.46, 3137.99, 3539.63, 3513.21, 3905.46, 3664.71, 3751.03,
        3943.04, 4003.52, 4357.61, 4266.0, 3859.66, 4130.94, 3764.53, 3867.3,
        3930.71, 3928.36, 4061.07, 3985.32, 4031.12, 4131.53, 4083.97, 4075.16,
        3958.9, 3614.8, 3547.27, 3586.03, 3631.24, 3735.76, 3786.85, 3771.58,
        3904.28, 4068.7, 4100.42, 4077.51, 3978.27, 3988.84, 3973.58, 3765.12,
        3642.99, 3904.28, 4082.21, 4115.09, 4088.67, 4020.55, 4021.14, 3954.78,
        3906.63, 3928.36, 4098.06, 4059.89, 4059.89, 4099.83, 4058.14, 4159.72,
        4162.66, 4226.66, 4274.81, 4764.53, 4485.03, 5069.88, 4699.94, 4522.03,
        4000.59, 4064.01, 4083.38, 3966.53, 4022.32, 3991.19, 4048.74, 3781.56,
        3719.91, 3706.98, 3724.6, 3645.33, 3635.94, 3648.86, 3608.34, 3672.34,
        3684.68, 3677.04, 3479.74, 3588.37, 3578.39, 3719.32, 3801.53, 3721.08,
        3743.39, 3528.48, 3598.94, 3461.53, 3530.83, 3638.28, 3383.45, 3443.34,
        3442.16, 3329.42, 3495.01, 3750.44, 3710.51, 3574.87, 3553.73, 3557.25,
        3554.31, 3548.44, 3652.38, 3563.71, 3501.47, 3534.35, 3874.34, 3790.37,
        3724.6, 3658.25, 3607.17, 3630.65, 3500.88, 3520.85, 3519.08, 3510.86,
        3439.82, 3451.56, 3470.93, 3517.91, 3501.47, 3453.32, 3495.6, 3485.03,
        3501.47, 3536.11, 3496.18, 3670.0, 4233.71, 4517.32, 5080.45, 7011.74,
        6843.22, 7322.96, 8225.49, 7432.76, 7334.11, 7462.12, 7438.06, 6993.54,
        6399.88, 5035.23, 4290.66, 5271.88, 4927.19, 5358.2, 4981.8, 5002.94,
        5076.34, 5028.77, 4992.96, 4952.43, 4965.35, 4557.84, 4362.3, 4602.47,
        4764.53, 4778.04, 5226.66, 5191.42, 6152.09, 5588.96, 6025.25, 6335.87,
        5976.52, 5940.7, 5869.06, 6098.65, 5934.82, 5813.86, 5610.1, 5786.85,
        5654.72, 5667.06, 5436.29, 5268.94, 5328.25, 5207.28, 4965.35, 4502.64,
        4650.61, 4645.33, 4167.94, 4375.8, 4292.42, 4518.5, 4509.69, 4549.62,
        4439.81, 4384.61, 4322.96, 4118.03, 4136.81, 3996.47, 4125.07
      ],
      LINK: [
        10000.0, 10481.81, 11153.25, 11978.48, 12301.38, 12516.66, 13024.09,
        13413.64, 13316.25, 14213.23, 14038.96, 13859.56, 13710.91, 12803.69,
        13039.47, 13024.09, 13141.98, 12142.49, 11916.97, 11225.01, 10589.44,
        9015.89, 8016.4, 8057.4, 7939.52, 7898.51, 7703.74, 7626.86, 8165.05,
        8364.94, 8954.38, 8769.86, 8739.1, 8144.54, 8277.81, 8800.61, 9154.28,
        9000.52, 9733.47, 9410.56, 9579.7, 9108.15, 8226.55, 8231.68, 8144.54,
        8170.17, 8708.35, 8800.61, 8047.15, 7760.12, 7898.51, 7606.36, 7088.67,
        7129.68, 7006.67, 6683.75, 7416.71, 7534.6, 7032.29, 7637.11, 7831.88,
        7898.51, 7647.36, 7052.79, 7211.68, 7052.79, 6509.49, 6647.87, 7088.67,
        6781.13, 6724.76, 6786.27, 6504.35, 6776.01, 7011.79, 7426.96, 7452.59,
        7631.99, 7652.48, 7503.85, 7652.48, 7965.14, 8001.03, 8298.31, 7965.14,
        8067.66, 8549.46, 8605.84, 8631.47, 8810.87, 8744.24, 8841.62, 8990.27,
        9308.05, 8949.26, 8698.1, 7924.14, 8083.03, 7765.25, 7862.63, 7929.26,
        7068.17, 7104.05, 7273.19, 6981.04, 7252.7, 7201.44, 7191.19, 7196.31,
        7262.94, 7191.19, 6950.28, 6981.04, 6878.52, 6806.76, 6878.52, 6396.72,
        6488.98, 6468.48, 6130.19, 5802.15, 5725.27, 5745.77, 5689.39, 6217.32,
        5535.62, 5540.74, 5197.33, 5161.45, 4408.0, 4331.11, 3582.77, 3275.24,
        3741.68, 3731.42, 4013.32, 3844.19, 3931.32, 3582.77, 3628.91, 3531.53,
        3613.53, 3751.92, 3639.16, 3700.67, 3613.53, 3429.01, 3249.62, 3290.62,
        3429.01, 3823.68, 3951.82, 3526.4, 3675.04, 3516.14, 3762.17, 3962.08,
        4028.71, 4464.38, 4510.51, 4710.41, 4187.6, 3700.67, 3295.74, 2911.33,
        3300.87, 3685.29, 3264.99, 3244.5, 3054.84, 3408.51, 3541.77, 3567.4,
        3439.26, 3577.65, 3741.68, 3700.67, 3521.27, 3382.88, 3234.24, 3218.87,
        3131.73, 3116.35, 3198.36, 3167.61, 3259.87, 3275.24, 3264.99, 3377.76,
        3285.5, 3326.5, 3157.36, 3126.61, 3090.73, 3108.15, 3187.6, 3260.38,
        3376.73, 3305.48, 3547.41, 3752.95, 3523.83, 3567.4, 3506.41, 3515.12,
        3625.32, 3400.83, 3230.65, 3491.03, 3735.01, 4145.56, 4014.86, 3925.68,
        3862.12, 3729.88, 3769.86, 3744.24, 4010.25, 4036.39, 4232.19, 4395.19,
        4478.73, 4624.81, 4585.34, 4750.39, 4692.46, 4492.57, 4401.34, 4338.29,
        4108.15, 4084.05, 3549.97, 3521.78, 3640.7, 3552.53, 3701.69, 3674.52,
        3644.28, 3392.62, 3342.39, 3286.01, 3412.61, 3402.36, 3420.3, 3520.76,
        3559.2, 3499.75, 3631.47, 3755.51, 3442.85, 3632.5, 3804.21, 3958.99,
        4077.39, 4069.71, 3962.08, 3631.98, 3844.19, 3844.19, 3895.43, 4138.9,
        3858.02, 3759.61, 3529.99, 3400.83, 3640.7, 3822.65, 3924.65, 4002.05,
        4009.23, 4162.48, 4028.71, 4017.42, 3880.06, 3786.78, 3692.47, 3742.7,
        3969.25, 4046.64, 3924.65, 3891.85, 3787.29, 3882.62, 3880.57, 3659.15,
        3657.1, 3621.74, 3517.17, 3527.43, 3687.86, 3758.07, 3653.0, 3490.51,
        3416.71, 3494.11, 3519.22, 3615.07, 3558.18, 3616.6, 3668.37, 3547.92,
        3654.03, 3850.85, 3983.6, 4008.2, 3926.19, 3788.83, 3950.28, 4451.56,
        4467.45, 4237.31, 4455.66, 3766.78, 3187.6, 3736.03, 3564.33, 3230.14,
        3115.84, 3160.43, 3273.19, 3165.04, 3221.94, 3163.51, 3163.0, 2969.76,
        2984.62, 3258.33, 3435.17, 3512.04, 3501.28, 3645.82, 3632.5, 3683.76,
        3784.22, 3961.56, 3970.27, 3887.24, 3718.61, 3796.51, 3721.17, 3585.34,
        3531.53, 3600.21, 3512.04, 3510.51, 3449.51, 3425.42, 3533.58, 3454.64,
        3298.31, 3029.22, 3086.11, 3071.25, 2957.97, 3073.81, 3057.91, 3077.4,
        3039.47, 3025.11, 3060.49, 3082.52, 3015.89, 2895.95, 2844.7, 2783.7,
        2852.9
      ],
      DOT: [
        10000.0, 10618.9, 11249.06, 11399.1, 10948.99, 10000.0, 10093.77,
        9358.59, 9111.03, 9272.32, 8837.21, 9546.13, 10262.57, 9756.19,
        10348.84, 10502.63, 10401.35, 9598.65, 9463.61, 9111.03, 8844.71,
        7366.84, 6781.7, 6822.96, 6732.93, 6909.23, 6732.93, 6695.43, 6819.2,
        7025.5, 6770.44, 7299.33, 7389.34, 7081.77, 7018.01, 7543.14, 8053.26,
        7936.99, 8435.86, 8147.04, 8259.56, 7880.72, 7014.26, 7096.78, 7044.27,
        7044.27, 7468.11, 7460.62, 6849.21, 6654.16, 6654.16, 6436.61, 6121.53,
        6072.77, 6095.28, 5911.48, 6429.11, 6759.2, 6597.9, 6972.99, 7100.52,
        7025.5, 6744.19, 6286.58, 6507.88, 6496.62, 6129.04, 6316.58, 6657.91,
        6399.1, 6725.43, 6841.71, 6496.62, 6609.15, 6695.43, 7078.02, 7033.01,
        7100.52, 7261.81, 7033.01, 7029.26, 7524.38, 7801.95, 7970.74, 7700.68,
        7809.45, 8274.57, 8402.1, 8274.57, 8465.86, 8079.52, 8240.81, 8668.42,
        8750.94, 8510.88, 8285.82, 7468.11, 7663.17, 7306.83, 7359.34, 7333.09,
        6507.88, 6639.16, 6837.97, 6676.67, 6856.72, 6954.24, 6792.95, 6837.97,
        7066.77, 7201.8, 6845.46, 6882.97, 7096.78, 6849.21, 6815.45, 6327.83,
        6312.82, 6376.59, 6042.76, 5675.17, 5716.43, 5626.41, 5521.38, 6042.76,
        5405.1, 5367.6, 5093.77, 5033.76, 4163.54, 4159.8, 3345.84, 3158.29,
        3998.5, 4302.32, 4384.85, 4054.77, 4133.54, 3660.92, 3724.68, 3638.41,
        3743.43, 3803.45, 3765.95, 3810.95, 3747.18, 3462.11, 3469.62, 3597.15,
        3713.42, 3964.74, 3960.99, 3540.88, 3717.18, 3525.89, 3514.63, 3544.64,
        3548.39, 3458.36, 3394.6, 3458.36, 3270.81, 3072.02, 2839.46, 2546.89,
        2704.43, 3079.52, 2648.16, 2738.19, 2659.42, 2816.96, 2936.98, 2944.49,
        2816.96, 2888.22, 3094.52, 3034.5, 2974.5, 2933.23, 2741.94, 2651.91,
        2550.64, 2535.63, 2565.64, 2558.14, 2674.42, 2591.9, 2606.91, 2715.68,
        2659.42, 2726.93, 2573.14, 2460.61, 2389.35, 2381.84, 2501.88, 2580.65,
        2704.43, 2633.16, 2786.95, 2959.49, 2798.2, 2813.21, 2764.44, 2756.94,
        2779.44, 2595.65, 2516.88, 2753.19, 2963.24, 3128.28, 3102.03, 3289.58,
        3083.28, 3004.51, 3042.01, 3023.26, 3285.82, 3218.3, 3274.57, 3495.88,
        3338.34, 3555.88, 3447.12, 3522.13, 3484.62, 3364.59, 3270.81, 3312.08,
        3135.79, 3132.04, 2749.44, 2708.18, 2790.7, 2734.44, 2861.97, 2854.46,
        2831.96, 2640.66, 2644.41, 2621.9, 2711.93, 2648.16, 2659.42, 2689.43,
        2738.19, 2726.93, 2738.19, 2753.19, 2599.4, 2708.18, 2771.94, 2906.97,
        2918.23, 2869.47, 2869.47, 2659.42, 2708.18, 2595.65, 2576.9, 2629.41,
        2434.36, 2404.35, 2351.83, 2280.57, 2423.11, 2423.11, 2351.83, 2318.08,
        2445.61, 2393.1, 2396.85, 2393.1, 2359.34, 2355.59, 2336.83, 2389.35,
        2430.6, 2408.1, 2374.35, 2374.35, 2363.09, 2419.36, 2359.34, 2321.84,
        2310.58, 2303.07, 2261.82, 2273.06, 2325.58, 2344.34, 2303.07, 2303.07,
        2205.55, 2190.55, 2194.29, 2231.81, 2228.06, 2408.1, 2426.86, 2378.09,
        2449.37, 2475.62, 2479.37, 2490.62, 2434.36, 2325.58, 2419.36, 2651.91,
        2644.41, 2591.9, 2663.17, 2340.59, 2029.26, 2246.81, 2160.54, 2190.55,
        2160.54, 2183.05, 2213.06, 2145.53, 2141.78, 2108.03, 2100.53, 1980.5,
        1946.74, 1976.75, 2021.76, 2014.25, 1976.75, 1980.5, 2021.76, 1935.48,
        1999.25, 2055.52, 2021.76, 2111.78, 2066.76, 2089.27, 2055.52, 2040.51,
        1984.24, 2033.01, 1984.24, 1973.0, 1931.73, 1931.73, 1976.75, 1956.87,
        1959.49, 1763.31, 1765.94, 1749.81, 1677.79, 1726.93, 1672.92, 1682.67,
        1675.92, 1666.54, 1677.04, 1701.8, 1680.42, 1600.9, 1606.53, 1616.65,
        1620.4
      ],
      AVAX: [
        10000.0, 10368.27, 10373.75, 9904.05, 9565.01, 8748.06, 8748.97,
        7912.82, 7744.67, 8177.83, 7667.92, 8137.62, 8653.93, 8217.13, 8250.94,
        8544.27, 8462.03, 7901.86, 7893.63, 7652.38, 7427.58, 6010.23, 5405.28,
        5641.96, 5742.48, 6119.89, 5988.3, 5820.15, 6052.27, 6541.17, 6211.28,
        6367.54, 6622.5, 6281.64, 6198.48, 6925.88, 7090.38, 6930.45, 7636.85,
        7847.93, 8237.23, 8398.97, 7349.91, 7461.39, 7194.55, 7456.82, 8271.04,
        8777.3, 8144.94, 7609.43, 7689.85, 7179.02, 6564.93, 6634.37, 6921.32,
        6802.52, 7360.87, 7479.66, 6873.8, 7617.66, 7872.62, 7647.81, 7243.9,
        6924.06, 7083.07, 6739.47, 6576.81, 6622.5, 7025.5, 6756.83, 6566.75,
        6520.15, 6178.38, 6175.64, 6417.8, 6663.62, 7289.59, 7824.17, 8217.13,
        7915.56, 7928.36, 7815.04, 7814.12, 7954.86, 7594.81, 7821.43, 8051.72,
        8403.54, 8383.44, 8750.8, 8827.56, 8855.89, 8899.75, 9050.54, 8715.16,
        8410.85, 7693.5, 8014.25, 7636.85, 7641.42, 7573.79, 6807.09, 6896.64,
        7313.35, 7005.39, 7052.0, 7093.12, 7020.02, 7102.26, 7274.05, 7194.55,
        6871.06, 6793.38, 6725.76, 6585.94, 6625.24, 6289.86, 6341.95, 6142.74,
        5793.65, 5206.06, 5320.3, 5503.97, 5450.06, 6074.2, 5363.25, 5212.46,
        4920.95, 4740.02, 4023.58, 4011.7, 2807.28, 2686.64, 3032.99, 3102.44,
        3291.61, 3045.79, 3121.63, 2785.34, 2687.56, 2652.83, 2706.75, 2918.76,
        2665.63, 2648.27, 2527.64, 2194.1, 2078.04, 2253.5, 2392.39, 2526.73,
        2428.95, 2184.96, 2247.1, 2110.93, 2206.89, 2250.75, 2357.67, 2254.41,
        2230.65, 2227.0, 2059.76, 1860.55, 1629.35, 1420.09, 1452.98, 1633.01,
        1442.02, 1466.69, 1365.26, 1477.65, 1537.97, 1567.21, 1504.15, 1654.94,
        1887.05, 1941.88, 1833.13, 1782.87, 1678.7, 1635.75, 1497.76, 1474.91,
        1507.81, 1511.47, 1637.58, 1669.56, 1745.41, 1834.04, 1824.91, 1825.82,
        1749.98, 1596.46, 1567.21, 1671.39, 1791.1, 1800.23, 1947.37, 1911.73,
        2124.64, 2268.12, 2169.43, 2274.51, 2183.13, 2163.03, 2180.39, 1964.73,
        1864.21, 2130.12, 2222.43, 2275.43, 2224.25, 2168.51, 2158.46, 2122.81,
        2136.52, 2125.55, 2264.46, 2425.29, 2543.18, 2575.16, 2511.2, 2642.78,
        2619.02, 2663.8, 2654.66, 2581.56, 2501.14, 2482.87, 2324.77, 2295.53,
        2067.07, 2022.3, 2098.14, 2026.87, 2142.01, 2109.11, 2099.97, 1917.21,
        1832.22, 1707.03, 1754.54, 1792.92, 1767.34, 1761.85, 1725.3, 1724.39,
        1725.3, 1731.7, 1679.61, 1737.18, 1739.92, 1856.89, 1887.97, 1879.74,
        1945.54, 1735.35, 1743.58, 1667.73, 1649.46, 1692.41, 1543.45, 1581.84,
        1531.57, 1503.24, 1617.48, 1642.15, 1613.82, 1584.58, 1585.49, 1582.75,
        1577.27, 1569.95, 1565.38, 1556.24, 1527.91, 1554.41, 1589.15, 1586.41,
        1557.15, 1544.36, 1529.74, 1544.36, 1486.79, 1461.21, 1460.29, 1438.36,
        1429.22, 1415.52, 1440.19, 1474.91, 1449.33, 1389.93, 1392.67, 1437.45,
        1431.97, 1485.88, 1443.84, 1517.86, 1558.07, 1551.67, 1674.13, 1664.99,
        1664.99, 1760.03, 1709.77, 1626.61, 1650.37, 1768.25, 1810.29, 1710.68,
        1641.23, 1436.53, 1172.44, 1414.6, 1276.61, 1214.47, 1187.06, 1187.97,
        1231.83, 1196.2, 1188.89, 1182.49, 1178.83, 1115.78, 1079.23, 1125.83,
        1173.35, 1182.49, 1172.44, 1158.73, 1203.51, 1141.37, 1146.85, 1198.02,
        1177.92, 1240.97, 1235.49, 1261.08, 1240.97, 1234.58, 1208.99, 1224.52,
        1226.35, 1230.01, 1200.77, 1187.06, 1254.68, 1255.59, 1198.94, 1096.59,
        1088.37, 1091.11, 1036.28, 1092.02, 1063.7, 1078.32, 1073.75, 1066.44,
        1065.52, 1060.95, 1061.87, 1020.75, 996.07, 988.76, 994.24
      ],
      LTC: [
        10000.0, 10252.91, 10328.1, 10123.03, 10061.52, 9227.61, 9357.49,
        8933.7, 8926.87, 9008.89, 8626.11, 8981.54, 9637.73, 9316.47, 9911.14,
        10102.53, 10020.51, 10375.94, 9767.6, 9425.83, 9125.09, 7778.54,
        7354.75, 7409.43, 7436.78, 7382.09, 7265.89, 7238.56, 7409.43, 7580.31,
        7375.26, 7491.45, 7860.57, 7429.94, 7498.29, 8168.15, 8352.7, 8489.41,
        9371.16, 9145.59, 9583.05, 9336.98, 8571.43, 8660.29, 8646.62, 8516.75,
        8872.18, 8803.83, 8004.1, 7867.4, 7887.9, 7655.5, 7259.06, 7245.39,
        7341.08, 7019.82, 7525.64, 7388.93, 7060.83, 7648.67, 7662.34, 7559.81,
        7587.16, 6951.47, 7183.87, 7074.51, 6739.58, 6821.6, 7293.23, 7040.33,
        7163.37, 7259.06, 6978.82, 7170.2, 7306.9, 7552.97, 7539.3, 7621.33,
        7737.52, 7928.91, 7942.59, 8352.7, 8325.36, 8632.94, 8441.56, 8530.42,
        8769.65, 8851.68, 8831.17, 8974.71, 8516.75, 8564.6, 8557.76, 8783.32,
        8537.25, 8489.41, 7764.87, 7799.05, 7607.66, 7676.0, 7614.49, 7074.51,
        7129.19, 7559.81, 7341.08, 7587.16, 7710.19, 7566.64, 7614.49, 7771.71,
        7669.17, 7313.74, 7224.88, 7252.23, 7190.71, 7149.7, 6760.08, 6848.94,
        7047.16, 6869.44, 6739.58, 6725.91, 6896.79, 6780.59, 7218.04, 6575.53,
        6623.38, 6390.98, 6473.0, 5454.54, 5420.37, 4572.8, 4292.55, 4695.84,
        4723.17, 4818.87, 4620.65, 4955.58, 4620.65, 4859.87, 4695.84, 4764.18,
        4907.73, 4757.35, 4812.03, 4695.84, 4408.75, 4244.7, 4278.88, 4354.07,
        4730.01, 4716.34, 4278.88, 4395.08, 4299.39, 4360.91, 4340.39, 4388.24,
        4347.24, 4237.87, 4114.83, 3896.1, 3622.69, 3349.28, 2898.15, 3082.71,
        3439.51, 3046.48, 3252.22, 3154.47, 3702.67, 3591.93, 3696.51, 3613.12,
        3843.47, 3831.17, 4016.41, 3922.76, 3840.06, 3628.16, 3670.54, 3544.09,
        3496.93, 3457.28, 3472.32, 3552.98, 3445.66, 3445.66, 3528.36, 3536.57,
        3648.67, 3580.32, 3327.41, 3263.16, 3326.04, 3486.67, 3557.76, 3740.94,
        3881.75, 3948.05, 4000.68, 3921.39, 3958.99, 3850.99, 3865.35, 4000.0,
        3805.2, 3655.51, 4008.21, 4365.01, 4219.42, 4158.57, 4105.94, 4033.49,
        4014.35, 3973.35, 4058.1, 4261.11, 4198.9, 4183.19, 4284.35, 4045.8,
        4215.32, 4226.93, 4273.41, 4353.38, 4313.05, 4113.47, 4195.49, 4108.68,
        4162.0, 3679.42, 3699.25, 3795.63, 3872.18, 3898.16, 3866.72, 3891.32,
        3676.69, 3623.38, 3745.73, 3789.47, 3657.55, 3714.97, 3897.47, 4190.7,
        4102.53, 4095.01, 4132.6, 3741.63, 3963.78, 3980.18, 4168.15, 4358.17,
        4244.02, 4172.93, 4034.18, 4112.78, 3850.3, 3799.04, 3934.38, 3645.25,
        3593.99, 3589.2, 3467.53, 3652.08, 3743.68, 3669.18, 3611.76, 3635.68,
        3606.98, 3639.78, 3656.87, 3644.57, 3619.96, 3595.35, 3676.69, 3783.33,
        3728.64, 3656.18, 3618.59, 3596.03, 3655.51, 3606.98, 3555.71, 3562.55,
        3518.8, 3511.96, 3453.86, 3529.73, 3537.93, 3539.3, 3483.26, 3498.97,
        3529.05, 3566.65, 3669.85, 3600.14, 3813.4, 3852.36, 3747.1, 3760.77,
        3850.99, 3779.23, 3764.18, 3770.34, 4068.35, 4224.2, 4639.09, 4803.83,
        4738.21, 4613.12, 3847.57, 3388.93, 4133.29, 4177.03, 4109.37, 3924.82,
        3819.55, 3965.14, 3915.25, 4250.86, 4278.88, 4374.57, 4231.03, 4185.92,
        4766.91, 5444.3, 5327.41, 5029.39, 5210.53, 5209.84, 4999.32, 5237.87,
        5398.5, 5320.58, 5296.65, 5213.94, 5243.33, 5498.3, 5369.11, 5267.26,
        5330.15, 5227.62, 5213.94, 5249.49, 5209.84, 5287.08, 5149.01, 4956.26,
        4495.56, 4421.74, 4414.21, 4269.31, 4484.63, 4466.85, 4503.07, 4479.84,
        4486.67, 4693.09, 4788.11, 4680.79, 4511.96, 4548.19, 4642.52, 4790.84
      ],
      SHIB: [
        10000.0, 10194.79, 10188.79, 9892.11, 9790.23, 9127.96, 9265.81,
        8684.45, 8345.82, 8537.61, 7992.21, 8279.89, 9640.4, 8942.16, 9313.76,
        9235.84, 9148.94, 8606.53, 8483.67, 8261.92, 8091.1, 6703.63, 5996.4,
        6508.84, 6290.09, 6308.06, 6302.07, 6382.98, 6314.05, 6490.86, 6308.06,
        6406.95, 6514.83, 6194.18, 6170.22, 6574.77, 6820.5, 8136.05, 9799.22,
        9223.85, 9877.13, 9415.64, 8468.69, 8762.36, 9103.98, 8882.23, 9274.8,
        9355.71, 8522.63, 8390.78, 8190.0, 7737.49, 7410.85, 7347.92, 7644.59,
        6973.33, 7434.82, 7359.91, 7051.24, 7596.65, 8121.06, 7815.41, 7497.75,
        7045.25, 7407.86, 7147.14, 6877.44, 6811.51, 7114.17, 6802.51, 6667.66,
        6676.66, 6409.94, 6568.78, 6508.84, 6679.66, 6673.65, 6670.66, 7186.09,
        6919.39, 6886.43, 7087.21, 7311.96, 7389.87, 7189.09, 7284.99, 7572.67,
        8225.95, 8031.16, 8213.97, 7824.39, 7845.37, 7833.38, 8058.14, 7953.25,
        8055.13, 7350.92, 7431.83, 7120.17, 7305.96, 7344.92, 6664.67, 7800.42,
        7995.21, 7701.53, 7749.48, 7692.54, 7668.57, 7539.71, 7569.67, 7455.8,
        7252.02, 7273.0, 7290.98, 7144.15, 7299.97, 6934.37, 6931.37, 6877.44,
        6628.71, 6320.05, 6373.99, 6176.21, 6077.31, 6436.92, 6008.39, 6002.39,
        5678.75, 5549.89, 4486.06, 4788.73, 3635.0, 3179.5, 3901.7, 3733.9,
        3862.75, 3617.02, 3757.87, 3515.13, 3512.13, 3482.17, 3515.13, 3563.08,
        3527.12, 3521.13, 3497.15, 3218.46, 3146.54, 3212.47, 3440.21, 3673.96,
        3542.11, 3266.41, 3362.3, 3245.43, 3248.42, 3251.42, 3290.38, 3224.45,
        3194.49, 3164.52, 3062.64, 2846.87, 2625.11, 2382.38, 2403.36, 2592.14,
        2379.38, 2445.31, 2319.45, 2454.3, 2421.34, 3185.49, 2951.75, 3044.65,
        3287.38, 3497.15, 3392.27, 3320.34, 2999.7, 3098.59, 3011.69, 2993.71,
        3029.67, 3002.7, 3119.56, 3146.54, 3137.55, 3410.25, 3371.29, 3476.18,
        3365.3, 3092.6, 3017.68, 3095.59, 3161.52, 3275.4, 3302.37, 3260.42,
        3497.15, 3685.95, 3590.06, 3593.05, 3530.12, 3476.18, 3497.15, 3296.37,
        3218.46, 3422.24, 3575.06, 3557.09, 3581.06, 3533.12, 3581.06, 3563.08,
        3548.1, 3530.12, 3623.01, 3608.03, 3608.03, 3673.96, 3614.03, 3694.93,
        3685.95, 3784.84, 3778.84, 5028.46, 4665.87, 4749.77, 4432.12, 4201.38,
        3958.64, 3853.77, 4072.52, 3892.72, 3967.63, 3922.69, 4135.45, 3802.82,
        3629.01, 3635.0, 3730.9, 3679.95, 3646.98, 3688.94, 3643.99, 3685.95,
        3808.81, 3742.88, 3587.06, 3673.96, 3661.98, 3868.75, 3982.62, 3874.74,
        3841.77, 3638.0, 3655.98, 3509.14, 3467.19, 3566.08, 3206.48, 3302.37,
        3176.5, 3080.61, 3251.42, 3329.34, 3386.27, 3296.37, 3326.35, 3326.35,
        3335.33, 3317.35, 3404.26, 3344.32, 3302.37, 3323.35, 3470.19, 3425.23,
        3344.32, 3323.35, 3287.38, 3296.37, 3104.58, 3074.62, 3047.64, 3146.54,
        3035.66, 3062.64, 3092.6, 3104.58, 3041.65, 2960.74, 2981.72, 3008.69,
        2999.7, 3038.66, 2981.72, 3047.64, 3257.42, 3200.48, 3554.09, 3814.8,
        3563.08, 3733.9, 3853.77, 3509.14, 3527.12, 3742.88, 3781.85, 3670.96,
        3503.14, 3002.7, 2604.14, 3074.62, 2945.76, 2891.82, 2715.01, 2691.04,
        2771.95, 2741.99, 2727.0, 2718.01, 2741.99, 2631.11, 2526.22, 2634.1,
        2688.04, 2712.01, 2756.97, 2688.04, 2849.86, 2724.0, 2741.99, 2801.92,
        2756.97, 2792.92, 2756.97, 2795.93, 2819.9, 2765.96, 2724.0, 2777.94,
        2756.97, 2747.98, 2730.0, 2679.06, 2744.98, 2694.04, 2643.09, 2457.29,
        2472.28, 2574.17, 2442.31, 2520.22, 2463.29, 2490.26, 2481.27, 2490.26,
        2478.28, 2478.28, 2451.3, 2361.4, 2457.29, 2406.36, 2424.33
      ],
      POL: [
        10000.0, 10110.94, 10031.7, 9607.77, 9457.21, 8613.31, 8989.7, 8177.5,
        7987.32, 8383.52, 8129.96, 9278.92, 9354.21, 9005.55, 9274.96, 9350.24,
        9195.72, 8783.68, 8545.96, 8169.58, 7991.29, 6683.84, 5990.49, 6125.2,
        5907.29, 6152.93, 6164.82, 6212.37, 6596.68, 6727.41, 6319.34, 6505.55,
        6513.47, 6129.16, 6077.66, 6616.48, 6790.81, 6707.61, 7971.48, 7662.44,
        8058.63, 7626.78, 6695.73, 6763.07, 6664.03, 6584.78, 7218.7, 7313.79,
        6667.99, 6303.49, 6398.58, 6105.39, 5693.35, 5685.43, 5867.67, 5570.52,
        6168.78, 6030.11, 5800.32, 6271.79, 6378.76, 6525.36, 6271.79, 5855.78,
        5990.49, 5824.09, 5625.99, 5693.35, 5907.29, 5740.89, 5578.45, 5566.56,
        5408.09, 5400.16, 5495.25, 5717.12, 5768.62, 5891.44, 6022.19, 5867.67,
        5816.16, 5982.57, 6133.12, 6410.46, 6240.09, 6362.92, 6652.14, 6683.84,
        6572.9, 6707.61, 6461.97, 6723.45, 6656.1, 6703.65, 6545.17, 6366.88,
        5824.09, 5982.57, 5713.15, 5764.66, 5709.19, 5305.08, 5475.44, 5625.99,
        5427.89, 5519.02, 5499.21, 5396.19, 5629.95, 5637.88, 5649.77, 5455.63,
        5522.98, 5455.63, 5328.84, 5348.65, 4964.34, 4948.5, 4889.07, 4524.56,
        4259.12, 4322.51, 4271.0, 4263.08, 4615.69, 4156.1, 4148.18, 3981.78,
        3890.65, 3359.75, 3490.49, 2634.7, 2341.52, 2725.83, 2690.18, 2892.24,
        2717.91, 2872.42, 2559.43, 2563.39, 2519.81, 2575.28, 2674.32, 2575.28,
        2626.78, 2587.16, 2400.95, 2305.86, 2309.83, 2369.26, 2603.01, 2638.66,
        2373.22, 2444.53, 2341.52, 2353.4, 2381.14, 2488.11, 2440.57, 2420.76,
        2503.97, 2389.06, 2179.08, 2024.57, 1664.03, 1600.64, 1687.79, 1509.51,
        1576.86, 1378.76, 1509.51, 1572.9, 1691.76, 1834.39, 2202.85, 2396.99,
        2357.37, 2278.13, 2131.54, 2011.89, 1986.92, 1805.08, 1833.99, 1940.97,
        1824.09, 1931.46, 2059.03, 2060.61, 2209.98, 2408.88, 2333.6, 2281.3,
        2228.6, 2169.18, 2378.37, 2737.72, 2837.56, 2878.37, 3007.92, 3698.89,
        3684.62, 3304.68, 3541.6, 3421.15, 3429.09, 3516.65, 3202.46, 3036.84,
        3502.78, 3754.36, 3772.59, 3710.78, 3666.0, 3547.94, 3529.32, 3515.06,
        3521.4, 3663.63, 3632.33, 3615.3, 3641.45, 3525.36, 3689.39, 3643.42,
        3673.94, 4025.36, 3965.92, 3758.32, 3702.85, 3490.49, 3529.71, 3088.35,
        3093.11, 3244.45, 3164.42, 3275.76, 3232.17, 3233.75, 3101.43, 3175.91,
        3195.72, 3215.92, 3257.93, 3329.63, 3485.34, 3452.46, 3513.47, 3522.98,
        3485.34, 3283.28, 3332.01, 3332.01, 3528.53, 3594.3, 3519.41, 3665.61,
        3368.07, 3410.86, 3292.0, 3193.34, 3268.23, 3006.73, 3045.17, 2934.62,
        2770.61, 2982.17, 3080.43, 2995.25, 2928.29, 2972.27, 2943.35, 2964.35,
        2997.63, 3062.99, 3042.4, 3066.56, 3160.46, 3297.15, 3396.2, 3307.45,
        3293.59, 3211.96, 3243.66, 3269.42, 3142.23, 3117.67, 3087.95, 3109.35,
        3137.09, 3185.03, 3343.9, 3434.63, 3396.6, 3211.17, 3290.02, 3303.88,
        3528.53, 3562.99, 3632.33, 3729.01, 3587.56, 3730.98, 3688.6, 3600.23,
        3580.03, 3481.78, 3380.74, 3781.7, 4676.31, 4739.31, 4672.75, 4898.98,
        4087.56, 3174.72, 4396.59, 4141.83, 3775.75, 3505.55, 3579.64, 3706.41,
        3556.26, 3497.23, 3473.46, 3477.02, 3212.36, 3137.09, 3360.15, 3376.39,
        3371.63, 3337.96, 3339.94, 3411.65, 3256.34, 3343.11, 3685.82, 3610.54,
        3702.06, 3585.18, 3641.05, 3599.84, 3595.88, 3528.13, 3674.73, 3605.0,
        3602.22, 3535.27, 3605.39, 3635.5, 3585.18, 3467.91, 3173.54, 3220.69,
        3226.23, 3048.33, 3169.97, 3127.18, 3133.52, 3160.06, 3154.52, 3139.46,
        3210.78, 3192.95, 3090.33, 3062.6, 3003.96, 2997.23
      ],
      UNI: [
        10000.0, 10158.83, 10735.3, 10694.11, 10858.82, 10158.83, 10064.71,
        9270.59, 8894.11, 9323.53, 8870.58, 9358.82, 9517.65, 9123.53, 9317.65,
        9735.29, 10500.0, 9711.76, 9511.76, 9058.83, 8741.17, 7441.17, 6476.47,
        6558.83, 6229.42, 6182.36, 6170.59, 5964.71, 6182.36, 6564.7, 6305.88,
        6882.35, 6558.83, 6200.0, 6058.82, 6435.29, 6676.47, 6617.65, 7270.59,
        6964.71, 7252.94, 6858.82, 6147.06, 6264.71, 6129.41, 6252.95, 6647.06,
        6670.58, 6170.59, 5929.41, 5870.59, 5588.24, 5200.0, 5152.95, 5317.65,
        5070.59, 5523.53, 5347.06, 5758.82, 6100.0, 6052.95, 5941.18, 5629.41,
        5188.24, 5305.89, 5123.53, 4905.88, 5152.95, 5358.82, 5129.42, 5058.83,
        5017.65, 4811.76, 4941.17, 5100.0, 5311.76, 5400.0, 5629.41, 5652.94,
        5488.23, 5464.7, 5770.58, 5811.77, 6300.0, 6223.53, 6188.23, 6652.94,
        6570.59, 6541.17, 6888.24, 6682.35, 6852.94, 6947.06, 6982.35, 6764.7,
        6600.0, 5947.05, 6135.3, 5735.29, 5870.59, 5852.94, 5317.65, 5429.41,
        5682.35, 5570.59, 5729.41, 5670.59, 5564.71, 5517.65, 5576.47, 5529.41,
        5194.12, 5217.65, 5305.89, 5152.95, 5123.53, 4770.59, 4800.0, 4782.35,
        4476.47, 4317.65, 4123.53, 4082.35, 4017.65, 4647.06, 4229.42, 4405.89,
        4158.83, 4241.18, 3652.94, 3523.53, 2870.58, 2694.11, 3023.53, 3029.41,
        3158.82, 2988.24, 3194.12, 2935.29, 3000.01, 2988.24, 3070.59, 3223.53,
        3317.65, 3282.36, 3329.42, 2952.94, 2770.59, 2911.77, 2888.24, 3317.65,
        3358.82, 3023.53, 3105.89, 3011.77, 3005.88, 3011.77, 3117.65, 3076.47,
        3005.88, 3047.06, 2858.82, 2623.53, 2464.71, 2076.48, 2176.47, 2552.94,
        2229.42, 2305.88, 2129.41, 2441.18, 2464.71, 2835.29, 3000.01, 3252.94,
        3247.06, 3335.29, 3376.47, 3164.71, 2858.82, 3011.77, 2829.41, 2864.71,
        2911.77, 2888.24, 3029.41, 3170.59, 3164.71, 3370.59, 3435.3, 3805.88,
        3735.29, 3264.7, 3305.89, 3511.76, 4064.71, 4052.95, 4329.41, 4176.47,
        4211.76, 4382.36, 4052.95, 4229.42, 4135.3, 4105.88, 4164.7, 4341.17,
        3847.06, 4717.64, 5470.59, 5364.71, 5076.47, 4988.24, 4852.94, 4941.17,
        5235.3, 5141.18, 5235.3, 5247.06, 5205.88, 5176.48, 4952.94, 5388.24,
        5247.06, 5352.94, 5282.36, 5235.3, 4935.29, 4888.24, 4658.82, 4605.88,
        4070.59, 4129.41, 4282.35, 4035.29, 4170.59, 4176.47, 4123.53, 3705.88,
        3558.82, 3458.83, 3700.0, 3664.7, 3647.06, 3711.76, 3764.71, 3700.0,
        3752.94, 3864.7, 3458.83, 3617.64, 3635.3, 3805.88, 3923.52, 3800.0,
        3900.0, 3576.47, 3641.17, 3470.59, 3411.77, 3517.65, 3252.94, 3229.41,
        3147.06, 3105.89, 3376.47, 3494.12, 3417.64, 3335.29, 3447.06, 3776.47,
        3852.94, 3717.65, 3741.18, 3788.24, 3711.76, 3900.0, 3917.65, 3994.12,
        4041.18, 3988.24, 3817.64, 3876.47, 3729.41, 3535.29, 3564.71, 3594.11,
        3647.06, 3552.94, 3611.77, 3758.82, 3876.47, 3900.0, 3570.59, 3547.06,
        3582.35, 3688.23, 3629.41, 3858.82, 3947.05, 4047.06, 4041.18, 4088.24,
        4070.59, 4082.35, 4205.89, 4135.3, 4135.3, 4429.41, 4517.64, 4270.59,
        4111.77, 3488.23, 2858.82, 3394.12, 3517.65, 3282.36, 3429.41, 3517.65,
        3688.23, 3517.65, 3405.88, 3417.64, 3405.88, 3194.12, 3035.3, 3117.65,
        3229.41, 3200.0, 3182.35, 3170.59, 3264.7, 3111.76, 3223.53, 3452.94,
        3470.59, 3747.05, 3594.11, 3670.59, 3635.3, 3576.47, 3535.29, 3617.64,
        3605.88, 3576.47, 3494.12, 3482.36, 3570.59, 3501.77, 3405.88, 3156.47,
        3172.94, 3147.06, 2986.47, 3135.29, 3098.24, 3138.23, 3084.71, 3052.94,
        3027.06, 3043.52, 3032.35, 2923.53, 2961.17, 2990.0, 3035.3
      ],
      USDT: [
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0
      ],
      HODLIE: [
        10000.0, 10097.35, 10101.75, 10152.92, 10222.42, 10278.39, 10378.39,
        10446.21, 10425.71, 10601.17, 10578.18, 10554.62, 10509.58, 10351.89,
        10530.24, 10502.42, 10478.25, 10728.97, 10652.43, 10357.06, 10323.25,
        10316.13, 10312.53, 10314.73, 10314.27, 10273.68, 10087.23, 9957.4,
        10155.46, 10208.98, 10205.29, 10160.47, 10435.42, 10152.75, 10134.02,
        10494.52, 10607.29, 10555.39, 10791.82, 10640.2, 10661.21, 10588.9,
        10234.14, 10271.36, 10270.83, 10271.2, 10414.08, 10460.56, 10264.91,
        10193.87, 10194.0, 10192.27, 10161.23, 10162.2, 10006.46, 9895.74,
        10092.31, 9939.02, 9674.64, 9766.7, 9907.98, 9898.6, 9723.47, 9679.68,
        9680.88, 9680.3, 9612.85, 9597.37, 9569.39, 9280.33, 9309.1, 9245.11,
        9154.94, 9192.12, 9074.42, 9168.69, 9264.27, 9445.72, 9540.13, 9456.85,
        9465.69, 9605.76, 9905.46, 10104.8, 10002.95, 10119.96, 10323.02,
        10424.11, 10457.04, 10462.93, 10568.99, 10897.07, 10834.59, 10926.68,
        10923.06, 11142.34, 10785.64, 10789.3, 10787.46, 10787.86, 10776.67,
        10625.78, 10720.12, 10779.28, 10699.16, 10721.08, 10717.57, 10677.11,
        10581.67, 10587.17, 10525.61, 10376.8, 10250.95, 10252.65, 10252.2,
        10467.77, 10297.25, 10262.33, 10289.59, 10224.51, 10147.14, 10098.26,
        10098.31, 9968.8, 10155.85, 9781.56, 9696.33, 9537.4, 9565.2, 9558.55,
        9559.14, 9552.79, 9551.82, 9434.19, 9625.3, 9812.16, 9630.3, 9756.78,
        9432.59, 9442.11, 9291.65, 9330.95, 9395.98, 9286.35, 9255.58, 9206.34,
        8960.75, 8907.29, 8908.07, 8915.29, 9036.73, 8932.42, 8545.51, 8721.07,
        8593.24, 8593.84, 8564.91, 8508.71, 8520.48, 8472.83, 8440.82, 8209.63,
        8175.58, 8173.33, 8167.89, 8167.66, 8403.66, 8076.33, 8025.26, 8084.57,
        8252.04, 8310.18, 8451.26, 8312.51, 8747.56, 8940.77, 8926.35, 8848.06,
        8780.53, 8614.13, 8620.87, 8619.27, 8619.9, 8637.16, 8492.58, 8685.11,
        8720.71, 8866.0, 9009.18, 9014.71, 8912.99, 8872.2, 8621.91, 8625.75,
        8626.53, 8800.91, 8929.74, 8854.16, 8869.77, 9360.86, 9514.23, 9405.87,
        9373.6, 9317.74, 9282.85, 9290.75, 9092.37, 8967.16, 8971.54, 9267.77,
        9309.02, 9194.36, 9135.47, 9033.78, 8998.09, 8905.0, 8937.23, 8983.33,
        8988.29, 9074.95, 9113.62, 9056.86, 9121.22, 9172.24, 9312.66, 9424.21,
        9603.86, 9467.16, 9497.49, 9452.32, 9356.66, 9299.42, 9299.67, 9302.09,
        9281.71, 9270.22, 9255.12, 9348.49, 9080.96, 9074.7, 9074.79, 9075.52,
        8981.71, 9049.78, 9100.66, 9061.46, 9028.57, 8997.43, 8987.96, 8759.46,
        8580.66, 8582.54, 8811.59, 8942.93, 8913.0, 9050.62, 8749.0, 8716.74,
        8715.18, 8715.7, 8831.51, 8770.89, 8894.33, 9068.33, 8932.13, 9468.19,
        9636.32, 9447.84, 9466.86, 9451.73, 9301.84, 9341.74, 9277.08, 9276.88,
        9253.12, 9154.84, 9210.3, 9307.35, 9275.72, 9283.6, 9404.29, 9381.07,
        9470.96, 9315.88, 9291.37, 9291.36, 9291.59, 9229.23, 9145.12, 9138.74,
        9250.7, 9313.45, 9289.87, 9112.14, 9116.54, 9109.14, 9167.46, 9125.71,
        9171.23, 9498.39, 9345.87, 9390.35, 10051.41, 10019.61, 10156.84,
        10402.1, 10290.8, 10378.48, 10633.71, 10670.15, 10635.23, 10760.15,
        10176.89, 10273.58, 10280.76, 10277.53, 10210.82, 10081.11, 10128.59,
        10237.96, 10156.54, 10183.75, 10163.27, 10210.91, 10141.28, 10143.57,
        10146.21, 10428.64, 10464.24, 10348.83, 10334.26, 10715.84, 10517.63,
        10681.61, 10858.51, 10768.85, 10822.68, 10704.04, 10724.47, 10703.54,
        10690.06, 10579.21, 10522.33, 10451.62, 10461.52, 10433.77, 10430.06,
        10601.26, 10567.33, 10496.81, 10111.39, 10052.7, 10054.65, 10052.05,
        10140.65, 10115.81, 10141.19, 10143.64, 10160.41, 10132.07, 10165.55,
        10118.21, 10055.84, 9955.07, 9930.8, 9917.08
      ]
    },
    2023: {
      TIMESTAMP: [
        '2023-01-01',
        '2023-01-02',
        '2023-01-03',
        '2023-01-04',
        '2023-01-05',
        '2023-01-06',
        '2023-01-07',
        '2023-01-08',
        '2023-01-09',
        '2023-01-10',
        '2023-01-11',
        '2023-01-12',
        '2023-01-13',
        '2023-01-14',
        '2023-01-15',
        '2023-01-16',
        '2023-01-17',
        '2023-01-18',
        '2023-01-19',
        '2023-01-20',
        '2023-01-21',
        '2023-01-22',
        '2023-01-23',
        '2023-01-24',
        '2023-01-25',
        '2023-01-26',
        '2023-01-27',
        '2023-01-28',
        '2023-01-29',
        '2023-01-30',
        '2023-01-31',
        '2023-02-01',
        '2023-02-02',
        '2023-02-03',
        '2023-02-04',
        '2023-02-05',
        '2023-02-06',
        '2023-02-07',
        '2023-02-08',
        '2023-02-09',
        '2023-02-10',
        '2023-02-11',
        '2023-02-12',
        '2023-02-13',
        '2023-02-14',
        '2023-02-15',
        '2023-02-16',
        '2023-02-17',
        '2023-02-18',
        '2023-02-19',
        '2023-02-20',
        '2023-02-21',
        '2023-02-22',
        '2023-02-23',
        '2023-02-24',
        '2023-02-25',
        '2023-02-26',
        '2023-02-27',
        '2023-02-28',
        '2023-03-01',
        '2023-03-02',
        '2023-03-03',
        '2023-03-04',
        '2023-03-05',
        '2023-03-06',
        '2023-03-07',
        '2023-03-08',
        '2023-03-09',
        '2023-03-10',
        '2023-03-11',
        '2023-03-12',
        '2023-03-13',
        '2023-03-14',
        '2023-03-15',
        '2023-03-16',
        '2023-03-17',
        '2023-03-18',
        '2023-03-19',
        '2023-03-20',
        '2023-03-21',
        '2023-03-22',
        '2023-03-23',
        '2023-03-24',
        '2023-03-25',
        '2023-03-26',
        '2023-03-27',
        '2023-03-28',
        '2023-03-29',
        '2023-03-30',
        '2023-03-31',
        '2023-04-01',
        '2023-04-02',
        '2023-04-03',
        '2023-04-04',
        '2023-04-05',
        '2023-04-06',
        '2023-04-07',
        '2023-04-08',
        '2023-04-09',
        '2023-04-10',
        '2023-04-11',
        '2023-04-12',
        '2023-04-13',
        '2023-04-14',
        '2023-04-15',
        '2023-04-16',
        '2023-04-17',
        '2023-04-18',
        '2023-04-19',
        '2023-04-20',
        '2023-04-21',
        '2023-04-22',
        '2023-04-23',
        '2023-04-24',
        '2023-04-25',
        '2023-04-26',
        '2023-04-27',
        '2023-04-28',
        '2023-04-29',
        '2023-04-30',
        '2023-05-01',
        '2023-05-02',
        '2023-05-03',
        '2023-05-04',
        '2023-05-05',
        '2023-05-06',
        '2023-05-07',
        '2023-05-08',
        '2023-05-09',
        '2023-05-10',
        '2023-05-11',
        '2023-05-12',
        '2023-05-13',
        '2023-05-14',
        '2023-05-15',
        '2023-05-16',
        '2023-05-17',
        '2023-05-18',
        '2023-05-19',
        '2023-05-20',
        '2023-05-21',
        '2023-05-22',
        '2023-05-23',
        '2023-05-24',
        '2023-05-25',
        '2023-05-26',
        '2023-05-27',
        '2023-05-28',
        '2023-05-29',
        '2023-05-30',
        '2023-05-31',
        '2023-06-01',
        '2023-06-02',
        '2023-06-03',
        '2023-06-04',
        '2023-06-05',
        '2023-06-06',
        '2023-06-07',
        '2023-06-08',
        '2023-06-09',
        '2023-06-10',
        '2023-06-11',
        '2023-06-12',
        '2023-06-13',
        '2023-06-14',
        '2023-06-15',
        '2023-06-16',
        '2023-06-17',
        '2023-06-18',
        '2023-06-19',
        '2023-06-20',
        '2023-06-21',
        '2023-06-22',
        '2023-06-23',
        '2023-06-24',
        '2023-06-25',
        '2023-06-26',
        '2023-06-27',
        '2023-06-28',
        '2023-06-29',
        '2023-06-30',
        '2023-07-01',
        '2023-07-02',
        '2023-07-03',
        '2023-07-04',
        '2023-07-05',
        '2023-07-06',
        '2023-07-07',
        '2023-07-08',
        '2023-07-09',
        '2023-07-10',
        '2023-07-11',
        '2023-07-12',
        '2023-07-13',
        '2023-07-14',
        '2023-07-15',
        '2023-07-16',
        '2023-07-17',
        '2023-07-18',
        '2023-07-19',
        '2023-07-20',
        '2023-07-21',
        '2023-07-22',
        '2023-07-23',
        '2023-07-24',
        '2023-07-25',
        '2023-07-26',
        '2023-07-27',
        '2023-07-28',
        '2023-07-29',
        '2023-07-30',
        '2023-07-31',
        '2023-08-01',
        '2023-08-02',
        '2023-08-03',
        '2023-08-04',
        '2023-08-05',
        '2023-08-06',
        '2023-08-07',
        '2023-08-08',
        '2023-08-09',
        '2023-08-10',
        '2023-08-11',
        '2023-08-12',
        '2023-08-13',
        '2023-08-14',
        '2023-08-15',
        '2023-08-16',
        '2023-08-17',
        '2023-08-18',
        '2023-08-19',
        '2023-08-20',
        '2023-08-21',
        '2023-08-22',
        '2023-08-23',
        '2023-08-24',
        '2023-08-25',
        '2023-08-26',
        '2023-08-27',
        '2023-08-28',
        '2023-08-29',
        '2023-08-30',
        '2023-08-31',
        '2023-09-01',
        '2023-09-02',
        '2023-09-03',
        '2023-09-04',
        '2023-09-05',
        '2023-09-06',
        '2023-09-07',
        '2023-09-08',
        '2023-09-09',
        '2023-09-10',
        '2023-09-11',
        '2023-09-12',
        '2023-09-13',
        '2023-09-14',
        '2023-09-15',
        '2023-09-16',
        '2023-09-17',
        '2023-09-18',
        '2023-09-19',
        '2023-09-20',
        '2023-09-21',
        '2023-09-22',
        '2023-09-23',
        '2023-09-24',
        '2023-09-25',
        '2023-09-26',
        '2023-09-27',
        '2023-09-28',
        '2023-09-29',
        '2023-09-30',
        '2023-10-01',
        '2023-10-02',
        '2023-10-03',
        '2023-10-04',
        '2023-10-05',
        '2023-10-06',
        '2023-10-07',
        '2023-10-08',
        '2023-10-09',
        '2023-10-10',
        '2023-10-11',
        '2023-10-12',
        '2023-10-13',
        '2023-10-14',
        '2023-10-15',
        '2023-10-16',
        '2023-10-17',
        '2023-10-18',
        '2023-10-19',
        '2023-10-20',
        '2023-10-21',
        '2023-10-22',
        '2023-10-23',
        '2023-10-24',
        '2023-10-25',
        '2023-10-26',
        '2023-10-27',
        '2023-10-28',
        '2023-10-29',
        '2023-10-30',
        '2023-10-31',
        '2023-11-01',
        '2023-11-02',
        '2023-11-03',
        '2023-11-04',
        '2023-11-05',
        '2023-11-06',
        '2023-11-07',
        '2023-11-08',
        '2023-11-09',
        '2023-11-10',
        '2023-11-11',
        '2023-11-12',
        '2023-11-13',
        '2023-11-14',
        '2023-11-15',
        '2023-11-16',
        '2023-11-17',
        '2023-11-18',
        '2023-11-19',
        '2023-11-20',
        '2023-11-21',
        '2023-11-22',
        '2023-11-23',
        '2023-11-24',
        '2023-11-25',
        '2023-11-26',
        '2023-11-27',
        '2023-11-28',
        '2023-11-29',
        '2023-11-30',
        '2023-12-01',
        '2023-12-02',
        '2023-12-03',
        '2023-12-04',
        '2023-12-05',
        '2023-12-06',
        '2023-12-07',
        '2023-12-08',
        '2023-12-09',
        '2023-12-10',
        '2023-12-11',
        '2023-12-12',
        '2023-12-13',
        '2023-12-14',
        '2023-12-15',
        '2023-12-16',
        '2023-12-17',
        '2023-12-18',
        '2023-12-19',
        '2023-12-20',
        '2023-12-21',
        '2023-12-22',
        '2023-12-23',
        '2023-12-24',
        '2023-12-25',
        '2023-12-26',
        '2023-12-27',
        '2023-12-28',
        '2023-12-29',
        '2023-12-30',
        '2023-12-31',
        '2024-01-01'
      ],
      BTC: [
        10000.0, 10049.21, 10107.49, 10094.6, 10185.13, 10191.9, 10264.83,
        10251.51, 10267.75, 10414.02, 10549.16, 10626.83, 11448.54, 12014.78,
        12705.15, 12657.53, 12823.74, 12851.88, 12593.67, 12750.72, 13676.74,
        13776.37, 13726.01, 13876.64, 13773.86, 13880.8, 13924.11, 13926.8,
        13908.28, 14385.43, 13758.28, 14031.87, 14354.31, 14258.47, 14176.66,
        14165.68, 13909.44, 13848.81, 14060.24, 13878.69, 13175.8, 13056.99,
        13210.31, 13192.16, 13098.85, 13430.14, 14618.88, 14523.96, 14938.21,
        14906.83, 14824.42, 14953.58, 14759.63, 14588.08, 14446.09, 13994.39,
        13993.69, 14210.21, 14213.65, 14019.5, 14243.82, 14200.85, 13510.82,
        13501.75, 13578.64, 13579.01, 13380.47, 13196.78, 12313.9, 12164.8,
        12307.84, 13136.39, 14648.87, 14949.85, 14745.7, 15121.29, 16653.71,
        16288.82, 17016.64, 16919.38, 16967.17, 16469.65, 17077.9, 16523.42,
        16613.78, 16972.69, 16403.53, 16475.19, 17184.03, 16886.93, 17293.52,
        17261.82, 17012.36, 16827.26, 17061.66, 17054.41, 16953.07, 16921.66,
        16910.65, 17173.11, 17913.16, 18318.56, 18062.87, 18338.13, 18422.41,
        18371.36, 18359.28, 17857.65, 18383.38, 17599.73, 17099.08, 16504.06,
        16859.51, 16701.19, 16623.8, 17089.49, 17128.84, 17786.77, 17762.64,
        17675.06, 17782.84, 16960.41, 17394.36, 17618.45, 17420.37, 17910.56,
        17453.8, 17395.75, 16738.32, 16693.85, 16748.78, 16284.8, 16152.2,
        16276.39, 16282.95, 16530.76, 16342.01, 16545.04, 16277.49, 16265.04,
        16383.77, 16194.59, 16265.56, 16461.01, 15948.69, 16012.78, 16152.95,
        16223.95, 17003.03, 16760.19, 16790.04, 16454.39, 16270.96, 16502.15,
        16386.31, 16484.7, 15562.61, 16435.41, 15904.71, 16067.22, 16035.95,
        15662.16, 15674.64, 15695.3, 15640.86, 15153.59, 15513.35, 15961.98,
        16082.82, 15981.19, 16194.7, 17077.52, 18228.24, 18115.2, 18548.83,
        18470.84, 18462.09, 18296.63, 18513.87, 18223.03, 18406.67, 18447.44,
        18508.55, 18553.14, 18837.27, 18655.25, 18423.37, 18260.16, 18342.95,
        18294.78, 18264.24, 18369.16, 18512.74, 18382.08, 18951.35, 18296.66,
        18331.68, 18353.24, 18223.53, 18049.75, 18081.91, 18046.31, 18113.55,
        18042.7, 18167.12, 17651.22, 17678.58, 17841.73, 17676.07, 17730.36,
        17766.89, 17648.04, 17680.72, 17707.36, 17666.19, 17696.01, 17588.49,
        17608.45, 17614.14, 17663.27, 18018.48, 17917.95, 17835.08, 17816.65,
        17811.76, 17746.95, 17818.73, 17664.74, 17499.14, 16222.73, 15781.29,
        15770.4, 15864.51, 15817.62, 15621.5, 16013.73, 15810.19, 15770.4,
        15756.01, 15791.75, 15774.88, 16729.36, 16488.89, 15770.37, 15595.06,
        15653.89, 15707.81, 15601.53, 15597.46, 15585.32, 15933.83, 15680.65,
        15682.36, 15648.35, 15222.4, 15719.57, 15884.31, 16101.6, 16207.62,
        16076.69, 16035.52, 16265.61, 16457.68, 16401.08, 16094.87, 16091.46,
        16078.02, 16034.83, 15908.32, 15833.13, 15939.96, 16349.67, 16274.75,
        16349.24, 16946.16, 16678.68, 16587.69, 16798.65, 16606.47, 16930.91,
        16918.68, 16899.3, 16698.15, 16614.12, 16212.36, 16194.76, 16240.04,
        16251.89, 16404.86, 17256.76, 17200.68, 17156.29, 17341.54, 17954.1,
        18156.89, 18182.49, 20166.03, 20674.31, 20947.2, 20717.94, 20462.99,
        20643.27, 21005.28, 20863.72, 20918.53, 21355.03, 21057.55, 20960.32,
        21282.24, 21168.08, 21255.43, 21356.13, 21659.74, 22122.76, 22608.87,
        22330.55, 22490.2, 22073.52, 21553.4, 22823.71, 21922.4, 22120.45,
        22096.96, 22384.06, 22653.72, 22019.23, 22665.88, 22580.58, 22808.89,
        22834.84, 22791.41, 22446.36, 22917.22, 22874.57, 22816.08, 23427.31,
        23817.84, 24248.61, 25392.56, 26805.82, 26491.93, 26110.1, 26790.76,
        26572.63, 26502.01, 24990.45, 24956.5, 26005.0, 26070.77, 25459.48,
        25610.9, 25251.7, 25731.83, 25624.42, 26397.99, 26602.45, 26512.01,
        26479.24, 26014.02, 26439.45, 25706.95, 26223.77, 25801.43, 25277.82,
        25502.8, 25578.56
      ],
      ETH: [
        10000.0, 10040.47, 10158.17, 10137.95, 10477.53, 10456.14, 10614.37,
        10559.45, 10612.79, 11042.75, 11155.95, 11280.18, 11892.27, 12149.86,
        12966.67, 13020.93, 13208.64, 13159.9, 12802.16, 12970.38, 13810.05,
        13617.93, 13615.84, 13605.48, 13059.5, 13258.36, 13390.71, 13316.8,
        13080.73, 13735.48, 13074.52, 13294.9, 13692.57, 13840.98, 13937.7,
        14044.63, 13662.3, 13650.45, 13921.79, 13801.33, 12857.43, 12610.97,
        12843.14, 12651.53, 12469.62, 12995.77, 13925.72, 13761.12, 14201.18,
        14127.98, 14091.02, 14152.63, 13820.06, 13664.84, 13769.48, 13416.63,
        13306.53, 13656.22, 13635.74, 13479.92, 13848.39, 13783.62, 13099.68,
        13054.4, 13088.15, 13119.84, 12985.24, 12920.97, 11975.87, 11905.74,
        12202.84, 13077.47, 14047.13, 14223.53, 13779.53, 13983.5, 14815.3,
        14790.14, 15089.43, 14608.9, 14995.63, 14435.19, 15118.84, 14573.46,
        14528.05, 14847.82, 14291.16, 14801.01, 14968.56, 14917.98, 15268.02,
        15242.41, 14992.71, 15100.56, 15607.84, 15966.97, 15628.22, 15614.85,
        15473.72, 15597.96, 15916.8, 15834.56, 15992.55, 16781.47, 17595.63,
        17516.15, 17776.81, 17385.99, 17493.08, 16435.53, 16243.26, 15424.82,
        15695.12, 15563.76, 15374.44, 15613.01, 15591.2, 15928.34, 15834.31,
        15913.29, 15820.18, 15265.07, 15665.84, 15941.05, 15643.78, 16624.32,
        15845.18, 15946.4, 15441.37, 15415.29, 15402.42, 14961.53, 15068.3,
        15063.1, 15047.98, 15241.58, 15243.42, 15217.66, 15121.03, 15150.95,
        15201.11, 15089.53, 15203.62, 15502.56, 15042.63, 15108.67, 15267.57,
        15299.17, 15939.97, 15818.85, 15920.57, 15628.16, 15611.68, 15935.44,
        15811.65, 15897.25, 15107.75, 15738.51, 15296.25, 15461.36, 15369.49,
        14662.33, 14662.07, 14571.28, 14503.39, 13793.73, 13923.41, 14380.84,
        14467.04, 14399.22, 14490.43, 14923.56, 15827.12, 15637.25, 15803.19,
        15674.3, 15885.11, 15540.88, 15766.9, 15280.22, 15479.08, 16172.97,
        16073.9, 16235.33, 16346.41, 16231.31, 15957.27, 15621.28, 15614.85,
        15557.17, 15605.56, 15685.58, 15708.31, 15644.95, 16646.37, 16104.58,
        16122.89, 16162.92, 15965.64, 15894.65, 15784.81, 15837.64, 15825.94,
        15751.37, 15746.02, 15446.98, 15547.06, 15654.97, 15520.22, 15683.4,
        15714.52, 15514.61, 15519.3, 15476.83, 15403.94, 15363.88, 15286.9,
        15368.32, 15299.77, 15279.71, 15527.66, 15512.36, 15479.24, 15437.53,
        15462.95, 15374.91, 15430.34, 15284.97, 15149.87, 14172.6, 13897.33,
        13931.27, 14076.48, 13936.78, 13540.26, 14012.75, 13831.19, 13826.91,
        13781.68, 13855.94, 13801.93, 14462.1, 14224.19, 13790.97, 13598.95,
        13683.63, 13674.95, 13579.14, 13641.26, 13640.15, 13798.0, 13675.93,
        13672.51, 13538.51, 12940.43, 13351.41, 13433.01, 13626.71, 13789.73,
        13676.69, 13548.78, 13727.52, 13726.95, 13568.02, 13257.54, 13332.01,
        13296.84, 13286.38, 13266.98, 13274.75, 13361.01, 13796.07, 13936.27,
        14002.32, 14500.64, 13888.29, 13857.11, 13781.94, 13507.58, 13751.67,
        13669.84, 13636.22, 13183.47, 13109.48, 13086.66, 12873.56, 12953.49,
        13011.17, 13001.12, 13392.13, 13059.72, 13079.97, 13076.71, 13445.21,
        13630.45, 13932.5, 14925.49, 14994.3, 14987.45, 15108.73, 14855.52,
        14854.44, 15090.76, 15125.88, 15123.22, 15410.28, 15013.69, 15308.64,
        15531.09, 15763.16, 15909.61, 15712.49, 15809.31, 17506.35, 17402.79,
        17095.98, 17228.13, 17210.26, 16609.42, 17161.27, 16451.19, 16406.94,
        16361.88, 16559.51, 16920.92, 16238.41, 17262.01, 17270.79, 17337.82,
        17412.64, 17361.24, 16889.64, 17130.33, 16962.56, 17082.1, 17434.64,
        18039.72, 18433.3, 18626.43, 19074.51, 18674.25, 19644.23, 19761.52,
        19674.24, 19740.95, 18586.46, 18292.43, 18899.13, 19352.73, 18650.92,
        18633.68, 18549.58, 18498.17, 18206.32, 18325.96, 18765.87, 19336.35,
        19197.89, 18890.01, 19102.02, 18625.41, 19768.87, 19705.93, 19074.1,
        19125.92, 19016.83
      ],
      BNB: [
        10000.0, 9939.1, 9987.82, 9987.82, 10462.85, 10430.36, 10548.1,
        10596.82, 11035.31, 11100.27, 11238.31, 11384.48, 11648.38, 11900.11,
        12391.37, 12293.95, 12168.09, 12212.74, 11786.43, 11932.6, 12310.18,
        12228.97, 12245.23, 12415.74, 12273.64, 12379.19, 12362.96, 12468.51,
        12375.14, 12915.13, 12423.86, 12691.82, 12874.53, 13260.25, 13422.65,
        13438.89, 13321.13, 13260.25, 13495.72, 13300.84, 12350.78, 12391.37,
        12618.74, 12833.94, 11827.02, 12017.86, 12829.86, 12480.69, 12732.44,
        12842.05, 12708.08, 12736.49, 12630.92, 12618.74, 12525.36, 12220.86,
        12245.23, 12517.24, 12367.01, 12289.87, 12261.46, 12188.37, 11741.78,
        11697.11, 11733.65, 11680.87, 11676.8, 11668.69, 11213.95, 11246.44,
        11120.58, 11550.94, 12553.78, 12549.73, 12403.56, 13430.76, 13670.3,
        13483.54, 13743.39, 13654.07, 13617.52, 13053.17, 13329.26, 13061.3,
        13065.35, 13345.49, 12533.49, 12675.59, 12748.67, 12817.68, 12870.48,
        12801.45, 12704.0, 12476.64, 12626.86, 12773.03, 12683.72, 12622.81,
        12610.63, 12712.13, 12809.58, 13166.85, 12967.91, 13166.85, 13377.98,
        13552.57, 14198.11, 13832.71, 13922.02, 13183.09, 12882.66, 13016.63,
        13487.61, 13418.58, 13455.12, 13686.56, 13394.22, 13503.84, 13154.67,
        13085.66, 13710.92, 13341.44, 13105.95, 13264.3, 13126.26, 13280.53,
        13101.9, 13101.9, 12724.31, 12647.17, 12801.45, 12444.17, 12476.64,
        12651.23, 12663.41, 12744.62, 12630.92, 12732.44, 12602.5, 12545.67,
        12594.4, 12452.28, 12561.91, 12724.31, 12411.68, 12358.91, 12456.35,
        12448.23, 12740.55, 12622.81, 12659.35, 12431.99, 12411.68, 12484.77,
        12436.04, 12423.86, 11161.17, 11453.51, 10483.14, 10682.09, 10600.89,
        9715.79, 9545.25, 9370.67, 9801.05, 9593.98, 9602.11, 9711.73, 9971.56,
        9878.19, 9853.83, 9926.91, 10142.1, 9752.33, 9935.02, 9585.87, 9707.66,
        9589.92, 9650.83, 9374.74, 9464.06, 9784.8, 10056.83, 10020.29,
        10004.05, 9857.88, 9703.61, 9488.42, 9561.51, 9585.87, 9512.79, 9898.48,
        10101.5, 9898.48, 10328.86, 10000.0, 10211.11, 9930.97, 9894.43,
        9768.56, 9788.87, 9861.96, 9890.37, 9817.29, 9821.34, 9683.3, 9650.83,
        9711.73, 9756.38, 9833.52, 9833.52, 9817.29, 9809.16, 10004.05, 9796.98,
        9788.87, 9805.11, 9870.06, 9870.06, 9821.34, 9959.38, 9898.48, 9792.93,
        9711.73, 9764.51, 9756.38, 9760.44, 9622.39, 9415.34, 8976.85, 8777.9,
        8794.14, 8810.39, 8562.72, 8473.4, 8777.9, 8834.75, 8850.99, 8786.03,
        8863.17, 8871.3, 9220.45, 9062.12, 8810.39, 8656.09, 8700.76, 8692.64,
        8725.13, 8704.82, 8733.25, 8822.57, 8708.89, 8708.89, 8635.81, 8371.9,
        8595.21, 8635.81, 8635.81, 8712.95, 8729.18, 8790.08, 8798.21, 8814.45,
        8684.53, 8558.67, 8578.96, 8534.31, 8518.05, 8522.13, 8591.14, 8599.26,
        8729.18, 8729.18, 8733.25, 8891.58, 8708.89, 8668.27, 8668.27, 8566.77,
        8676.4, 8615.5, 8574.9, 8359.72, 8477.46, 8375.96, 8335.36, 8363.77,
        8384.08, 8501.82, 8737.31, 8578.96, 8558.67, 8538.36, 8635.81, 8721.07,
        8846.93, 9334.13, 9212.34, 9037.75, 9086.48, 9086.48, 9159.56, 9248.88,
        9261.06, 9183.92, 9220.45, 9362.56, 9350.38, 9654.88, 9922.84, 10349.15,
        9991.87, 10008.11, 10081.19, 10203.0, 10194.87, 10089.32, 9805.11,
        9841.65, 10263.9, 9861.96, 9935.02, 9914.73, 9930.97, 10324.81, 9135.2,
        9593.98, 9496.53, 9447.83, 9480.3, 9435.65, 9196.08, 9317.89, 9232.63,
        9228.57, 9265.12, 9281.35, 9281.35, 9415.34, 9358.49, 9334.13, 9427.52,
        9675.19, 9707.66, 9732.02, 9939.1, 10353.22, 10288.27, 10300.45,
        9967.51, 9951.28, 9788.87, 9760.44, 10235.47, 10527.81, 11019.08,
        11047.49, 11035.31, 10706.45, 10856.67, 11940.7, 12898.9, 13207.45,
        12622.81, 12809.58, 12618.74
      ],
      SOL: [
        10000.0, 10020.11, 11336.86, 13437.24, 13618.24, 13497.57, 13437.24,
        13105.58, 13778.95, 16392.17, 16372.06, 16271.5, 16472.44, 18160.89,
        23738.91, 23266.47, 23769.07, 23306.69, 21256.42, 21537.8, 25346.92,
        24934.82, 24331.83, 24502.78, 23105.76, 24321.78, 24432.39, 24231.28,
        23980.07, 26402.22, 23879.51, 24161.07, 25065.54, 24703.71, 24824.38,
        24743.94, 23698.69, 23115.82, 23769.07, 23266.47, 20372.06, 20100.73,
        20884.54, 21688.63, 20542.82, 21809.3, 23889.57, 22522.71, 23296.64,
        23708.74, 25326.81, 26362.0, 24975.04, 24231.28, 23949.9, 22924.76,
        22321.78, 23176.15, 22814.32, 22251.39, 22522.71, 22171.12, 21487.7,
        20824.2, 21156.04, 20814.32, 20150.83, 18462.38, 17216.19, 18181.0,
        18040.4, 19929.79, 20643.38, 20995.15, 19336.86, 19728.85, 21296.64,
        21638.35, 22110.79, 22532.77, 22583.05, 21547.85, 22151.01, 20653.44,
        20442.45, 21135.93, 19909.68, 20542.82, 21226.25, 20532.77, 21507.63,
        21276.53, 20653.44, 20552.88, 21055.48, 21025.32, 20693.66, 20593.1,
        20040.4, 20462.56, 20944.87, 23316.75, 23799.24, 24512.83, 24834.44,
        24241.33, 25548.03, 24914.7, 24864.6, 22995.15, 22341.89, 21346.92,
        21970.01, 21588.07, 21467.58, 21809.3, 21316.75, 22362.0, 23377.08,
        23166.09, 23256.42, 22080.62, 22372.06, 22281.56, 21769.07, 22964.98,
        22020.29, 22271.5, 20753.99, 20713.77, 21145.98, 20271.5, 20814.32,
        21005.2, 21055.48, 21216.19, 20753.99, 21085.65, 20552.88, 20462.56,
        20341.89, 19759.02, 19698.69, 20140.95, 19336.86, 19326.81, 19477.46,
        20472.61, 21055.48, 20683.6, 21346.92, 20874.48, 20663.49, 21427.36,
        21246.36, 22201.11, 20090.68, 20492.55, 18673.55, 19025.32, 17537.8,
        15819.18, 15588.07, 15276.53, 14995.15, 14482.49, 14844.32, 15427.19,
        15809.12, 15457.35, 16050.45, 16603.16, 17346.92, 16703.71, 17125.69,
        16633.32, 17105.58, 16362.0, 16743.94, 16080.62, 17990.12, 19236.31,
        18532.77, 19608.19, 19165.92, 19557.91, 18924.76, 20402.22, 21608.19,
        22020.29, 21507.63, 21377.08, 22120.84, 22090.68, 25909.68, 26462.56,
        27477.64, 27738.91, 26764.05, 25628.3, 26522.89, 25638.35, 25879.69,
        25306.69, 24804.27, 23527.92, 23276.53, 25457.53, 25256.42, 25025.32,
        25387.14, 24221.22, 23899.62, 23789.19, 23336.86, 22834.44, 22924.76,
        22774.1, 23367.03, 23156.04, 24221.22, 24472.61, 24733.88, 24693.66,
        25115.82, 24412.28, 25206.32, 23959.95, 22954.93, 22110.79, 21507.63,
        21899.62, 21909.68, 21356.97, 20442.45, 21638.35, 21045.43, 20462.56,
        20422.33, 20904.65, 20623.27, 21959.95, 20944.87, 19889.57, 19356.97,
        19598.13, 19608.19, 19437.42, 20321.78, 19688.63, 20030.34, 19698.69,
        19588.07, 18432.39, 17658.46, 18100.73, 18512.66, 19035.37, 19246.36,
        19165.92, 18884.54, 19909.68, 20160.89, 20392.17, 19648.41, 19567.96,
        19507.63, 19608.19, 19467.58, 19035.37, 19155.86, 19939.84, 20382.11,
        21527.74, 24502.78, 23457.53, 23678.57, 23316.75, 22874.48, 23628.3,
        23417.31, 23356.97, 22201.11, 22261.44, 22070.57, 21447.47, 21899.62,
        22140.95, 21980.07, 24261.44, 24100.73, 23748.96, 24934.82, 27467.58,
        29879.69, 29407.25, 32573.17, 30472.61, 32332.01, 33025.32, 31738.91,
        31789.19, 33125.87, 35125.87, 38130.9, 42522.89, 40231.45, 39940.02,
        42623.45, 41136.1, 41497.75, 43276.7, 43467.76, 45628.47, 56905.0,
        58492.9, 57367.38, 52502.95, 57457.7, 65437.77, 58372.41, 59015.61,
        58633.68, 61025.67, 56724.0, 52080.8, 58291.96, 57126.05, 57156.21,
        58945.22, 58121.02, 55015.61, 58121.02, 59457.7, 59558.26, 60412.63,
        63176.33, 64060.86, 61236.66, 60744.29, 63045.78, 68251.74, 73900.15,
        74261.97, 74553.41, 70080.97, 68080.97, 71658.82, 76101.08, 75065.89,
        74352.3, 72563.46, 74915.23, 73196.61, 82040.93, 95045.96, 97548.56,
        109357.68, 112523.6, 120342.77, 112222.1, 107749.67, 104272.21,
        105508.34, 102322.48, 101036.08
      ],
      XRP: [
        10000.0, 9938.35, 10231.86, 10102.72, 10179.03, 9958.89, 10155.54,
        10093.92, 10044.03, 10270.03, 10308.17, 10912.82, 11024.34, 11262.11,
        11584.96, 11317.88, 11364.82, 11414.73, 11206.34, 11543.88, 12034.05,
        11840.31, 11752.28, 12521.27, 11987.08, 12142.65, 12022.3, 12042.85,
        11940.11, 12119.16, 11537.99, 11954.79, 12163.19, 12057.53, 12092.73,
        12160.25, 11708.25, 11637.79, 11831.51, 11675.96, 11053.71, 11176.97,
        11253.28, 11027.28, 10889.34, 11132.96, 11740.53, 11367.76, 11602.56,
        11584.96, 11367.76, 11655.39, 11488.11, 11587.91, 11414.73, 11059.57,
        11062.51, 11056.65, 11144.68, 11080.11, 11250.36, 11106.54, 11015.54,
        10909.88, 10810.08, 10895.2, 11191.65, 11361.88, 10977.4, 10877.6,
        10613.43, 10751.4, 10971.51, 10971.51, 10540.06, 10710.28, 11118.28,
        10983.25, 11493.97, 11047.83, 13554.44, 12353.96, 13073.07, 12400.93,
        13034.93, 13216.9, 14000.58, 15312.57, 15999.4, 15685.34, 15817.43,
        15010.26, 15271.49, 14637.49, 14790.12, 14884.06, 14743.17, 15054.29,
        14840.03, 14863.52, 15133.54, 15171.69, 14796.0, 15036.69, 15441.72,
        15294.97, 15312.57, 15051.35, 15526.85, 14567.06, 13880.24, 13087.75,
        13836.21, 13583.78, 13539.75, 13756.95, 13460.52, 13639.55, 14009.38,
        14026.98, 13891.98, 13621.95, 13645.44, 13642.49, 13504.53, 13721.75,
        13419.41, 13328.44, 12497.79, 12521.27, 12641.61, 12318.73, 12626.93,
        12456.7, 12503.65, 12579.96, 12800.1, 13111.24, 13563.24, 13748.15,
        13795.12, 13457.58, 13551.5, 13665.98, 13322.55, 13319.61, 13715.87,
        13850.89, 14194.29, 14479.0, 15315.51, 15233.34, 14939.83, 15397.69,
        15248.0, 15887.86, 14834.15, 15585.54, 15203.97, 15438.8, 15779.26,
        14954.49, 15186.37, 15391.83, 15236.26, 14047.55, 14079.83, 13894.92,
        14117.98, 14297.03, 14517.15, 14440.83, 14740.23, 14508.35, 14561.18,
        14285.29, 14399.75, 14082.75, 14150.26, 13648.35, 13918.38, 13968.29,
        13871.44, 14235.38, 14343.98, 14346.92, 14029.92, 13733.47, 13730.55,
        13798.04, 13759.89, 14003.52, 13980.04, 13827.41, 23780.44, 21085.97,
        20956.83, 22145.56, 21646.59, 22574.1, 23988.84, 23489.84, 22612.25,
        21969.45, 21576.14, 20654.51, 20871.71, 21103.6, 20956.83, 20895.2,
        20915.74, 20589.94, 20510.69, 20657.46, 20158.49, 19571.46, 18693.84,
        18497.18, 18353.38, 18259.44, 18911.04, 18808.32, 18573.5, 18570.58,
        18438.5, 18385.67, 18567.64, 17869.07, 17240.96, 14977.97, 14869.37,
        15224.52, 15917.22, 15383.03, 15195.17, 15500.43, 15198.11, 15450.54,
        15391.83, 15341.94, 15350.74, 15911.34, 15494.57, 15024.94, 14614.01,
        14640.43, 14798.92, 14913.4, 14840.03, 14751.97, 14828.29, 14828.29,
        14787.2, 14634.57, 13883.18, 14164.95, 14211.89, 14429.09, 14757.83,
        14684.46, 14458.46, 14831.23, 15077.77, 15289.09, 14919.26, 15092.43,
        14884.06, 14878.17, 14816.55, 14687.4, 14693.26, 14919.26, 15297.91,
        15154.09, 15415.31, 15022.0, 15497.49, 15656.0, 15383.03, 15450.54,
        15315.51, 15177.57, 14754.92, 14649.23, 14314.63, 14223.64, 14276.49,
        14273.55, 14308.78, 14643.37, 14417.37, 14352.78, 14928.09, 15268.54,
        15330.2, 15324.31, 16149.08, 16498.37, 16278.23, 16257.68, 16014.08,
        16025.82, 16354.54, 16973.87, 17557.96, 17898.44, 17722.33, 17986.47,
        18092.15, 19134.12, 21179.91, 20023.46, 20176.09, 19474.6, 19410.03,
        19533.29, 19515.69, 19195.75, 18476.64, 19031.38, 18053.99, 18074.53,
        17913.1, 18277.07, 17986.47, 17038.45, 18007.04, 18209.56, 18212.5,
        18215.41, 18159.67, 17643.07, 17916.04, 17857.33, 17772.22, 17957.13,
        18133.24, 18359.24, 18282.93, 18194.87, 18652.75, 18825.92, 19618.41,
        19697.66, 19448.18, 18200.75, 18133.24, 18476.64, 18573.5, 18165.53,
        18186.07, 17995.3, 17960.07, 17804.5, 18156.73, 18306.41, 18324.01,
        18183.13, 17913.1, 18896.38, 18221.3, 18567.64, 18594.07, 18180.21,
        18233.04, 17945.39
      ],
      ADA: [
        10000.0, 10117.74, 10304.59, 10231.47, 10808.26, 10897.61, 11226.64,
        11279.4, 11791.21, 12895.97, 13054.38, 12859.44, 13354.95, 13915.51,
        14228.25, 14260.71, 14244.48, 14106.38, 13419.93, 13761.12, 14569.43,
        14943.08, 15227.43, 15259.89, 14675.02, 14918.7, 15438.65, 15629.52,
        15434.58, 16060.06, 15073.09, 15787.93, 16181.93, 16255.05, 16445.92,
        16348.48, 15889.49, 15686.41, 16145.4, 15991.01, 14589.74, 14451.63,
        14955.29, 14809.05, 14492.24, 15657.97, 16376.87, 15881.35, 16405.32,
        16498.74, 16356.57, 16352.49, 15865.12, 15743.25, 15548.31, 14768.44,
        14626.27, 14906.55, 14776.59, 14321.67, 14573.5, 14199.8, 13911.44,
        13566.18, 13732.73, 13456.52, 13330.58, 12855.36, 12384.22, 12830.99,
        12302.95, 13151.87, 14045.47, 13964.2, 13066.59, 13107.2, 14041.4,
        13789.57, 14130.75, 13586.48, 14983.74, 14524.76, 15052.79, 14581.59,
        14289.16, 14480.08, 13956.11, 14849.71, 15511.72, 15255.87, 16299.73,
        16047.9, 15499.56, 15779.84, 15865.12, 15942.32, 15560.47, 15592.99,
        15625.45, 15856.98, 16056.04, 16299.73, 16303.8, 17302.98, 17843.18,
        18529.62, 18395.59, 17692.92, 17960.98, 16961.8, 16344.41, 15446.74,
        16096.65, 15743.25, 15584.84, 15901.65, 16299.73, 16584.02, 16441.91,
        16336.26, 16226.61, 15670.12, 15905.73, 16068.2, 15686.41, 16023.53,
        15410.21, 15357.39, 14780.66, 14679.09, 15032.43, 14561.29, 14991.83,
        14841.57, 15020.27, 14979.67, 14910.62, 15231.5, 15215.21, 14975.6,
        14857.8, 14654.71, 14934.99, 15048.72, 14772.51, 14549.13, 14719.69,
        14898.4, 15552.38, 15377.69, 15361.46, 15215.21, 14849.71, 15369.6,
        15251.8, 15446.74, 14256.69, 14362.28, 13082.82, 13139.71, 12075.5,
        11295.69, 11047.93, 11185.97, 11076.32, 10633.62, 10641.71, 10710.76,
        10852.93, 10580.8, 10674.23, 10885.45, 11677.48, 11754.67, 12022.73,
        11730.3, 11917.09, 11380.97, 11644.96, 10881.38, 11169.74, 11705.92,
        11852.11, 11880.56, 12047.11, 11965.84, 11515.0, 11494.7, 11563.75,
        11770.9, 11592.2, 11697.78, 11864.33, 11689.64, 14037.32, 13212.78,
        13212.78, 12875.67, 12660.43, 12416.68, 13066.59, 12875.67, 12717.26,
        12656.35, 12814.76, 12388.29, 12355.78, 12477.65, 12473.58, 12607.61,
        12688.82, 12631.98, 12473.58, 12457.34, 12177.07, 11884.63, 11925.24,
        11925.24, 11888.7, 11791.21, 12132.39, 12262.35, 12043.04, 11921.16,
        11868.4, 11734.31, 11811.51, 11429.72, 11125.07, 10446.77, 10727.05,
        10857.01, 10986.96, 10694.53, 10393.95, 10832.63, 10779.81, 10601.1,
        10617.33, 10690.46, 10840.77, 11100.69, 10804.18, 10337.11, 10349.27,
        10381.79, 10365.56, 10389.88, 10446.77, 10463.0, 10475.22, 10320.88,
        10292.44, 10109.66, 9776.56, 10008.09, 10113.73, 10239.62, 10223.38,
        10150.26, 10024.37, 10272.13, 10381.79, 10215.24, 10000.0, 9955.32,
        9971.55, 9934.96, 9951.25, 9943.11, 9959.34, 10117.74, 10081.21,
        10296.51, 10869.16, 10511.75, 10564.57, 10536.12, 10584.87, 10759.51,
        10487.37, 10406.16, 10215.24, 10125.89, 10048.69, 9979.64, 9995.93,
        10020.3, 10016.23, 10231.47, 10016.23, 9906.57, 9975.63, 10219.31,
        10507.68, 10702.67, 11340.36, 11385.04, 11413.43, 11701.85, 11689.64,
        11835.88, 12063.34, 12266.42, 11835.88, 12428.9, 13038.14, 13273.75,
        13354.95, 13935.81, 14825.34, 14236.33, 14569.43, 14922.78, 15471.11,
        15702.64, 15670.12, 14480.08, 14573.5, 15373.62, 15069.02, 14882.17,
        15296.48, 15442.67, 15503.63, 14528.83, 15446.74, 15552.38, 15670.12,
        15991.01, 15808.23, 15219.28, 15580.77, 15536.09, 15247.73, 15523.94,
        16027.6, 16153.48, 16336.26, 17148.59, 18131.54, 18460.57, 22156.71,
        23688.0, 24203.82, 22497.89, 22969.09, 26811.48, 26165.64, 24679.09,
        24796.83, 23647.39, 24211.96, 23371.19, 23895.15, 25629.52, 25203.05,
        24910.56, 24118.53, 25499.56, 24622.2, 25670.12, 25373.62, 24508.47,
        24390.67, 23931.68
      ],
      DOGE: [
        10000.0, 9982.93, 10220.65, 9982.93, 10306.06, 10297.51, 10331.69,
        10251.98, 10384.34, 10802.87, 11005.0, 10948.04, 11372.25, 11860.5,
        12391.47, 12290.4, 11911.74, 11874.76, 11558.73, 11601.43, 12236.32,
        12240.58, 12545.21, 12587.91, 12005.69, 12203.57, 12279.02, 12320.3,
        12401.44, 12817.08, 12391.47, 13637.02, 13400.73, 13043.42, 13177.24,
        13857.67, 13155.89, 12921.01, 13148.78, 12812.82, 11386.49, 11427.76,
        11725.27, 11713.9, 11594.31, 11840.57, 12597.88, 12102.5, 12486.84,
        12654.82, 12425.62, 12495.39, 12160.86, 12126.71, 11984.34, 11434.88,
        11444.85, 11664.08, 11551.61, 11513.17, 11641.3, 11454.83, 10896.82,
        10502.5, 10670.48, 10643.44, 10439.88, 10098.23, 9288.27, 9339.52,
        9318.17, 9961.58, 10417.1, 10622.06, 9867.63, 10156.6, 10901.08,
        10481.15, 10707.49, 10202.13, 10859.8, 10472.6, 10955.16, 10629.18,
        10485.41, 10592.19, 10353.04, 10462.63, 10787.21, 10496.81, 11063.37,
        11699.66, 11209.97, 13443.43, 13664.06, 13281.14, 12227.77, 11769.4,
        11555.89, 11874.76, 12052.68, 11957.3, 11803.56, 12452.69, 12664.77,
        12661.94, 12975.11, 13116.02, 13305.35, 12539.52, 11873.33, 11117.45,
        11437.74, 11197.16, 11185.78, 11340.93, 11138.8, 11393.61, 11434.88,
        11577.24, 11380.8, 11187.19, 11202.85, 11442.0, 11125.99, 11442.0,
        10912.46, 10967.99, 10279.02, 10378.65, 10435.59, 10298.94, 10264.78,
        10239.15, 10246.26, 10290.39, 10328.84, 10663.36, 10558.01, 10481.15,
        10472.6, 10281.85, 10397.17, 10374.39, 10064.08, 10091.12, 10088.26,
        10400.01, 10459.8, 10407.12, 10320.29, 10185.07, 10209.25, 10328.84,
        10334.52, 10380.08, 9453.39, 10085.43, 9564.43, 9685.42, 9906.05,
        8851.25, 8755.88, 8754.45, 8754.45, 8542.37, 8762.99, 8845.57, 8865.49,
        8818.5, 8862.63, 8942.35, 9375.11, 9328.12, 9699.66, 9480.43, 9517.45,
        9188.61, 9306.77, 8909.62, 9027.78, 9511.76, 9718.15, 9728.13, 9726.7,
        9830.62, 9516.02, 9370.82, 9321.0, 9379.37, 9333.81, 9271.18, 9274.04,
        9187.21, 9935.95, 9711.03, 10223.51, 9951.61, 9907.48, 9753.74,
        10007.12, 10038.44, 10491.12, 10089.69, 10186.5, 10566.55, 11609.97,
        11120.3, 11017.81, 11022.07, 11460.52, 11047.7, 11061.94, 10982.22,
        10610.68, 10503.93, 10444.14, 10834.17, 10639.15, 10451.25, 10669.05,
        10688.97, 10774.38, 10771.54, 10948.04, 10632.04, 10647.7, 10079.72,
        9600.02, 8804.27, 9002.14, 9059.08, 9081.86, 8888.27, 8874.03, 9061.93,
        8959.44, 8980.79, 8956.58, 8996.45, 9024.92, 9517.45, 9342.35, 9073.31,
        9053.39, 9042.01, 8972.24, 8960.87, 9103.21, 9053.39, 9066.19, 9046.27,
        9036.3, 8735.95, 8590.76, 8691.82, 8717.43, 8861.23, 8916.74, 8838.45,
        8755.88, 8844.14, 8915.31, 8912.48, 8743.07, 8760.16, 8734.53, 8694.68,
        8661.93, 8583.64, 8606.42, 8738.79, 8874.03, 8862.63, 9040.58, 8815.67,
        8751.62, 8747.33, 8691.82, 8741.64, 8750.19, 8667.62, 8357.3, 8405.69,
        8290.39, 8243.43, 8304.62, 8522.42, 8438.44, 8566.55, 8407.12, 8372.96,
        8348.75, 8550.89, 8718.86, 8743.07, 9538.8, 9548.77, 9809.27, 10266.21,
        9611.4, 9833.45, 9930.26, 9891.82, 9676.88, 9755.16, 9642.72, 9698.23,
        9817.81, 10126.7, 10815.67, 10432.76, 10771.54, 10337.38, 10693.26,
        11147.34, 11164.43, 10596.45, 10325.98, 10838.45, 11187.19, 12136.66,
        11470.46, 11326.7, 11094.67, 10187.9, 10794.32, 10851.26, 11161.58,
        11217.08, 11103.21, 11091.83, 11412.1, 11470.46, 11861.92, 11907.48,
        12187.9, 12219.23, 12770.12, 13340.93, 13693.95, 13486.13, 14469.76,
        14193.6, 14569.4, 13480.44, 13202.85, 13718.16, 13972.97, 13295.38,
        13611.41, 13306.78, 13081.87, 12876.88, 12975.11, 13601.43, 13483.28,
        13289.69, 12995.03, 13453.4, 13000.72, 13215.66, 13070.46, 12861.21,
        12804.28, 12691.83
      ],
      LINK: [
        10000.0, 10089.83, 10226.37, 10059.29, 10332.36, 10159.89, 10271.28,
        10432.96, 10526.41, 10885.74, 11027.67, 11117.5, 11439.07, 11762.46,
        12281.71, 12024.77, 11994.23, 12391.28, 11534.3, 11681.64, 12391.28,
        12463.17, 12500.89, 12637.43, 12010.39, 12353.56, 12840.45, 13135.08,
        12867.41, 13235.72, 12358.96, 12486.52, 13020.11, 12838.66, 13109.94,
        13118.92, 12536.83, 12416.46, 12885.36, 12752.4, 12427.23, 12323.02,
        12583.54, 12251.17, 11855.9, 12217.02, 12887.15, 12608.68, 13630.95,
        14461.01, 14254.38, 14293.93, 13665.1, 13803.43, 14191.51, 13223.13,
        13208.75, 13368.64, 13018.32, 13021.9, 13444.1, 13047.08, 12482.91,
        12249.35, 12513.45, 12362.53, 12193.67, 11771.47, 10937.84, 11189.35,
        10991.73, 11598.99, 12209.83, 12463.17, 11676.24, 11952.92, 12890.76,
        12536.83, 12835.05, 12748.83, 13273.45, 12788.34, 13604.02, 12930.28,
        12671.58, 13059.63, 12269.12, 12447.0, 13273.45, 12924.88, 13756.72,
        13564.47, 13074.0, 13007.52, 13293.18, 13153.07, 12883.58, 13088.38,
        12784.77, 12991.36, 13183.61, 13151.25, 12935.68, 13375.83, 14001.08,
        14428.68, 14599.33, 14752.07, 15391.66, 14098.1, 13541.12, 12696.72,
        12897.95, 12678.77, 12703.91, 13032.7, 12540.41, 12840.45, 12653.59,
        12892.55, 12691.32, 12371.55, 12551.21, 12824.28, 12522.46, 12985.96,
        12459.55, 12454.19, 11845.1, 11676.24, 11859.48, 11395.98, 11888.23,
        11713.97, 11735.53, 11963.69, 11940.34, 12120.0, 11773.26, 11701.38,
        11694.19, 11546.89, 11737.32, 11703.2, 11374.41, 11289.98, 11404.95,
        11550.47, 11933.15, 11934.93, 11904.39, 11625.92, 11435.49, 11602.57,
        11523.54, 11703.2, 10846.18, 11209.12, 10538.99, 10792.29, 10776.13,
        9464.58, 9265.18, 9297.5, 9432.25, 9396.31, 9536.46, 9563.43, 9505.92,
        9283.13, 9178.92, 9439.44, 9998.21, 9955.08, 10905.47, 10982.76,
        11009.68, 10937.84, 11115.68, 10529.98, 10594.67, 11336.69, 11652.89,
        11739.11, 11864.88, 11803.8, 11295.34, 11054.6, 11058.21, 11112.1,
        11061.79, 11060.0, 11160.6, 11194.75, 12495.49, 12317.62, 12409.27,
        11940.34, 12901.53, 12470.35, 12378.73, 14802.35, 14655.05, 14322.64,
        14134.0, 13650.73, 13338.1, 13923.8, 14367.56, 14026.22, 14107.08,
        13444.1, 13524.95, 13521.38, 13278.81, 12833.26, 12806.29, 12993.15,
        12799.14, 13158.44, 13368.64, 13922.01, 13672.29, 13426.15, 13411.77,
        13181.82, 13356.09, 12623.05, 12128.98, 11250.43, 11081.56, 11052.81,
        11151.62, 11137.24, 10873.15, 11370.8, 10883.91, 10779.74, 10779.74,
        10831.84, 10740.19, 11153.41, 10671.92, 10555.16, 10582.09, 10720.45,
        10817.47, 10767.15, 11043.84, 11254.04, 11503.76, 11236.05, 11086.96,
        10810.28, 10422.2, 10725.81, 10804.88, 11130.06, 11412.14, 11263.02,
        11009.68, 11888.23, 12367.93, 12429.01, 12053.52, 12499.11, 12959.03,
        12630.24, 13399.18, 13165.62, 13699.22, 14065.74, 14302.91, 14692.77,
        14523.87, 13413.56, 13381.23, 13778.29, 13526.74, 13776.5, 13568.08,
        13817.8, 13095.57, 13075.79, 13318.36, 12969.8, 13072.22, 13160.26,
        13287.82, 13605.81, 13197.99, 13242.91, 13117.13, 13656.13, 16097.73,
        18147.67, 19619.11, 18395.61, 19574.19, 19818.51, 19956.87, 19646.03,
        20188.64, 20194.0, 20422.2, 20896.5, 19647.86, 20612.63, 20715.05,
        21771.43, 23629.16, 23318.32, 26613.33, 26379.78, 27590.69, 29320.86,
        28956.13, 26029.42, 25131.14, 27267.3, 25037.69, 24701.75, 24593.93,
        26620.52, 25975.53, 24101.68, 25641.38, 26110.28, 25830.02, 26408.53,
        26656.46, 25305.4, 26033.04, 26126.44, 25734.79, 26812.77, 28697.43,
        28756.72, 27996.72, 27908.72, 27565.55, 27944.66, 30113.15, 29500.52,
        29464.58, 26611.55, 25727.6, 26510.95, 27757.8, 25966.55, 25874.93,
        25325.18, 26201.9, 25238.92, 25431.17, 27346.37, 27606.89, 28120.71,
        27508.04, 28201.56, 27084.06, 29865.21, 29051.36, 27610.47, 27107.41,
        26767.86
      ],
      DOT: [
        10000.0, 10122.69, 10384.25, 10451.41, 10675.97, 10699.1, 10872.73,
        10782.43, 11004.63, 11356.48, 11370.41, 11648.2, 12101.85, 12400.47,
        13935.18, 13789.35, 13476.86, 13865.78, 13240.74, 13409.76, 14321.79,
        14402.82, 14317.13, 15423.62, 14395.86, 14615.75, 14967.6, 15027.8,
        14694.48, 15314.85, 14296.29, 14513.89, 14949.07, 15231.52, 16206.05,
        16101.89, 15381.95, 15219.96, 15951.4, 15592.64, 14273.16, 14252.33,
        14523.15, 14284.73, 13972.24, 14400.46, 15196.76, 14789.37, 16997.69,
        16770.83, 17307.87, 17386.6, 16699.13, 16548.64, 16351.88, 15178.29,
        15060.2, 15386.62, 15210.69, 14689.81, 15020.83, 14613.45, 13946.81,
        13562.49, 13861.11, 13736.12, 13425.93, 12881.98, 12298.61, 12861.15,
        12513.9, 13393.53, 14206.06, 14437.52, 13518.53, 14064.84, 15120.39,
        14736.14, 15044.03, 14185.23, 14706.04, 13979.21, 14560.22, 13895.88,
        13611.12, 14013.91, 13527.79, 14020.87, 14312.53, 14078.7, 14645.85,
        14662.08, 14527.82, 14796.34, 14937.5, 14821.77, 14562.52, 14321.79,
        14208.36, 14446.79, 14543.98, 14881.97, 14766.24, 15280.09, 15622.74,
        15710.67, 15870.37, 15553.28, 15988.46, 14750.01, 14101.9, 13467.59,
        13782.45, 13643.52, 13631.95, 13835.68, 13504.66, 13782.45, 13722.25,
        13923.61, 13703.72, 13078.74, 13256.97, 13430.6, 13078.74, 13555.59,
        13060.21, 13120.41, 12414.4, 12289.34, 12668.99, 12201.41, 12337.97,
        12419.0, 12419.0, 12358.81, 12252.34, 12495.37, 12391.2, 12442.14,
        12513.9, 12229.21, 12300.97, 12493.07, 12164.35, 12143.51, 12136.61,
        12351.91, 12807.92, 12645.86, 12622.73, 12287.04, 12083.38, 12245.38,
        12261.61, 12395.87, 11710.7, 12078.72, 11590.3, 11652.8, 11548.64,
        10483.81, 10437.55, 10474.55, 10648.17, 10560.18, 10136.56, 10222.26,
        10525.48, 10488.41, 10442.15, 10798.6, 11215.32, 11150.45, 11740.73,
        11555.6, 11988.42, 11724.56, 11775.5, 11326.38, 11493.11, 11958.32,
        12312.54, 12694.49, 12502.33, 12412.04, 12018.52, 11740.73, 11810.19,
        11858.83, 11810.19, 11844.96, 12039.35, 11881.96, 12787.09, 12486.1,
        12546.3, 12358.81, 12203.71, 11981.52, 11993.09, 12388.9, 12708.36,
        12398.17, 12476.9, 12076.42, 11967.59, 12122.68, 12115.78, 12055.58,
        12131.95, 11944.46, 11824.13, 11912.06, 11708.33, 11543.97, 11543.97,
        11527.81, 11594.9, 11490.74, 11696.77, 11685.2, 11567.17, 11592.6,
        11669.03, 11527.81, 11557.9, 11129.62, 10789.4, 10372.69, 10469.95,
        10430.58, 10456.02, 10280.09, 10122.69, 10393.52, 10201.42, 10377.35,
        10439.85, 10412.05, 10648.17, 10849.53, 10490.78, 9884.27, 9724.58,
        9861.14, 9861.14, 9768.54, 9865.74, 9865.74, 9928.24, 9826.37, 9821.77,
        9608.78, 9215.26, 9256.93, 9273.16, 9439.82, 9641.25, 9664.38, 9428.26,
        9557.92, 9613.45, 9583.35, 9310.23, 9307.86, 9310.23, 9319.43, 9402.82,
        9270.86, 9243.06, 9432.86, 9439.82, 9509.29, 9900.5, 9532.42, 9449.09,
        9356.49, 9321.79, 9428.26, 9379.62, 9280.13, 8930.58, 8844.95, 8636.62,
        8495.39, 8624.99, 8641.22, 8685.19, 8824.11, 8483.83, 8476.86, 8407.4,
        8624.99, 9055.57, 9159.73, 10004.67, 9736.14, 9905.1, 9821.77, 9548.65,
        9678.25, 10046.33, 10368.08, 10261.62, 10912.03, 10671.31, 10689.84,
        10886.6, 11055.56, 11541.67, 11361.15, 11731.53, 11516.24, 12129.65,
        12898.15, 13300.93, 12391.2, 12078.72, 12884.28, 12486.1, 12173.61,
        12208.38, 12511.6, 12317.14, 11256.98, 11956.02, 11888.92, 12143.51,
        12312.54, 12312.54, 11861.13, 12155.08, 11949.12, 12682.86, 12668.99,
        12851.89, 12916.68, 12895.85, 13419.03, 13993.07, 14358.79, 15629.64,
        16583.34, 16817.16, 15583.38, 16088.02, 17273.18, 17532.43, 16210.65,
        16446.78, 15803.27, 15844.93, 15525.48, 16020.86, 19011.61, 18416.73,
        19469.92, 19949.13, 21280.12, 20451.41, 19990.8, 19557.92, 19020.87,
        19296.36, 18812.55
      ],
      AVAX: [
        10000.0, 10018.41, 10229.73, 10432.0, 11020.21, 10808.78, 10818.04,
        10753.66, 11020.21, 11240.78, 11700.35, 14301.41, 14053.27, 14604.76,
        15505.48, 15551.44, 15367.58, 15735.31, 14770.22, 14816.19, 15937.48,
        15716.9, 15909.92, 16626.77, 15735.31, 16185.62, 16626.77, 18667.28,
        18887.86, 19163.56, 18023.86, 18299.56, 19108.44, 19678.24, 19623.12,
        19568.0, 18556.95, 18345.52, 19034.91, 18400.74, 16295.95, 16240.74,
        16534.94, 16378.63, 15974.19, 16617.62, 18069.82, 17178.26, 17931.93,
        17996.3, 18327.21, 19117.59, 19181.96, 18639.63, 18042.27, 16773.82,
        16461.41, 16847.35, 16488.97, 15772.02, 16222.43, 16038.56, 15248.09,
        14770.22, 14935.68, 15174.56, 14742.66, 13897.06, 13106.59, 13658.08,
        13207.67, 14696.69, 15174.56, 15698.5, 14264.69, 14521.98, 15965.04,
        15735.31, 16240.74, 15431.95, 15974.19, 15395.14, 16066.12, 15615.82,
        15367.58, 15735.31, 15110.29, 15330.87, 15919.07, 15753.61, 16277.55,
        16378.63, 15863.95, 15744.46, 16176.46, 16525.68, 16525.68, 16222.43,
        16029.41, 16194.87, 16443.01, 16700.3, 16672.74, 17279.35, 17499.93,
        17573.45, 18768.37, 19007.35, 19457.66, 17573.45, 16856.6, 15496.32,
        15873.11, 15551.44, 15551.44, 16148.9, 15707.65, 16277.55, 16121.24,
        16011.0, 15845.55, 15257.34, 15523.88, 15891.51, 15505.48, 15965.04,
        15284.9, 15202.22, 14145.2, 14034.86, 14292.25, 13740.76, 13915.37,
        13750.01, 13814.29, 13961.34, 13676.49, 13933.78, 13556.99, 13511.03,
        13446.66, 13170.96, 13492.62, 13529.34, 12977.94, 12950.38, 13180.11,
        13373.13, 13759.17, 13281.2, 13336.42, 12968.69, 13005.5, 13299.61,
        13382.28, 13658.08, 12738.96, 13446.66, 12867.6, 12858.45, 12702.15,
        10707.7, 10625.02, 10606.61, 10772.07, 10404.34, 10477.97, 10606.61,
        10726.1, 10468.71, 10542.24, 11093.73, 11939.33, 11617.67, 12141.5,
        11939.33, 12362.08, 12086.38, 12113.94, 11369.43, 11635.97, 11966.89,
        11911.77, 12067.97, 12141.5, 12058.82, 11617.67, 11544.14, 11681.94,
        12518.38, 12545.94, 12288.55, 12159.91, 11966.89, 12849.2, 13299.61,
        13621.27, 13189.27, 13189.27, 12637.87, 12858.45, 12757.36, 12867.6,
        12555.1, 12435.6, 12012.85, 12260.99, 12215.03, 12067.97, 12159.91,
        12150.75, 12003.6, 11783.03, 11709.5, 11534.89, 11452.21, 11396.99,
        11433.8, 11562.45, 11424.65, 11654.38, 11590.01, 11488.92, 11433.8,
        11387.84, 11249.94, 11341.87, 10836.34, 10432.0, 9761.02, 9935.63,
        9889.66, 10018.41, 9531.19, 9227.93, 9494.47, 9264.64, 9255.49, 9283.05,
        9365.83, 9503.63, 9981.59, 9558.85, 9209.52, 9090.03, 9108.44, 9062.47,
        8961.39, 9117.59, 9154.4, 9273.9, 9117.59, 9062.47, 8704.0, 8474.27,
        8501.83, 8556.95, 8667.28, 8685.69, 8621.32, 8345.52, 8437.45, 8474.27,
        8299.65, 8124.94, 8161.76, 8152.6, 8161.76, 8216.88, 8272.0, 8216.88,
        8483.42, 8409.89, 8492.67, 9025.76, 8556.95, 8694.84, 9347.42, 9310.61,
        9843.7, 9503.63, 9292.3, 8713.25, 8777.52, 8510.98, 8382.33, 8428.3,
        8409.89, 8419.15, 8667.28, 8373.18, 8216.88, 8327.21, 8483.42, 8897.01,
        9227.93, 9761.02, 9568.0, 9623.12, 10165.46, 9669.08, 10082.68, 10432.0,
        10514.68, 10321.66, 11148.85, 10854.75, 11130.45, 11222.38, 11314.31,
        12022.01, 11737.06, 12031.26, 11801.43, 13465.06, 15487.07, 17720.5,
        14926.42, 15781.17, 18906.17, 20294.1, 20321.66, 20220.58, 20284.85,
        19283.05, 17637.82, 19200.37, 19126.84, 19136.0, 19439.26, 19420.95,
        18584.51, 18933.83, 19347.42, 19623.12, 20036.71, 20321.66, 20358.38,
        20376.78, 22601.06, 24080.83, 24145.2, 27564.3, 29191.12, 34200.32,
        35854.7, 33841.85, 36406.19, 36259.14, 37453.96, 39181.87, 37481.52,
        37692.94, 36516.43, 39430.1, 42509.13, 41066.07, 43299.51, 43409.84,
        44402.49, 40707.6, 38795.83, 37196.57, 36930.03, 36130.4, 35156.16
      ],
      LTC: [
        10000.0, 10104.16, 10674.85, 10743.33, 10706.23, 10617.78, 10836.07,
        10877.45, 10918.82, 11636.46, 11526.61, 11806.26, 12265.66, 12264.23,
        12549.58, 12502.5, 12262.8, 12418.32, 11933.23, 11986.02, 12660.87,
        12727.93, 12471.1, 12894.84, 12375.52, 12636.6, 12463.97, 12696.54,
        12639.46, 13683.84, 12910.55, 13369.96, 14368.67, 14150.38, 14230.27,
        14120.41, 13722.35, 13807.96, 14365.81, 14101.87, 12949.06, 13261.53,
        13455.55, 13157.37, 12842.06, 13564.0, 14592.67, 14245.98, 14327.3,
        14233.13, 14011.99, 13568.27, 13274.36, 13489.81, 13459.84, 12984.74,
        13284.35, 13521.18, 13377.09, 13421.31, 13896.43, 13596.8, 12844.92,
        12619.48, 12864.9, 12525.34, 12221.44, 11749.18, 10817.52, 10241.12,
        9744.62, 10525.04, 11756.31, 11938.93, 10808.96, 11231.27, 12047.38,
        11897.57, 12131.54, 11402.49, 11817.65, 12324.15, 13301.47, 13205.89,
        13014.69, 13439.86, 12635.19, 12633.76, 13028.97, 12633.76, 12843.49,
        13258.67, 13242.98, 13288.64, 13215.86, 13184.48, 12929.1, 12930.53,
        12824.94, 12979.02, 13287.21, 13415.61, 13111.72, 13414.18, 13780.85,
        13807.96, 14387.21, 14096.17, 14465.7, 13271.5, 12776.43, 12190.04,
        12432.6, 12348.41, 12610.92, 12971.89, 12553.85, 12769.3, 12792.14,
        12964.77, 12658.01, 12346.98, 12622.34, 12723.64, 12558.14, 12677.99,
        11906.12, 11971.76, 11102.87, 11366.8, 11559.43, 11446.72, 11423.88,
        11480.95, 11910.39, 12489.65, 12767.87, 13378.52, 13043.23, 13071.76,
        13124.55, 13188.75, 12983.31, 13027.54, 12268.5, 12235.7, 12458.27,
        12639.46, 13071.76, 12994.73, 13153.08, 12810.68, 13485.52, 13569.7,
        13739.49, 13606.79, 12443.99, 12973.32, 12592.38, 12636.6, 12723.64,
        11075.76, 11070.06, 11024.4, 10970.19, 10416.61, 10609.22, 10881.72,
        11005.86, 10991.58, 11035.8, 11409.62, 12181.48, 12168.63, 12960.48,
        12645.18, 12569.56, 12431.17, 12501.07, 11864.74, 12063.07, 15380.22,
        15069.2, 16093.6, 15179.05, 15146.25, 14565.56, 13840.79, 14037.66,
        13947.78, 13669.57, 13733.77, 13835.07, 13726.64, 14415.75, 13448.42,
        13508.35, 13444.15, 13143.09, 13030.4, 13140.26, 13201.6, 13468.4,
        13237.26, 13203.03, 12686.55, 12783.55, 12847.78, 12862.04, 13037.53,
        13357.11, 13330.0, 13121.71, 13331.43, 12445.42, 11920.38, 11694.97,
        11881.86, 11784.85, 11760.6, 11974.6, 11976.03, 11846.19, 11897.57,
        11928.94, 11670.7, 11712.09, 11342.56, 10614.92, 9330.87, 9156.81,
        9095.44, 9308.03, 9514.92, 9169.63, 9350.84, 9238.13, 9308.03, 9283.78,
        9305.19, 9353.68, 9860.18, 9626.2, 9146.82, 9014.12, 9202.46, 9105.43,
        9049.79, 8994.14, 8944.22, 9105.43, 8912.82, 9015.55, 8714.52, 8362.11,
        8581.82, 8855.75, 8997.0, 9409.33, 9285.21, 9062.64, 9464.97, 9613.35,
        9199.6, 9202.46, 9189.61, 9256.67, 9161.07, 9168.2, 9056.94, 9068.33,
        9318.02, 9365.1, 9454.98, 9794.54, 9383.65, 9362.24, 9192.47, 9258.1,
        9356.54, 9330.87, 9286.64, 8982.75, 9096.87, 8763.01, 8720.21, 8771.59,
        8795.84, 8782.99, 9058.35, 8861.47, 8661.73, 8784.42, 9061.21, 9258.1,
        9260.94, 9921.54, 9863.04, 9808.82, 9821.65, 9502.07, 9678.99, 9861.61,
        9851.62, 9805.96, 9970.03, 9870.16, 9912.98, 9964.33, 10192.61,
        10686.25, 10456.55, 10472.26, 10312.45, 10469.4, 10710.52, 10731.91,
        10175.49, 10031.4, 10552.15, 10142.68, 10022.84, 9942.93, 9985.74,
        9905.83, 9393.64, 9783.15, 9928.67, 10065.63, 10241.12, 10018.55,
        9845.92, 9930.1, 9978.59, 9900.13, 10188.34, 10303.9, 10342.42,
        10313.88, 10445.15, 10385.22, 10550.72, 11041.52, 11017.25, 11018.68,
        10338.13, 10283.92, 10450.85, 10420.88, 10165.5, 10295.34, 10229.7,
        10081.32, 10059.93, 10007.13, 10108.43, 10486.51, 10301.04, 10107.0,
        10328.14, 10399.5, 10696.24, 10933.1, 10432.3, 10408.05, 10335.29
      ],
      SHIB: [
        10000.0, 10024.72, 10123.59, 10037.08, 10420.25, 10506.8, 10432.61,
        10395.53, 10593.32, 10766.39, 11396.78, 11161.92, 11730.51, 12360.95,
        12818.28, 13374.52, 13028.42, 13040.78, 13967.83, 14153.26, 14956.72,
        14845.49, 14746.58, 14882.57, 13893.68, 14252.17, 14313.97, 14437.56,
        14536.47, 14894.93, 14140.9, 14561.19, 14697.14, 15265.74, 15599.52,
        18603.22, 17873.91, 17750.28, 17775.04, 16823.23, 14709.5, 15995.05,
        15896.18, 15859.06, 15228.66, 15648.95, 16860.3, 15723.11, 16316.42,
        16279.35, 16674.88, 16551.29, 16106.28, 15995.05, 16229.91, 15401.73,
        15302.82, 15784.9, 15438.81, 14932.0, 15068.0, 14932.0, 14029.67,
        13671.17, 13757.73, 13807.17, 13461.07, 13461.07, 12410.39, 12669.97,
        12583.41, 13102.57, 13733.01, 13844.24, 12793.56, 12917.19, 13658.81,
        13337.44, 13584.66, 12991.34, 13498.15, 13016.06, 13349.8, 13127.29,
        12954.27, 13127.29, 12842.99, 12880.07, 13213.85, 13028.42, 13461.07,
        13856.6, 13374.52, 13881.32, 13918.4, 13856.6, 13510.51, 13522.87,
        13386.87, 13584.66, 13720.65, 13683.53, 13522.87, 13720.65, 13943.11,
        14091.46, 14338.69, 14128.54, 14301.61, 13510.51, 13065.5, 12657.61,
        12904.83, 12744.12, 12694.69, 12830.63, 12509.26, 12694.69, 12669.97,
        12694.69, 12496.9, 12311.47, 12385.67, 12571.05, 12224.96, 12336.19,
        11693.44, 11631.64, 10939.41, 11100.12, 11161.92, 10729.31, 10889.97,
        10815.82, 10852.9, 10927.05, 10815.82, 10865.26, 10815.82, 10741.67,
        10778.74, 10556.24, 10840.54, 11001.25, 10519.16, 10506.8, 10568.6,
        10655.11, 10939.41, 10766.39, 10754.03, 10494.45, 10482.09, 10630.39,
        10630.39, 10692.19, 9851.65, 10160.67, 9703.34, 9802.21, 9740.42,
        8615.58, 8257.08, 8244.72, 8356.0, 8096.42, 8343.64, 8417.79, 8615.58,
        8862.8, 8949.32, 8961.67, 9357.2, 9690.98, 9814.57, 9666.26, 9740.42,
        9320.13, 9344.85, 8974.03, 9060.55, 9394.32, 9443.76, 9480.84, 9579.71,
        9542.63, 9208.9, 8949.32, 9035.83, 9480.84, 9554.99, 9295.41, 9295.41,
        9258.33, 9814.57, 9789.85, 10024.72, 9789.85, 9653.91, 9530.27, 9567.35,
        9542.63, 9690.98, 9666.26, 9690.98, 9468.48, 9777.5, 9703.34, 9616.79,
        10148.31, 10580.96, 10210.15, 10457.37, 10296.66, 10123.59, 10234.86,
        10716.95, 12484.54, 11569.85, 11100.12, 11644.0, 11928.3, 12249.68,
        12966.63, 13337.44, 12632.89, 12645.25, 12299.11, 11606.92, 10024.72,
        10630.39, 10370.81, 10333.74, 9864.01, 9777.5, 10309.02, 10074.15,
        10049.44, 10024.72, 9950.56, 10160.67, 10370.81, 10061.79, 9901.13,
        9703.34, 9690.98, 9468.48, 9245.97, 9456.12, 9468.48, 9493.2, 9357.2,
        9344.85, 9122.38, 8702.09, 8875.16, 8961.67, 9134.74, 9221.26, 9122.38,
        8850.44, 8986.39, 9159.46, 9184.18, 8998.75, 9035.83, 9233.61, 9085.26,
        8986.39, 8924.6, 8924.6, 9011.11, 9035.83, 9085.26, 9320.13, 9023.47,
        8998.75, 8961.67, 8862.8, 8936.96, 8949.32, 8887.52, 8529.02, 8541.38,
        8368.36, 8417.79, 8553.79, 8652.66, 8627.94, 8800.96, 8541.38, 8417.79,
        8417.79, 8590.86, 8751.53, 8776.25, 9221.26, 9196.54, 9715.7, 9777.5,
        9480.84, 9913.49, 9950.56, 9851.65, 9616.79, 9765.14, 9703.34, 9666.26,
        9826.93, 10037.08, 10469.73, 10111.23, 10284.3, 10148.31, 10531.52,
        11149.56, 11100.12, 10593.32, 10321.38, 10877.62, 10531.52, 10741.67,
        10543.88, 10580.96, 10469.73, 9554.99, 10012.36, 10037.08, 10111.23,
        10445.01, 10259.58, 10074.15, 10222.5, 10160.67, 10234.86, 10346.09,
        10519.16, 11273.19, 11186.63, 11582.21, 12064.25, 12064.25, 12645.25,
        12472.18, 12793.56, 11742.87, 11767.59, 12101.37, 12472.18, 11928.3,
        13609.38, 12818.28, 12892.47, 12484.54, 12571.05, 12892.47, 13423.95,
        13584.66, 13127.29, 13634.1, 13040.78, 13362.16, 13114.93, 12805.92,
        12892.47, 12731.76
      ],
      POL: [
        10000.0, 10018.49, 10325.16, 10268.34, 10605.4, 10440.18, 10552.53,
        10654.31, 10838.05, 11161.92, 11288.81, 11528.08, 12085.89, 12274.93,
        13123.59, 13050.89, 13483.13, 13300.7, 12605.4, 12548.55, 13393.25,
        13255.75, 13065.42, 13161.92, 12691.32, 12972.88, 14637.14, 15222.73,
        15040.31, 15484.44, 14240.56, 14660.9, 15726.35, 15817.55, 16383.33,
        16658.26, 15960.32, 15928.58, 16604.08, 17243.85, 16126.89, 16371.43,
        16527.39, 16429.57, 15485.76, 16536.65, 17439.49, 18274.93, 20144.06,
        19693.3, 19702.56, 19344.31, 18276.25, 18181.07, 17789.8, 16630.51,
        16341.01, 16819.52, 16306.64, 15894.24, 16415.03, 16211.49, 15435.53,
        14812.93, 15079.95, 14979.5, 14983.45, 13895.56, 13298.07, 14029.07,
        13801.69, 14774.6, 15773.94, 15664.23, 14667.52, 15111.66, 16043.6,
        15603.42, 15516.18, 14687.36, 15148.67, 14610.67, 14885.64, 14440.15,
        14182.39, 14641.09, 13768.64, 14355.55, 14790.45, 14347.64, 14778.56,
        14734.95, 14511.53, 14499.64, 15099.76, 15073.33, 14684.69, 14641.09,
        14573.66, 14626.56, 14794.41, 14721.7, 14534.01, 14968.92, 15406.47,
        15528.08, 15676.13, 15298.07, 15442.16, 14483.78, 13859.86, 13296.75,
        13558.47, 13233.27, 13081.27, 13220.06, 12979.5, 13335.08, 13198.93,
        13270.32, 13044.26, 12664.89, 12938.5, 13372.09, 12946.44, 13210.83,
        12905.48, 12845.99, 11896.88, 11541.3, 11588.89, 11070.69, 11411.74,
        11241.22, 11349.61, 11426.27, 11165.88, 11698.6, 11592.84, 11502.97,
        11562.45, 11344.34, 11517.5, 11775.26, 11545.25, 11748.83, 12036.98,
        12166.53, 12538.0, 11989.42, 11945.78, 11787.16, 11759.41, 11904.82,
        11890.25, 11908.77, 10999.31, 10848.63, 10080.61, 10347.64, 9636.48,
        8113.67, 8347.64, 8506.26, 8450.76, 8146.73, 7820.22, 7820.22, 8152.0,
        7886.3, 8091.19, 8306.67, 8883.0, 8668.84, 8901.49, 8597.49, 8839.36,
        8538.0, 8795.76, 8150.68, 8237.92, 8773.28, 8883.0, 9035.0, 9432.9,
        9245.2, 8822.19, 8856.57, 8939.85, 8974.19, 9140.76, 9684.07, 9799.06,
        9587.57, 11150.02, 10575.01, 10545.91, 10270.98, 10289.47, 9763.36,
        9832.11, 10202.23, 10182.39, 10017.17, 9984.11, 9615.32, 9348.3,
        9533.35, 9464.64, 9383.99, 9493.7, 9176.45, 9083.91, 9094.49, 9016.51,
        8835.41, 8773.28, 8822.19, 8816.92, 8795.76, 9046.9, 9119.6, 9052.2,
        8999.34, 9017.83, 8950.43, 8984.77, 8346.32, 8215.44, 7669.53, 7664.23,
        7596.83, 7673.49, 7370.77, 7205.56, 7337.72, 7193.66, 7217.42, 7250.48,
        7352.28, 7421.0, 7891.6, 7586.25, 7265.01, 7134.17, 7147.39, 7138.13,
        7316.59, 7383.99, 7382.67, 7358.88, 7165.88, 7143.4, 6920.01, 6626.56,
        6723.06, 6794.44, 6938.53, 7029.73, 6979.5, 6835.41, 6980.82, 7183.08,
        7169.86, 6890.94, 6883.0, 6863.16, 6851.26, 6851.26, 6725.69, 6659.61,
        6842.03, 6945.13, 7058.8, 7545.25, 7245.2, 7533.35, 7452.74, 7255.78,
        7477.85, 7468.59, 7393.25, 6995.35, 6853.93, 6790.45, 6717.78, 6816.92,
        6832.77, 6803.7, 7070.69, 6860.52, 6781.23, 6822.19, 7107.7, 7595.48,
        7919.35, 8511.57, 8321.2, 8347.64, 8366.16, 8023.8, 8224.7, 8469.25,
        8541.95, 8380.69, 8754.76, 8713.8, 8844.67, 8960.98, 9110.38, 9690.66,
        9771.31, 10409.76, 11032.36, 11241.22, 10660.93, 11986.75, 11797.74,
        12158.59, 12270.98, 11284.85, 11044.26, 10955.7, 11103.75, 10758.75,
        9612.68, 10259.08, 10280.2, 10027.75, 10244.55, 10113.67, 9769.99,
        9903.5, 10019.81, 10058.14, 10489.09, 10741.55, 10840.68, 10590.86,
        10641.09, 10872.42, 11024.42, 11907.45, 11841.34, 12220.75, 11109.02,
        11472.55, 11701.24, 11658.92, 11153.98, 11228.0, 10918.69, 10576.33,
        10099.14, 10200.91, 10721.74, 11460.65, 11265.01, 11222.73, 12130.84,
        13465.95, 13726.35, 13229.32, 12705.85, 12593.5, 12778.56
      ],
      UNI: [
        10000.0, 10182.14, 10379.82, 10399.22, 10635.63, 10436.03, 10689.9,
        10476.74, 10660.82, 11195.71, 11182.14, 11356.58, 11943.76, 12168.58,
        12788.73, 12941.83, 12749.97, 12360.44, 11813.92, 11870.13, 12777.11,
        13077.5, 13236.43, 13005.78, 12263.56, 12643.38, 13056.16, 13176.33,
        12746.09, 13401.15, 12498.06, 12765.49, 13220.92, 13965.08, 13687.97,
        14122.07, 13372.07, 13050.37, 13488.35, 13153.08, 12116.26, 12290.69,
        12565.86, 12308.11, 12040.68, 12738.35, 13383.69, 12691.85, 13021.29,
        13565.87, 14077.51, 14199.58, 13581.38, 13410.83, 13416.66, 12655.0,
        12480.6, 12724.78, 12474.77, 12532.93, 13218.97, 12854.63, 12112.37,
        11841.05, 12356.55, 12302.32, 12389.52, 11868.19, 11013.54, 10922.45,
        10467.02, 11281.0, 11866.25, 12267.41, 11358.52, 11434.1, 12282.92,
        12515.47, 12668.57, 12050.36, 12403.08, 11744.17, 12061.99, 11401.13,
        11063.92, 11209.28, 10877.9, 11244.15, 11534.87, 11354.64, 11769.37,
        11796.5, 11517.41, 11525.18, 11959.28, 12063.93, 11757.74, 11660.82,
        11457.35, 11519.35, 11678.28, 11525.18, 11470.91, 12011.6, 12218.97,
        12168.58, 12280.98, 11926.35, 12257.73, 11354.64, 11203.49, 10529.06,
        10649.19, 10536.8, 10372.08, 10529.06, 10308.13, 10773.24, 10689.9,
        10889.52, 10653.08, 10308.13, 10333.32, 10439.92, 10155.04, 10750.0,
        10279.05, 10302.3, 9625.94, 9581.38, 9854.64, 9810.05, 10056.18,
        9920.54, 9945.73, 9986.43, 9974.77, 10184.08, 10290.68, 10298.42,
        10238.35, 9924.39, 9835.25, 9852.7, 9635.65, 9589.12, 9635.65, 9753.88,
        9992.23, 9910.82, 9961.24, 9684.09, 9693.78, 9831.39, 9829.45, 9779.07,
        9153.09, 9277.1, 8765.49, 8984.48, 8891.45, 7926.35, 7848.83, 8339.14,
        8267.41, 8575.58, 8589.15, 8810.05, 8790.68, 8691.86, 8707.34, 8827.5,
        9288.73, 9155.03, 9414.72, 9127.9, 10563.94, 10195.7, 10186.02, 9381.76,
        9536.79, 10265.49, 10780.98, 10850.76, 10686.05, 10674.38, 10463.17,
        10416.64, 10596.87, 10125.96, 10189.91, 10118.19, 10164.72, 10176.34,
        11261.61, 11236.41, 11195.71, 11098.83, 11451.52, 11437.95, 11391.45,
        11594.93, 12031.0, 11858.51, 11897.27, 11187.97, 11222.85, 11317.82,
        11352.69, 11639.52, 12232.53, 12284.86, 12641.43, 12806.19, 11937.97,
        11831.37, 11740.29, 11779.05, 11571.68, 11343.01, 11748.02, 12038.74,
        12031.0, 11980.62, 12011.6, 11784.88, 12172.47, 11550.38, 10490.3,
        9705.4, 9560.08, 9468.99, 9513.55, 9300.39, 8999.99, 9209.3, 8976.74,
        8811.99, 8936.04, 9102.7, 9067.83, 9356.56, 9013.56, 8465.1, 8251.94,
        8484.5, 8517.43, 8554.24, 8472.84, 8625.97, 8748.04, 8467.04, 8405.03,
        8178.27, 7928.3, 8149.19, 8253.85, 8321.69, 8447.64, 8416.66, 8257.73,
        8494.18, 8542.62, 8449.59, 8228.66, 8259.68, 8251.94, 8277.13, 8263.56,
        8205.41, 8230.6, 8567.81, 8527.11, 8647.27, 9054.26, 8664.73, 8465.1,
        8387.58, 8315.89, 8509.69, 8476.72, 8410.83, 7998.04, 7990.3, 7984.47,
        7728.67, 7804.25, 7916.64, 7945.72, 8017.44, 7540.7, 7639.53, 7600.77,
        7761.6, 8054.26, 8096.9, 8538.73, 8211.24, 8063.94, 7999.99, 7753.86,
        7924.41, 8129.83, 8065.88, 8019.38, 9217.04, 9063.94, 9040.7, 9195.73,
        9279.05, 9976.72, 9542.62, 10029.04, 9701.55, 10217.05, 10509.67,
        10591.07, 10131.75, 9817.83, 10385.65, 10110.45, 9862.38, 9804.26,
        9968.98, 10050.38, 9428.29, 11802.29, 12436.01, 11943.76, 11937.97,
        11887.59, 12036.79, 11874.02, 11668.6, 11449.61, 11686.02, 11868.19,
        11823.64, 11651.14, 12071.67, 11651.14, 12104.63, 12532.93, 13408.88,
        12827.49, 11817.81, 11930.2, 12224.8, 12242.22, 11687.96, 11945.71,
        11905.01, 11498.05, 11308.1, 11436.04, 11951.54, 12422.45, 12201.51,
        13364.33, 13490.29, 14220.89, 14021.3, 15494.16, 14453.48, 14271.31,
        14044.55
      ],
      USDT: [
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0
      ],
      HODLIE: [
        10000.0, 10003.46, 10198.01, 10786.18, 10827.92, 10796.81, 10778.37,
        10793.13, 10925.34, 11231.89, 11215.07, 11188.49, 11114.88, 11217.99,
        11644.82, 11571.98, 11636.38, 11556.52, 11323.91, 11366.13, 11495.17,
        11408.61, 11315.16, 11332.8, 11131.22, 11151.15, 11138.21, 11205.77,
        11151.72, 11236.19, 11066.59, 11139.08, 11099.32, 11033.91, 11072.22,
        11139.74, 11058.66, 11040.68, 11043.53, 11081.81, 10906.06, 10945.89,
        10923.36, 10923.82, 10707.38, 10880.06, 11213.49, 11150.93, 11262.0,
        11184.96, 11154.83, 11343.21, 11141.72, 10954.4, 10906.42, 10726.36,
        10759.76, 10780.99, 10758.55, 10692.84, 10835.8, 10783.39, 10638.96,
        10619.68, 10620.04, 10619.76, 10641.47, 10644.55, 10557.59, 10472.73,
        10471.96, 10614.99, 10841.6, 10941.53, 10867.88, 11005.73, 11548.42,
        11424.0, 11673.55, 11535.28, 11530.1, 11242.46, 11403.21, 11320.34,
        11283.88, 11379.82, 11531.02, 11794.91, 11932.19, 11874.79, 11912.91,
        11823.9, 11731.69, 11722.33, 11714.07, 11654.03, 11469.96, 11399.85,
        11374.16, 11375.39, 11593.89, 11723.18, 11820.32, 11926.6, 11995.24,
        11969.46, 12059.21, 11956.2, 12012.32, 11715.78, 11692.21, 11692.25,
        11694.12, 11681.32, 11687.65, 11749.38, 11638.13, 11855.54, 11846.41,
        11792.36, 11807.08, 11601.01, 11558.59, 11582.51, 11470.46, 11765.85,
        11493.89, 11491.02, 11489.21, 11488.84, 11489.4, 11460.97, 11450.12,
        11380.32, 11389.21, 11465.76, 11517.58, 11634.89, 11587.99, 11632.5,
        11641.7, 11570.81, 11507.84, 11620.08, 11449.71, 11492.62, 11563.05,
        11592.45, 11686.11, 11577.39, 11706.11, 11690.2, 11637.83, 11695.51,
        11710.24, 11770.37, 11520.23, 11538.24, 11465.4, 11464.5, 11457.4,
        11219.14, 11218.08, 11217.31, 11073.59, 11127.52, 11126.78, 11124.38,
        11145.94, 11102.1, 11189.29, 11436.13, 11811.71, 11773.6, 11911.95,
        11900.56, 11915.93, 11843.98, 11838.03, 11746.16, 11713.46, 11972.82,
        11836.28, 12013.21, 11863.46, 11818.84, 11679.33, 11764.11, 11998.57,
        12078.87, 11974.04, 11856.93, 11881.92, 11820.71, 12282.33, 11941.99,
        11925.12, 12089.48, 12009.06, 11973.28, 12155.41, 12085.88, 12058.01,
        11991.02, 12003.82, 11774.6, 11997.73, 11897.08, 11889.27, 11854.87,
        11906.18, 11896.58, 11970.91, 12011.22, 11826.66, 11820.76, 11816.02,
        12183.56, 12004.02, 11917.89, 12046.05, 12083.03, 12085.7, 12224.63,
        12298.22, 12167.77, 12181.32, 11955.51, 11959.98, 11957.09, 11955.98,
        11956.02, 11955.92, 11879.12, 11771.68, 11815.25, 11645.23, 11639.97,
        11636.89, 11627.23, 11644.04, 11747.31, 11628.14, 11379.14, 11301.04,
        11322.53, 11342.8, 11325.74, 11240.13, 11116.64, 11163.59, 11097.36,
        11078.24, 11015.15, 11005.96, 10975.35, 10946.9, 10998.38, 10973.73,
        10951.38, 10906.0, 10886.17, 11015.71, 11026.35, 10952.16, 11059.75,
        11162.22, 11088.36, 11250.07, 11204.25, 11314.67, 11387.74, 11421.36,
        11439.92, 11945.32, 11772.88, 11810.96, 11698.78, 11698.31, 11878.57,
        11782.47, 11708.88, 11612.57, 11612.99, 11612.28, 11611.96, 11612.05,
        11590.52, 11578.28, 11969.25, 11933.19, 11879.22, 12071.56, 12467.24,
        12823.43, 12959.13, 13213.65, 13016.18, 13099.29, 13029.45, 12956.83,
        13033.46, 13108.3, 13133.51, 13493.82, 13985.8, 13760.28, 13722.65,
        13671.67, 13833.85, 13986.39, 13841.81, 14053.6, 13848.02, 14268.58,
        14399.73, 14312.32, 13730.29, 13928.72, 13942.93, 13643.24, 13652.21,
        13349.84, 13237.69, 13020.05, 12762.16, 12876.59, 13002.74, 12885.92,
        12882.88, 12761.22, 12599.39, 12553.24, 12479.21, 12483.31, 12538.95,
        12560.83, 12675.55, 12814.54, 13164.05, 13087.33, 13085.79, 13403.74,
        13685.75, 13766.13, 13923.54, 13741.5, 14003.52, 13798.66, 13496.06,
        13443.92, 13084.83, 13022.28, 12950.97, 13222.78, 13890.03, 14005.87,
        14562.02, 14694.21, 15027.28, 14702.16, 14798.96, 14666.65, 14424.21,
        14379.51, 14418.46
      ]
    },
    2024: {
      TIMESTAMP: [
        '2024-01-01',
        '2024-01-02',
        '2024-01-03',
        '2024-01-04',
        '2024-01-05',
        '2024-01-06',
        '2024-01-07',
        '2024-01-08',
        '2024-01-09',
        '2024-01-10',
        '2024-01-11',
        '2024-01-12',
        '2024-01-13',
        '2024-01-14',
        '2024-01-15',
        '2024-01-16',
        '2024-01-17',
        '2024-01-18',
        '2024-01-19',
        '2024-01-20',
        '2024-01-21',
        '2024-01-22',
        '2024-01-23',
        '2024-01-24',
        '2024-01-25',
        '2024-01-26',
        '2024-01-27',
        '2024-01-28',
        '2024-01-29',
        '2024-01-30',
        '2024-01-31',
        '2024-02-01',
        '2024-02-02',
        '2024-02-03',
        '2024-02-04',
        '2024-02-05',
        '2024-02-06',
        '2024-02-07',
        '2024-02-08',
        '2024-02-09',
        '2024-02-10',
        '2024-02-11',
        '2024-02-12',
        '2024-02-13',
        '2024-02-14',
        '2024-02-15',
        '2024-02-16',
        '2024-02-17',
        '2024-02-18',
        '2024-02-19',
        '2024-02-20',
        '2024-02-21',
        '2024-02-22',
        '2024-02-23',
        '2024-02-24',
        '2024-02-25',
        '2024-02-26',
        '2024-02-27',
        '2024-02-28',
        '2024-02-29',
        '2024-03-01',
        '2024-03-02',
        '2024-03-03',
        '2024-03-04',
        '2024-03-05',
        '2024-03-06',
        '2024-03-07',
        '2024-03-08',
        '2024-03-09',
        '2024-03-10',
        '2024-03-11',
        '2024-03-12',
        '2024-03-13',
        '2024-03-14',
        '2024-03-15',
        '2024-03-16',
        '2024-03-17',
        '2024-03-18',
        '2024-03-19',
        '2024-03-20',
        '2024-03-21',
        '2024-03-22',
        '2024-03-23',
        '2024-03-24',
        '2024-03-25',
        '2024-03-26',
        '2024-03-27',
        '2024-03-28',
        '2024-03-29',
        '2024-03-30',
        '2024-03-31',
        '2024-04-01',
        '2024-04-02',
        '2024-04-03',
        '2024-04-04',
        '2024-04-05',
        '2024-04-06',
        '2024-04-07',
        '2024-04-08',
        '2024-04-09',
        '2024-04-10',
        '2024-04-11',
        '2024-04-12',
        '2024-04-13',
        '2024-04-14',
        '2024-04-15',
        '2024-04-16',
        '2024-04-17',
        '2024-04-18',
        '2024-04-19',
        '2024-04-20',
        '2024-04-21',
        '2024-04-22',
        '2024-04-23',
        '2024-04-24',
        '2024-04-25',
        '2024-04-26',
        '2024-04-27',
        '2024-04-28',
        '2024-04-29',
        '2024-04-30',
        '2024-05-01',
        '2024-05-02',
        '2024-05-03',
        '2024-05-04',
        '2024-05-05',
        '2024-05-06',
        '2024-05-07',
        '2024-05-08',
        '2024-05-09',
        '2024-05-10',
        '2024-05-11',
        '2024-05-12',
        '2024-05-13',
        '2024-05-14',
        '2024-05-15',
        '2024-05-16',
        '2024-05-17',
        '2024-05-18',
        '2024-05-19',
        '2024-05-20',
        '2024-05-21',
        '2024-05-22',
        '2024-05-23',
        '2024-05-24',
        '2024-05-25',
        '2024-05-26',
        '2024-05-27',
        '2024-05-28',
        '2024-05-29',
        '2024-05-30',
        '2024-05-31',
        '2024-06-01',
        '2024-06-02',
        '2024-06-03',
        '2024-06-04',
        '2024-06-05',
        '2024-06-06',
        '2024-06-07',
        '2024-06-08',
        '2024-06-09',
        '2024-06-10',
        '2024-06-11',
        '2024-06-12',
        '2024-06-13',
        '2024-06-14',
        '2024-06-15',
        '2024-06-16',
        '2024-06-17',
        '2024-06-18',
        '2024-06-19',
        '2024-06-20',
        '2024-06-21',
        '2024-06-22',
        '2024-06-23',
        '2024-06-24',
        '2024-06-25',
        '2024-06-26',
        '2024-06-27',
        '2024-06-28',
        '2024-06-29',
        '2024-06-30',
        '2024-07-01',
        '2024-07-02',
        '2024-07-03',
        '2024-07-04',
        '2024-07-05',
        '2024-07-06',
        '2024-07-07',
        '2024-07-08',
        '2024-07-09',
        '2024-07-10',
        '2024-07-11',
        '2024-07-12',
        '2024-07-13',
        '2024-07-14',
        '2024-07-15',
        '2024-07-16',
        '2024-07-17',
        '2024-07-18',
        '2024-07-19',
        '2024-07-20',
        '2024-07-21',
        '2024-07-22',
        '2024-07-23',
        '2024-07-24',
        '2024-07-25',
        '2024-07-26',
        '2024-07-27',
        '2024-07-28',
        '2024-07-29',
        '2024-07-30',
        '2024-07-31',
        '2024-08-01',
        '2024-08-02',
        '2024-08-03',
        '2024-08-04',
        '2024-08-05',
        '2024-08-06',
        '2024-08-07',
        '2024-08-08',
        '2024-08-09',
        '2024-08-10',
        '2024-08-11',
        '2024-08-12',
        '2024-08-13',
        '2024-08-14',
        '2024-08-15',
        '2024-08-16',
        '2024-08-17',
        '2024-08-18',
        '2024-08-19',
        '2024-08-20',
        '2024-08-21',
        '2024-08-22',
        '2024-08-23',
        '2024-08-24',
        '2024-08-25',
        '2024-08-26',
        '2024-08-27',
        '2024-08-28',
        '2024-08-29',
        '2024-08-30',
        '2024-08-31',
        '2024-09-01',
        '2024-09-02',
        '2024-09-03',
        '2024-09-04',
        '2024-09-05',
        '2024-09-06',
        '2024-09-07',
        '2024-09-08',
        '2024-09-09',
        '2024-09-10',
        '2024-09-11',
        '2024-09-12',
        '2024-09-13',
        '2024-09-14',
        '2024-09-15',
        '2024-09-16',
        '2024-09-17',
        '2024-09-18',
        '2024-09-19',
        '2024-09-20',
        '2024-09-21',
        '2024-09-22',
        '2024-09-23',
        '2024-09-24',
        '2024-09-25',
        '2024-09-26',
        '2024-09-27',
        '2024-09-28',
        '2024-09-29',
        '2024-09-30',
        '2024-10-01',
        '2024-10-02',
        '2024-10-03',
        '2024-10-04',
        '2024-10-05',
        '2024-10-06',
        '2024-10-07',
        '2024-10-08',
        '2024-10-09',
        '2024-10-10',
        '2024-10-11',
        '2024-10-12',
        '2024-10-13',
        '2024-10-14',
        '2024-10-15',
        '2024-10-16',
        '2024-10-17',
        '2024-10-18',
        '2024-10-19',
        '2024-10-20',
        '2024-10-21',
        '2024-10-22',
        '2024-10-23',
        '2024-10-24',
        '2024-10-25',
        '2024-10-26',
        '2024-10-27',
        '2024-10-28',
        '2024-10-29',
        '2024-10-30',
        '2024-10-31',
        '2024-11-01',
        '2024-11-02',
        '2024-11-03',
        '2024-11-04',
        '2024-11-05',
        '2024-11-06',
        '2024-11-07',
        '2024-11-08',
        '2024-11-09',
        '2024-11-10',
        '2024-11-11',
        '2024-11-12',
        '2024-11-13',
        '2024-11-14',
        '2024-11-15',
        '2024-11-16',
        '2024-11-17',
        '2024-11-18',
        '2024-11-19',
        '2024-11-20',
        '2024-11-21',
        '2024-11-22',
        '2024-11-23',
        '2024-11-24',
        '2024-11-25',
        '2024-11-26',
        '2024-11-27',
        '2024-11-28',
        '2024-11-29',
        '2024-11-30',
        '2024-12-01',
        '2024-12-02',
        '2024-12-03',
        '2024-12-04',
        '2024-12-05',
        '2024-12-06',
        '2024-12-07',
        '2024-12-08',
        '2024-12-09',
        '2024-12-10',
        '2024-12-11',
        '2024-12-12',
        '2024-12-13',
        '2024-12-14',
        '2024-12-15',
        '2024-12-16',
        '2024-12-17',
        '2024-12-18',
        '2024-12-19',
        '2024-12-20',
        '2024-12-21',
        '2024-12-22',
        '2024-12-23',
        '2024-12-24',
        '2024-12-25',
        '2024-12-26',
        '2024-12-27',
        '2024-12-28',
        '2024-12-29',
        '2024-12-30',
        '2024-12-31',
        '2025-01-01'
      ],
      BTC: [
        10000.0, 10301.02, 10644.57, 10116.38, 10487.51, 10404.55, 10383.54,
        10379.8, 11122.18, 10945.65, 11099.16, 10980.2, 10059.66, 10173.41,
        10003.11, 10087.58, 10224.37, 10109.86, 9786.26, 9864.92, 9887.28,
        9852.22, 9426.12, 9339.96, 9436.35, 9455.35, 9916.8, 9975.78, 9899.7,
        10201.91, 10253.18, 10094.23, 10158.37, 10223.55, 10183.03, 10074.24,
        10058.42, 10222.94, 10450.28, 10713.39, 11186.7, 11317.63, 11391.01,
        11837.11, 11718.89, 12256.28, 12221.25, 12300.16, 12236.89, 12362.14,
        12290.35, 12345.31, 12189.71, 12176.67, 12018.59, 12200.47, 12234.88,
        12934.81, 13463.27, 14500.23, 14488.19, 14787.79, 14597.29, 14867.63,
        16074.9, 15002.64, 15598.64, 15879.21, 16115.83, 16178.05, 16190.72,
        17142.63, 16839.94, 17263.48, 16929.2, 16352.99, 15703.84, 16145.63,
        16018.79, 14821.84, 16016.4, 15474.55, 14913.66, 15322.08, 15843.49,
        16638.32, 16623.93, 16432.48, 16765.63, 16519.52, 16498.91, 16794.51,
        16496.75, 15574.83, 15651.52, 16076.77, 16003.76, 16341.57, 16375.64,
        17018.39, 16405.38, 16689.91, 16600.75, 15867.65, 15214.54, 15459.91,
        15017.31, 15093.66, 14598.45, 15026.72, 15148.06, 15290.29, 15388.94,
        15861.65, 15704.25, 15167.82, 15287.79, 15102.96, 14969.99, 14944.21,
        15119.07, 14338.1, 13696.99, 14039.52, 14856.98, 15053.74, 15111.98,
        15025.76, 14873.19, 14494.57, 14974.84, 14400.63, 14413.88, 14512.32,
        14854.04, 14602.91, 15645.91, 15480.89, 15800.98, 15838.94, 15704.43,
        16468.12, 16617.54, 16361.75, 16017.48, 16278.15, 16358.84, 16224.2,
        16468.31, 16237.3, 16015.95, 16166.2, 15975.61, 16036.16, 16074.63,
        16338.37, 16697.78, 16835.87, 16778.17, 16446.41, 16408.33, 16478.43,
        16445.68, 15940.27, 16173.51, 15830.42, 15621.7, 15665.72, 15797.29,
        15773.62, 15448.0, 15342.94, 15379.36, 15181.78, 15219.83, 15064.17,
        14210.12, 14650.52, 14417.34, 14586.63, 14285.62, 14428.08, 14885.04,
        14902.57, 14693.12, 14273.32, 13734.45, 13398.94, 13750.44, 13345.56,
        13413.94, 13710.1, 13656.79, 13568.73, 13676.03, 14071.83, 14402.38,
        15226.42, 15291.09, 15208.8, 15152.68, 15789.05, 15882.0, 16094.51,
        16005.3, 15598.99, 15449.9, 15534.14, 16074.16, 16238.39, 16094.09,
        15908.83, 15670.92, 15377.01, 15425.38, 14526.52, 14347.58, 13894.65,
        12943.35, 13299.36, 13082.53, 14750.38, 14379.23, 14418.67, 13960.95,
        13974.12, 14337.01, 13958.57, 13634.71, 13953.98, 14036.24, 14044.71,
        14018.92, 14048.97, 14476.94, 14301.49, 15165.21, 15105.81, 15259.17,
        14902.07, 14081.86, 14008.15, 14012.05, 14018.1, 13967.57, 13561.73,
        14044.71, 13693.3, 13756.25, 13276.72, 12694.44, 12767.8, 12904.11,
        13539.72, 13634.34, 13599.36, 13759.17, 14342.42, 14200.18, 14063.73,
        13718.16, 14255.33, 14360.58, 14894.27, 14951.11, 14962.96, 15117.06,
        14960.29, 15282.34, 14970.46, 15402.17, 15587.62, 15542.67, 15541.34,
        15022.52, 14380.27, 14400.09, 14410.39, 14722.26, 14681.18, 14818.26,
        14783.3, 14713.24, 14387.38, 14233.56, 14778.37, 14978.65, 14870.13,
        15725.83, 15795.3, 16041.98, 15960.1, 16140.33, 16169.77, 16360.8,
        16039.87, 16026.36, 15730.31, 16135.17, 15766.63, 15886.26, 16121.01,
        16555.88, 17194.42, 17132.28, 16678.54, 16432.44, 16431.02, 16299.92,
        16072.74, 16468.89, 17899.27, 17954.52, 18072.16, 18107.84, 19076.68,
        20819.62, 20964.61, 21230.39, 20739.33, 21583.67, 21375.51, 21192.73,
        21569.47, 21729.96, 22282.36, 23275.18, 23339.4, 23103.86, 23190.93,
        22340.09, 21726.39, 22728.89, 22635.25, 23023.56, 22827.96, 23087.42,
        22569.04, 22745.52, 23232.26, 22857.04, 23783.5, 23568.42, 23716.29,
        22950.51, 22918.67, 23950.5, 23769.3, 23954.65, 23928.71, 24439.77,
        25040.54, 25077.46, 23936.94, 23283.52, 23064.08, 22989.19, 22610.56,
        22526.41, 23374.83, 23382.9, 22675.52, 22347.96, 22558.25, 22039.75,
        21956.79, 22220.63
      ],
      ETH: [
        10000.0, 10261.29, 10370.41, 9695.0, 9979.02, 9881.21, 9835.93, 9743.36,
        10276.32, 10290.7, 11199.36, 11497.51, 10983.97, 11333.75, 11028.98,
        11051.58, 11401.37, 11115.89, 10860.56, 10950.12, 10866.36, 10823.85,
        10201.55, 9787.4, 9765.29, 9765.42, 9970.27, 9963.47, 9888.43, 10161.15,
        10396.48, 10048.26, 10097.94, 10149.98, 10103.87, 10058.86, 10087.31,
        10470.29, 10662.83, 10657.25, 10969.1, 10972.48, 10991.78, 11671.81,
        11587.46, 12185.93, 12367.88, 12260.75, 12245.32, 12660.61, 13018.94,
        13166.38, 12898.87, 13134.9, 12849.24, 13137.01, 13638.3, 14001.33,
        14276.82, 14684.24, 14606.35, 15092.55, 14964.15, 15271.08, 15863.05,
        15414.78, 16763.98, 16955.59, 17042.95, 17149.32, 16809.16, 17882.2,
        17363.47, 17509.94, 17046.15, 16396.37, 15641.09, 16025.74, 15484.24,
        14116.98, 15476.11, 15312.31, 14450.68, 14845.89, 15129.16, 15877.42,
        15847.84, 15452.76, 15679.34, 15482.39, 15414.3, 15956.76, 15404.67,
        14499.66, 14588.55, 14560.94, 14571.32, 14851.61, 15038.87, 16358.74,
        15468.36, 15554.14, 15410.92, 14150.66, 13270.83, 13876.96, 13745.69,
        13599.62, 13225.91, 13497.39, 13495.16, 13807.98, 13872.21, 14149.94,
        14135.1, 13769.3, 13890.62, 13770.18, 14266.0, 14465.68, 14135.1,
        13232.59, 13054.32, 13176.76, 13654.13, 13660.28, 13768.37, 13547.69,
        13349.21, 13052.66, 13427.02, 12772.98, 12818.83, 12889.07, 12925.57,
        12702.78, 13246.82, 12922.35, 13588.14, 13706.52, 13526.86, 15878.34,
        16765.65, 16462.98, 16753.33, 16429.7, 16430.93, 16872.28, 17091.21,
        16981.92, 16614.16, 16473.93, 16574.76, 16801.08, 16627.16, 16610.51,
        16768.23, 16928.11, 16757.41, 16243.65, 16162.91, 16299.39, 16153.84,
        15388.8, 15700.49, 15306.11, 15320.31, 15696.65, 15951.54, 15491.71,
        15299.51, 15636.78, 15472.01, 15489.78, 15397.15, 15058.27, 14650.58,
        14960.36, 14860.14, 15171.89, 14833.68, 14843.99, 15169.22, 15137.96,
        15055.1, 14515.71, 13751.24, 13088.52, 13471.11, 12913.82, 13296.68,
        13450.67, 13631.88, 13640.85, 13747.67, 14031.82, 14269.08, 15266.33,
        15072.58, 14925.37, 15094.22, 15422.83, 15448.58, 15533.39, 15158.36,
        15308.74, 14635.55, 13913.67, 14417.53, 14397.89, 14329.93, 14636.15,
        14414.82, 14257.87, 14107.83, 13093.54, 12789.73, 11966.78, 10782.57,
        10881.98, 10359.11, 11903.04, 11422.69, 11457.72, 11287.12, 11853.14,
        11928.62, 11737.89, 11326.2, 11411.69, 11468.75, 11634.53, 11529.91,
        11372.53, 11564.99, 11536.81, 12132.47, 12097.04, 12181.63, 11810.42,
        10815.98, 11132.72, 11096.51, 11104.94, 11064.84, 10578.29, 11188.55,
        10786.1, 10826.18, 10425.97, 9712.98, 9956.3, 10082.16, 10363.06,
        10509.01, 10286.7, 10379.03, 10756.24, 10624.94, 10314.97, 10042.51,
        10277.04, 10285.65, 10867.03, 11212.46, 11323.73, 11385.46, 11633.43,
        11720.66, 11308.43, 11595.49, 11893.51, 11759.42, 11675.11, 11440.22,
        10787.59, 10471.9, 10339.85, 10642.43, 10629.64, 10690.8, 10623.92,
        10734.09, 10433.84, 10453.62, 10723.63, 10934.37, 10845.53, 11648.65,
        11405.54, 11526.04, 11485.47, 11590.01, 11653.03, 12077.79, 11769.59,
        11587.24, 11036.76, 11136.15, 10832.73, 10931.96, 11053.81, 11292.52,
        11590.75, 11692.56, 11085.16, 11048.28, 10952.54, 10815.12, 10577.77,
        10703.45, 12024.54, 12666.81, 13007.33, 13778.83, 13998.59, 14690.71,
        14455.96, 13978.55, 13574.99, 13629.9, 13772.77, 13487.43, 13843.63,
        13595.52, 13515.11, 14820.56, 14542.74, 14972.36, 14765.57, 15171.41,
        14618.56, 16115.46, 15793.33, 15808.04, 16303.84, 16358.62, 15891.83,
        15968.29, 16889.62, 16719.57, 17689.43, 17559.13, 17629.64, 16327.76,
        15971.81, 16863.29, 17068.66, 17128.98, 16977.04, 17097.15, 17720.63,
        17071.3, 16180.75, 15207.72, 15263.06, 14641.03, 14458.41, 15208.92,
        15353.76, 15278.9, 14687.02, 14598.57, 14993.19, 14684.72, 14766.02,
        14738.85
      ],
      BNB: [
        10000.0, 10090.11, 10035.4, 10186.62, 10350.72, 10183.41, 9890.6,
        9700.77, 9842.36, 9671.81, 9864.88, 9887.39, 9498.08, 9732.96, 9758.69,
        10176.97, 10157.66, 10000.0, 10057.92, 10080.45, 10196.27, 10289.59,
        9855.22, 9565.65, 9420.85, 9401.55, 9723.31, 9839.13, 9790.86, 9977.48,
        9958.18, 9694.35, 9642.87, 9694.35, 9652.51, 9835.92, 9665.39, 9761.92,
        9909.91, 10267.06, 10408.64, 10411.85, 10321.76, 10566.28, 10444.02,
        10740.04, 11303.1, 11566.93, 11357.79, 11367.45, 11367.45, 11412.5,
        12065.64, 12380.96, 12043.12, 12290.87, 12557.93, 12918.28, 12741.32,
        13256.12, 12747.76, 13072.73, 13156.38, 13352.65, 13481.35, 12612.62,
        13725.87, 15112.62, 15424.72, 15704.65, 16750.34, 16801.81, 17265.14,
        19797.32, 19430.52, 19642.87, 18857.8, 18545.71, 17815.33, 16389.98,
        17892.55, 17799.23, 17426.01, 17960.12, 18359.09, 18938.24, 18677.62,
        18507.1, 18822.4, 19890.62, 19430.52, 19491.65, 18593.96, 17947.24,
        18121.0, 18719.45, 18680.84, 18922.14, 18725.89, 18976.85, 18751.62,
        19562.44, 19510.96, 19134.51, 17853.94, 18217.53, 17927.94, 17400.27,
        17310.18, 17728.45, 17911.86, 18301.17, 18667.97, 19462.7, 19601.05,
        19504.52, 19729.75, 19250.33, 19128.07, 19321.13, 19131.28, 18590.75,
        18008.37, 18104.9, 18893.2, 18825.63, 18963.99, 19009.02, 18703.35,
        18912.49, 19263.21, 18854.59, 19070.15, 19150.59, 19047.63, 18214.3,
        18809.54, 18301.17, 18674.41, 18597.19, 18519.96, 19050.84, 19893.83,
        19787.67, 19240.69, 19372.61, 19350.09, 19333.99, 19430.52, 19391.91,
        19192.43, 19144.16, 19131.28, 19340.44, 19372.61, 20205.94, 21956.26,
        22387.4, 22898.99, 22039.91, 21965.92, 21666.69, 19887.41, 19417.64,
        19954.96, 19359.72, 19356.51, 19575.3, 19649.31, 19504.52, 18960.76,
        19243.91, 19041.19, 18861.01, 18992.94, 18661.53, 18149.95, 18574.65,
        18426.66, 18667.97, 18252.91, 18317.27, 18812.75, 18606.84, 18658.32,
        17924.72, 16875.81, 15974.92, 17001.3, 15855.87, 16444.67, 16589.47,
        16850.08, 16888.69, 17146.09, 17168.61, 17525.76, 18725.89, 18420.22,
        18314.04, 18410.57, 19095.9, 19089.46, 19321.13, 19021.89, 18745.2,
        18455.6, 18407.36, 18722.66, 18790.23, 18735.54, 18577.88, 18738.75,
        18616.49, 18526.41, 17451.74, 16985.22, 16206.57, 15038.63, 15675.69,
        15318.55, 16698.86, 16386.75, 16846.87, 16306.32, 16550.85, 16879.04,
        16862.94, 16734.24, 16731.03, 17419.57, 17255.49, 17982.64, 18458.83,
        18336.57, 18870.67, 19066.94, 18571.44, 18597.19, 17651.24, 17084.96,
        17290.87, 17252.26, 17229.74, 17168.61, 16367.45, 16981.99, 16846.87,
        16438.23, 16148.66, 15575.95, 15820.47, 16122.93, 16705.28, 16750.34,
        17052.78, 17445.32, 17956.89, 17779.93, 18034.12, 17062.43, 17548.27,
        17770.29, 18211.08, 18281.87, 18909.28, 18931.8, 19507.73, 19559.23,
        18889.97, 19176.33, 19591.4, 19324.34, 19214.95, 18349.44, 17622.29,
        17538.62, 17532.2, 17892.55, 18143.51, 18256.12, 18272.21, 18677.62,
        18355.86, 18053.42, 18423.43, 18590.75, 18388.05, 19034.77, 19047.63,
        19401.57, 19057.29, 19269.63, 19231.04, 19456.25, 19340.44, 19160.24,
        18751.62, 19089.46, 18700.14, 18777.37, 18983.29, 19366.17, 19475.56,
        19202.08, 18552.13, 18410.57, 18291.52, 17976.2, 17752.9, 18179.88,
        19113.91, 19215.91, 19181.48, 20067.9, 20160.56, 20572.41, 20423.13,
        19799.89, 20289.59, 19909.6, 19995.18, 19837.21, 19919.58, 19700.15,
        19593.97, 20030.91, 20205.61, 21054.39, 21288.63, 20554.4, 19745.52,
        20717.52, 21209.48, 21000.01, 21084.64, 21144.81, 20635.15, 23663.46,
        23613.92, 23000.67, 23600.4, 24084.64, 23921.5, 21946.61, 21745.52,
        22834.32, 22780.26, 23383.54, 22996.16, 22979.43, 23306.98, 23216.87,
        22352.66, 21724.28, 21808.26, 21276.72, 20919.26, 22457.55, 22394.48,
        22557.93, 22234.58, 22216.88, 23278.02, 22309.87, 22667.32, 22612.63
      ],
      SOL: [
        10000.0, 10752.0, 10741.06, 9828.91, 10422.75, 9868.7, 9290.76, 8881.92,
        9748.33, 9900.53, 10272.54, 9977.11, 8979.4, 9566.3, 9616.03, 9371.32,
        9762.26, 10066.65, 9416.09, 9294.74, 9262.91, 9100.75, 8352.73, 8224.41,
        8703.86, 8680.99, 9181.33, 9319.6, 9434.0, 10037.78, 10291.45, 9718.49,
        9660.8, 9980.1, 9743.35, 9526.51, 9425.04, 9694.62, 10042.76, 10208.88,
        10731.12, 10881.32, 10697.3, 11099.17, 11144.92, 11650.24, 11142.94,
        10913.15, 10865.41, 11220.52, 11146.91, 10827.61, 10406.83, 10242.7,
        9930.37, 10357.1, 10296.43, 10909.17, 10788.81, 11521.92, 12299.8,
        12997.1, 12762.35, 13025.95, 13010.04, 12125.72, 12999.09, 14397.68,
        14334.01, 14417.58, 14108.22, 14708.04, 14757.78, 16259.81, 17200.83,
        18316.9, 18005.56, 20008.94, 19710.52, 16835.77, 18891.86, 17736.99,
        16941.19, 17382.85, 18399.47, 18996.3, 19123.63, 18598.41, 18892.85,
        19122.63, 19514.56, 19956.23, 19182.33, 18312.94, 18515.86, 18132.88,
        17348.04, 17765.83, 17762.85, 18018.48, 17307.27, 17304.28, 17183.91,
        15071.11, 14026.64, 14981.59, 13920.22, 13686.46, 13387.05, 14213.66,
        14290.26, 14838.34, 14816.46, 15719.68, 15446.12, 14614.53, 14451.4,
        13847.6, 13993.82, 13833.67, 13691.42, 12670.84, 13267.67, 13800.84,
        14261.4, 14523.02, 14439.46, 15377.49, 14922.9, 14078.38, 15214.35,
        14505.11, 14503.12, 14247.48, 14614.53, 14230.56, 15505.8, 15802.23,
        16776.07, 17111.29, 17051.61, 18462.14, 17755.88, 17656.41, 17530.08,
        16636.82, 16704.46, 16289.65, 17024.76, 16813.87, 16874.54, 16631.84,
        16527.4, 16583.1, 16242.9, 16457.76, 16990.94, 17226.69, 16965.08,
        16223.01, 15766.44, 16106.62, 15852.97, 14869.18, 15477.95, 14738.87,
        14256.44, 14470.29, 14960.71, 14306.17, 13691.42, 13399.97, 13321.4,
        13411.91, 13356.19, 12853.86, 12952.35, 13617.82, 13667.55, 14941.8,
        13929.16, 13932.15, 14626.48, 14601.6, 15390.42, 14061.46, 13176.16,
        13353.23, 14311.14, 13234.84, 13956.03, 14059.47, 14082.36, 13604.88,
        13804.82, 14145.02, 14640.39, 15794.28, 15907.67, 15517.75, 15912.65,
        16790.0, 17246.57, 18298.01, 17757.87, 17199.83, 17643.49, 17138.16,
        18227.38, 18471.09, 18246.29, 18234.35, 17838.45, 17241.62, 16658.7,
        15199.42, 14208.68, 13999.79, 13181.14, 14436.47, 14507.1, 16064.85,
        15493.88, 15384.45, 14144.03, 14337.0, 14632.43, 14358.9, 14293.24,
        13886.4, 14078.38, 14382.76, 14283.29, 14248.48, 14214.66, 14244.5,
        15103.93, 15823.13, 15977.3, 15660.99, 14670.23, 14278.32, 13908.28,
        13697.39, 13467.61, 12842.93, 13408.93, 12880.73, 13336.31, 12888.67,
        12406.24, 12614.14, 12871.77, 13374.11, 13526.29, 13188.09, 13564.1,
        13863.51, 13643.68, 13118.47, 13003.07, 13101.55, 13074.7, 14166.9,
        14635.42, 14776.67, 14471.29, 14363.85, 15254.15, 14838.34, 15551.57,
        15722.66, 15589.37, 15818.15, 15358.59, 14391.72, 14081.36, 13621.8,
        14217.64, 14221.62, 14458.37, 14369.83, 14248.48, 13846.61, 13782.95,
        14508.1, 14628.47, 14703.06, 15714.7, 15218.33, 15401.35, 15013.42,
        15347.65, 15807.21, 16581.1, 16691.52, 16807.9, 17003.87, 17779.75,
        16292.64, 17046.64, 17682.27, 17769.81, 17863.31, 17442.55, 16861.62,
        16497.56, 16502.53, 16198.14, 15718.68, 16650.74, 18804.33, 19422.04,
        19786.13, 19899.52, 20941.99, 21909.86, 21391.62, 21192.67, 21095.19,
        21708.92, 21307.05, 23350.22, 23816.75, 23420.85, 23519.33, 25357.58,
        25349.64, 25360.57, 25213.36, 23550.16, 22870.78, 23940.11, 23670.54,
        24253.43, 23715.29, 23702.37, 22325.66, 23555.14, 22781.24, 23380.06,
        23617.8, 23741.16, 23542.22, 21672.12, 21334.9, 22706.63, 22700.68,
        22248.07, 21686.05, 21815.36, 21673.11, 22258.02, 20535.15, 19583.2,
        19164.41, 18023.46, 18046.35, 19150.5, 19594.13, 19572.25, 18805.32,
        18386.53, 19415.09, 18851.09, 19016.21, 19214.16
      ],
      XRP: [
        10000.0, 10253.51, 10260.06, 9537.14, 9612.37, 9388.29, 9291.79,
        9005.56, 9486.43, 9352.31, 9939.48, 9852.79, 9280.34, 9425.91, 9574.74,
        9458.63, 9458.63, 9329.41, 9039.92, 8941.78, 8997.39, 8967.94, 8653.91,
        8428.21, 8446.2, 8420.02, 8706.26, 8678.44, 8562.31, 8771.68, 8339.88,
        8212.3, 8269.55, 8357.87, 8508.34, 8240.11, 8305.53, 8303.89, 8377.5,
        8410.2, 8601.57, 8572.14, 8585.22, 8688.26, 8603.21, 8819.11, 9172.4,
        9241.08, 9000.66, 9160.95, 9257.45, 9231.27, 8958.13, 8891.07, 8735.69,
        8925.41, 8902.52, 8994.11, 9596.01, 9370.3, 9555.13, 9824.99, 10392.54,
        10282.97, 10657.51, 9633.64, 9983.65, 10328.76, 10109.59, 10168.46,
        9816.81, 11871.13, 11239.79, 11220.15, 10945.37, 10356.56, 9913.32,
        10140.66, 10353.29, 9589.47, 10022.9, 10441.61, 9952.57, 10179.92,
        10358.2, 10521.75, 10354.93, 10049.07, 10224.08, 10279.69, 10140.66,
        10279.69, 10008.18, 9627.09, 9404.64, 9646.71, 9597.65, 9718.69,
        9692.51, 10122.67, 10181.55, 10094.87, 9947.67, 8913.97, 7778.88,
        8254.83, 8164.87, 8127.25, 8130.53, 8225.39, 8271.18, 8608.12, 8599.93,
        9138.04, 8941.78, 8599.93, 8603.21, 8572.14, 8460.91, 8395.49, 8433.11,
        8173.05, 8418.38, 8496.89, 8722.61, 8653.91, 8652.28, 8864.9, 8644.1,
        8451.1, 8521.43, 8205.76, 8274.46, 8168.14, 8248.28, 8187.77, 8470.73,
        8428.21, 8572.14, 8511.62, 8339.88, 8748.78, 8802.75, 8619.56, 8614.66,
        8758.59, 8856.73, 8662.09, 8758.59, 8662.09, 8581.94, 8500.17, 8490.35,
        8505.08, 8405.3, 8523.07, 8593.39, 8596.67, 8536.15, 8191.04, 8070.01,
        8150.14, 8123.98, 7855.74, 8034.03, 7839.39, 7765.79, 8052.02, 8002.95,
        8290.81, 8034.03, 8066.73, 8001.31, 8016.04, 7970.23, 7849.2, 7724.9,
        7780.5, 7708.54, 7782.14, 7715.08, 7723.26, 7800.13, 7809.95, 7930.99,
        7636.57, 7268.57, 6951.26, 7345.44, 6879.29, 7055.94, 7123.0, 7168.79,
        7322.54, 7759.24, 8563.95, 8637.56, 8760.23, 9432.46, 10358.2, 9308.14,
        9332.68, 9753.03, 9774.29, 9934.58, 9793.92, 9972.2, 9756.29, 9864.25,
        9826.63, 9793.92, 9854.43, 10309.13, 10371.28, 9787.37, 9174.02, 9080.8,
        8639.2, 8112.54, 8302.26, 10035.98, 10003.28, 9509.32, 9600.92, 9030.09,
        9254.17, 9421.01, 9298.33, 9169.12, 9228.01, 9250.91, 9345.76, 9764.48,
        9728.49, 9823.35, 9775.92, 9991.83, 9972.2, 9862.61, 9630.36, 9265.62,
        9327.77, 9167.49, 9262.35, 9280.34, 8920.51, 9286.89, 9208.38, 9156.03,
        8910.7, 8506.71, 8578.68, 8614.66, 8820.75, 8863.27, 8758.59, 9213.28,
        9362.12, 9744.85, 9425.91, 9514.23, 9576.38, 9509.32, 9643.44, 9605.82,
        9790.65, 9641.8, 9576.38, 9689.23, 9568.2, 9663.07, 9618.91, 10071.96,
        10474.32, 10125.94, 9733.4, 8915.6, 8585.22, 8738.96, 8704.62, 8712.8,
        8694.81, 8662.09, 8604.84, 8650.64, 8822.37, 8825.65, 8688.26, 8984.3,
        8837.1, 9007.2, 8936.87, 8917.24, 8891.07, 8967.94, 8935.23, 8750.41,
        8593.39, 8698.07, 8372.59, 8379.14, 8449.46, 8501.8, 8627.75, 8572.14,
        8354.6, 8364.41, 8339.88, 8233.57, 8231.93, 8405.3, 8925.41, 9048.09,
        9051.36, 9077.52, 9648.35, 10107.95, 11701.02, 11305.21, 12973.51,
        14699.06, 18091.27, 17448.48, 18398.77, 17837.75, 18174.69, 19705.6,
        23977.76, 24255.81, 23601.57, 23230.29, 22733.08, 24147.86, 24669.62,
        28686.62, 31275.77, 35493.96, 45029.45, 42620.23, 38776.59, 37376.53,
        40027.81, 41601.26, 42427.22, 36246.33, 38174.69, 39793.93, 38446.2,
        39507.7, 39249.28, 39275.44, 40969.92, 41769.72, 38410.22, 37355.26,
        37392.88, 36560.36, 36153.11, 36931.64, 38047.12, 37544.99, 35464.52,
        35036.0, 35937.2, 34192.03, 33773.32, 34317.97
      ],
      ADA: [
        10000.0, 10505.78, 10319.09, 9484.06, 9680.93, 9090.3, 8844.21, 8372.39,
        9183.64, 8669.38, 9665.67, 9845.57, 9227.78, 9346.58, 9054.66, 8969.8,
        9164.97, 8954.51, 8564.15, 8465.73, 8735.57, 8579.44, 8168.71, 8015.96,
        8012.56, 7939.58, 8246.79, 8265.46, 8268.84, 8910.4, 8778.01, 8486.1,
        8572.66, 8716.9, 8725.39, 8438.58, 8345.21, 8489.48, 8486.1, 8935.85,
        9232.87, 9383.91, 9164.97, 9523.1, 9232.87, 9809.91, 10145.97, 10064.51,
        10330.98, 10578.77, 10724.72, 10614.41, 10096.75, 10030.56, 9881.21,
        10093.37, 10035.66, 10431.1, 10602.52, 10668.71, 11006.47, 11856.76,
        12472.85, 12401.58, 13099.14, 11520.73, 12381.21, 12596.75, 12233.56,
        12584.88, 11958.59, 13292.61, 12593.37, 12842.86, 12720.64, 12284.46,
        11300.09, 11575.03, 11150.71, 9998.32, 10858.8, 10706.05, 10295.32,
        10712.83, 10940.28, 11223.71, 11330.64, 11040.4, 11065.86, 11155.82,
        10930.09, 11011.55, 10543.11, 9928.73, 9730.16, 9803.13, 9743.72,
        9933.81, 9921.95, 10442.99, 10130.7, 9938.91, 9937.21, 8492.88, 7574.67,
        8000.69, 7837.76, 7800.42, 7595.04, 7786.83, 7995.59, 8526.82, 8479.29,
        8828.94, 8481.0, 8024.44, 8004.07, 7839.46, 7890.36, 7866.61, 7749.5,
        7486.44, 7620.52, 7795.32, 7946.37, 7875.1, 7752.9, 7732.53, 7566.19,
        7693.49, 7863.21, 7581.48, 7462.66, 7415.14, 7410.06, 7250.53, 7664.64,
        7802.12, 8185.68, 8168.71, 7985.41, 8435.18, 8430.09, 8209.45, 7875.1,
        7791.94, 7827.57, 7808.91, 7948.07, 7761.38, 7683.31, 7601.85, 7610.33,
        7676.52, 7573.0, 7788.54, 7839.46, 7805.5, 7754.6, 7656.15, 7393.09,
        7533.96, 7491.52, 7152.08, 7452.48, 7170.75, 6992.55, 7019.7, 7080.79,
        6851.68, 6496.96, 6508.84, 6534.29, 6406.99, 6547.86, 6488.47, 6371.36,
        6656.49, 6546.18, 6648.01, 6556.37, 6536.0, 6666.67, 6861.84, 7118.12,
        6922.95, 6318.75, 5924.99, 6323.84, 5918.21, 6274.61, 6356.09, 6542.78,
        6693.83, 7019.7, 7435.51, 7350.65, 7500.0, 7401.58, 7455.88, 7218.27,
        7430.43, 7403.28, 7545.84, 7242.02, 6967.07, 6890.7, 6656.49, 7090.97,
        7135.11, 6892.4, 6890.7, 6838.09, 6622.56, 6637.82, 6184.67, 6113.38,
        5931.77, 5390.36, 5626.27, 5532.93, 5982.7, 5896.14, 5891.06, 5595.74,
        5692.46, 5775.64, 5695.86, 5522.74, 5599.12, 5692.46, 5743.39, 5704.35,
        5855.4, 6271.23, 6388.32, 6600.48, 6648.01, 6590.3, 6250.86, 5938.58,
        5938.58, 6025.12, 5899.54, 5860.5, 5573.67, 5709.45, 5444.67, 5512.56,
        5509.18, 5319.09, 5483.7, 5702.65, 5836.73, 5828.24, 6052.29, 6037.0,
        6135.45, 5992.88, 5750.17, 5560.08, 5672.11, 5733.2, 5969.11, 5987.78,
        6057.37, 5979.29, 6181.27, 6602.19, 6486.77, 6805.84, 6834.69, 6773.61,
        6756.64, 6386.62, 5943.66, 5862.2, 5863.88, 5953.84, 5945.36, 6038.7,
        5982.7, 5831.65, 5743.39, 5741.68, 5970.81, 6001.37, 5897.84, 6198.24,
        6023.44, 6043.78, 5846.91, 5928.39, 5977.59, 6198.24, 6176.19, 6205.04,
        5911.4, 5894.44, 5738.31, 5668.71, 5787.5, 5843.53, 6030.22, 6028.52,
        5816.36, 6064.15, 5885.95, 5678.9, 5553.3, 5682.28, 6186.35, 6726.08,
        7527.15, 7929.4, 10027.18, 10179.93, 9957.58, 9782.76, 9815.02, 11466.4,
        12564.51, 11955.21, 12608.63, 12508.51, 13922.28, 13873.06, 17421.95,
        18316.38, 17438.91, 16255.97, 16125.26, 17072.33, 17627.3, 17941.29,
        18275.64, 19103.89, 20227.45, 20746.79, 20145.99, 20037.36, 20972.54,
        20376.8, 20222.37, 17242.04, 17214.89, 18514.95, 18944.35, 18788.2,
        17942.99, 18173.82, 18503.07, 17895.47, 16641.22, 15283.45, 16040.4,
        15322.49, 15074.7, 15824.87, 15887.65, 15475.24, 14623.23, 14840.47,
        15193.49, 14506.13, 14540.07, 14465.39
      ],
      DOGE: [
        10000.0, 10262.45, 10244.5, 9221.62, 9448.17, 9235.08, 9036.57, 8711.31,
        9154.33, 8853.75, 9321.45, 9458.28, 8906.45, 9091.52, 9052.26, 9100.48,
        9131.9, 9024.23, 8786.44, 8796.55, 9749.89, 9522.19, 9074.69, 8728.13,
        8807.76, 8742.7, 8961.42, 9006.28, 8817.85, 9154.33, 8997.3, 8836.93,
        8887.39, 8900.84, 8841.41, 8785.33, 8755.04, 8839.16, 8972.63, 8946.83,
        9136.38, 9136.38, 9106.09, 9240.69, 9106.09, 9647.81, 9512.1, 9589.5,
        9401.08, 9601.82, 10050.47, 9641.1, 9453.78, 9497.53, 9468.37, 9655.67,
        9669.13, 9967.46, 11131.66, 12997.97, 13079.85, 15668.44, 15945.48,
        16927.99, 20178.32, 17207.26, 17547.09, 17252.12, 18490.34, 20112.15,
        18672.04, 19851.93, 18622.69, 18882.89, 19836.24, 18202.09, 15971.27,
        17256.6, 16077.83, 14524.44, 16999.76, 17335.11, 17063.7, 18523.99,
        19773.43, 19837.36, 20354.4, 21423.27, 24652.29, 23806.62, 22388.95,
        24579.39, 22950.86, 20798.56, 19659.03, 19980.93, 19882.22, 20911.84,
        22037.89, 22818.52, 21306.62, 22402.4, 21865.18, 19482.94, 17034.53,
        18272.76, 18239.11, 17595.33, 16659.93, 17064.82, 17365.4, 18112.37,
        17803.93, 18192.0, 17919.46, 16915.65, 17014.35, 16529.83, 16477.12,
        16576.93, 16144.0, 14954.01, 14490.8, 14925.98, 16384.02, 17913.85,
        17963.2, 17641.31, 17014.35, 15999.31, 17196.05, 16091.28, 16091.28,
        15805.28, 16589.27, 16320.08, 17400.17, 16810.21, 17479.8, 17107.43,
        16756.39, 18172.95, 19085.91, 18666.43, 17902.64, 18374.82, 19020.85,
        18718.02, 18954.69, 18572.23, 18482.49, 17883.56, 17893.67, 18034.99,
        17628.97, 17762.45, 18080.97, 18246.97, 18000.22, 16668.9, 16347.02,
        16469.26, 16232.61, 15534.99, 16409.83, 15902.86, 15137.94, 15339.83,
        15360.01, 14583.88, 13720.27, 13712.42, 13979.35, 13914.31, 13918.79,
        13866.07, 13216.68, 14152.08, 13861.59, 14337.14, 13832.43, 13639.52,
        14019.73, 13873.93, 14010.76, 13270.52, 12085.0, 11756.39, 12711.98,
        11719.37, 12090.61, 12036.78, 12070.43, 11973.98, 12058.09, 12649.17,
        12888.07, 13904.22, 13912.06, 13667.56, 13420.8, 14054.5, 15045.99,
        15772.76, 15509.18, 14608.56, 14393.22, 13988.33, 15117.76, 14913.64,
        14491.92, 14402.18, 14138.63, 13743.83, 13417.45, 12461.85, 12119.77,
        11799.0, 10776.13, 10862.5, 10770.52, 12061.45, 11648.72, 11805.73,
        11364.96, 11945.94, 11944.81, 11527.58, 11224.76, 11259.53, 11521.98,
        11366.07, 11301.03, 11618.43, 11920.13, 11827.05, 12705.25, 12548.22,
        12399.04, 11833.77, 11073.35, 11168.68, 11165.32, 11371.68, 11361.59,
        10588.83, 11113.73, 10930.91, 11029.6, 11059.89, 10271.41, 10641.53,
        10729.02, 11590.39, 11539.92, 11364.96, 11569.09, 12036.78, 11825.93,
        11646.47, 11119.34, 11326.82, 11470.38, 11793.39, 11856.2, 12285.77,
        11979.58, 12098.47, 12363.17, 12242.03, 13223.41, 13909.83, 14452.65,
        14116.18, 12999.09, 11954.9, 11836.02, 11793.39, 12265.59, 12302.59,
        12443.91, 12349.7, 12016.59, 12060.34, 11870.79, 12422.6, 12519.05,
        12471.96, 13188.64, 12918.34, 14239.56, 14414.52, 15500.22, 16086.8,
        16007.17, 16241.58, 15791.83, 15524.9, 15927.54, 15121.12, 15445.27,
        15976.88, 17748.98, 19590.61, 18983.85, 18167.34, 17716.46, 17877.96,
        16975.08, 17694.03, 19162.17, 22285.77, 21471.51, 22912.73, 23999.55,
        31126.05, 36721.6, 44393.2, 43795.4, 41702.54, 42375.49, 40726.77,
        40460.95, 42141.08, 43403.96, 42502.21, 43154.98, 46243.81, 48760.63,
        48194.23, 44129.63, 42806.16, 45132.33, 45310.65, 47827.47, 47252.1,
        49627.61, 47336.22, 46041.92, 46942.54, 48280.6, 48945.68, 50733.48,
        52179.21, 46285.31, 44071.3, 46705.9, 45677.41, 45189.53, 44180.09,
        44962.96, 45287.09, 44293.39, 40328.6, 36152.96, 35698.73, 36114.84,
        35150.28, 36659.91, 37760.18, 37396.79, 35131.21, 34940.53, 36486.08,
        35204.1, 35188.41, 35647.14
      ],
      LINK: [
        10000.0, 10380.55, 10183.23, 9503.32, 9738.24, 9372.44, 8993.22,
        8790.53, 9534.2, 9156.98, 10108.05, 10053.03, 9350.29, 9671.12,
        10075.84, 10216.12, 10255.05, 10524.87, 9829.52, 10632.92, 10628.9,
        10359.75, 9793.27, 9504.66, 9456.34, 9261.7, 9506.01, 9648.29, 9630.84,
        10055.03, 10367.81, 10214.77, 11506.14, 11939.05, 11879.32, 12255.19,
        12710.25, 12332.36, 12683.39, 12275.99, 12391.43, 12561.24, 13479.42,
        13821.73, 13317.68, 13600.23, 13296.19, 13061.27, 13531.11, 13619.7,
        13394.85, 12994.15, 12378.68, 12273.97, 12010.87, 12409.56, 12535.74,
        12843.14, 12845.82, 12886.1, 12774.68, 13400.89, 14279.47, 13790.85,
        13759.31, 12570.64, 13382.78, 13437.14, 13184.1, 13413.65, 13866.7,
        14401.64, 13776.76, 13796.89, 14184.85, 13131.08, 12252.5, 12522.98,
        12330.36, 11360.49, 12343.11, 12302.84, 12019.6, 12247.13, 12443.79,
        13004.9, 13396.87, 13009.6, 12896.84, 12763.94, 12687.43, 12785.42,
        12354.52, 12148.47, 11867.23, 11856.5, 11592.05, 11813.54, 11942.41,
        12231.02, 11748.44, 11705.48, 11728.3, 10085.9, 8907.3, 9496.6, 9169.74,
        9091.21, 8893.21, 9329.49, 9397.94, 9989.26, 10000.0, 10481.91,
        10220.82, 9724.82, 9787.9, 9718.09, 9513.38, 9307.34, 9422.11, 8830.12,
        8905.3, 9170.41, 9444.26, 9598.63, 9618.1, 9803.34, 9488.56, 9320.76,
        9632.19, 9079.14, 8969.73, 9102.62, 8979.79, 8718.04, 9316.73, 10571.85,
        10911.47, 10904.75, 11181.29, 11542.39, 11218.2, 10986.65, 11149.06,
        11586.01, 11673.94, 11510.83, 12548.49, 12439.08, 12457.21, 12015.56,
        12366.59, 12388.74, 12196.79, 11808.17, 11882.01, 11877.97, 11636.35,
        10971.87, 10649.7, 10953.76, 10678.57, 10072.49, 10752.4, 10206.73,
        9892.61, 9993.28, 10163.1, 9823.48, 9318.74, 9598.63, 9607.36, 9340.23,
        9070.4, 8918.71, 9030.8, 9556.35, 9393.25, 9674.48, 9262.37, 9078.46,
        9612.06, 9661.05, 9695.94, 9207.32, 8672.4, 8246.19, 8865.02, 8291.83,
        8799.92, 8589.17, 8584.46, 8299.22, 8545.53, 8761.65, 9019.39, 9665.75,
        9504.66, 9242.9, 9146.24, 9400.63, 9533.53, 10020.13, 9369.75, 9309.35,
        8953.62, 8665.01, 9101.27, 9208.67, 8879.78, 9107.99, 8852.94, 8638.16,
        8665.01, 7973.68, 7873.02, 7342.78, 6450.09, 6772.26, 6436.68, 7235.38,
        7067.58, 7087.73, 6752.13, 7007.18, 7107.86, 6987.05, 6825.96, 6819.25,
        6799.12, 6879.65, 6792.4, 6886.37, 7537.42, 7685.08, 8101.21, 8222.03,
        8181.76, 7987.11, 7436.74, 7503.86, 7309.22, 7443.44, 7403.18, 6893.07,
        7215.25, 6960.19, 6973.62, 6725.28, 6416.53, 6691.72, 6906.5, 7087.73,
        7107.86, 6993.75, 7221.97, 7705.22, 7584.4, 7336.06, 7034.03, 7114.57,
        7174.98, 7544.12, 7678.36, 7664.94, 7503.86, 7631.38, 8201.89, 8154.91,
        8430.09, 8537.48, 8638.16, 8409.96, 7973.68, 7396.47, 7141.42, 7148.13,
        7436.74, 7557.55, 7530.71, 7517.28, 7275.66, 7067.58, 7060.87, 7336.06,
        7430.03, 7215.25, 7597.82, 7570.98, 7617.96, 7416.6, 7658.23, 7664.94,
        8034.09, 7879.72, 8101.21, 7544.12, 7745.48, 7705.22, 7389.75, 7403.18,
        7517.28, 7886.43, 8342.84, 7671.66, 7577.68, 7503.86, 7242.1, 6899.8,
        7289.07, 8235.45, 8423.39, 9175.11, 9228.81, 9544.26, 9866.43, 9591.24,
        8980.46, 8839.52, 9295.93, 9759.04, 9168.39, 10255.72, 9759.04, 9651.66,
        10000.67, 10658.44, 11806.16, 12135.04, 11846.43, 11638.36, 12571.3,
        12114.91, 12235.72, 12786.09, 12665.28, 16430.63, 16491.04, 16189.0,
        15699.03, 17222.63, 16517.88, 17524.66, 15001.01, 14886.9, 16195.72,
        19343.58, 19034.84, 19685.88, 19303.3, 19504.66, 18699.24, 17068.26,
        15558.08, 15712.46, 14792.93, 14920.46, 16638.69, 17142.09, 16323.24,
        15276.19, 14363.37, 14907.04, 14007.64, 13799.58, 13477.4
      ],
      DOT: [
        10000.0, 10486.02, 10387.58, 9442.58, 9660.38, 9058.7, 8763.37, 8487.75,
        9216.18, 8765.82, 9746.53, 10009.85, 9242.03, 9419.21, 9158.36, 9229.73,
        9278.95, 8994.7, 8663.71, 8436.05, 8504.98, 8423.76, 7854.07, 7745.78,
        7894.66, 7929.11, 8219.5, 8215.8, 8324.09, 8870.41, 8498.83, 8182.58,
        8345.02, 8562.83, 8432.39, 8235.5, 8223.21, 8389.31, 8532.05, 8660.01,
        8869.19, 8890.11, 8731.38, 9041.48, 9000.85, 9443.83, 9513.94, 9335.54,
        9462.28, 9673.9, 9958.15, 9605.01, 9130.07, 9260.47, 9261.73, 9634.53,
        9676.38, 9956.93, 10281.77, 10239.92, 10018.44, 10612.76, 11469.17,
        12036.41, 12315.74, 11346.1, 12848.53, 12757.46, 12997.42, 12900.2,
        12350.18, 13692.62, 13116.75, 13554.81, 14166.34, 13232.41, 11993.34,
        12420.33, 12100.41, 10798.57, 11738.65, 11358.44, 10829.34, 11183.7,
        11566.39, 12052.41, 11930.6, 11680.8, 11733.72, 11755.87, 11581.13,
        11803.87, 11279.66, 10642.31, 10387.58, 10397.43, 10285.47, 10471.24,
        10649.68, 11146.78, 10761.64, 10398.65, 10269.47, 8853.19, 7838.07,
        8436.05, 8303.17, 8278.58, 8144.43, 8367.17, 8265.02, 8829.82, 8783.08,
        9282.62, 8909.81, 8514.83, 8434.83, 8284.72, 8294.58, 8338.87, 8153.06,
        7867.59, 8452.05, 9027.92, 8954.11, 8775.67, 9014.4, 8822.45, 8692.01,
        8616.94, 8706.78, 8290.87, 8226.88, 8145.69, 8199.84, 7993.1, 8550.49,
        8630.49, 8861.82, 8790.45, 8562.83, 9254.32, 9388.46, 9171.88, 8869.19,
        8901.19, 9088.22, 9320.76, 9357.69, 9067.29, 8832.3, 8618.19, 8602.19,
        8721.53, 8588.64, 8661.23, 8853.19, 8888.89, 8780.6, 8199.84, 7851.59,
        8023.88, 8009.1, 7863.89, 8282.28, 7863.89, 7595.67, 7657.19, 7855.29,
        7540.3, 7110.87, 7142.84, 6974.29, 6911.51, 7086.25, 6942.29, 6992.73,
        7224.06, 7155.17, 7744.56, 7605.52, 7478.75, 7663.34, 7850.37, 8082.92,
        7446.79, 7018.58, 7021.03, 7655.97, 7292.98, 7286.83, 7488.6, 7482.45,
        7288.05, 7476.31, 7711.34, 7780.22, 7940.18, 7777.78, 7724.85, 7514.45,
        7787.63, 7774.07, 7872.52, 7541.53, 7284.35, 7035.8, 7060.4, 7206.83,
        7230.2, 7018.58, 6941.06, 6797.11, 6649.44, 6587.93, 6281.53, 6200.31,
        5858.25, 5220.86, 5559.26, 5612.15, 6013.28, 5869.32, 5854.55, 5553.08,
        5590.0, 5596.15, 5407.89, 5271.31, 5292.23, 5357.45, 5540.78, 5492.78,
        5575.22, 5738.88, 5717.96, 6034.21, 6083.43, 5983.76, 5633.07, 5363.6,
        5240.57, 5195.01, 5255.31, 5230.71, 4982.17, 5180.27, 5054.76, 5080.61,
        4931.69, 4841.88, 5000.61, 5110.13, 5316.82, 5239.31, 5155.64, 5286.08,
        5458.34, 5439.89, 5446.04, 5113.83, 5112.57, 5063.35, 5239.31, 5329.16,
        5482.96, 5329.16, 5460.82, 5754.88, 5746.29, 5919.77, 6035.43, 5906.25,
        5879.17, 5498.96, 5115.05, 5049.83, 5075.68, 5160.57, 5094.13, 5139.64,
        5133.5, 5035.06, 4940.32, 4998.17, 5131.05, 5190.09, 5101.5, 5412.82,
        5383.3, 5346.38, 5169.2, 5266.38, 5454.67, 5639.22, 5407.89, 5342.67,
        5164.27, 5171.64, 5025.2, 4961.24, 5068.28, 5094.13, 5156.87, 5156.87,
        4861.58, 4801.28, 4798.8, 4674.55, 4622.85, 4771.73, 5074.42, 5091.68,
        5337.75, 5604.78, 6431.64, 6683.89, 6678.96, 6221.24, 5922.25, 6334.42,
        6976.73, 6611.3, 7443.09, 7071.47, 7076.39, 7294.2, 7984.48, 10633.68,
        11055.74, 10269.47, 9905.26, 10343.29, 10649.68, 10745.64, 11068.04,
        11358.44, 12058.56, 12102.86, 13164.75, 12933.42, 13225.04, 12976.49,
        12816.54, 10462.65, 10303.92, 11154.18, 11065.56, 11023.74, 10497.1,
        10915.45, 10903.16, 10463.87, 9595.16, 8742.45, 9020.55, 8551.75,
        8450.83, 9149.73, 9301.1, 9197.73, 8615.71, 8488.98, 8801.52, 8420.09,
        8225.65, 8235.5
      ],
      AVAX: [
        10000.0, 10797.39, 10656.2, 9597.4, 10081.05, 9445.76, 8904.57, 8734.66,
        9443.13, 9071.91, 10224.85, 10311.11, 9338.56, 9563.41, 9422.22,
        9278.42, 9401.3, 9409.14, 8831.39, 8541.2, 8606.54, 8549.03, 7790.87,
        7830.06, 7976.49, 7971.25, 8433.99, 8637.93, 9035.29, 9430.06, 9288.89,
        8779.09, 8823.52, 9600.0, 9432.69, 9155.57, 8977.79, 8972.55, 9223.55,
        9267.98, 9997.4, 10648.36, 10355.57, 10732.02, 10363.41, 11040.52,
        10805.23, 10460.14, 10413.07, 10632.69, 10407.84, 10081.05, 9772.55,
        9691.5, 9377.79, 9597.4, 9769.95, 10261.44, 10253.6, 10426.15, 10499.36,
        11111.1, 11356.87, 11173.88, 11200.0, 10141.19, 10818.31, 11322.88,
        11100.66, 11254.9, 10789.55, 12797.39, 14360.79, 14169.93, 14101.96,
        14847.08, 13916.36, 14928.1, 15945.11, 14149.02, 14962.09, 14026.14,
        13649.69, 14109.82, 14465.36, 15207.85, 14664.06, 14190.85, 14305.89,
        13994.78, 13856.22, 14088.91, 13469.3, 12405.23, 12020.91, 12120.27,
        11830.08, 12648.39, 12601.32, 13121.57, 12300.66, 12426.14, 12054.9,
        10211.77, 8881.06, 9853.6, 9296.73, 9137.26, 8867.98, 9139.86, 9137.26,
        9950.33, 9712.41, 10350.33, 10000.0, 9498.03, 9286.29, 8983.02, 8941.19,
        8933.33, 9160.78, 8575.16, 8640.53, 8836.6, 9291.5, 9701.97, 9775.18,
        9783.02, 9315.04, 8854.91, 9220.92, 8700.67, 8771.25, 8674.52, 8473.22,
        8266.68, 9035.29, 8993.47, 9542.49, 9701.97, 9372.55, 10300.66,
        10792.16, 10470.58, 10018.31, 9926.81, 9986.93, 9657.54, 10000.0,
        9701.97, 9547.73, 9422.22, 9453.6, 9390.86, 9150.33, 9239.23, 9380.39,
        9498.03, 9424.85, 8794.77, 8418.31, 8593.47, 8481.06, 8237.9, 8713.74,
        8149.04, 7937.26, 7853.61, 7850.98, 7492.8, 6949.01, 7037.9, 7223.53,
        7189.54, 6705.88, 6577.79, 6439.21, 6669.29, 6802.61, 7320.26, 7338.57,
        7249.68, 7688.9, 7566.02, 7511.11, 6964.72, 6528.1, 6572.56, 7181.7,
        6721.56, 6627.46, 6841.84, 6721.56, 6616.99, 6794.77, 6807.85, 6907.18,
        7247.07, 7304.58, 7145.1, 7139.87, 7343.8, 7584.33, 8549.03, 8366.02,
        7819.62, 7461.45, 7105.88, 7461.45, 7416.99, 7121.56, 7129.43, 6904.58,
        6766.02, 6703.28, 6164.72, 5918.96, 5644.44, 5173.86, 5505.89, 5226.14,
        5934.64, 5670.59, 5654.92, 5372.56, 5490.21, 5600.01, 5495.41, 5257.52,
        5346.41, 5375.17, 5552.95, 5513.72, 5866.66, 6156.85, 6499.34, 6969.93,
        7045.74, 7163.42, 6792.17, 6311.12, 6138.57, 5989.54, 6081.04, 5950.34,
        5552.95, 5874.53, 5623.53, 5759.49, 5610.46, 5511.12, 5660.12, 6034.0,
        6266.66, 6397.38, 6109.82, 6282.36, 6549.01, 6588.24, 6264.05, 6099.35,
        6219.62, 6271.89, 6980.4, 7213.08, 7244.44, 7173.86, 7142.5, 7396.07,
        7171.25, 7628.76, 7947.71, 7649.67, 7602.61, 7267.99, 6786.93, 6684.97,
        6460.12, 6854.91, 6698.04, 6996.07, 6983.0, 6899.34, 6758.18, 6729.43,
        7184.33, 7482.36, 7550.34, 7688.9, 7294.14, 7354.25, 7147.71, 7312.42,
        7356.88, 7537.26, 7301.97, 7252.28, 6967.32, 7022.22, 6708.51, 6619.62,
        6742.47, 6883.67, 7006.55, 6860.15, 6541.17, 6481.06, 6305.88, 6167.32,
        5968.63, 6279.73, 7116.35, 7126.79, 7477.13, 7780.39, 8384.33, 9160.78,
        9179.09, 8588.23, 8284.96, 8674.52, 9100.67, 9087.59, 9202.61, 8852.3,
        8833.99, 9372.55, 10956.87, 10922.88, 10949.03, 11006.53, 11150.33,
        11482.35, 11273.21, 11660.13, 11775.18, 11735.95, 13440.52, 13456.22,
        13848.38, 13294.12, 13764.73, 13482.37, 14120.27, 11681.05, 11694.12,
        12583.01, 13877.14, 13600.02, 13009.16, 12847.06, 12988.25, 12567.33,
        11330.72, 10366.01, 10400.0, 9769.95, 9581.72, 10342.49, 10758.17,
        10486.29, 9785.62, 9529.42, 9926.81, 9320.28, 9403.94, 9393.46
      ],
      LTC: [
        10000.0, 10269.17, 10088.35, 9011.6, 9162.06, 9041.95, 9033.67, 8855.59,
        9411.93, 9175.86, 9664.54, 9897.84, 9947.52, 9969.62, 9730.81, 9578.96,
        9625.9, 9588.62, 9422.95, 9856.43, 9831.59, 9961.34, 9308.38, 8972.94,
        9087.51, 9050.23, 9257.31, 9407.78, 9418.82, 9450.57, 9377.42, 9204.85,
        9297.35, 9399.49, 9532.02, 9229.7, 9326.33, 9434.0, 9450.57, 9518.22,
        9759.8, 9772.21, 9859.18, 10118.71, 9519.6, 9672.82, 9599.67, 9693.53,
        9657.64, 9792.93, 9877.12, 9613.46, 9500.27, 9541.68, 9490.61, 9726.66,
        9667.3, 9988.95, 10277.45, 10282.99, 10891.75, 11713.13, 12672.54,
        12552.45, 12276.36, 11224.45, 11726.95, 12245.98, 12088.62, 12457.2,
        11862.21, 14424.35, 13463.56, 13325.5, 13052.18, 12345.38, 11711.75,
        11866.36, 11742.12, 10835.17, 11700.72, 11851.18, 11374.92, 11927.1,
        12385.42, 12512.41, 13176.42, 13053.56, 13032.85, 14838.47, 14040.58,
        14483.7, 13721.7, 14910.26, 13585.03, 13344.83, 13551.9, 13897.01,
        13899.76, 14381.54, 13546.37, 13390.39, 13571.21, 11833.24, 10692.98,
        11028.43, 10842.07, 11043.61, 11144.39, 11195.46, 11214.79, 11697.95,
        11652.39, 11874.65, 11717.28, 11503.31, 11591.66, 12074.82, 11519.87,
        11673.11, 11492.26, 10996.69, 11002.2, 11079.5, 11299.0, 11285.19,
        11229.97, 11187.18, 11188.56, 11229.97, 11450.85, 11028.43, 11261.73,
        11238.25, 11133.34, 10901.42, 11343.18, 11361.12, 11604.07, 11559.89,
        11372.15, 12170.07, 12196.29, 11847.03, 11761.45, 11707.62, 11703.46,
        11615.12, 11786.3, 11536.43, 11576.46, 11653.78, 11495.03, 11532.28,
        11472.93, 11470.16, 11521.26, 11735.23, 11663.44, 11089.16, 11042.24,
        11111.26, 10985.64, 10691.6, 10847.58, 10923.52, 10687.45, 10940.08,
        10897.29, 10607.38, 10034.51, 10189.11, 10316.11, 10229.15, 10298.17,
        10215.33, 9548.58, 9853.66, 9820.54, 10107.68, 10078.68, 10392.05,
        10427.94, 10273.32, 10495.58, 9983.42, 9243.51, 8520.15, 9039.21,
        8585.04, 8952.22, 9024.01, 9225.57, 9340.14, 9559.63, 9657.64, 9663.15,
        9951.68, 10040.02, 9888.17, 9861.94, 10126.99, 10082.81, 10190.49,
        9842.61, 9976.52, 9819.15, 9508.55, 9852.28, 9884.04, 9801.21, 10197.39,
        9899.22, 9779.11, 9645.21, 8960.5, 8881.82, 8731.35, 7839.58, 8082.54,
        7785.74, 8514.62, 8336.54, 8444.21, 8221.96, 8426.27, 8723.07, 8885.97,
        8941.19, 9162.06, 9347.04, 9155.17, 9144.12, 8930.15, 8909.43, 8814.19,
        9128.92, 9152.4, 9004.68, 8771.4, 8343.46, 8561.56, 8551.89, 8981.22,
        8985.37, 8703.74, 9030.91, 8977.09, 9037.82, 9124.79, 8622.29, 8539.48,
        8366.92, 8482.87, 8502.2, 8597.45, 8670.62, 8954.99, 9108.22, 8796.24,
        8596.07, 8776.91, 8856.98, 9054.39, 8999.16, 9214.52, 9422.95, 9254.54,
        9237.98, 9243.51, 9443.67, 9786.03, 9634.18, 9618.98, 9254.54, 8745.17,
        8681.67, 8757.58, 8966.04, 9144.12, 9290.43, 8943.94, 9076.46, 8894.25,
        8848.7, 9048.85, 9152.4, 8960.5, 9287.69, 9688.02, 9676.97, 10121.47,
        10172.54, 10360.29, 10241.56, 9801.21, 9672.82, 9689.38, 9786.03,
        9683.87, 9443.67, 9799.82, 9791.54, 10234.66, 9928.22, 9570.67, 9699.04,
        9562.39, 9246.26, 9065.43, 9146.86, 9863.33, 9870.23, 9994.47, 10095.24,
        10509.37, 10930.41, 10808.94, 10375.47, 11543.33, 11608.22, 13144.65,
        12008.55, 12268.08, 11878.8, 11617.88, 12295.69, 12548.3, 13674.76,
        13429.03, 12865.82, 12664.26, 13477.35, 13260.61, 14228.32, 14064.04,
        16554.37, 18855.59, 18199.87, 18405.56, 18638.86, 18839.03, 18346.2,
        18597.45, 15075.9, 15207.06, 16292.1, 16417.72, 16997.49, 16253.44,
        16325.22, 16475.69, 17099.65, 15142.18, 14024.02, 14051.61, 13946.7,
        13786.57, 14815.01, 14955.82, 15053.83, 14149.62, 13792.1, 13968.8,
        13469.07, 13728.6, 14335.98
      ],
      SHIB: [
        10000.0, 10330.08, 10262.13, 9271.86, 9718.46, 9524.28, 9300.98,
        8766.98, 9252.41, 9349.52, 9660.18, 9825.24, 9300.98, 9660.18, 9446.59,
        9281.57, 9407.76, 9242.7, 8951.45, 9019.4, 9378.64, 9087.39, 8679.62,
        8495.15, 8592.22, 8592.22, 8766.98, 8893.21, 8708.74, 8932.04, 8844.67,
        8699.03, 8737.86, 8805.81, 8864.09, 8660.2, 8611.63, 8631.08, 8776.69,
        8922.33, 9135.92, 9165.05, 9126.22, 9359.22, 9184.46, 9475.71, 9601.94,
        9533.99, 9368.93, 9485.45, 9611.64, 9466.0, 9223.29, 9310.69, 9203.87,
        9349.52, 9398.05, 9873.77, 10621.36, 11339.8, 11970.86, 16087.39,
        21961.16, 21592.22, 34097.08, 32300.98, 32475.71, 29912.6, 34048.54,
        34456.31, 31116.5, 32262.11, 30990.27, 31048.55, 31359.21, 28834.93,
        24116.49, 28611.63, 26174.76, 24242.72, 26844.65, 26281.54, 25427.17,
        26679.6, 27252.42, 28252.43, 29980.59, 29504.83, 30902.9, 29951.46,
        28815.52, 29640.77, 27786.39, 25932.04, 25679.62, 26446.59, 25980.58,
        26708.72, 27233.01, 28106.79, 26922.31, 27165.06, 26776.7, 23776.71,
        20349.53, 22067.97, 21349.5, 22135.92, 21621.34, 22174.75, 22213.58,
        25436.87, 25640.76, 26291.24, 25883.47, 24854.38, 25106.8, 24407.78,
        23902.9, 23563.12, 23427.18, 21844.67, 21776.69, 22446.58, 23650.48,
        24300.96, 23854.36, 23330.11, 22466.0, 21834.93, 22970.88, 21699.03,
        21873.79, 21660.2, 22543.69, 22359.22, 24330.09, 23728.14, 24359.21,
        24067.96, 23203.88, 24601.92, 25106.8, 24611.66, 23796.12, 23718.43,
        24048.54, 23708.72, 24834.96, 25883.47, 27019.42, 25126.21, 24757.27,
        24640.78, 23941.73, 23737.84, 23864.07, 24922.33, 24592.21, 23058.24,
        22563.11, 22815.53, 22232.99, 21271.85, 21543.68, 20611.65, 19728.16,
        20155.35, 20359.23, 18951.45, 17669.9, 17592.24, 17621.37, 17339.79,
        17524.26, 17106.78, 16378.64, 17203.89, 16708.72, 17067.95, 16446.59,
        16407.76, 16834.95, 16631.06, 16757.29, 15883.51, 14601.95, 14281.55,
        16786.41, 15038.85, 15737.87, 15815.52, 15883.51, 15757.28, 15970.87,
        16747.58, 16980.59, 18349.51, 18669.91, 18116.5, 16883.48, 17252.42,
        17563.09, 17679.61, 17097.08, 16563.11, 16291.24, 15902.92, 16495.13,
        16708.72, 16242.71, 16203.88, 15922.34, 15524.27, 15349.51, 14242.72,
        13786.41, 13126.22, 13106.81, 13184.47, 12553.4, 13902.9, 13611.65,
        13728.14, 13067.95, 13339.82, 13495.13, 13233.0, 12873.77, 12844.65,
        12970.88, 13048.53, 12883.48, 13330.11, 13728.14, 13660.19, 14864.08,
        15067.97, 14825.25, 14233.01, 13339.82, 13475.72, 13601.94, 13592.24,
        13407.77, 12708.75, 13281.54, 12825.23, 13097.07, 12834.94, 12368.93,
        12398.05, 12650.47, 13135.93, 13165.05, 12873.77, 13077.65, 13611.65,
        13349.52, 13155.35, 12747.58, 12922.34, 13087.36, 13728.14, 13980.59,
        14388.36, 14213.6, 14058.25, 14466.02, 14902.91, 18174.74, 19699.01,
        19029.11, 18815.52, 17281.55, 15834.94, 15873.8, 15660.21, 16883.48,
        16873.78, 17524.26, 17213.6, 16669.89, 16436.88, 16184.46, 17106.78,
        17436.89, 16990.3, 18407.75, 17475.72, 18135.91, 17679.61, 18174.74,
        18427.16, 18553.39, 17970.86, 17718.44, 17048.54, 17495.14, 16669.89,
        16533.99, 16728.17, 17572.8, 18417.46, 18038.84, 17407.77, 16970.88,
        16922.31, 16368.93, 16339.81, 17349.5, 18669.91, 18349.51, 18563.1,
        19475.71, 25106.8, 26611.65, 26514.54, 24699.03, 23417.47, 24689.32,
        23533.96, 23737.84, 24048.54, 23873.78, 23349.52, 23970.89, 24446.61,
        25330.1, 24932.03, 24349.5, 23495.13, 24864.08, 24796.1, 25407.75,
        25990.29, 30504.84, 28572.81, 27893.2, 29834.94, 29825.24, 30174.76,
        30970.85, 31213.6, 26368.93, 26504.83, 28106.79, 27359.2, 27378.65,
        26563.11, 26466.01, 26660.18, 25553.39, 23572.82, 21388.33, 21796.1,
        21116.5, 20941.73, 22097.09, 22582.52, 22097.09, 20922.32, 20980.56,
        21621.34, 20776.71, 20524.26, 20834.95
      ],
      POL: [
        10000.0, 10462.41, 10064.14, 8849.71, 9120.72, 8742.1, 8526.95, 8280.75,
        8692.46, 8334.55, 9296.59, 9530.37, 8860.05, 9173.49, 8795.91, 8777.28,
        8788.66, 8364.55, 8166.96, 7805.93, 8104.9, 8132.84, 7754.22, 7436.65,
        7568.03, 7586.63, 7881.45, 8117.31, 8125.59, 8406.95, 8329.37, 8152.49,
        8355.23, 8280.75, 8129.71, 8016.98, 8046.98, 8357.3, 8628.34, 8717.29,
        8860.05, 8816.59, 8611.78, 9146.59, 8795.91, 9225.2, 9115.56, 9798.29,
        9617.26, 10322.75, 10365.15, 10651.7, 9740.36, 10416.89, 10214.15,
        10283.45, 10195.52, 10998.25, 10658.94, 10425.17, 10261.73, 10567.93,
        11224.78, 11305.48, 11853.73, 10508.97, 11368.59, 12029.6, 11615.8,
        11800.98, 12439.24, 12826.11, 12059.59, 13050.6, 12575.78, 11746.15,
        10842.04, 11260.99, 10619.64, 9642.09, 10482.07, 10294.82, 9924.48,
        10245.17, 10448.95, 10873.09, 10844.13, 10501.72, 10408.61, 10236.89,
        10142.76, 10348.62, 9832.43, 9364.86, 9264.51, 9317.28, 9137.28,
        9340.03, 9428.98, 9802.44, 9359.68, 9187.96, 9086.6, 7878.35, 6703.22,
        7362.16, 7390.1, 7279.4, 6975.27, 7015.63, 7000.11, 7598.03, 7398.38,
        7726.29, 7520.45, 7280.43, 7434.59, 7268.03, 7562.85, 7552.5, 7328.02,
        6895.63, 7122.18, 7485.27, 7591.81, 7533.88, 7569.06, 7389.07, 7228.72,
        7052.88, 7210.1, 6929.77, 7031.13, 6949.43, 6858.38, 6719.78, 7087.0,
        7173.89, 7399.41, 7325.95, 7101.49, 7745.94, 7566.97, 7460.43, 7721.13,
        7475.96, 7535.95, 7445.96, 7695.26, 7580.44, 7436.65, 7216.31, 7209.07,
        7257.68, 7124.24, 7309.4, 7393.19, 7472.86, 7522.51, 6910.12, 6707.35,
        6773.55, 6720.81, 6400.12, 6671.16, 6261.52, 6100.14, 6359.78, 6437.36,
        6086.68, 5738.08, 5970.82, 5958.42, 5909.8, 5917.05, 5823.94, 5764.98,
        5887.05, 5726.71, 5878.78, 5734.99, 5670.85, 5818.76, 5791.88, 5892.21,
        5504.29, 5247.76, 4828.81, 5239.49, 4957.07, 5178.44, 5169.13, 5287.07,
        5162.94, 5241.55, 5516.72, 5513.6, 5650.16, 5593.27, 5607.73, 5350.16,
        5509.47, 5587.05, 5648.07, 5500.16, 5530.15, 5356.37, 5122.57, 5320.16,
        5387.4, 5238.45, 5281.89, 5244.64, 5145.35, 5091.55, 4831.9, 4678.81,
        4409.86, 4017.8, 4252.61, 4082.98, 4459.5, 4357.09, 4399.51, 4190.55,
        4342.62, 4373.64, 4364.33, 4147.09, 4189.52, 4227.79, 4310.53, 4438.82,
        4792.6, 5411.2, 5529.12, 5548.78, 5850.84, 5483.6, 5207.4, 4829.84,
        4503.99, 4368.46, 4360.21, 4335.37, 4118.13, 4260.88, 4148.12, 3951.59,
        3848.15, 3750.91, 3808.84, 3882.29, 3960.91, 3924.69, 3924.69, 3924.69,
        4325.03, 4191.58, 4095.38, 3881.24, 3948.47, 3982.62, 4105.72, 4144.0,
        4217.45, 4156.4, 4194.68, 4235.04, 4275.38, 4405.7, 4496.75, 4376.74,
        4363.3, 4128.47, 3879.17, 3889.51, 3898.83, 3974.34, 3929.88, 3950.56,
        3884.36, 3884.36, 3798.5, 3797.47, 3866.77, 3855.39, 3777.81, 3906.07,
        3814.0, 3855.39, 3786.07, 3803.66, 3848.15, 3968.15, 3862.64, 3798.5,
        3669.18, 3686.77, 3439.53, 3402.29, 3412.63, 3380.57, 3466.43, 3438.5,
        3316.43, 3279.19, 3237.82, 3111.62, 3014.39, 3144.71, 3478.84, 3562.64,
        4060.2, 3957.78, 4211.24, 4351.93, 4225.73, 3867.8, 3752.98, 3834.71,
        4381.92, 4094.35, 4656.06, 4431.57, 4494.68, 4646.75, 5007.75, 5886.0,
        5950.14, 5764.98, 5429.83, 5997.73, 5857.03, 6045.31, 6162.2, 6213.93,
        6684.6, 7404.57, 7359.07, 7091.15, 7455.27, 7219.41, 7280.43, 5935.67,
        6003.94, 6554.25, 6443.58, 6333.92, 6190.13, 6220.12, 6200.47, 5841.53,
        5410.17, 4995.34, 5176.37, 4876.4, 4910.52, 5216.71, 5423.61, 5301.54,
        4945.7, 4943.63, 5089.48, 4796.73, 4761.57, 4710.87
      ],
      UNI: [
        10000.0, 10444.33, 9925.48, 8862.97, 9025.81, 8749.84, 8755.35, 8286.19,
        8592.53, 8225.48, 9129.31, 9311.45, 8937.48, 9225.89, 8818.82, 9183.12,
        9483.92, 9289.36, 8771.9, 8760.88, 8980.28, 8847.8, 8348.28, 7971.57,
        8062.64, 7949.51, 8193.74, 8254.44, 8162.01, 8447.65, 8672.55, 8279.3,
        8333.11, 8475.25, 8415.9, 8271.02, 8240.65, 8696.03, 8845.03, 8720.86,
        9225.89, 9162.42, 9260.38, 9446.67, 9224.5, 9481.16, 10278.74, 10223.55,
        10684.43, 10651.3, 10681.66, 10438.79, 10143.52, 10231.82, 15298.74,
        15453.28, 15032.43, 14615.7, 14836.48, 14915.14, 15146.97, 15963.85,
        17259.57, 17516.23, 17105.01, 17579.7, 21406.11, 20390.52, 20113.16,
        19592.93, 18948.53, 20033.13, 19301.79, 19510.14, 19242.44, 17988.13,
        16664.83, 17248.53, 16322.62, 14750.94, 16448.2, 16609.64, 15959.72,
        16227.4, 16584.81, 17236.1, 17547.95, 17031.87, 17680.43, 17407.19,
        17394.79, 17808.75, 17128.48, 15730.64, 15082.11, 15042.09, 15073.84,
        15614.74, 15607.85, 16180.5, 15493.31, 13807.09, 12624.53, 10965.91,
        9304.55, 10335.32, 10135.22, 9955.85, 9563.95, 10078.67, 10365.66,
        10765.84, 10681.66, 11404.73, 11023.87, 10572.65, 10932.81, 10569.89,
        10981.11, 10910.72, 10640.28, 9718.52, 9670.21, 9871.67, 10491.25,
        10371.2, 10405.69, 10382.24, 10295.3, 10117.28, 10390.52, 9768.18,
        9740.58, 9739.19, 9637.08, 9366.64, 10157.31, 10059.35, 10378.09,
        10845.86, 10586.44, 12659.04, 13245.48, 12893.61, 12569.33, 13837.46,
        15436.73, 15468.48, 15265.64, 15736.18, 14510.84, 14792.33, 13837.46,
        13671.87, 13021.94, 13159.92, 15313.94, 15360.83, 14713.69, 13529.74,
        13764.33, 13538.01, 14182.41, 12380.3, 13927.14, 13969.91, 14417.01,
        15747.22, 16054.92, 14819.92, 13408.3, 13733.96, 13873.33, 13500.75,
        13595.97, 13582.16, 12596.93, 13084.05, 13079.9, 13130.94, 12406.52,
        12224.38, 12885.33, 12667.32, 12399.62, 11753.84, 10833.46, 10663.72,
        11353.66, 10607.14, 11103.9, 11159.09, 11101.16, 10829.31, 11152.2,
        11331.6, 11633.79, 11774.54, 11225.33, 11026.64, 10819.65, 10975.57,
        11008.7, 11208.78, 10786.54, 10656.83, 10220.78, 10055.19, 10608.53,
        10658.21, 10336.7, 10600.25, 10216.65, 9964.13, 9918.59, 9114.11,
        9103.07, 8620.13, 7440.33, 7988.13, 7814.26, 8689.11, 8453.16, 8552.5,
        8174.42, 8500.08, 8782.94, 8777.43, 8649.11, 8981.66, 8825.71, 8598.04,
        8524.91, 8673.94, 9775.07, 9550.16, 9664.68, 9499.09, 9217.61, 8707.04,
        7930.19, 7937.08, 8036.43, 8117.84, 8167.52, 7672.15, 8509.74, 8406.24,
        8956.8, 8698.77, 8377.26, 8831.25, 8700.15, 9276.96, 9274.19, 9385.96,
        9431.5, 9539.12, 9194.16, 9081.01, 8813.31, 9365.26, 9158.26, 9413.56,
        9362.49, 9503.25, 9201.06, 9427.35, 9605.36, 9385.96, 10189.06, 10556.1,
        10465.03, 10258.04, 10288.4, 9512.91, 9282.47, 9076.86, 9271.42, 9443.9,
        9660.55, 10011.04, 9850.97, 9988.96, 11127.37, 10895.55, 11342.62,
        10499.52, 11273.64, 10771.35, 10510.57, 10171.12, 10216.65, 10143.52,
        10696.83, 10854.14, 11156.35, 10767.22, 11034.92, 10683.04, 10229.06,
        10416.73, 10705.13, 10949.36, 11268.13, 10571.27, 10442.94, 10129.71,
        9764.05, 9322.49, 9889.61, 13208.23, 12275.42, 12062.92, 12857.74,
        12679.72, 12580.38, 12561.06, 11862.84, 11469.59, 11755.22, 12545.89,
        11929.08, 12686.64, 12781.84, 12167.8, 12806.69, 13068.86, 14800.6,
        14995.17, 15707.2, 14899.95, 18948.53, 17765.99, 17459.65, 17643.17,
        18119.22, 18958.19, 20582.31, 21695.87, 20738.24, 24164.48, 24611.57,
        25569.21, 21484.75, 20968.68, 23427.62, 24186.57, 23707.75, 23182.01,
        22866.01, 22685.26, 22578.99, 20542.29, 18462.82, 18908.53, 18397.96,
        19259.02, 20157.31, 20155.92, 18981.66, 18487.65, 18193.74, 18987.17,
        17833.58, 18349.66, 18542.84
      ],
      USDT: [
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0,
        10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0, 10000.0
      ],
      HODLIE: [
        10000.0, 10090.8, 10175.66, 9927.65, 10105.49, 10049.71, 9973.45,
        9959.12, 10215.18, 10134.48, 10348.73, 10486.17, 10256.67, 10415.11,
        10395.89, 10326.54, 10346.98, 10381.18, 10159.87, 10211.98, 10238.87,
        10172.6, 10074.29, 9983.41, 9982.69, 9914.35, 10104.47, 10153.52,
        10094.06, 10276.93, 10365.22, 10131.66, 10439.88, 10537.79, 10526.13,
        10608.94, 10712.39, 10635.14, 10734.62, 10733.75, 10948.06, 11010.73,
        11045.14, 11032.58, 10961.29, 11238.42, 11322.45, 11275.79, 11301.67,
        11222.84, 11203.82, 11275.03, 11167.27, 11260.6, 11190.41, 11166.51,
        11100.76, 11040.86, 11204.06, 11624.07, 11621.04, 11550.74, 12249.08,
        12215.68, 13474.28, 13390.88, 13400.22, 13195.49, 13132.75, 13160.38,
        13115.11, 13200.81, 13004.54, 12938.43, 12926.05, 13108.95, 13042.47,
        13504.65, 13442.21, 12983.11, 12970.42, 13018.07, 12977.03, 13195.59,
        13369.11, 13349.08, 13330.5, 13254.75, 13394.56, 13307.49, 13149.03,
        13221.0, 13171.57, 12983.38, 12789.56, 12761.73, 12758.33, 12853.56,
        12865.9, 13121.98, 12768.33, 12805.73, 12768.0, 12722.82, 12533.17,
        12523.75, 12522.54, 12520.67, 12392.07, 12389.08, 12415.96, 12573.98,
        12577.31, 12641.89, 12608.7, 12337.87, 12366.81, 12383.64, 12312.44,
        12371.81, 12234.34, 11840.02, 11838.16, 11993.58, 11976.12, 12021.27,
        12013.91, 11970.52, 11881.69, 11759.0, 11771.43, 11632.34, 11582.53,
        11501.72, 11483.56, 11281.46, 11498.98, 11414.92, 11555.9, 11603.07,
        11662.74, 11984.38, 12247.11, 12139.21, 12246.0, 12035.16, 12275.56,
        12280.21, 12244.43, 12189.86, 12229.0, 12048.28, 11827.64, 11809.28,
        11752.65, 11778.25, 11999.72, 11917.04, 11809.34, 11581.77, 11582.51,
        11581.86, 11577.81, 11314.56, 11316.3, 11321.7, 11241.4, 11462.32,
        11509.7, 11395.52, 11305.76, 11313.31, 11226.32, 11166.44, 10972.02,
        10955.27, 10924.58, 10966.44, 10916.69, 11241.07, 11069.21, 11032.92,
        11188.88, 11143.9, 11237.85, 10976.19, 10836.1, 10833.52, 10836.94,
        10523.18, 10523.73, 10500.17, 10555.66, 10529.81, 10547.53, 10632.67,
        10655.64, 10855.68, 10878.26, 10964.91, 10686.99, 10741.75, 10838.19,
        11054.66, 10961.32, 10778.83, 10718.02, 10659.04, 10752.82, 10800.5,
        10716.94, 10717.35, 10694.23, 10708.01, 10560.85, 10539.1, 10537.58,
        10534.98, 10531.85, 10534.26, 10567.77, 10763.09, 10662.63, 10660.3,
        10557.82, 10567.38, 10601.86, 10520.28, 10437.45, 10494.16, 10543.53,
        10476.75, 10415.0, 10480.28, 10804.2, 10866.22, 10876.84, 10927.93,
        10929.92, 10840.35, 10762.89, 10763.2, 10762.99, 10816.95, 10820.82,
        10744.62, 10835.51, 10808.13, 10961.58, 10892.16, 10856.3, 10938.33,
        11063.41, 11173.54, 11168.91, 11143.79, 11208.89, 11287.74, 11185.3,
        11089.75, 10949.06, 10968.8, 10864.01, 10961.28, 11010.57, 11065.66,
        11095.87, 11215.84, 11257.25, 11173.67, 11332.28, 11529.04, 11446.44,
        11416.7, 11305.54, 11200.2, 11204.16, 11204.06, 11205.16, 11211.34,
        11202.08, 11167.93, 11129.14, 11166.68, 11437.86, 11387.0, 11487.42,
        11475.09, 11653.83, 11539.78, 11623.49, 11632.37, 11757.63, 11844.08,
        11833.62, 11826.56, 11852.29, 11808.57, 11983.63, 11649.62, 11664.56,
        11740.95, 11931.52, 12170.3, 12097.1, 11994.37, 11952.86, 11851.29,
        11859.12, 11858.43, 11848.35, 12236.63, 12284.25, 12445.56, 12806.66,
        13455.73, 13512.06, 13926.16, 13889.13, 13765.07, 14222.65, 14629.91,
        14512.18, 14702.97, 14409.34, 14415.16, 14266.18, 14297.86, 14429.33,
        14568.9, 14338.2, 14174.58, 14742.18, 14533.16, 14714.04, 15038.02,
        15534.97, 16540.27, 16336.39, 15988.32, 15785.62, 16051.23, 15993.53,
        16079.67, 15416.46, 15396.64, 15400.55, 15464.49, 15407.85, 15541.82,
        15510.3, 15697.79, 15568.35, 15148.31, 14889.99, 14818.94, 14816.84,
        14815.55, 14963.61, 14902.0, 14733.47, 14462.23, 14341.67, 14327.89,
        14111.82, 14122.83, 14164.94
      ]
    }
  }
};
