import React, { useState, useEffect /*, useContext*/ } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Card,
  Accordion,
  Form,
  Dropdown,
  Pagination,
  ButtonGroup,
  ToggleButton,
  Spinner
} from 'react-bootstrap';
// import AppContext from 'context/Context';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InvoiceRow from './InvoiceRow';
import FalconCloseButton from 'components/common/FalconCloseButton';

const ClusterInvoices = ({ invoices, usersData, dataLoaded }) => {
  // const {
  //   config: { lang}
  // } = useContext(AppContext);
  const itemPerPage = 10;

  const [invoicesData, setInvoicesData] = useState([]);
  const [searchInvoicesData, setSearchInvoicesData] = useState([]);
  const [invoicesDataPageShow, setInvoicesDataPageShow] = useState(0);
  const [invoicesDataSearchValue, setInvoicesDataSearchValue] = useState('');
  const [showFuture, setShowFuture] = useState(true);

  const filterInvoicesData = (invoicesList, searchString) => {
    setInvoicesDataSearchValue(searchString);
    const lowerCasedSearch = searchString.toLowerCase();

    const filteredUsers = invoicesList.filter(
      user =>
        user.name?.toLowerCase().includes(lowerCasedSearch) ||
        user.surname?.toLowerCase().includes(lowerCasedSearch) ||
        user.email?.toLowerCase().includes(lowerCasedSearch)
    );
    setSearchInvoicesData(filteredUsers);
  };

  const combineInvoiceWithUser = invoice => {
    const user = usersData.find(
      user =>
        user.user_id === invoice.user_id ||
        user.user_id === parseInt(invoice.user_id)
    );

    return {
      ...invoice,
      email: user?.email || null,
      name: user?.name || null,
      surname: user?.surname || null
    };
  };

  const processInvoices = (localShowFuture = showFuture) => {
    const invoicesToProcess = localShowFuture
      ? invoices.future_invoices
      : invoices.paid_invoices;

    const processedInvoices = localShowFuture
      ? Object.entries(invoicesToProcess).map(([userId, invoice]) => ({
          ...combineInvoiceWithUser({ ...invoice, user_id: parseInt(userId) })
        }))
      : invoicesToProcess.map(combineInvoiceWithUser);

    // order invoices only if localShowFuture
    if (localShowFuture) {
      return processedInvoices.sort((a, b) => {
        const aEnd = a.period_end || a.cur_end;
        const bEnd = b.period_end || b.cur_end;
        return new Date(aEnd) - new Date(bEnd);
      });
    }

    return processedInvoices;
  };

  const handleChangeButton = localShowFuture => {
    if (localShowFuture !== showFuture) {
      const localProcessInvoices = processInvoices(localShowFuture);
      setInvoicesData(localProcessInvoices);
      setSearchInvoicesData(localProcessInvoices);
      setInvoicesDataPageShow(0);
      setShowFuture(localShowFuture);
    }
  };

  useEffect(() => {
    if (dataLoaded) {
      const localProcessInvoices = processInvoices();
      setInvoicesData(localProcessInvoices);
      setSearchInvoicesData(localProcessInvoices);
    }
  }, [invoices, dataLoaded]);

  return dataLoaded ? (
    <>
      {/* active users */}
      <Row className="mb-3">
        <Col>
          <Card>
            <Card.Header className="d-sm-flex justify-content-between align-items-center">
              <h4 className="mb-0">Lista Fatture</h4>
              <ButtonGroup className="">
                <ToggleButton
                  size="sm"
                  id={`radio-past`}
                  type="radio"
                  variant={'falcon-primary'}
                  checked={!showFuture}
                  onClick={() => handleChangeButton(false)}
                >
                  {'Passate'}
                </ToggleButton>
                <ToggleButton
                  size="sm"
                  id={`radio-future`}
                  type="radio"
                  variant={'falcon-primary'}
                  checked={showFuture}
                  onClick={() => handleChangeButton(true)}
                >
                  {'In corso'}
                </ToggleButton>
              </ButtonGroup>
              <div className="mt-2 mt-sm-0">
                <Dropdown className="search-box w-auto">
                  <Dropdown.Toggle
                    as="div"
                    data-toggle="dropdown"
                    bsPrefix="toggle"
                  >
                    <Form className="position-relative">
                      <Form.Control
                        type="search"
                        placeholder="Search..."
                        aria-label="Search"
                        value={invoicesDataSearchValue}
                        className="rounded-pill search-input"
                        onChange={({ target }) =>
                          filterInvoicesData(invoicesData, target.value)
                        }
                      />
                      <FontAwesomeIcon
                        icon="search"
                        className="position-absolute text-400 search-box-icon"
                      />
                      {invoicesDataSearchValue && (
                        <div className="search-box-close-btn-container">
                          <FalconCloseButton
                            size="sm"
                            noOutline
                            onClick={() => filterInvoicesData(invoicesData, '')}
                          />
                        </div>
                      )}
                    </Form>
                  </Dropdown.Toggle>
                </Dropdown>
              </div>
            </Card.Header>
            <Card.Body>
              {searchInvoicesData.length > 0 ? (
                <Accordion>
                  {searchInvoicesData
                    .slice(
                      invoicesDataPageShow * itemPerPage,
                      invoicesDataPageShow * itemPerPage + itemPerPage
                    )
                    .map((invoice, index) => (
                      <InvoiceRow key={index} invoice={invoice} />
                    ))}
                </Accordion>
              ) : (
                <p>Non è presente nessuna fattura</p>
              )}
              {searchInvoicesData.length > itemPerPage && (
                <Row className="mt-3">
                  <Col as={Flex} justifyContent="end">
                    <Pagination>
                      {invoicesDataPageShow !== 0 && (
                        <Pagination.Prev
                          onClick={() =>
                            setInvoicesDataPageShow(invoicesDataPageShow - 1)
                          } // go to prev
                        />
                      )}
                      {Array.from(
                        {
                          length: Math.ceil(
                            searchInvoicesData.length / itemPerPage
                          )
                        },
                        (_, i) => (
                          <Pagination.Item
                            key={i}
                            active={i === invoicesDataPageShow}
                            onClick={() => setInvoicesDataPageShow(i)}
                          >
                            {i + 1}
                          </Pagination.Item>
                        )
                      )}
                      {invoicesDataPageShow !==
                        Math.ceil(searchInvoicesData.length / itemPerPage) -
                          1 && (
                        <Pagination.Next
                          onClick={() =>
                            setInvoicesDataPageShow(invoicesDataPageShow + 1)
                          } // go to next
                        />
                      )}
                    </Pagination>
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  ) : (
    <Flex justifyContent="center" className="mt-3">
      <Spinner />
    </Flex>
  );
};

ClusterInvoices.propTypes = {
  invoices: PropTypes.object,
  usersData: PropTypes.array,
  dataLoaded: PropTypes.bool
};

export default ClusterInvoices;
