import React, { useState, useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Card, Spinner } from 'react-bootstrap';
import { VirtualMode as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import SoftBadge from 'components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { walletImgRender } from 'services/coins/components';
import { currencyMap } from 'services/coins/common';
import { calculateCoinsValue } from 'services/coins/functions';
import {
  defaultPortfolio,
  portfolioList
} from 'pages/CreateBot/staticData/portfolioList';
import Flex from 'components/common/Flex';
import ButtonGrid from 'pages/CreateBot/ButtonGrid';
import HalfDoughnutChart from 'pages/common/HalfChartPortfolio';
import { coinsData } from 'services/coins/common';
import SimpleBarReact from 'simplebar-react';
import CardDropdown from 'pages/common/portfolioRow/CardDropdown';
import LinePaymentChart from 'pages/common/BotWallet/LinePaymentChart';
import LottieBotLazy from 'services/lazyLoading/LottieBotLazy';
import classNames from 'classnames';
import { breakpoints } from 'helpers/utils';
import { firstProTier, getBreakpoint } from 'staticData/common';
import PortfolioButtons from './PortfolioButtons';
import IconAlert from 'components/common/IconAlert';
import { Link } from 'react-router-dom';
import { trackEvent } from 'services/externals/mixpanel';
import InfoPopover from 'components/utilities/InfoPopover';

const LinePaymentButtonMap = {
  '24h': '1d_timepoints',
  '7d': '7d_timepoints',
  '1m': '30d_timepoints',
  '6m': '6m_timepoints',
  All: 'all_timepoints'
};
const chartStyle = {
  xs: { height: '200px' },
  sm: { height: '300px' },
  md: { height: '300px' },
  lg: { height: '300px' },
  xl: { height: '300px' },
  xxl: { height: '300px' }
};
const convertWallet = wallet => {
  return Object.entries(wallet).map(([timestamp, values]) => ({
    ts: timestamp,
    EUR: values.EUR,
    USDT: values.USDT
  }));
};

const getColorAndCaret = num => {
  if (Number(num) < 0) return ['text-danger', 'caret-down'];
  if (Number(num) > 0) return ['text-success', 'caret-up'];
  return ['text-dark', 'minus'];
};

const PortfolioRowData = ({
  portfolioData = {},
  dynamicCoins,
  showChart = true,
  exchange = 'binance',
  botList = [],
  forceOpenModal = false,
  setForceOpenModal = () => null,
  addCapitalClickEvent = null,
  setAddCapitalClickEvent = () => null,
  clusterChildID = null
}) => {
  const {
    config: { lang, currency },
    userInfos
  } = useContext(AppContext);

  const [loaded, setLoaded] = useState(false);
  const [bot, setBot] = useState({});
  const [showedWallet, setShowedWallet] = useState({});
  const [granularity, setGranularity] = useState('All');
  const [riskLevel, setRiskLevel] = useState(0);
  const chartHeigth = 18;
  const [initialEquivalent, setInitialEquivalent] = useState(0);
  const [addedCapital, setAddedCapital] = useState(0);
  const chartRef = useRef(null);
  const [lastValue, setLastValue] = useState(0);
  const [performance, setPerformance] = useState(0);
  const [performanceColorAndCaret, setPerformanceColorAndCaret] = useState();
  const [coinListItems, setCoinListItems] = useState([]);
  const [donutData, setDonutData] = useState();
  const [portfolioDetails, setPortfolioDetails] = useState();
  const [missingEventsData, setMissingEventsData] = useState(false);
  const hideData =
    userInfos.pro_tier === 0 && exchange === 'binance' && !clusterChildID;

  const now = new Date();
  const isBolloTime =
    now >= new Date(2025, 0, 21) && now <= new Date(2025, 1, 1);

  const botStatusColor = status => {
    if (status.toUpperCase() === 'ACTIVE') return 'success';
    if (status.toUpperCase() === 'ERROR') return 'danger';
    else return 'warning';
  };

  const handleButtonClick = key => {
    if (Object.keys(bot.wallet?.[LinePaymentButtonMap[key]]).length === 0)
      return;
    setGranularity(key);
    setShowedWallet(convertWallet(bot.wallet?.[LinePaymentButtonMap[key]]));
  };

  const manageDynamicData = (localBot = bot) => {
    const localInitialEquivalent =
      localBot.bot_details.initial_eqv[currencyMap[currency]];
    setInitialEquivalent(localInitialEquivalent);
    const localLastValue = localBot.last_stat
      ? Object.keys(dynamicCoins).length
        ? calculateCoinsValue(localBot.last_stat.wallet, dynamicCoins)
        : localBot.last_stat.eqv[currencyMap[currency]]
      : localInitialEquivalent;
    const localAddedCapital = localBot.added_capital;
    setAddedCapital(localAddedCapital);
    setLastValue(localLastValue);
    const localPerformance =
      localLastValue - (localInitialEquivalent + localAddedCapital);
    setPerformance(localPerformance);
    setPerformanceColorAndCaret(getColorAndCaret(localPerformance));
  };

  const loadDonutData = bot => {
    const localDonutData = Object.keys(dynamicCoins).length
      ? {
          labels: Object.keys(bot.last_stat.wallet),
          datasets: [
            {
              data: Object.keys(bot.last_stat.wallet).map(key =>
                Number(
                  (
                    (bot.last_stat.wallet[key] * dynamicCoins[key] * 100) /
                    calculateCoinsValue(bot.last_stat.wallet, dynamicCoins)
                  ).toFixed(2)
                )
              ),
              backgroundColor: Object.keys(bot.last_stat.wallet).map(
                key => coinsData[key].color
              ),
              borderColor: Object.keys(bot.last_stat.wallet).map(
                key => coinsData[key].color
              )
            }
          ]
        }
      : {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: [],
              borderColor: []
            }
          ]
        };
    setDonutData(localDonutData);
  };

  const loadCoinListItems = bot => {
    setCoinListItems(
      Object.keys(dynamicCoins).length ? (
        Object.keys(bot.last_stat.wallet)
          .filter(key => bot.last_stat.wallet[key] > 0)
          .sort(
            (a, b) =>
              bot.last_stat.wallet[b] * dynamicCoins[b] -
              bot.last_stat.wallet[a] * dynamicCoins[a]
          )
          .map(key => (
            <>
              {bot.last_stat.wallet[key] > 0 && (
                <p key={key} className="pe-2">
                  <Flex
                    style={{ color: coinsData[key].color }}
                    className="justify-content-between"
                  >
                    <Flex>
                      {walletImgRender([key], 'me-2')}
                      {coinsData[key].name}
                    </Flex>
                    <span className="text-900 ps-2 fw-medium fs--1">
                      {currency}
                      {(bot.last_stat.wallet[key] * dynamicCoins[key]).toFixed(
                        2
                      )}
                      <span className="text-600 ms-1">
                        (
                        {(
                          (bot.last_stat.wallet[key] *
                            dynamicCoins[key] *
                            100) /
                          calculateCoinsValue(
                            bot.last_stat.wallet,
                            dynamicCoins
                          )
                        ).toFixed(2)}
                        %)
                      </span>
                    </span>
                  </Flex>
                </p>
              )}
            </>
          ))
      ) : (
        <></>
      )
    );
  };

  const checkAndSetMissingEventsData = localBot => {
    // check if bot has missing events
    if (!localBot.snapshots || !localBot.last_stat) {
      console.error('Dati mancanti in localBot.');
      return;
    }

    const { snapshots, last_stat } = localBot;

    // convert last_stat.ts to Date object
    const lastStatDate = new Date(last_stat.ts);

    const hasNewerSnapshot = snapshots.some(snapshot => {
      const snapshotDate = new Date(snapshot.ts);
      return snapshotDate > lastStatDate;
    });

    // if there is at least one newer snapshot, call setMissingEventsData(true)
    if (hasNewerSnapshot) {
      setMissingEventsData(true);
    }
  };

  const handleTrackEvent = () => {
    trackEvent('Error Link to Guide', {
      error: '410',
      mail: userInfos.email
    });
  };

  const formattedCapitalAlignement = alignementArray => {
    const concatenatedString =
      alignementArray
        ?.map(item => {
          // Estrai la data e convertila in un oggetto Date
          const date = new Date(item.ts);

          // Format the date to "gg/mm/aaaa"
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0'); // I mesi sono indicizzati da 0
          const year = date.getFullYear();

          // Ottieni il valore formattato
          const value =
            item.event_metadata.eqv[currencyMap[currency]].toFixed(2);

          // Restituisci la stringa formattata
          return `${value} ${currencyMap[currency]} in data ${day}/${month}/${year}`;
        })
        .join(', ') || ''; // Unisce le stringhe e gestisce il caso di undefined

    return concatenatedString;
  };

  useEffect(() => {
    try {
      if (Object.keys(portfolioData).length) {
        const localBot = portfolioData;
        if (!loaded) {
          setBot(localBot);
          checkAndSetMissingEventsData(localBot);
          const botType = localBot.bot_details.type;
          const { id, name, bgStyle, backendMinLevels, riskLevels } =
            portfolioList.find(
              localBot => String(localBot.id) === String(botType)
            ) || defaultPortfolio;
          setPortfolioDetails({ id, name, bgStyle });
          setShowedWallet(convertWallet(localBot.wallet?.all_timepoints || {}));
          setRiskLevel(
            riskLevels[backendMinLevels.indexOf(localBot.bot_details.min_level)]
          );
          loadDonutData(localBot);
          loadCoinListItems(localBot);
          manageDynamicData(localBot);
          setLoaded(true);
        }
      }
    } catch (error) {
      trackEvent('DEV Error', {
        msg: error.message,
        description: 'Error in PortfolioRowData',
        data: {
          user_mail: userInfos.email,
          screen_width: window.innerWidth,
          loaded: loaded,
          coin: dynamicCoins,
          portfolio_data: portfolioData,
          exchange: exchange
        }
      });
      console.error(error);
    }
  }, [Object.keys(portfolioData), dynamicCoins]);

  useEffect(() => {
    if (loaded && (!donutData || !donutData.labels?.length)) {
      loadCoinListItems(bot);
      loadDonutData(bot);
    }
    if (loaded) manageDynamicData();
  }, [dynamicCoins]);

  const borderStyle =
    window.innerWidth > breakpoints['sm'] ? 'borderRight' : 'borderBottom';

  return (
    <>
      <Row className="">
        <Col xs={12}>
          {loaded &&
            userInfos.pro_tier >= firstProTier &&
            bot.bot_details.status.toUpperCase() === 'ERROR' &&
            bot?.bot_details.status_info &&
            (() => {
              const shouldWrapInLink =
                bot.bot_details.status_info[lang]?.startsWith('Errore 410') ||
                bot.bot_details.status_info[lang]?.startsWith('Error 410');

              let stringToRemove1 =
                "Per ripristinare l'operatività, eliminala e ricreala.";
              let stringToRemove2 = 'Please delete and recreate it.';

              let resultString = bot.bot_details.status_info[lang]
                .replace(stringToRemove1, '')
                .replace(stringToRemove2, '');

              const iconAlertContent = (
                <IconAlert
                  variant="danger"
                  className={classNames('mb-1', {
                    'hover-alert-danger': shouldWrapInLink
                  })}
                  {...(shouldWrapInLink && {
                    title: (
                      <div className="mb-2">
                        {textLang.goToGuide[lang]}
                        <FontAwesomeIcon
                          icon={'chevron-right'}
                          className="ms-2"
                        />
                      </div>
                    )
                  })}
                >
                  <p
                    className="mb-0 fs--1"
                    dangerouslySetInnerHTML={{
                      __html:
                        shouldWrapInLink && isBolloTime
                          ? resultString + textLang.bolloText[lang]
                          : bot.bot_details.status_info[lang]
                    }}
                  ></p>
                </IconAlert>
              );

              return shouldWrapInLink ? (
                <Link
                  to={{
                    pathname: isBolloTime
                      ? 'https://support.hodlie.ai/come-pagare-limposta-di-bollo'
                      : 'https://support.hodlie.ai/tipologie-di-errori-410'
                  }}
                  target="_blank"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  onClick={handleTrackEvent}
                >
                  {iconAlertContent}
                </Link>
              ) : (
                iconAlertContent
              );
            })()}
        </Col>
        <Col xs={12}>
          <Card style={{ backgroundImage: portfolioDetails?.bgStyle || '' }}>
            <Card.Body>
              <Row>
                <Col
                  md={8}
                  style={{
                    position: 'relative',
                    ...(window.innerWidth > breakpoints['sm'] ||
                    !loaded ||
                    (loaded &&
                      bot.wallet?.all_timepoints &&
                      Object.keys(bot.wallet?.all_timepoints).length > 0)
                      ? {
                          [borderStyle]: 'var(--falcon-500) 1px solid'
                        }
                      : {})
                  }}
                >
                  <Row className="mb-4">
                    <Col xs={4} as={Flex} className="justify-content-center">
                      {loaded ? (
                        <LottieBotLazy
                          type={portfolioDetails.id || ''}
                          loop={true}
                          style={{ width: '100%' }}
                        />
                      ) : (
                        <Flex className="my-5 align-items-center justify-content-center">
                          <Spinner />
                        </Flex>
                      )}
                    </Col>
                    <Col xs={8} as={Flex} className="align-items-center">
                      <div>
                        <small>AI Portfolio</small>
                        {loaded && (
                          <h4>
                            {hideData
                              ? 'Eh Volevi!'
                              : portfolioDetails?.name || ''}
                          </h4>
                        )}
                        <Flex className="align-items-center">
                          <h6 className="mb-0 me-2">
                            {textLang.riskLevel[lang]}
                          </h6>

                          {loaded && (
                            <ButtonGrid
                              actives={[riskLevel]}
                              click={false}
                              buttonSize={'sm'}
                            />
                          )}
                        </Flex>
                      </div>
                    </Col>
                  </Row>
                  {showChart &&
                    !hideData &&
                    (!loaded ||
                      (loaded &&
                        bot.wallet?.all_timepoints &&
                        Object.keys(bot.wallet?.all_timepoints).length >
                          0)) && (
                      <>
                        <Row className="mx-sm-5 mb-1 mb-sm-4">
                          <Col
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                          >
                            {Object.keys(LinePaymentButtonMap).map(key => (
                              <p
                                key={key}
                                className={classNames('', {
                                  pointerCursor:
                                    loaded &&
                                    Object.keys(
                                      bot.wallet?.[LinePaymentButtonMap[key]]
                                    ).length,
                                  'fw-bold': granularity === key
                                })}
                                style={{
                                  borderBottom:
                                    granularity === key ? '2px solid' : 'none',
                                  opacity:
                                    loaded &&
                                    Object.keys(
                                      bot.wallet?.[LinePaymentButtonMap[key]]
                                    ).length === 0
                                      ? '.5'
                                      : '1'
                                }}
                                onClick={() => handleButtonClick(key)}
                              >
                                {key}
                              </p>
                            ))}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {loaded ? (
                              <LinePaymentChart
                                displayedWallet={showedWallet}
                                granularity={granularity}
                                ref={chartRef}
                                style={chartStyle}
                                events={bot?.snapshots || []}
                                handleCheckpointData={() => null}
                                setShowPercentage={() => null}
                                simplifyChart={true}
                                bgStyle={true}
                              />
                            ) : (
                              <Flex
                                className="justify-content-center align-items-center"
                                style={
                                  chartStyle[getBreakpoint(window.innerWidth)]
                                }
                              >
                                <Spinner />
                              </Flex>
                            )}
                          </Col>
                        </Row>
                      </>
                    )}
                  {bot?.wallet?.pending_change_allocation_task ||
                  bot?.wallet?.pending_events?.length > 0 ||
                  missingEventsData ? (
                    <h6 className="mt-5">*{textLang.eventPending[lang]}</h6>
                  ) : (
                    <Row className="mt-4 mb-2">
                      <Col xs={4} className="text-center">
                        <p className="mt-2 mb-0">{textLang.invested[lang]}</p>
                        {loaded ? (
                          <p className="mt-2 fs-sm-3">
                            <b className={'text-dark'}>
                              {hideData ? (
                                '$$$.$$'
                              ) : (
                                <>
                                  {currency}
                                  {(initialEquivalent + addedCapital).toFixed(
                                    2
                                  )}
                                </>
                              )}
                            </b>
                          </p>
                        ) : (
                          <Flex className="justify-content-center mt-2">
                            <Spinner />
                          </Flex>
                        )}
                      </Col>
                      <Col xs={4} className="text-center">
                        <p
                          className={classNames('mt-2 mb-0', {
                            'd-flex justify-content-center':
                              bot.wallet?.capitalAlignment?.length > 0
                          })}
                        >
                          {textLang.managed[lang]}
                          {!isBolloTime &&
                          bot.wallet?.capitalAlignment?.length > 0 ? (
                            <InfoPopover
                              Text={
                                textLang.capitalAlignementInfo[lang] +
                                formattedCapitalAlignement(
                                  bot.wallet?.capitalAlignment
                                )
                              }
                              className="d-flex align-items-center"
                            />
                          ) : (
                            <></>
                          )}
                        </p>
                        {loaded ? (
                          <p className="mt-2 fs-sm-3">
                            <b className={'text-dark'}>
                              {hideData ? (
                                '$$$.$$'
                              ) : (
                                <>
                                  {lastValue != null
                                    ? currency + lastValue.toFixed(2)
                                    : ''}
                                </>
                              )}
                            </b>
                          </p>
                        ) : (
                          <Flex className="justify-content-center mt-2">
                            <Spinner />
                          </Flex>
                        )}
                      </Col>
                      <Col xs={4} className="text-center">
                        <p className="mt-2 mb-0">
                          <span className="d-none d-sm-block">
                            {textLang.performance[lang]}
                          </span>
                          <span className="d-sm-none">
                            <FontAwesomeIcon
                              icon="arrow-up"
                              size="xs"
                              className="text-success"
                            />
                            <FontAwesomeIcon
                              icon="arrow-down"
                              size="xs"
                              className="text-danger mt-1"
                            />
                          </span>
                        </p>
                        {loaded ? (
                          <p className="mt-2 fs-sm-3">
                            {hideData ? (
                              '%%'
                            ) : (
                              <>
                                {performance != null && (
                                  <b className={performanceColorAndCaret[0]}>
                                    {Number(performance) !== 0 && (
                                      <span className="me-1">
                                        <FontAwesomeIcon
                                          icon={performanceColorAndCaret[1]}
                                        />
                                      </span>
                                    )}
                                    {currency}
                                    {Math.abs(Number(performance).toFixed(2))}
                                  </b>
                                )}
                              </>
                            )}
                          </p>
                        ) : (
                          <Flex className="justify-content-center mt-2">
                            <Spinner />
                          </Flex>
                        )}
                      </Col>
                    </Row>
                  )}
                  {loaded && exchange === 'virtual' && (
                    <Row className="mt-2">
                      <Col>
                        <PortfolioButtons
                          portfolio={bot}
                          managed={lastValue}
                          dynamicCoins={dynamicCoins}
                          botList={botList}
                        />
                      </Col>
                    </Row>
                  )}
                  {/* BOT info: Top Right */}
                  {loaded && !hideData && (
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: '10px'
                      }}
                    >
                      <Flex className="align-items-center">
                        <CardDropdown
                          portfolio={bot}
                          botList={botList}
                          managed={lastValue}
                          clusterChildID={clusterChildID}
                        />
                      </Flex>
                    </div>
                  )}
                </Col>
                {loaded ? (
                  <>
                    <Col md={4}>
                      {exchange !== 'virtual' && (
                        <Row className="mt-5">
                          <Col>
                            <PortfolioButtons
                              portfolio={bot}
                              managed={lastValue}
                              dynamicCoins={dynamicCoins}
                              botList={botList}
                              forceAddCapitalModalShow={forceOpenModal}
                              setForceAddCapitalModalShow={setForceOpenModal}
                              addCapitalClickEvent={addCapitalClickEvent}
                              setAddCapitalClickEvent={setAddCapitalClickEvent}
                              eventPending={
                                bot?.wallet?.pending_change_allocation_task ||
                                bot?.wallet?.pending_events.length > 0 ||
                                missingEventsData
                              }
                              clusterChildID={clusterChildID}
                            />
                          </Col>
                        </Row>
                      )}
                      {(!loaded ||
                        (loaded &&
                          (exchange === 'virtual' ||
                            (bot.wallet?.all_timepoints &&
                              Object.keys(bot.wallet?.all_timepoints).length >
                                0)))) && (
                        <Flex className="justify-content-center">
                          <div
                            style={{
                              width: 'fit-content'
                            }}
                          >
                            <div
                              className="text-center pb-3"
                              style={{
                                maxWidth: `${chartHeigth}rem`
                              }}
                            >
                              <HalfDoughnutChart
                                data={donutData}
                                style={{ marginTop: '-2rem' }}
                              />
                              {!hideData && (
                                <h4 className="d-inline-block mb-0 mt-n4 fs-1 fw-medium font-sans-serif">
                                  {textLang.now[lang]}
                                </h4>
                              )}
                            </div>
                            <SimpleBarReact
                              style={{
                                maxHeight: `${
                                  exchange !== 'virtual'
                                    ? chartHeigth * 0.8
                                    : chartHeigth * 0.5
                                }rem`,
                                width: '100%'
                              }}
                            >
                              {!hideData && (
                                <div className="mt-3">{coinListItems}</div>
                              )}
                            </SimpleBarReact>
                          </div>
                        </Flex>
                      )}
                    </Col>

                    {isBolloTime && bot.wallet?.capitalAlignment?.length > 0 ? (
                      <small className="mt-2">
                        *
                        {textLang.capitalAlignementInfo_2[lang] +
                          formattedCapitalAlignement(
                            bot.wallet?.capitalAlignment
                          )}
                      </small>
                    ) : null}
                  </>
                ) : (
                  <Col
                    md={4}
                    as={Flex}
                    className="justify-content-center align-items-center"
                  >
                    <Spinner />
                  </Col>
                )}
              </Row>
            </Card.Body>

            {/* BOT STATUS: Top Left */}
            {loaded && !hideData && (
              <div style={{ position: 'absolute', top: '10px', left: '10px' }}>
                <SoftBadge pill bg={botStatusColor(bot.bot_details.status)}>
                  {bot.bot_details.status.toUpperCase() === 'ACTIVE'
                    ? textLang.active[lang]
                    : bot.bot_details.status.toUpperCase() === 'PAUSED'
                    ? textLang.paused[lang]
                    : textLang.error[lang]}
                </SoftBadge>
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
};

PortfolioRowData.propTypes = {
  portfolioData: PropTypes.object.isRequired,
  dynamicCoins: PropTypes.object,
  showChart: PropTypes.bool,
  exchange: PropTypes.string,
  botList: PropTypes.array,
  forceOpenModal: PropTypes.bool,
  setForceOpenModal: PropTypes.func,
  addCapitalClickEvent: PropTypes.string,
  setAddCapitalClickEvent: PropTypes.func,
  clusterChildID: PropTypes.number
};

export default PortfolioRowData;
