import React /*, { useContext }*/ from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Tooltip, OverlayTrigger, ListGroup } from 'react-bootstrap';
// import AppContext from 'context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import { traderProTier } from 'staticData/common';

const InactiveUserRow = ({ user }) => {
  // const {
  //   config: { lang, currency }
  // } = useContext(AppContext);
  const {
    // user_id: userID,
    email,
    kyc_status: kycStatus,
    connected_exchange: connectedExchange,
    pro_tier: proTier
  } = user;

  return (
    <ListGroup.Item>
      <Row>
        <Col>
          <FontAwesomeIcon icon="at" className="me-2" size="xs" />
          {email}
        </Col>
        <Col className="d-sm-flex justify-content-end mt-2 mt-sm-0">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                {kycStatus !== null && kycStatus !== 'todo'
                  ? "La verifica dell'identità è stata completata"
                  : "È necessario effettuare la verifica dell'identità"}
              </Tooltip>
            }
          >
            <div style={{ width: 'fit-content' }}>
              <SoftBadge
                bg={
                  kycStatus !== null && kycStatus !== 'todo'
                    ? 'success'
                    : 'danger'
                }
                className="me-2"
              >
                <FontAwesomeIcon icon="user" className="me-2" />
                KYC
              </SoftBadge>
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                {connectedExchange !== null
                  ? "Il collegamento con l'exchange è stato completato"
                  : "È necessario collegare l'exchange"}
              </Tooltip>
            }
          >
            <div style={{ width: 'fit-content' }}>
              <SoftBadge
                bg={connectedExchange !== null ? 'success' : 'danger'}
                className="me-2"
              >
                <FontAwesomeIcon icon="wallet" className="me-2" />
                Binance
              </SoftBadge>
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                {proTier >= traderProTier
                  ? "L'utente ha un abbonamento attivo"
                  : "È necessario effettuare l'abbonamento"}
              </Tooltip>
            }
          >
            <div style={{ width: 'fit-content' }}>
              <SoftBadge
                bg={proTier >= traderProTier ? 'success' : 'danger'}
                className="me-2"
              >
                <FontAwesomeIcon icon="dollar-sign" className="me-2" />
                Abbonamento
              </SoftBadge>
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>{"L'utente non ha lanciato nessuna strategia"}</Tooltip>
            }
          >
            <div style={{ width: 'fit-content' }}>
              <SoftBadge bg={'danger'} className="me-2">
                <FontAwesomeIcon icon="robot" className="me-2" />
                Portfolio
              </SoftBadge>
            </div>
          </OverlayTrigger>
        </Col>
      </Row>
    </ListGroup.Item>
  );
};

InactiveUserRow.propTypes = {
  user: PropTypes.object
};

export default InactiveUserRow;
