import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CardDropdown as textLang, monthsList } from 'staticData/languages';
import AppContext from 'context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Alert, Card, Col, Row, Spinner } from 'react-bootstrap';
import DeleteBotModal from 'pages/common/modals/deleteBot';
import SuccessfullyDeleteBotModal from 'pages/common/modals/successfullyDeleteBot';
import { endpointInterfaceV2 } from 'services/endpointInterface/endpointInterfaceV2';
import SoftBadge from 'components/common/SoftBadge';
import { cleanCache } from 'services/cache';
import GeneralModal from '../modals/GeneralModal';
import IconButton from 'components/common/IconButton';
import {
  defaultPortfolio,
  portfolioList
} from 'pages/CreateBot/staticData/portfolioList';
import ButtonGrid from 'pages/CreateBot/ButtonGrid';
import checkPrimaryLight from 'assets/img/animated-icons/check-primary-light.json';
import Lottie from 'lottie-react';
import { walletImgRender } from 'services/coins/components';
import { currencyMap } from 'services/coins/common';
import { getColor } from 'helpers/utils';

const CardDropdown = ({ portfolio, managed, clusterChildID = null }) => {
  const {
    config: { lang, currency }
  } = useContext(AppContext);

  const { bot_details: bot } = portfolio;
  const managed_capital = managed;

  const { name, riskLevels, backendMinLevels, coins } =
    portfolioList.find(
      portfolio => String(portfolio.id) === String(bot.type)
    ) || defaultPortfolio;
  const riskLevel = riskLevels[backendMinLevels.indexOf(bot.min_level)];

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [successfullyDeleteBotModalShow, setSuccessfullyDeleteBotModalShow] =
    useState(false);
  const [waitDelete, setWaitDelete] = useState(false);
  const [portfolioStatsModalShow, setPortfolioStatsModalShow] = useState(false);
  const [waitChangeStatus, setWaitChangeStatus] = useState(false);
  const [changeStatusSuccess, setchangeStatusSuccess] = useState(false);
  const [redirectTimer, setRedirectTimer] = useState(null);

  const handleSetStatus = async (sell = false) => {
    setWaitDelete(true);
    let setStatusResponse = await endpointInterfaceV2({
      internalEndpoint: 'setBotStatus',
      httpMethod: 'post',
      usingCredentials: true,
      pathParams: {
        bot_id: bot.id
      },
      bodyParams: {
        status: 'TERMINATED',
        sell_all: sell
      }
    });
    setWaitDelete(false);
    if (setStatusResponse.validResponse) {
      setShowDeleteModal(false);
      setSuccessfullyDeleteBotModalShow(true);
      await cleanCache(0);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const closeSuccessfullyDeleteBotModal = () => {
    setSuccessfullyDeleteBotModalShow(false);
  };

  const botStatusColor = status => {
    if (status?.toUpperCase() === 'ACTIVE') return 'success';
    if (status?.toUpperCase() === 'ERROR') return 'danger';
    else return 'warning';
  };

  const getDate = dateStr => {
    let date = new Date(dateStr);

    let day = date.getDate();
    let month = monthsList.monthsLong[lang][date.getMonth() || 0];
    let year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  const restartPortfolio = async () => {
    setWaitChangeStatus(true);
    let setStatusResponse = await endpointInterfaceV2({
      internalEndpoint: 'setBotStatus',
      httpMethod: 'post',
      usingCredentials: true,
      pathParams: {
        bot_id: bot.id
      },
      bodyParams: {
        status: 'ACTIVE'
      }
    });
    if (setStatusResponse.validResponse) {
      setchangeStatusSuccess(true);
      await cleanCache(0);
      const timer = setTimeout(() => {
        window.location.reload();
      }, 5000);
      setRedirectTimer(timer);
    }
    setWaitChangeStatus(false);
  };

  const handleCloseClickTimer = () => {
    if (redirectTimer) {
      clearTimeout(redirectTimer);
    }
    window.location.reload();
  };

  // del timer during return
  useEffect(() => {
    return () => {
      if (redirectTimer) {
        clearTimeout(redirectTimer);
      }
    };
  }, [redirectTimer]);

  const portfolioStatsData = {
    button: {
      text: textLang.close[lang],
      variant: 'primary',
      icon: 'times',
      buttonFunction: () =>
        !changeStatusSuccess
          ? setPortfolioStatsModalShow(false)
          : handleCloseClickTimer()
    },
    head: textLang.info[lang],
    body: !changeStatusSuccess ? (
      <>
        <h5 className="mb-2">{name}</h5>
        {bot.status.toUpperCase() === 'ERROR' && bot?.status_info && (
          <Alert variant="danger">
            <p
              className="mb-0 fs--1"
              dangerouslySetInnerHTML={{
                __html: bot.status_info[lang]
              }}
            ></p>
          </Alert>
        )}
        <p className="d-flex align-items-center">
          <b>{textLang.state[lang]}</b>:{' '}
          <SoftBadge pill bg={botStatusColor(bot?.status)} className="ms-2">
            {bot?.status?.toUpperCase() === 'ACTIVE'
              ? textLang.active[lang]
              : bot?.status?.toUpperCase() === 'PAUSED'
              ? textLang.paused[lang]
              : textLang.error[lang]}
          </SoftBadge>
          {bot?.status.toUpperCase() === 'ERROR' &&
            !bot?.status_info[lang]?.startsWith('Errore 410') &&
            !bot?.status_info[lang]?.startsWith('Error 410') && (
              <IconButton
                variant={'primary'}
                className="ms-2"
                icon={waitChangeStatus ? '' : 'play'}
                disabled={waitChangeStatus}
                iconAlign="left"
                transform="shrink-3"
                onClick={() => restartPortfolio()}
              >
                {waitChangeStatus && (
                  <Spinner
                    className="me-2 mt-1"
                    style={{ width: '1rem', height: '1rem' }}
                  />
                )}
                {textLang.restart[lang]}
              </IconButton>
            )}
        </p>
        <p className="d-flex align-items-center">
          <b>{textLang.assets[lang]}</b>:{' '}
          {walletImgRender(coins, 'ms-2', true, 's', null)}
        </p>
        <p className="d-flex align-items-center">
          <b>{textLang.risks[lang]}</b>:{' '}
          <ButtonGrid
            actives={[riskLevel]}
            click={false}
            buttonSize={'sm'}
            className="ms-2"
          />
        </p>
        <p>
          <b>{textLang.creationDate[lang]}</b>:{' '}
          <span className="text-dark">
            {bot?.created_at ? getDate(bot?.created_at) : 'n.d.'}
          </span>
        </p>
        <p>
          <b>{textLang.startValue[lang]}</b>:{' '}
          <span className="text-dark">
            {currency}
            {bot.initial_eqv[currencyMap[currency]].toFixed(2) || 'n.d.'}
          </span>
        </p>
        {portfolio.added_capital != null && (
          <p>
            <b>{textLang.addedCapital[lang]}</b>:{' '}
            <span className="text-dark">
              {currency}
              {portfolio.added_capital.toFixed(2) || 'n.d.'}
            </span>
          </p>
        )}
        <p>
          <b>{textLang.underManagement[lang]}</b>:{' '}
          <span className="text-dark">
            {currency}
            {managed_capital || 'n.d.'}
          </span>
        </p>
        {portfolio?.indicators?.sharpe > 0 && (
          <p>
            <b>Sharpe Ratio</b>:{' '}
            <span className="text-dark">{portfolio?.indicators?.sharpe}</span>
          </p>
        )}
      </>
    ) : (
      <Card.Body>
        <Row className="align-items-center">
          <Col lg={12} className="d-flex justify-content-center">
            <Lottie
              animationData={checkPrimaryLight}
              loop={true}
              style={{ height: '120px', width: '120px' }}
            />
          </Col>
        </Row>
        <h4
          className="text-center font-weight-bold"
          dangerouslySetInnerHTML={{
            __html: textLang.changeStatusText[lang]
          }}
        ></h4>
      </Card.Body>
    )
  };

  return (
    bot && (
      <>
        {/* PORTFOLIO Statistics */}
        <GeneralModal
          showModal={portfolioStatsModalShow}
          setShowModal={
            !changeStatusSuccess
              ? () => setPortfolioStatsModalShow()
              : () => handleCloseClickTimer()
          }
          button={portfolioStatsData.button}
          head={portfolioStatsData.head}
          body={portfolioStatsData.body}
        />
        {/* Delete BOT */}
        <DeleteBotModal
          deleteBotModalShow={showDeleteModal}
          setDeleteBotModalShow={setShowDeleteModal}
          confirmBotDeletion={handleSetStatus}
          bot={bot}
          waitDelete={waitDelete}
        />
        <SuccessfullyDeleteBotModal
          successfullyDeleteBotModalShow={successfullyDeleteBotModalShow}
          closeSuccessfullyDeleteBotModal={closeSuccessfullyDeleteBotModal}
          type={'portfolio'}
        />
        <Dropdown
          className="font-sans-serif btn-reveal-trigger position-relative"
          align="start"
        >
          {bot.status.toUpperCase() === 'ERROR' && bot?.status_info && (
            <FontAwesomeIcon
              icon={'exclamation'}
              className="position-absolute"
              style={{
                left: '7px',
                top: '7px',
                fontSize: '0.7em',
                color: getColor('danger')
              }}
            />
          )}
          <Dropdown.Toggle
            variant="link"
            size="sm"
            data-boundary="viewport"
            className="text-600"
          >
            <FontAwesomeIcon icon="ellipsis-h" className="fs--2" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="border py-0">
            <div className="py-2">
              <Dropdown.Item onClick={() => setPortfolioStatsModalShow(true)}>
                {bot.status.toUpperCase() === 'ERROR' && bot?.status_info && (
                  <FontAwesomeIcon
                    icon={'exclamation'}
                    className="me-2"
                    style={{
                      color: getColor('danger')
                    }}
                  />
                )}
                {textLang.info[lang]}
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                className="text-danger"
                onClick={() => setShowDeleteModal(true)}
                disabled={clusterChildID}
                style={clusterChildID ? { opacity: 0.5 } : {}}
              >
                {textLang.delete[lang]}
              </Dropdown.Item>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </>
    )
  );
};

CardDropdown.propTypes = {
  portfolio: PropTypes.object,
  managed: PropTypes.number,
  clusterChildID: PropTypes.number
};

export default CardDropdown;
