import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import {
  defaultPortfolio,
  portfolioList as portList
} from 'pages/CreateBot/staticData/portfolioList';
import 'assets/scss/custom/addCapital.scss';
import PacSection from 'pages/Dashboard/modals/addCapital/PacSection';

const StartPac = ({
  addCapitalModalShow,
  setAddCapitalModalShow,
  addCapitalBotList,
  dynamicCoins
}) => {
  const [portfolioListItems, setPortfolioListItems] = useState([]);

  const handleCloseModal = () => {
    setAddCapitalModalShow(false);
  };

  useEffect(() => {
    if (addCapitalBotList.length) {
      let localPortfolioList = addCapitalBotList.filter(function (item) {
        return item.bot_details?.category == 'portfolio';
      });
      localPortfolioList = localPortfolioList.map(bot => {
        const { name, bgStyle, riskLevels, backendMinLevels } =
          portList.find(X => String(X.id) === String(bot.bot_details.type)) ||
          defaultPortfolio;
        const riskLevel = riskLevels[backendMinLevels.indexOf(bot.min_level)];
        bot.name = name;
        bot.riskLevel = riskLevel;
        bot.bgStyle = bgStyle;
        return bot;
      });
      setPortfolioListItems(localPortfolioList);
    }
  }, [addCapitalBotList]);

  return (
    <>
      <Modal
        show={addCapitalModalShow}
        onHide={handleCloseModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title id="contained-modal-title-vcenter">
            Imposta un aumento ricorrente
          </Modal.Title>
        </Modal.Header>
        <PacSection
          portfolioList={portfolioListItems}
          dynamicCoins={dynamicCoins}
          startPage={1}
        />
      </Modal>
    </>
  );
};

StartPac.propTypes = {
  addCapitalModalShow: PropTypes.bool.isRequired,
  setAddCapitalModalShow: PropTypes.func.isRequired,
  addCapitalBotList: PropTypes.array.isRequired,
  dynamicCoins: PropTypes.object
};

export default StartPac;
