import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Modal, Spinner } from 'react-bootstrap';
import AppContext from 'context/Context';
import { Pricing as textLang } from 'staticData/languages';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import { StripeEmbedded } from 'services/externals/stripe/components';
import Flex from 'components/common/Flex';
import { breakpoints } from 'helpers/utils';

const PrivatePaymentModal = ({ showModal, setShowModal }) => {
  const {
    config: { lang }
  } = useContext(AppContext);

  const [checkoutSession, setCheckoutSession] = useState('');
  const [errorSub, setErrorSub] = useState(false);

  const createSubscriptionTrader = async () => {
    let form = new FormData();
    form.append('tolt_referral', window.tolt_referral || '');
    form.append('success_url', '#');
    form.append('embedded', true);
    const resp = await endpointInterface(
      lang,
      'backend',
      'createTraderSubscription',
      'post',
      true,
      form
    );
    if (resp.responseStatus !== 200) {
      setErrorSub(true);
      return;
    }
    setCheckoutSession(resp.data.client_secret);
  };

  const handleHideModal = () => {
    setShowModal(false);
    setCheckoutSession('');
  };

  useEffect(() => {
    if (showModal && !checkoutSession) createSubscriptionTrader();
  }, [showModal]);

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => handleHideModal()}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton closeVariant="white">
          <h5>Effettua l'abbonamento</h5>
        </Modal.Header>
        {!checkoutSession ? (
          <Modal.Body>
            {!errorSub ? (
              <Flex className="mt-4 justify-content-center">
                <Spinner />
              </Flex>
            ) : (
              <Row>
                <Col className="h-100">
                  <h5>{textLang.error[lang]}</h5>
                </Col>
              </Row>
            )}
          </Modal.Body>
        ) : (
          <Modal.Body
            style={
              window.innerWidth > breakpoints['sm']
                ? { minHeight: '700px' }
                : {}
            }
          >
            <StripeEmbedded
              checkoutSession={checkoutSession}
              style={
                window.innerWidth > breakpoints['sm']
                  ? { minWidth: '992px' }
                  : {}
              }
            />
          </Modal.Body>
        )}
      </Modal>
    </>
  );
};

PrivatePaymentModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func
};

export default PrivatePaymentModal;
